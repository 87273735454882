import {
  StripeCustomer,
  StripeInvoice,
  // StripePaymentEvent,
  StripePaymentMethod,
  StripeSubscription,
  StripePlan,
  StripeProduct
} from '@/types/index';

export type State = {
  stripeCustomer: StripeCustomer[],
  stripeInvoice: StripeInvoice[],
  // stripePaymentEvent: StripePaymentEvent[],
  stripePaymentMethod: StripePaymentMethod[],
  stripeSubscription: StripeSubscription[],
  stripePlan: StripePlan | null,
  stripeProduct: StripeProduct | null
};

export const state: State = {
  stripeCustomer: [],
  stripeInvoice: [],
  // stripePaymentEvent: [],
  stripePaymentMethod: [],
  stripeSubscription: [],
  stripePlan: null,
  stripeProduct: null
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): State => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};