import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { PaymentActionTypes } from "./action-types";
import { Config } from "../../../config";
import { Mutations } from "./mutations";
import { PaymentsMutationTypes } from "./mutation-types";
import { HttpClient as http, AxiosError } from "@/util/ZebritAxios";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [PaymentActionTypes.LOAD_STRIPECUSTOMER_ACTION]({ commit }: AugmentedActionContext): void;
  [PaymentActionTypes.LOAD_STRIPEINVOICE_ACTION]({ commit }: AugmentedActionContext): void;
  [PaymentActionTypes.LOAD_STRIPEPAYMENTMETHOD_ACTION]({ commit }: AugmentedActionContext): void;
  [PaymentActionTypes.LOAD_STRIPESUBSCRIPTION_ACTION]({ commit }: AugmentedActionContext): void;
  [PaymentActionTypes.LOAD_STRIPEPLANANDPRODUCT_ACTION]({ commit }: AugmentedActionContext, payload: { subscriptionID: string; }): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [PaymentActionTypes.LOAD_STRIPECUSTOMER_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.payment.customer)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(PaymentsMutationTypes.SET_STRIPECUSTOMER, []);
            resolve(response);
          } else {
            commit(PaymentsMutationTypes.SET_STRIPECUSTOMER, response.data.docs);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async [PaymentActionTypes.LOAD_STRIPEINVOICE_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.payment.invoice)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(PaymentsMutationTypes.SET_STRIPEINVOICE, []);
            resolve(response);
          } else {
            commit(PaymentsMutationTypes.SET_STRIPEINVOICE, response.data.docs);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async [PaymentActionTypes.LOAD_STRIPEPAYMENTMETHOD_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.payment.paymentMethod)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(PaymentsMutationTypes.SET_STRIPEPAYMENTMETHOD, []);
            resolve(response);
          } else {
            commit(PaymentsMutationTypes.SET_STRIPEPAYMENTMETHOD, response.data.docs);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async [PaymentActionTypes.LOAD_STRIPESUBSCRIPTION_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.payment.subscription)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(PaymentsMutationTypes.SET_STRIPESUBSCRIPTION, []);
            resolve(response);
          } else {
            commit(PaymentsMutationTypes.SET_STRIPESUBSCRIPTION, response.data.docs);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async [PaymentActionTypes.LOAD_STRIPEPLANANDPRODUCT_ACTION]({ commit }, payload: { subscriptionID: string; }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.stripe.plan.replace('{id}', payload.subscriptionID))
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(PaymentsMutationTypes.SET_STRIPEPLAN, null);
            commit(PaymentsMutationTypes.SET_STRIPEPRODUCT, null);
            resolve(response);
          } else {
            commit(PaymentsMutationTypes.SET_STRIPEPLAN, response.data.plan);
            commit(PaymentsMutationTypes.SET_STRIPEPRODUCT, response.data.product);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

};
