import { FieldDef } from './FieldDef';

const OSortDirection = {
    ASC: "asc",
    DESC: "desc"
} as const;
type SortDirectionType = typeof OSortDirection[keyof typeof OSortDirection];

class SortDef {
    position: number;
    field: FieldDef;
    direction: SortDirectionType;

    constructor(
        position: number,
        field: FieldDef,
        direction: SortDirectionType
    ) {
        this.position = position
        this.field = field
        this.direction = direction
    }

    static fromObject(obj: any): SortDef {
        return new SortDef(
            obj.position,
            obj.field,
            obj.direction
        )
    }
}

export {
    SortDef,
    OSortDirection,
    SortDirectionType
}
