/* eslint-disable no-unused-vars */
class ErrorValidation {
  source: string | null;
  keys: Array<string> | null;
  messag: string | null;

  constructor(
    source: string | null,
    keys: Array<string> | null,
    messag: string | null
  ) {
    this.source = source;
    this.keys = keys;
    this.messag = messag;
  }

  static fromObject(obj: any): ErrorValidation {
    return new ErrorValidation(obj.source, obj.keys, obj.message);
  }

  static getDefaultObject(): ErrorValidation {
    return ErrorValidation.fromObject({
      source: null,
      keys: null,
      message: null
    });
  }
}

export { ErrorValidation };
