import {
  ActionTree,
} from 'vuex';
import { RootState } from '@/store';
import { State } from './state';
// import { NavActionTypes } from './action-types';
// import axios from 'axios';
// import { axios, AxiosRequestConfig } from "@/util/ZebritAxios";
// import { Config } from '../../../config';
// import { Mutations } from './mutations';
// import { NavMutationTypes } from './mutation-types';
// import ZebritAxios from '../../../util/ZebritAxios';
// import { User } from '@/types/index';
// import { LoginBody, LoginResponse } from '@/types/LoginTypes'

// type AugmentedActionContext = {
//   commit<K extends keyof Mutations>(
//     key: K,
//     payload: Parameters<Mutations[K]>[1],
//   ): ReturnType<Mutations[K]>
// } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions { }

export const actions: ActionTree<State, RootState> & Actions = {}
