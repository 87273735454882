import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';
import { User } from '@/types/index';

export type Getters = {
  StateUsers(state: State): User[],
  StateIsLoadingUsers(state: State): boolean,
  StateIsUsersLoaded(state: State): boolean,
}

export const getters: GetterTree<State, RootState> & Getters = {
  StateUsers: state => state.users,
  StateIsLoadingUsers: state => state.isLoadingUsers,
  StateIsUsersLoaded: state => state.isUsersLoaded
};
