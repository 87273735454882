import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { ChatBotMessage, User, ChatBotSettings } from '@/types/index';
import { ChatBotState as State } from "./state";

export type ChatBotGetters = {
  StateChatBotIsOpen(state: State): Boolean;
  StateChatBotMessages(state: State): ChatBotMessage[];
  StateChatBotIsTyping(state: State): Boolean;
  StateChatBotUser(state: State): User | null;

  StateChatBotSettings( state: State ): ChatBotSettings;
  StateIsLoadingChatBotSettings( state: State ): boolean;
  StateChatBotSettingsLoaded( state: State ): boolean;
  StateIsSavingChatBotSettings( state: State ): boolean;
};

export const getters: GetterTree<State, RootState> & ChatBotGetters = {
  StateChatBotMessages: (state: State) => state.chatBotMessages,
  StateChatBotIsOpen: (state: State) => state.chatBotOpenState,
  StateChatBotIsTyping: (state: State) => state.chatBotIsTyping,
  StateChatBotUser: (state: State) => state.chatBotUser,

  StateChatBotSettings: ( state: State ) => state.chatBotSettings,
  StateIsLoadingChatBotSettings: ( state: State ) => state.isLoadingChatBotSettings,
  StateChatBotSettingsLoaded: ( state: State ) => state.isChatBotSettingsLoaded,
  StateIsSavingChatBotSettings: ( state: State ) => state.isSavingChatBotSettings,
};
