class Address {
  country: string;
  city: string;
  streetAddressLine1: string;
  streetAddressLine2?: string;
  postalCode?: string;

  constructor(
    country: string,
    city: string,
    streetAddressLine1: string,
    streetAddressLine2?: string,
    postalCode?: string
  ) {
    this.country = country
    this.city = city
    this.streetAddressLine1 = streetAddressLine1
    this.streetAddressLine2 = streetAddressLine2
    this.postalCode = postalCode
  }

  static fromObject(obj: any): Address {
    return new Address(
      obj.country,
      obj.city,
      obj?.streetAddressLine1,
      obj?.streetAddressLine2,
      obj?.postalCode
    )
  }
}

export {
  Address
}
