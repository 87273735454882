import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-7xl mx-auto py-1 px-4 overflow-hidden sm:px-6 lg:px-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLogoMonoColor = _resolveComponent("AppLogoMonoColor")!
  const _component_AppLogo = _resolveComponent("AppLogo")!

  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass([_ctx.theme === 'green' ? 'bg-themeprimary-500' : 'bg-gray-100 dark:bg-gray-800'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", {
        class: _normalizeClass([
          'my-4',
          'text-center text-xs',
          _ctx.theme === 'green' ? 'text-white' : 'text-gray-400 dark:text-gray-600',
          'space-y-2 space-x-0',
          'flex',
          'justify-center',
          'flex-col-reverse',
          'lg:flex-row lg:space-y-0 lg:space-x-2',
        ])
      }, [
        (_ctx.theme === 'green')
          ? (_openBlock(), _createBlock(_component_AppLogoMonoColor, {
              key: 0,
              class: "inline-block h-5 w-auto text-white dark:text-gray-900"
            }))
          : (_openBlock(), _createBlock(_component_AppLogo, {
              key: 1,
              class: "inline-block h-5 w-auto"
            })),
        _createElementVNode("span", {
          class: _normalizeClass(['py-1 px-2', (_ctx.theme === 'green' ? 'text-white dark:text-gray-900' : '') ])
        }, " © 2022 ZEBRIT All rights reserved", 2),
        _createElementVNode("span", {
          class: _normalizeClass([
            'py-1 px-2 block text-balance rounded-md text-xs text-gray-100 dark:text-gray-800',
            _ctx.theme === 'green' ? '' : 'bg-gray-400 dark:bg-gray-600',
          ])
        }, " App " + _toDisplayString(_ctx.version) + " | API " + _toDisplayString(_ctx.apiversion), 3)
      ], 2)
    ])
  ], 2))
}