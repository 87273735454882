// import { omit } from 'lodash';
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { UserActionTypes } from "./action-types";
// import axios, { AxiosResponse, AxiosError } from 'axios';
import { Config } from "../../../config";
import { Mutations } from "./mutations";
import { UserMutationTypes } from "./mutation-types";
import { HttpClient as http, AxiosError } from "@/util/ZebritAxios";
// import { User } from "@/types/index";
// import { LoginBody, LoginResponse } from "@/types/LoginTypes";
// import { AuthActionTypes } from "@/store/modules/auth/action-types";
// import { UserMutationTypes } from "@/store/modules/auth/mutation-types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [UserActionTypes.LOAD_USERS_ACTION]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [UserActionTypes.LOAD_USERS_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.user.users)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(UserMutationTypes.SET_IS_LOADING_USERS, false);
            if (response.data) {
              // commit(UserMutationTypes.SET_LOGIN_ERROR, response.data.error);
              // commit(UserMutationTypes.SET_LOGIN_ERROR_MESSAGE, response.data.message);
            } else if (response.isAxiosError) {
              // commit(UserMutationTypes.SET_LOGIN_ERROR, 0);
              // commit(UserMutationTypes.SET_LOGIN_ERROR_MESSAGE, response.message);
            }
            commit(UserMutationTypes.SET_USERS, []);
            resolve(response);
          } else {
            commit(UserMutationTypes.SET_USERS, response.data);
            resolve(response);
          }
        })
        .catch((err:AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

};
