import { Address } from './base/Address';
import { Picture } from './base/Picture';
import { FieldDef, OFieldType, OActionType, FieldAction } from "./FieldDef";
import { FilterDef, OFilterType, OOptionsType } from "./FilterDef";
// import { SortDef } from "./SortDef";


class OrganisationLogo extends Picture {
  data?: string;
  constructor(
    url: string,
    id?: number | undefined | string | readonly string[],
    name?: string | undefined,
    mime?: string | undefined,
    width?: string | undefined,
    height?: string | undefined,
    thumbnail?: Picture | undefined,
    alt?: string | undefined,
    title?: string | undefined,
    data?: string | undefined
  ) {
    super(
      url,
      id,
      name,
      mime,
      width,
      height,
      thumbnail,
      alt,
      title
    );
    this.data = data
  }

  static fromObject(obj: any): OrganisationLogo {
    if (obj === undefined)
      return new OrganisationLogo(
        '#'
      )
    return new OrganisationLogo(
      obj.url,
      obj.id,
      obj.name,
      obj.mime,
      obj.width,
      obj.height,
      obj.thumbnail,
      obj.alt,
      obj.title,
      obj.data
    )
  }
}

class OrganisationSupportEmailIMAP {
  _id: number | string | readonly string[];
  supportEmail: string;
  supportEmailPassword: string;
  supportEmailIMAPHost: string;
  supportEmailIMAPPort: string;
  supportEmailIMAPUseTLS: boolean;
  supportEmailSMTPPassword: string;
  supportEmailSMTPHost: string;
  supportEmailSMTPPort: string;
  supportEmailSMTPUseTLS: boolean;
  
  constructor(
    _id: number | string | readonly string[],
    supportEmail: string,
    supportEmailPassword: string,
    supportEmailIMAPHost: string,
    supportEmailIMAPPort: string,
    supportEmailIMAPUseTLS: boolean,
    supportEmailSMTPPassword: string,
    supportEmailSMTPHost: string,
    supportEmailSMTPPort: string,
    supportEmailSMTPUseTLS: boolean
  ) {
    this._id = _id
    this.supportEmail = supportEmail
    this.supportEmailPassword = supportEmailPassword
    this.supportEmailIMAPHost = supportEmailIMAPHost
    this.supportEmailIMAPPort = supportEmailIMAPPort
    this.supportEmailIMAPUseTLS = supportEmailIMAPUseTLS
    this.supportEmailSMTPPassword = supportEmailSMTPPassword
    this.supportEmailSMTPHost = supportEmailSMTPHost
    this.supportEmailSMTPPort = supportEmailSMTPPort
    this.supportEmailSMTPUseTLS = supportEmailSMTPUseTLS
  }

  static fromObject(obj: any): OrganisationSupportEmailIMAP {
    return new OrganisationSupportEmailIMAP(
      obj._id,
      obj?.supportEmail,
      obj?.supportEmailPassword,
      obj?.supportEmailIMAPHost,
      obj?.supportEmailIMAPPort,
      obj?.supportEmailIMAPUseTLS,
      obj?.supportEmailSMTPPassword,
      obj?.supportEmailSMTPHost,
      obj?.supportEmailSMTPPort,
      obj?.supportEmailSMTPUseTLS
    )
  }

  static getDefaultObject(): OrganisationSupportEmailIMAP {
    return OrganisationSupportEmailIMAP.fromObject({
      _id: -1,
      supportEmailIMAPUseTLS: true,
      supportEmailSMTPUseTLS: true
    })
  }
}

class Organisation {
  _id: number | string | readonly string[];
  name: string;
  emailDomain: string;
  supportEmail: string;
  supportEmailPassword: string;
  supportEmailIMAPHost: string;
  supportEmailIMAPPort: string;
  supportEmailIMAPUseTLS: boolean;
  supportEmailSMTPPassword: string;
  supportEmailSMTPHost: string;
  supportEmailSMTPPort: string;
  supportEmailSMTPUseTLS: boolean;
  code?: string;
  version?: number;
  phone?: string;
  adminemail? : string;
  accountnumber? : string;
  website?: string;
  address?: Address;
  logo?: string;
  logoUrl?: string;
  type?: string;
  registrationNumber?: string;
  vat?: string;
  constructor(
    _id: number | string | readonly string[],
    name: string,
    emailDomain: string,
    supportEmail: string,
    supportEmailPassword: string,
    supportEmailIMAPHost: string,
    supportEmailIMAPPort: string,
    supportEmailIMAPUseTLS: boolean,
    supportEmailSMTPPassword: string,
    supportEmailSMTPHost: string,
    supportEmailSMTPPort: string,
    supportEmailSMTPUseTLS: boolean,
    code?: string,
    version?: number,
    phone?: string,
    adminemail? : string,
    accountnumber?: string,
    website?: string,
    address?: Address,
    logo?: string,
    logoUrl?: string,
    type?: string,
    registrationNumber?: string,
    vat?: string,
  ) {
    this._id = _id
    this.name = name
    this.emailDomain = emailDomain
    this.supportEmail = supportEmail
    this.supportEmailPassword = supportEmailPassword
    this.supportEmailIMAPHost = supportEmailIMAPHost
    this.supportEmailIMAPPort = supportEmailIMAPPort
    this.supportEmailIMAPUseTLS = supportEmailIMAPUseTLS
    this.supportEmailSMTPPassword = supportEmailSMTPPassword
    this.supportEmailSMTPHost = supportEmailSMTPHost
    this.supportEmailSMTPPort = supportEmailSMTPPort
    this.supportEmailSMTPUseTLS = supportEmailSMTPUseTLS
    this.code = code
    this.version = version
    this.phone = phone
    this.adminemail = adminemail
    this.accountnumber = accountnumber
    this.website = website
    this.address = address
    this.logo = logo
    this.logoUrl = logoUrl
    this.type = type
    this.registrationNumber = registrationNumber
    this.vat = vat
  }

  static fromObject(obj: any): Organisation {
    return new Organisation(
      obj._id,
      obj.name,
      obj.emailDomain,
      obj?.supportEmail,
      obj?.supportEmailPassword,
      obj?.supportEmailIMAPHost,
      obj?.supportEmailIMAPPort,
      obj?.supportEmailIMAPUseTLS,
      obj?.supportEmailSMTPPassword,
      obj?.supportEmailSMTPHost,
      obj?.supportEmailSMTPPort,
      obj?.supportEmailSMTPUseTLS,
      obj?.code,
      obj?.version,
      obj?.phone,
      obj?.adminemail,
      obj?.accountnumber,
      obj?.website,
      obj?.address ? Address.fromObject(obj.address) : undefined,
      obj?.logo,
      obj?.logoUrl,
      obj?.type,
      obj?.registrationNumber,
      obj?.vat
    )
  }

  static getDefaultObject(): Organisation {
    return Organisation.fromObject({
      _id: -1,
      name: '',
      emailDomain: ''
    })
  }
}

const UserListFilters = [
  FilterDef.fromObject({
    id: 'firstName',
    queryParam: 'firstname',
    title: 'First Name',
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: ''
  }),
  FilterDef.fromObject({
    id: 'lastName',
    queryParam: 'lastname',
    title: 'Last Name',
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: ''
  }),
  FilterDef.fromObject({
    id: 'group',
    queryParam: 'group',
    title: 'Group',
    type: OFilterType.Enum,
    optionsType: OOptionsType.Group,
    options: [],
    value: ''
  }),
  FilterDef.fromObject({
    id: 'role',
    queryParam: 'role',
    title: 'Role',
    type: OFilterType.Enum,
    optionsType: OOptionsType.Role,
    options: [],
    value: ''
  })
];

const GroupListFilters = [
  FilterDef.fromObject({
    id: 'firstName',
    queryParam: 'firstname',
    title: 'First Name',
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: ''
  }),
  FilterDef.fromObject({
    id: 'lastName',
    queryParam: 'lastname',
    title: 'Last Name',
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: ''
  }),
  FilterDef.fromObject({
    id: 'group',
    queryParam: 'group',
    title: 'Group',
    type: OFilterType.Enum,
    optionsType: OOptionsType.Group,
    options: [],
    value: ''
  }),
  FilterDef.fromObject({
    id: 'role',
    queryParam: 'role',
    title: 'Role',
    type: OFilterType.Enum,
    optionsType: OOptionsType.Role,
    options: [],
    value: ''
  })
];

const RoleListFilters = [
  FilterDef.fromObject({
    id: 'firstName',
    queryParam: 'firstname',
    title: 'First Name',
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: ''
  }),
  FilterDef.fromObject({
    id: 'lastName',
    queryParam: 'lastname',
    title: 'Last Name',
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: ''
  }),
  FilterDef.fromObject({
    id: 'group',
    queryParam: 'group',
    title: 'Group',
    type: OFilterType.Enum,
    optionsType: OOptionsType.Group,
    options: [],
    value: ''
  }),
  FilterDef.fromObject({
    id: 'role',
    queryParam: 'role',
    title: 'Role',
    type: OFilterType.Enum,
    optionsType: OOptionsType.Role,
    options: [],
    value: ''
  })
];

const UserListFields = [
  FieldDef.fromObject({
    _id: -1,
    title: "ID",
    available: true,
    show: false,
    type: OFieldType.id,
    sortable: false,
    searchable: false,
    actions: [
      // FieldAction.fromObject({
      //   id: 'view',
      //   text: 'View',
      //   icon: null,
      //   type: OActionType.view,
      //   color: "themeprimary"

      // })
    ]
    // [OActionType.view],
  }),
  FieldDef.fromObject({
    id: "avatarUrl",
    title: "Avatar",
    available: true,
    show: true,
    type: OFieldType.Avatar,
    sortable: false,
    searchable: false,
    actions: [
      // FieldAction.fromObject({
      //   id: 'edit',
      //   text: 'Edit',
      //   icon: null,
      //   type: OActionType.edit,
      //   color: "themeprimary"

      // })
    ]
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "firstName",
    title: "First Name",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: 'details',
        text: 'Details',
        icon: null,
        type: OActionType.view,
        color: "themeprimary"

      })
    ]
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "lastName",
    title: "Last Name",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      // FieldAction.fromObject({
      //   id: 'edit',
      //   text: 'Edit',
      //   icon: null,
      //   type: OActionType.edit,
      //   color: "themeprimary"

      // })
    ]
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "email",
    title: "Email",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      // FieldAction.fromObject({
      //   id: 'edit',
      //   text: 'Edit',
      //   icon: null,
      //   type: OActionType.edit,
      //   color: "themeprimary"

      // })
    ]
    //[OActionType.edit],
  }),
  // FieldDef.fromObject({
  //   id: "organisation",
  //   title: "Organisation",
  //   available: true,
  //   show: true,
  //   type: OFieldType.String,
  //   actions: [
  //     FieldAction.fromObject({
  //       id: 'edit',
  //       text: 'Edit',
  //       icon: null,
  //       type: OActionType.edit,
  //       color: "themeprimary"

  //     })
  //   ]
  //   //[OActionType.edit],
  // }),
  // FieldDef.fromObject({
  //   id: "purchaseDate",
  //   title: "Purchase Date",
  //   available: true,
  //   show: true,
  //   type: OFieldType.DateTime,
  //   actions: [],
  // }),
  // FieldDef.fromObject({
  //   id: "group",
  //   title: "Group",
  //   available: true,
  //   show: true,
  //   type: OFieldType.String,
  //   sortable: true,
  //   actions: [],
  // }),
  FieldDef.fromObject({
    id: "role",
    title: "Role",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  
  FieldDef.fromObject({
    id: "isActive",
    title: "Active",
    available: true,
    show: false,
    type: OFieldType.Boolean,
    sortable: false,
    searchable: false,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "isEnabled",
    title: "Enabled",
    available: true,
    show: false,
    type: OFieldType.Boolean,
    sortable: false,
    searchable: false,
    actions: [],
  }),

  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    sortable: false,
    searchable: false,
    //actions: [OActionType.view, OActionType.edit],
    actions: [
      FieldAction.fromObject({
        id: 'details',
        text: 'Details',
        icon: null,
        type: OActionType.view,
        color: "themeprimary"
      }),
      FieldAction.fromObject({
        id: 'delete',
        text: 'Delete',
        icon: null,
        type: OActionType.delete,
        color: "red"
      })
    ]
    //  [OActionType.edit, OActionType.delete],
  })
];










const GroupListFields = [
  FieldDef.fromObject({
    id: "id",
    title: "ID",
    available: true,
    show: false,
    type: OFieldType.id,
    sortable: false,
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: 'details',
        text: 'Details',
        icon: null,
        type: OActionType.view,
        color: "themeprimary"

      })
    ]
    // [OActionType.view],
  }),
  FieldDef.fromObject({
    id: "name",
    title: "Name",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: 'details',
        text: 'Details',
        icon: null,
        type: OActionType.view,
        color: "themeprimary"

      })
    ]
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    sortable: false,
    searchable: false,
    //actions: [OActionType.view, OActionType.edit],
    actions: [
      FieldAction.fromObject({
        id: 'details',
        text: 'Details',
        icon: null,
        type: OActionType.view,
        color: "themeprimary"
      }),
      FieldAction.fromObject({
        id: 'delete',
        text: 'Delete',
        icon: null,
        type: OActionType.delete,
        color: "red"
      })
    ]
    //  [OActionType.edit, OActionType.delete],
  })
];
















const RoleListFields = [
  FieldDef.fromObject({
    id: "key",
    title: "KEY",
    available: true,
    show: false,
    type: OFieldType.id,
    sortable: false,
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: 'details',
        text: 'Details',
        icon: null,
        type: OActionType.view,
        color: "themeprimary"

      })
    ]
    // [OActionType.view],
  }),
  FieldDef.fromObject({
    id: "name",
    title: "Name",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: 'details',
        text: 'Details',
        icon: null,
        type: OActionType.view,
        color: "themeprimary"

      })
    ]
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    sortable: false,
    searchable: false,
    //actions: [OActionType.view, OActionType.edit],
    actions: [
      FieldAction.fromObject({
        id: 'details',
        text: 'Details',
        icon: null,
        type: OActionType.view,
        color: "themeprimary"
      }),
      FieldAction.fromObject({
        id: 'delete',
        text: 'Delete',
        icon: null,
        type: OActionType.delete,
        color: "red"
      })
    ]
    //  [OActionType.edit, OActionType.delete],
  })
];

// id: number;
// userName: string;
// password: string;
// email: string;
// firstName: string;
// lastName: string;
// groups: Array<Group>;
// organisation: Organisation;
// role: string;
// avatar: string;
// access: Permission[];
// mobilePhonePrefix: number;
// mobilePhone: number;
// officePhonePrefix: number;
// officePhone: number;




export {
  Organisation,
  OrganisationSupportEmailIMAP,
  OrganisationLogo,
  UserListFilters,
  UserListFields,
  GroupListFilters,
  GroupListFields,
  RoleListFilters,
  RoleListFields
}
