// import Constants from "../Constants";

class Document {
  _id: string;
  url: string;
  contains: string;
  mimeType: string;

  constructor (_id:string, url: string, contains: string, mimeType: string) {
    this._id = _id
    this.url = url
    this.contains = contains
    this.mimeType = mimeType
  }
}

export {
  Document
}
