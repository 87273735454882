class Group {
  id: number | string | readonly string[];
  name: string;
  description: string;
  constructor(
    id: number | string | readonly string[],
    name: string,
    description: string
  ) {
    this.id = id
    this.name = name
    this.description = description
  }

  static fromObject(obj: any): Group {
    return new Group(
      obj.id,
      obj.name,
      obj.description
    )
  }

  static getDefaultObject(): Group {
    return Group.fromObject({
      id: -1,
      name: 'Group Name',
      description: 'Group Description'
    })
  }
}

class GroupDTO {
  id: number | string | readonly string[];
  name: string;
  constructor(
    id: number | string | readonly string[],
    name: string
  ) {
    this.id = id
    this.name = name
  }

  static fromObject(obj: any): GroupDTO {
    return new GroupDTO(
      obj.id,
      obj.name
    )
  }

  static getDefaultObject(): GroupDTO {
    return GroupDTO.fromObject({
      id: -1,
      name: 'Group Name'
    })
  }
}

export {
  Group,
  GroupDTO
}
