import { Error } from '@/types/Error';
let defaultMode;
export type ThemeModes = 'dark' | 'light' | 'notset';
if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
  defaultMode = <ThemeModes>'dark'
} else {
  defaultMode = <ThemeModes>'light'
}

export type AppState = {
  mode: ThemeModes,
  errors: Error[],
  apiVersion: string
}

export const state: AppState = {
  mode: defaultMode,
  errors: [],
  apiVersion: ''
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): AppState => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};