/* eslint-disable no-unused-vars */
const OErrorSeverityType = {
  Error: "error",
  Warning: "warning",
  Info: "info",
  Success: "success",
};

type ErrorSeverityType = keyof typeof OErrorSeverityType;

class Error {
  message: string;
  severity: ErrorSeverityType;
  title: string | null;
  code: string | null;

  constructor(
    message: string,
    severity: ErrorSeverityType,
    title: string | null,
    code: string | null
  ) {
    this.message = message;
    this.title = title;
    this.code = code;
    this.severity = severity;
  }

  static fromObject(obj: any): Error {
    return new Error(obj.message, obj.severity, obj.title, obj.code);
  }

  static getDefaultObject(): Error {
    return Error.fromObject({
      message: "",
      severity: OErrorSeverityType.Error,
      title: null,
      code: null
    });
  }
}

export { Error, OErrorSeverityType, ErrorSeverityType };
