import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';

export type Getters = {
  sidebarOpen(state: State): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
  sidebarOpen: state => state.sidebarOpen
};
