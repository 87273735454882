// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum AuthMutationTypes {
  RESET = "reset",
  SET_IS_AUTHENTICATED = "setIsAuthenticated",
  // SET_SETACCESSTOKEN = "setAccessToken",
  SET_SETREFRESHTOKEN = "setRefreshToken",
  SET_LOGIN_ERROR = "setLoginError",
  SET_LOGIN_ERROR_MESSAGE = "setLoginErrorMessage",
  SET_REGISTER_ERROR = "setRegisterError",
  SET_REGISTER_ERROR_MESSAGE = "setRegisterErrorMessage",
  SET_USER = "setUser",
  SET_USER_LOADED = "setUserLoaded",
  SET_LOADING_USER = "setLoadingUser",

  SET_SAVING_USER = "setSavingUser",
  SET_USER_SAVED = "setUserSaved",
  SET_USER_SAVE_ERROR = "setUserSaveError",
  SET_USER_SAVE_ERROR_MESSAGE = "setUserSaveErrorMessage",

  SET_VERIFIED = "setVerified",
  SET_NOORGANISATION = "setNoOrganisation",
  SET_HASSUBSCRIPTION = "setHasSubscription",
  // SET_NOTIFICATIONS = "setNotifications",

  SET_ACCESS_TOKEN = "setAccessToken",
  SET_REFRESH_TOKEN = "setRefreshToken",
  SET_SCOPE = "setScope",
  CLEAR_ACCESS_TOKEN = "clearAccessToken",
  CLEAR_REFRESH_TOKEN = "clearRefreshToken",
  CLEAR_SCOPE = "clearScope",
  CLEAR_USER = "clearUser",



  LOGOUT = "LogOut",

  SET_FORGOT_PASS_SUCCESS = "SetForgotPassSuccess",
  SET_FORGOT_PASS_SENDING_REQUEST = "SetForgotPassSendingRequest",
  SET_FORGOT_PASS_ERROR_MESSAGE = "SetForgotPassErrorMessage",

  SET_RESET_PASS_SUCCESS = "SetResetPassSuccess",
  SET_RESET_PASS_SENDING_REQUEST = "SetResetPassSendingRequest",
  SET_RESET_PASS_ERROR_MESSAGE = "SetResetPassErrorMessage"
}
