import { MutationTree } from "vuex";
import {
  ChatBotMessage,
  ChatBotSettings,
  User,
} from "@/types/index";
import { ChatBotState as State } from './state';
import { ChatBotMutationTypes as MutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [MutationTypes.SET_CHATBOT_IS_OPEN](state: S, payload: boolean): void;
  [MutationTypes.SET_CHATBOT_MESSAGES](
    state: S,
    payload: ChatBotMessage[]
  ): void;
  [MutationTypes.SET_CHATBOT_BOT_IS_TYPING](state: S, payload: boolean): void;
  [MutationTypes.SET_CHATBOT_USER](state: S, payload: User): void;
  [MutationTypes.ADD_CHATBOT_MESSAGE](state: S, payload: ChatBotMessage): void;

  [MutationTypes.SET_CHATBOT_SETTINGS](state: S, payload: ChatBotSettings): void;
  [MutationTypes.SET_IS_LOADING_CHATBOT_SETTINGS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CHATBOT_SETTINGS_LOADED](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_SAVING_CHATBOT_SETTINGS](state: S, payload: boolean): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_CHATBOT_IS_OPEN](state: State, chatBotOpenState: boolean) {
    state.chatBotOpenState = chatBotOpenState;
  },

  [MutationTypes.SET_CHATBOT_MESSAGES](
    state: State,
    chatBotMessages: ChatBotMessage[]
  ) {
    state.chatBotMessages = chatBotMessages;
  },

  [MutationTypes.ADD_CHATBOT_MESSAGE](
    state: State,
    chatBotMessage: ChatBotMessage
  ) {
    
    state.chatBotMessages.push(chatBotMessage);
  },

  [MutationTypes.SET_CHATBOT_BOT_IS_TYPING](
    state: State,
    chatBotIsTyping: boolean
  ) {
    state.chatBotIsTyping = chatBotIsTyping;
  },

  [MutationTypes.SET_CHATBOT_USER](state: State, chatBotUser: User) {
    state.chatBotUser = chatBotUser;
  },

  [MutationTypes.SET_CHATBOT_SETTINGS](state: State, chatBotSettings: ChatBotSettings) {
    state.chatBotSettings = chatBotSettings;
  },
  [MutationTypes.SET_IS_LOADING_CHATBOT_SETTINGS](state: State, isLoadingChatBotSettings: boolean) {
    state.isLoadingChatBotSettings = isLoadingChatBotSettings;
  },
  [MutationTypes.SET_IS_CHATBOT_SETTINGS_LOADED](state: State, isChatBotSettingsLoaded: boolean) {
    state.isChatBotSettingsLoaded = isChatBotSettingsLoaded;
  },
  [MutationTypes.SET_IS_SAVING_CHATBOT_SETTINGS](state: State, isSavingChatBotSettings: boolean) {
    state.isSavingChatBotSettings = isSavingChatBotSettings;
  },
};
