import { NotificationMessage } from "@/types/index";

export type NotificationState = {
  notificationsCount: number;
  notificationsUnreadCount: number;
  notifications: NotificationMessage[];
  notificationOpenState: Boolean;
};

export const state: NotificationState = {
  notificationsCount: 0,
  notificationsUnreadCount: 0,
  notifications: [],
  notificationOpenState: false,
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): NotificationState => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};