import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { omit } from "lodash";
import { AssetActionTypes } from "./action-types";
import {
  AssetCategory,
  AssetCategoryTree,
  AssetListFields,
  AssetImport,
  AssetCategoryAssetFieldRelation,
  AssetExport
} from "@/types/index";
import { AssetState as State } from "./state";
import { AssetMutationTypes } from "./mutation-types";
// import axios, { AxiosRequestConfig } from "axios";
import { HttpClient as http, AxiosError, AxiosResponse } from "@/util/ZebritAxios";
import { Config } from "../../../config";
// import ZebritAxios from "../../../util/ZebritAxios";
import {
  Asset,
  IGetAsset,
  FieldDef,
  SortDef,
  FilterDef,
  AssetListFilters,
  AssetField,
} from "@/types/index";
import { Mutations } from "./mutations";
// import { AssetMutationTypes } from "./mutation-types";
// import { getters as AssetGetters } from './getters';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [AssetActionTypes.GETASSETS_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      page: number;
      page_size: number;
      category: string | null;
      search: string | null;
    }
  ): void;
  [AssetActionTypes.GETASSETS_MINE_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      page: number;
      page_size: number;
      category: string | null;
      search: string | null;
    }
  ): void;
  [AssetActionTypes.GETHASASSETSCHARTS_ACTION]({ commit }: AugmentedActionContext): void;
  [AssetActionTypes.GETASSETSCHARTS_ACTION]({ commit }: AugmentedActionContext): void;
  [AssetActionTypes.GETASSET_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetAsset
  ): void;
  [AssetActionTypes.SAVEASSET_ACTION](
    { commit }: AugmentedActionContext,
    payload: Asset
  ): void;
  [AssetActionTypes.SETASSET_ACTION](
    { commit }: AugmentedActionContext,
    payload: Asset | null
  ): void;
  [AssetActionTypes.DELETEASSET_ACTION](
    { commit }: AugmentedActionContext,
    payload: Asset
  ): void;
  [AssetActionTypes.GET_CATEGORIES_ACTION]({ commit }: AugmentedActionContext): void;
  [AssetActionTypes.SAVE_CATEGORIES_ACTION](
    { commit }: AugmentedActionContext,
    payload: AssetCategory[]
  ): void;

  [AssetActionTypes.GET_FIELDS_ACTION]({ commit }: AugmentedActionContext): void;
  [AssetActionTypes.GET_FIELD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;
  [AssetActionTypes.SAVE_FIELD_ACTION](
    { commit }: AugmentedActionContext,
    payload: AssetField
  ): void;

  [AssetActionTypes.GET_MANUFACTURERS_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [AssetActionTypes.GET_MODELS_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [AssetActionTypes.GET_LIFE_CYCLE_STAGES_ACTION]({
    commit,
  }: AugmentedActionContext): void;
  [AssetActionTypes.SAVE_MODELS_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [AssetActionTypes.SAVE_MANUFACTURERS_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;

  [AssetActionTypes.SAVE_CATEGORY_ACTION](
    { commit }: AugmentedActionContext,
    payload: AssetCategory
  ): void;
  [AssetActionTypes.DELETE_CATEGORY_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;

  [AssetActionTypes.SAVE_CATEGORY_TREE_ACTION](
    { commit }: AugmentedActionContext,
    payload: AssetCategoryTree[]
  ): void;

  [AssetActionTypes.SET_PAGE]({ commit }: AugmentedActionContext, payload: number): void;
  [AssetActionTypes.SET_PAGESIZE](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [AssetActionTypes.SET_TOTAL]({ commit }: AugmentedActionContext, payload: number): void;
  [AssetActionTypes.SET_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [AssetActionTypes.SET_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ): void;
  [AssetActionTypes.SET_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ): void;
  [AssetActionTypes.SET_SORTS](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ): void;

  [AssetActionTypes.SET_ASSET_TO_PREVIEW_MOD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;

  [AssetActionTypes.SET_ASSET_PREVIEW_OPEN_STATE_ACTION](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [AssetActionTypes.ASSET_IMPORT_FILEUPLOAD](
    { commit }: AugmentedActionContext,
    payload: File
  ): void;
  [AssetActionTypes.ASSET_IMPORT_DO_IMPORT](
    { commit }: AugmentedActionContext,
    payload: AssetImport
  ): void;

  [AssetActionTypes.ASSET_EXPORT_GENERATE](
    { commit }: AugmentedActionContext
  ): void;

  [AssetActionTypes.ASSET_LIST_EXPORTS](
    { commit }: AugmentedActionContext
  ): void;

  // [AssetActionTypes.ASSET_DELETE_EXPORT](
  //   { commit }: AugmentedActionContext,
  //   payload: AssetExport
  // ): void;

  [AssetActionTypes.ASSET_DOWNLOAD_EXPORT](
    { commit }: AugmentedActionContext,
    payload: AssetExport
  ): void;

}

// const getters = mapGetters({
//   page: "asset/StatePage",
//   page_size: "asset/StatePageSize",
//   total: "asset/StateTotal",
//   search: "asset/StateSearch",
//   filter: "asset/StateFilter"
// });

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.GETASSETS_ACTION](
    { commit, getters }: AugmentedActionContext // payload: { //   // page: number; //   // page_size: number, //   category: string | null;
  ) //   // search: string | null
  // }
  {
    commit(AssetMutationTypes.SET_IS_LOADING_ASSETS, true);

    const page = getters.StatePage; // AssetGetters.StatePage() || 1;
    const page_size = getters.StatePageSize; // AssetGetters.StatePageSize || 10;
    const search = getters.StateSearch; // AssetGetters.StateSearch || 1;
    const filter = getters.StateFilter;
    const sorts = getters.StateSorts;

    // debugger; // eslint-disable-line
    const params: any = {
      $page: page, //payload && payload.page ? payload.page : 1,
      $limit: page_size, //payload && payload.page_size ? payload.page_size : 10,
      // $text: search, //payload && payload.search ? payload.search : null
    };

    if (search) {
      params.$term = search;
      const searchFields = AssetListFields.filter((af) => af.searchable === true).map(
        (af) => af.id
      );
      params["$searchFields"] = searchFields; // `[${searchFields.join(',')}]`;//searchFields;
    }

    AssetListFilters.forEach((f) => {
      const _f = filter.find((__f: FilterDef) => __f.id == f.id);
      if (_f && _f.value != f.value) {
        // debugger; // eslint-disable-line
        if (typeof _f.value == "string") {
          params[_f.queryParam] = _f.value;
        } else {
          // debugger; // eslint-disable-line
          params[_f.queryParam] = _f.value._id;
        }
      }
    });

    if (sorts && sorts.length > 0) {
      params["$sort"] = (sorts[0].direction == "desc" ? "-" : "") + sorts[0].field.id;
    }

    await http
      .get(
        // Config.api.assets.assets +
        //   "?$embed=category&$embed=owner&$embed=manufacturer&$embed=model",
        Config.api.assets.assets,
        params,
        {
          paramsSerializer: {
            indexes: null,
          },
        }
      )
      .then((res: AxiosResponse) => {
        // res.data.docs.forEach(a => {
        //   a.ca
        // });

        res.data.docs.forEach((doc: any) => {
          if (doc.categoryName) doc.category = doc.categoryName;

          if (doc.manufacturerName) doc.manufacturer = doc.manufacturerName;
          if (doc.manufacturerText) doc.manufacturer = doc.manufacturerText;

          if (doc.modelName) doc.model = doc.modelName;

          if (doc.owner) doc.owner = `${doc.ownerFirstName} ${doc.ownerLastName}`;

          // if (doc.purchaseDate) doc.purchaseDate = new Date(doc.purchaseDate);
          // if (doc.warrantyExpireDate) doc.warrantyExpireDate = new Date(doc.warrantyExpireDate);
        });

        commit(AssetMutationTypes.SET_ASSETS, res.data.docs);
        commit(AssetMutationTypes.SET_PAGE, res.data.pages.current);
        commit(AssetMutationTypes.SET_PAGESIZE, res.data.items.limit);
        commit(AssetMutationTypes.SET_TOTAL, res.data.items.total);
        // commit(AssetMutationTypes.SET_STARTINDEX, res.data.startIndex);

        commit(AssetMutationTypes.SET_IS_LOADING_ASSETS, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_IS_LOADING_ASSETS, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.GETASSETS_MINE_ACTION](
    { commit }: AugmentedActionContext // payload: { //   // page: number; //   // page_size: number, //   // category: string | null
  ) //   // search: string | null
  // }
  {
    commit(AssetMutationTypes.SET_IS_LOADING_ASSETS_MINE, true);

    // const page = getters.StatePage;// AssetGetters.StatePage() || 1;
    // const page_size = getters.StatePageSize;// AssetGetters.StatePageSize || 10;
    // const search = getters.StateSearch;// AssetGetters.StateSearch || 1;
    // const filter = getters.StateFilter;
    // const params: any = {
    //   page: page, //payload && payload.page ? payload.page : 1,
    //   page_size: page_size, //payload && payload.page_size ? payload.page_size : 10,
    //   search: search //payload && payload.search ? payload.search : null
    // };

    // AssetListFilters.forEach(f => {
    //   const _f = filter.find((__f: FilterDef) => __f.id == f.id);
    //   if (_f && _f.value != f.value) {
    //     params[_f.queryParam] = _f.value;
    //   }
    // });

    await http
      .get(Config.api.assets.mine)
      .then((res: AxiosResponse) => {
        commit(AssetMutationTypes.SET_ASSETS_MINE, res.data.docs);
        // commit(AssetMutationTypes.SET_PAGE, res.data.page);
        // commit(AssetMutationTypes.SET_PAGESIZE, res.data.pageSize);
        // commit(AssetMutationTypes.SET_TOTAL, res.data.total);
        // commit(AssetMutationTypes.SET_STARTINDEX, res.data.startIndex);

        commit(AssetMutationTypes.SET_IS_LOADING_ASSETS_MINE, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_IS_LOADING_ASSETS_MINE, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.GETHASASSETSCHARTS_ACTION]({ commit }: AugmentedActionContext) {
    commit(AssetMutationTypes.SET_IS_LOADING_HAS_ASSETS_CHARTS, true);
    return await http
      .get(Config.api.asset.chart.hasAny)
      .then((res: AxiosResponse) => {
        commit(AssetMutationTypes.SET_HAS_ASSETS_CHARTS, res.data);
        commit(AssetMutationTypes.SET_IS_LOADING_HAS_ASSETS_CHARTS, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_HAS_ASSETS_CHARTS, false);
        commit(AssetMutationTypes.SET_IS_LOADING_HAS_ASSETS_CHARTS, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.GETASSETSCHARTS_ACTION]({ commit }: AugmentedActionContext) {
    commit(AssetMutationTypes.SET_IS_LOADING_ASSETS_CHARTS, true);
    await http
      .get(Config.api.assets.assetsGraph)
      .then((res: AxiosResponse) => {
        commit(AssetMutationTypes.SET_ASSETS_CHARTS, res.data.docs);
        commit(AssetMutationTypes.SET_IS_LOADING_ASSETS_CHARTS, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_IS_LOADING_ASSETS_CHARTS, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.GETASSET_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetAsset
  ) {
    //commit(AssetMutationTypes.SET_IS_LOADING_ASSET, true);
    commit(AssetMutationTypes.SET_IS_LOADING_ASSET, true);
    commit(AssetMutationTypes.SET_IS_ASSET_LOADED, false);
    // console.log(Config);
    // console.log(Config.api);
    // console.log(Config.api.asset);
    // console.log(Config.api.asset.get);
    // debugger; // eslint-disable-line
    await http
      .get(Config.api.asset.get.replace("{id}", payload.id.toString())) // `api/asset/${id}`)
      .then((res: AxiosResponse) => {
        // if (res.data.purchaseDate) res.data.purchaseDate = new Date(res.data.purchaseDate);
        // if (res.data.warrantyExpireDate) res.data.warrantyExpireDate = new Date(res.data.warrantyExpireDate);
        commit(AssetMutationTypes.SET_ASSET, res.data);
        commit(AssetMutationTypes.SET_IS_LOADING_ASSET, false);
        commit(AssetMutationTypes.SET_IS_ASSET_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_IS_LOADING_ASSET, false);
        commit(AssetMutationTypes.SET_IS_ASSET_LOADED, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.SAVEASSET_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: Asset
  ) {
    // debugger; // eslint-disable-line

    const actionType = payload._id
      ? AssetMutationTypes.SET_IS_UPDATING_ASSET
      : AssetMutationTypes.SET_IS_CREATING_ASSET;
    commit(actionType, true);

    const actionFinishedType = payload._id
      ? AssetMutationTypes.SET_IS_ASSET_UPDATED
      : AssetMutationTypes.SET_IS_ASSET_CREATED;
    commit(actionFinishedType, false);

    // await http.put(`api/assets/${payload.id}`, payload)
    //   .then(res => {
    //     commit(AssetMutationTypes.SET_ASSET, res.data)
    //     commit(actionType, false);
    //     dispatch(AssetActionTypes.GETASSETS_ACTION);
    //   })
    //   .catch(err => {
    //     console.log('error', err);
    //     commit(actionType, false);
    //   })
    // const axiosConfig: AxiosRequestConfig = {
    //   method: payload.id ? "PUT" : "POST",
    //   url: Config.api.assets.assets + (payload.id ? `/${payload.id}` : ""),
    //   data: payload,
    // };

    let _payload = JSON.parse(JSON.stringify(payload));
    // _payload.owner = _payload.ownerId;
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== null)
    );
    _payload = Object.fromEntries(Object.entries(_payload).filter(([id, v]) => v !== ""));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== undefined)
    );

    _payload = omit(_payload, [
      "categoryName",
      "subCategoryName",
      "documents",
      "manufacturerName",
      "manufacturerText",
      "modelText",
      "ownerFirstName",
      "ownerLastName",
      "ownerAvatar",
      "ownerAvatarUrl",
      "pictureUrl",
      "categoryIcon",
      "subCategoryIcon",
      "categoryIconUrl",
      "subCategoryIconUrl",
    ]);
    // debugger; // eslint-disable-line
    await http
      .request(
        payload._id ? "PUT" : "POST",
        Config.api.assets.assets + (payload._id ? `/${payload._id}` : ""),
        _payload
      )
      .then((res: any) => {
        // debugger; // eslint-disable-line
        if (res instanceof AxiosError) {
          const _err = res as AxiosError;
          const errorMessage: any = res?.response?.data?.message;
          if (errorMessage) {
            commit(AssetMutationTypes.SET_ASSET_ERROR, errorMessage);
          } else {
            commit(AssetMutationTypes.SET_ASSET_ERROR, _err.message);
          }
          const validationError: any = res?.response?.data?.validation;
          if (validationError) {
            commit(AssetMutationTypes.SET_ASSET_ERROR_VALIDATION, validationError);
          }
          commit(actionType, false);
          commit(actionFinishedType, true);
          // dispatch(AssetActionTypes.GETASSETS_ACTION);
        } else {
          // const _res = res as AxiosResponse;
          commit(AssetMutationTypes.SET_ASSET_ERROR, null);
          commit(AssetMutationTypes.SET_ASSET_ERROR_VALIDATION, null);
          commit(AssetMutationTypes.SET_ASSET, res.data);
          commit(actionType, false);
          commit(actionFinishedType, true);
          dispatch(AssetActionTypes.GETASSETS_ACTION);
        }
      })
      .catch((err: any) => {
        // debugger; // eslint-disable-line
        console.log("error", err);
        if (err?.data?.message) {
          const errorMessage: any = err?.data?.message;

          commit(AssetMutationTypes.SET_ASSET_ERROR, errorMessage);
        } else {
          commit(AssetMutationTypes.SET_ASSET_ERROR, null);
        }

        commit(actionType, false);
        commit(actionFinishedType, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.SETASSET_ACTION](
    { commit }: AugmentedActionContext,
    payload: Asset | null
  ) {
    // const actionType = (payload.asset.id) ?
    //   AssetMutationTypes.SET_IS_CREATING_ASSET
    //   :
    //   AssetMutationTypes.SET_IS_UPDATING_ASSET;
    // commit(actionType, true);

    commit(AssetMutationTypes.SET_ASSET, payload);
    // commit(actionType, false);
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.DELETEASSET_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: Asset
  ) {
    commit(AssetMutationTypes.SET_IS_DELETING_ASSET, true);
    // const axiosConfig: AxiosRequestConfig = {
    //   method: "DELETE",
    //   url: Config.api.asset.delete.replace("{id}", `${payload.id}`), // + `/${payload.id}`,
    //   data: payload,
    // };
    await http
      .request(
        "DELETE",
        Config.api.asset.delete.replace("{id}", `${payload._id}`), // + `/${payload.id}`,
        payload
      )
      .then(() => {
        commit(AssetMutationTypes.SET_ASSET, null);
        commit(AssetMutationTypes.SET_IS_DELETING_ASSET, false);
        dispatch(AssetActionTypes.GETASSETS_ACTION);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_IS_DELETING_ASSET, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.GET_CATEGORIES_ACTION]({ commit }: AugmentedActionContext) {
    commit(AssetMutationTypes.SET_IS_LOADING_CATEGORIES, true);
    await http
      .get(Config.api.assets.assetsCategories)
      .then((res: AxiosResponse) => {
        // commit(AssetMutationTypes.SET_CATEGORIES, res.data);
        commit(AssetMutationTypes.SET_CATEGORIES, res.data.docs);
        commit(AssetMutationTypes.SET_IS_LOADING_CATEGORIES, false);
        commit(AssetMutationTypes.SET_IS_CATEGORIES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_CATEGORIES, []);
        commit(AssetMutationTypes.SET_IS_LOADING_CATEGORIES, false);
        commit(AssetMutationTypes.SET_IS_CATEGORIES_LOADED, false);
      });
  },

  async [AssetActionTypes.SAVE_CATEGORIES_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: AssetCategory[]
  ) {
    console.log("SAVE_ASSETCATEGORIES_ACTION");
    console.log(payload);

    commit(AssetMutationTypes.SET_IS_SAVING_CATEGORIES, true);
    // debugger; // eslint-disable-line
    await http
      .put(Config.api.assets.assetsCategories, payload)
      .then((response: AxiosResponse) => {
        commit(AssetMutationTypes.SET_CATEGORIES, response.data);
        commit(AssetMutationTypes.SET_IS_SAVING_CATEGORIES, false);
        dispatch(AssetActionTypes.GET_CATEGORIES_ACTION);
      })
      .catch((err: AxiosError) => {
        commit(AssetMutationTypes.SET_IS_SAVING_CATEGORIES, false);
        console.log("error", err);
      });
  },

  async [AssetActionTypes.SAVE_CATEGORY_ACTION](
    { commit }: AugmentedActionContext,
    payload: AssetCategory
  ) {
    // console.log("SAVE_CATEGORY_ACTION");
    // console.log(payload);

    commit(AssetMutationTypes.SET_IS_SAVING_CATEGORY, true);
    commit(AssetMutationTypes.SET_IS_CATEGORY_SAVED, false);
    commit(AssetMutationTypes.SET_CATEGORY_SAVE_ERROR, null);
    // debugger; // eslint-disable-line
    const isNew = payload._id === null || payload._id == -1;

    let _payload = JSON.parse(JSON.stringify(payload));
    // _payload.owner = _payload.ownerId;
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== null))
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== ''))
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== undefined))
    if (isNew) _payload = omit(_payload, ["_id"]);

    _payload = omit(_payload, [
      "createdAt",
      "createdBy",
      "updatedAt",
      "updatedBy",
      "subCategories",
      "iconUrl",
      "expanded",
    ]);

    await http
      .request(
        isNew ? "POST" : "PUT",
        Config.api.assets.assetsCategory + (!isNew ? `/${_payload._id}` : ""),
        isNew ? [_payload] : _payload
      )
      // .put(Config.api.assets.assetsCategory, payload)
      .then(() => {
        // commit(AssetMutationTypes.SET_CATEGORIES, response.data);
        commit(AssetMutationTypes.SET_IS_SAVING_CATEGORY, false);
        commit(AssetMutationTypes.SET_IS_CATEGORY_SAVED, true);
        commit(AssetMutationTypes.SET_CATEGORY_SAVE_ERROR, null);
        // dispatch(AssetActionTypes.GET_CATEGORIES_ACTION);
      })
      .catch((err: AxiosError) => {
        commit(AssetMutationTypes.SET_IS_SAVING_CATEGORY, false);
        commit(AssetMutationTypes.SET_IS_CATEGORY_SAVED, false);
        commit(AssetMutationTypes.SET_CATEGORY_SAVE_ERROR, err.message);
        console.log("error", err);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.DELETE_CATEGORY_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: string
  ) {
    commit(AssetMutationTypes.SET_IS_DELETING_CATEGORY, true);
    // const axiosConfig: AxiosRequestConfig = {
    //   method: "DELETE",
    //   url: Config.api.asset.delete.replace("{id}", `${payload.id}`), // + `/${payload.id}`,
    //   data: payload,
    // };
    await http
      .request(
        "DELETE",
        Config.api.assets.assetsCategories, // + `/${payload.id}`,
        [payload]
      )
      .then(() => {
        commit(AssetMutationTypes.SET_ASSET, null);
        commit(AssetMutationTypes.SET_IS_DELETING_ASSET, false);
        dispatch(AssetActionTypes.GETASSETS_ACTION);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_IS_DELETING_ASSET, false);
      });
  },

  async [AssetActionTypes.SAVE_CATEGORY_TREE_ACTION](
    { commit }: AugmentedActionContext,
    payload: AssetCategoryTree[]
  ) {
    console.log("SAVE_CATEGORY_TREE_ACTION");
    console.log(payload);

    commit(AssetMutationTypes.SET_IS_SAVING_CATEGORY, true);
    // debugger; // eslint-disable-line
    // const isNew = payload._id === null || payload._id == -1;

    const _payload = JSON.parse(JSON.stringify(payload));
    // _payload.owner = _payload.ownerId;
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== null))
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== ''))
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== undefined))
    // if (isNew) _payload = omit(_payload, ["_id"]);

    // _payload = omit(_payload, [
    //   "createdAt",
    //   "createdBy",
    //   "updatedAt",
    //   "updatedBy",
    //   "subCategories",
    //   "updatedAt",
    //   "iconUrl",
    //   "expanded"
    // ]);

    await http
      .request("PUT", Config.api.assets.assetsCategoryTree, _payload)
      // .put(Config.api.assets.assetsCategory, payload)
      .then(() => {
        // commit(AssetMutationTypes.SET_CATEGORIES, response.data);
        commit(AssetMutationTypes.SET_IS_SAVING_CATEGORY, false);
        // dispatch(AssetActionTypes.GET_CATEGORIES_ACTION);
      })
      .catch((err: AxiosError) => {
        commit(AssetMutationTypes.SET_IS_SAVING_CATEGORY, false);
        console.log("error", err);
      });
  },

  // [AssetActionTypes.GET_FIELDS_ACTION]({ commit }: AugmentedActionContext): void;
  async [AssetActionTypes.GET_FIELDS_ACTION]({ commit }: AugmentedActionContext) {
    commit(AssetMutationTypes.SET_IS_LOADING_FIELDS, true);
    await http
      .get(Config.api.assets.assetsFields)
      .then((res: AxiosResponse) => {
        // commit(AssetMutationTypes.SET_FIELDS, res.data);
        // debugger; // eslint-disable-line
        commit(AssetMutationTypes.SET_FIELDS, res.data.docs);
        // debugger; // eslint-disable-line
        commit(AssetMutationTypes.SET_FIELDS_PAGE, res.data.pages.current);
        commit(AssetMutationTypes.SET_FIELDS_PAGESIZE, res.data.items.limit);
        commit(AssetMutationTypes.SET_FIELDS_TOTAL, res.data.items.total);
        commit(AssetMutationTypes.SET_IS_LOADING_FIELDS, false);
        commit(AssetMutationTypes.SET_IS_FIELDS_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_FIELDS, []);
        commit(AssetMutationTypes.SET_IS_LOADING_FIELDS, false);
        commit(AssetMutationTypes.SET_IS_FIELDS_LOADED, false);
      });
  },

  async [AssetActionTypes.GET_FIELD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ) {
    // debugger; // eslint-disable-line
    commit(AssetMutationTypes.SET_IS_LOADING_FIELD, true);
    await http
      .get(Config.api.assets.assetsField.replace("{id}", payload))
      .then((res: AxiosResponse) => {
        // commit(AssetMutationTypes.SET_FIELDS, res.data);
        // debugger; // eslint-disable-line
        commit(AssetMutationTypes.SET_FIELD, res.data);
        // commit(AssetMutationTypes.SET_FIELDS_PAGE, res.data.pages.current);
        // commit(AssetMutationTypes.SET_FIELDS_PAGESIZE, res.data.items.limit);
        // commit(AssetMutationTypes.SET_FIELDS_TOTAL, res.data.items.total);
        commit(AssetMutationTypes.SET_IS_LOADING_FIELD, false);
        // commit(AssetMutationTypes.SET_IS_FIELD_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_FIELD, null);
        commit(AssetMutationTypes.SET_IS_LOADING_FIELD, false);
        // commit(AssetMutationTypes.SET_IS_FIELD_LOADED, false);
      });
  },

  async [AssetActionTypes.SAVE_FIELD_ACTION](
    { commit }: AugmentedActionContext,
    payload: AssetField
  ) {
    debugger; // eslint-disable-line

    let _payload = JSON.parse(JSON.stringify(payload));
    // _payload.owner = _payload.ownerId;
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== null)
    );
    _payload = Object.fromEntries(Object.entries(_payload).filter(([id, v]) => v !== ""));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== undefined)
    );

    _payload = omit(_payload, ["category"]);

    // let _categories = JSON.parse(JSON.stringify(payload.category));

    await http
      .put(Config.api.assets.assetsCategory, _payload)
      // .put(Config.api.assets.assetsCategory, payload)
      .then(() => {
        // commit(AssetMutationTypes.SET_CATEGORIES, response.data);
        commit(AssetMutationTypes.SET_IS_SAVING_CATEGORY, false);
        // dispatch(AssetActionTypes.GET_CATEGORIES_ACTION);
      })
      .catch((err: AxiosError) => {
        commit(AssetMutationTypes.SET_IS_SAVING_CATEGORY, false);
        console.log("error", err);
      });
  },

  async [AssetActionTypes.ADD_FIELD_CATEGORY_ACTION](
    { commit }: AugmentedActionContext,
    payload: { id: string; cats: AssetCategoryAssetFieldRelation[] }
  ) {
    // eslint-disable-line

    const _payload = JSON.parse(JSON.stringify(payload));

    console.log(payload);
    console.log(commit);
    // _payload.owner = _payload.ownerId;
    // _payload = Object.fromEntries(
    //     Object.entries(_payload).filter(([id, v]) => v !== null)
    // );
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([id, v]) => v !== ""));
    // _payload = Object.fromEntries(
    //     Object.entries(_payload).filter(([id, v]) => v !== undefined)
    // );

    await http
      .put(
        Config.api.assets.assetsFieldCategories.replace("{id}", _payload.id),
        _payload.cats
      )
      // .put(Config.api.assets.assetsCategory, payload)
      .then(() => {
        // commit(AssetMutationTypes.SET_CATEGORIES, response.data);
        commit(AssetMutationTypes.SET_IS_SAVING_CATEGORY, false);
        // dispatch(AssetActionTypes.GET_CATEGORIES_ACTION);
      })
      .catch((err: AxiosError) => {
        commit(AssetMutationTypes.SET_IS_SAVING_CATEGORY, false);
        console.log("error", err);
      });
  },

  // [AssetActionTypes.SET_FIELDS_PAGE]({ commit }: AugmentedActionContext, payload: number) {
  //   commit(AssetMutationTypes.SET_FIELDS_PAGE, payload);
  // },

  // [AssetActionTypes.SET_FIELDS_PAGESIZE]({ commit }: AugmentedActionContext, payload: number) {
  //   commit(AssetMutationTypes.SET_FIELDS_PAGESIZE, payload);
  // },

  // [AssetActionTypes.SET_FIELDS_TOTAL]({ commit }: AugmentedActionContext, payload: number) {
  //   commit(AssetMutationTypes.SET_FIELDS_TOTAL, payload);
  // },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.GET_MANUFACTURERS_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    let url = `${Config.api.assets.assetsManufacturers}`;
    // if (payload) url += `?$text=${payload}`;

    url += (payload) ? `?$term=${payload}&$searchFields=text&$sort=text` : `?$sort=text`;

    commit(AssetMutationTypes.SET_IS_LOADING_MANUFACTURERS, true);
    await http
      .get(url)
      .then((res: AxiosResponse) => {
        commit(AssetMutationTypes.SET_MANUFACTURERS, res.data.docs);
        commit(AssetMutationTypes.SET_IS_LOADING_MANUFACTURERS, false);
        commit(AssetMutationTypes.SET_IS_MANUFACTURERS_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_MANUFACTURERS, []);
        commit(AssetMutationTypes.SET_IS_LOADING_MANUFACTURERS, false);
        commit(AssetMutationTypes.SET_IS_MANUFACTURERS_LOADED, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.GET_MODELS_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    let url = `${Config.api.assets.assetsManufacturerModels}`;
    // if (payload) url += `?$text=${payload}`;
    url += (payload) ? `?$term=${payload}&$searchFields=text&$sort=text` : `?$sort=text`;
    commit(AssetMutationTypes.SET_IS_LOADING_MANUFACTURER_MODELS, true);
    // debugger; // eslint-disable-line
    await http
      .get(url)
      .then((res: AxiosResponse) => {
        commit(AssetMutationTypes.SET_MANUFACTURER_MODELS, res.data.docs);
        commit(AssetMutationTypes.SET_IS_LOADING_MANUFACTURER_MODELS, false);
        commit(AssetMutationTypes.SET_IS_MANUFACTURER_MODELS_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_MANUFACTURER_MODELS, []);
        commit(AssetMutationTypes.SET_IS_LOADING_MANUFACTURER_MODELS, false);
        commit(AssetMutationTypes.SET_IS_MANUFACTURER_MODELS_LOADED, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.SAVE_MODELS_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    const url = `${Config.api.assets.assetsManufacturerModels}`;
    commit(AssetMutationTypes.SET_IS_SAVING_MANUFACTURER_MODELS, true);
    commit(AssetMutationTypes.SET_IS_MANUFACTURER_MODELS_SAVED, false);
    // debugger; // eslint-disable-line
    await http
      .post(url, [{ text: payload }])
      .then(() => {
        // .then((res: AxiosResponse) => {
        //   // dispatch(AssetActionTypes.GET_MODELS_ACTION, payload)
        //   // commit(AssetMutationTypes.SET_MANUFACTURER_MODELS, res.data.docs);
        commit(AssetMutationTypes.SET_IS_LOADING_MANUFACTURER_MODELS, false);
        commit(AssetMutationTypes.SET_IS_MANUFACTURER_MODELS_SAVED, true);
        //   // commit(AssetMutationTypes.SET_IS_MANUFACTURER_MODELS_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        // commit(AssetMutationTypes.SET_MANUFACTURER_MODELS, []);
        commit(AssetMutationTypes.SET_IS_LOADING_MANUFACTURER_MODELS, false);
        commit(AssetMutationTypes.SET_IS_MANUFACTURER_MODELS_SAVED, false);
        // commit(AssetMutationTypes.SET_IS_MANUFACTURER_MODELS_LOADED, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.SAVE_MANUFACTURERS_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    const url = `${Config.api.assets.assetsManufacturers}`;
    commit(AssetMutationTypes.SET_IS_SAVING_MANUFACTURERS, true);
    commit(AssetMutationTypes.SET_IS_MANUFACTURERS_SAVED, false);
    // debugger; // eslint-disable-line
    await http
      .post(url, [{ text: payload }])
      .then(() => {
        // .then((res: AxiosResponse) => {
        //   // dispatch(AssetActionTypes.GET_MODELS_ACTION, payload)
        //   // commit(AssetMutationTypes.SET_MANUFACTURER_MODELS, res.data.docs);
        commit(AssetMutationTypes.SET_IS_SAVING_MANUFACTURERS, false);
        commit(AssetMutationTypes.SET_IS_MANUFACTURERS_SAVED, true);

        //   // commit(AssetMutationTypes.SET_IS_MANUFACTURER_MODELS_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        // commit(AssetMutationTypes.SET_MANUFACTURER_MODELS, []);
        commit(AssetMutationTypes.SET_IS_SAVING_MANUFACTURERS, false);
        commit(AssetMutationTypes.SET_IS_MANUFACTURERS_SAVED, false);

        // commit(AssetMutationTypes.SET_IS_MANUFACTURER_MODELS_LOADED, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AssetActionTypes.GET_LIFE_CYCLE_STAGES_ACTION]({
    commit,
  }: AugmentedActionContext) {
    commit(AssetMutationTypes.SET_IS_LOADING_LIFE_CYCLE_STAGES, true);
    await http
      .get(Config.api.assets.lifecyclestage)
      .then((res: AxiosResponse) => {
        commit(AssetMutationTypes.SET_LIFE_CYCLE_STAGES, res.data);
        commit(AssetMutationTypes.SET_IS_LOADING_LIFE_CYCLE_STAGES, false);
        commit(AssetMutationTypes.SET_IS_LIFE_CYCLE_STAGES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_LIFE_CYCLE_STAGES, []);
        commit(AssetMutationTypes.SET_IS_LOADING_LIFE_CYCLE_STAGES, false);
        commit(AssetMutationTypes.SET_IS_LIFE_CYCLE_STAGES_LOADED, false);
      });
  },

  [AssetActionTypes.SET_PAGE]({ commit }: AugmentedActionContext, payload: number) {
    commit(AssetMutationTypes.SET_PAGE, payload);
  },

  [AssetActionTypes.SET_PAGESIZE]({ commit }: AugmentedActionContext, payload: number) {
    commit(AssetMutationTypes.SET_PAGESIZE, payload);
  },

  [AssetActionTypes.SET_TOTAL]({ commit }: AugmentedActionContext, payload: number) {
    commit(AssetMutationTypes.SET_TOTAL, payload);
  },

  [AssetActionTypes.SET_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(AssetMutationTypes.SET_SEARCH, payload);
  },

  [AssetActionTypes.SET_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ) {
    commit(AssetMutationTypes.SET_FILTER, payload);
  },

  [AssetActionTypes.SET_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ) {
    commit(AssetMutationTypes.SET_AVAILABLEFIELDS, payload);
  },

  [AssetActionTypes.SET_SORTS]({ commit }: AugmentedActionContext, payload: SortDef[]) {
    commit(AssetMutationTypes.SET_SORTS, payload);
  },

  [AssetActionTypes.SET_ASSET_TO_PREVIEW_MOD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(AssetMutationTypes.SET_ASSET_TO_PREVIEW_MOD, payload);
  },

  [AssetActionTypes.SET_ASSET_PREVIEW_OPEN_STATE_ACTION](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(AssetMutationTypes.SET_ASSET_PREVIEW_OPEN_STATE, payload);
  },

  async [AssetActionTypes.GET_CHART_ASSET_CATEGORIES]({
    commit,
  }: AugmentedActionContext) {
    // commit(RequestMutationTypes.SET_CHARTTYPES, payload);

    commit(AssetMutationTypes.SET_IS_LOADING_CHART_ASSET_CATEGORIES, true);
    await http
      .get(Config.api.asset.chart.categories)
      .then((res: AxiosResponse) => {
        commit(AssetMutationTypes.SET_CHART_ASSET_CATEGORIES, res.data);
        commit(AssetMutationTypes.SET_IS_LOADING_CHART_ASSET_CATEGORIES, false);
        commit(AssetMutationTypes.SET_IS_CHART_ASSET_CATEGORIES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(AssetMutationTypes.SET_CHART_ASSET_CATEGORIES, null);
        commit(AssetMutationTypes.SET_IS_LOADING_CHART_ASSET_CATEGORIES, false);
        commit(AssetMutationTypes.SET_IS_CHART_ASSET_CATEGORIES_LOADED, false);
      });
  },

  async [AssetActionTypes.ASSET_IMPORT_FILEUPLOAD](
    { commit }: AugmentedActionContext,
    payload: File
  ) {
    const data = new FormData();
    // const file = payload.files[0];
    data.append("name", payload.name);
    data.append("file", payload);
    // data.append("assetId", this.asset._id);
    return new Promise((resolve, reject) => {
      // debugger; // eslint-disable-line
      http
        .post(Config.file.assetFileUploadImportCSV, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          (response: AxiosResponse) => {
            commit(AssetMutationTypes.SET_ASSET_IMPORT, response.data as AssetImport);
            resolve();
          },
          (error: AxiosError) => {
            reject();
          }
        );
    });
  },

  async [AssetActionTypes.ASSET_IMPORT_DO_IMPORT](
    { commit }: AugmentedActionContext,
    payload: AssetImport
  ) {
    commit(AssetMutationTypes.SET_ASSET_IMPORT_IS_IMPORTING, true);
    return new Promise((resolve, reject) => {
      debugger; // eslint-disable-line
      http
        .put(Config.api.assets.assetImportDoImport, {
          _id: payload._id,
          mapping: payload.mapping,
        })
        .then(
          (response: AxiosResponse) => {
            commit(AssetMutationTypes.SET_ASSET_IMPORT_IS_IMPORTING, false);
            commit(AssetMutationTypes.SET_ASSET_IMPORT_DONE, true);
            commit(AssetMutationTypes.SET_ASSET_IMPORT_RESULTS, response.data);
            debugger; // eslint-disable-line
            //commit(AssetMutationTypes.SET_ASSET_IMPORT, response.data as AssetImport)
            resolve();
          },
          (error: AxiosError) => {
            commit(AssetMutationTypes.SET_ASSET_IMPORT_IS_IMPORTING, false);
            commit(AssetMutationTypes.SET_ASSET_IMPORT_DONE, true);
            commit(AssetMutationTypes.SET_ASSET_IMPORT_RESULTS, null);
            reject();
          }
        );
    });
  },

  async [AssetActionTypes.ASSET_EXPORT_GENERATE](
    { commit }: AugmentedActionContext
  ) {
    commit(AssetMutationTypes.SET_ASSET_EXPORT_IS_GENERATING, true);
    commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_DONE, false);
    return new Promise((resolve, reject) => {
      // debugger; // eslint-disable-line
      http
        .post(Config.api.assets.assetExportGenerate, {})
        .then(
          (response: AxiosResponse) => {
            commit(AssetMutationTypes.SET_ASSET_EXPORT_IS_GENERATING, false);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_DONE, true);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_RESULTS, response.data);
            // debugger; // eslint-disable-line
            //commit(AssetMutationTypes.SET_ASSET_IMPORT, response.data as AssetImport)
            resolve();
          },
          (error: AxiosError) => {
            commit(AssetMutationTypes.SET_ASSET_EXPORT_IS_GENERATING, false);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_DONE, false);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_RESULTS, null);
            reject();
          }
        );
    });
  },

  async [AssetActionTypes.ASSET_LIST_EXPORTS](
    { commit }: AugmentedActionContext
  ) {
    commit(AssetMutationTypes.SET_ASSET_EXPORTS_IS_LOADING, true);
    commit(AssetMutationTypes.SET_ASSET_EXPORTS_LEADED, false);
    return new Promise((resolve, reject) => {
      // debugger; // eslint-disable-line
      http
        .get(Config.api.assets.assetExportList)
        .then(
          (response: AxiosResponse) => {
            commit(AssetMutationTypes.SET_ASSET_EXPORTS_IS_LOADING, false);
            commit(AssetMutationTypes.SET_ASSET_EXPORTS_LEADED, true);
            commit(AssetMutationTypes.SET_ASSET_EXPORTS, response.data);
            // debugger; // eslint-disable-line
            //commit(AssetMutationTypes.SET_ASSET_IMPORT, response.data as AssetImport)
            resolve();
          },
          (error: AxiosError) => {
            commit(AssetMutationTypes.SET_ASSET_EXPORTS_IS_LOADING, false);
            commit(AssetMutationTypes.SET_ASSET_EXPORTS_LEADED, false);
            commit(AssetMutationTypes.SET_ASSET_EXPORTS, []);
            reject();
          }
        );
    });
  },

  async [AssetActionTypes.ASSET_DELETE_EXPORT](
    { commit }: AugmentedActionContext
  ) {
    commit(AssetMutationTypes.SET_ASSET_EXPORT_IS_GENERATING, true);
    return new Promise((resolve, reject) => {
      // debugger; // eslint-disable-line
      http
        .post(Config.api.assets.assetExportGenerate, {})
        .then(
          (response: AxiosResponse) => {
            commit(AssetMutationTypes.SET_ASSET_EXPORT_IS_GENERATING, false);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_DONE, true);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_RESULTS, response.data);
            // debugger; // eslint-disable-line
            //commit(AssetMutationTypes.SET_ASSET_IMPORT, response.data as AssetImport)
            resolve(response.data);
          },
          (error: AxiosError) => {
            commit(AssetMutationTypes.SET_ASSET_EXPORT_IS_GENERATING, false);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_DONE, true);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_RESULTS, null);
            reject();
          }
        );
    });
  },

  async [AssetActionTypes.ASSET_DOWNLOAD_EXPORT](
    { commit }: AugmentedActionContext,
    payload: AssetExport
  ) {
    commit(AssetMutationTypes.SET_ASSET_EXPORT_IS_GENERATING, true);
    const { name } = payload;
    return new Promise((resolve, reject) => {
      debugger; // eslint-disable-line
      http
        .get(Config.api.assets.assetExportDownload.replace('{file}', name), {})
        .then(
          (response: AxiosResponse) => {
            commit(AssetMutationTypes.SET_ASSET_EXPORT_IS_GENERATING, false);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_DONE, true);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_RESULTS, response.data);
            debugger; // eslint-disable-line
            //commit(AssetMutationTypes.SET_ASSET_IMPORT, response.data as AssetImport)
            resolve(response.data);
          },
          (error: AxiosError) => {
            commit(AssetMutationTypes.SET_ASSET_EXPORT_IS_GENERATING, false);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_DONE, true);
            commit(AssetMutationTypes.SET_ASSET_EXPORT_GENERATE_RESULTS, null);
            reject();
          }
        );
    });
  },

};
