// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow

export enum AssetMutationTypes {

  RESET = "reset",

  SET_IS_LOADING_HAS_ASSETS_CHARTS = 'setIsLoadingHasAssetsCharts',
  SET_HAS_ASSETS_CHARTS = 'setHasAssetsCharts',

  SET_ASSETS_CHARTS = 'setAssetsCharts',
  SET_IS_LOADING_ASSETS_CHARTS = 'setIsLoadingAssetsCharts',

  SET_ASSETS = 'setAssets',
  SET_IS_LOADING_ASSETS = 'setIsLoadingAssets',
  SET_DELETED_ASSETS_DATA = 'setDeletedAssetsData',
  SET_IS_DELETING_ASSETS = 'setIsDeletingAssets',
  SET_IS_UPDATING_ASSETS = 'setIsUpdatingAssets',
  SET_UPDATED_ASSETS_DATA = 'setUpdatedAssetsData',

  SET_ASSETS_MINE = 'setAssetsMine',
  SET_IS_LOADING_ASSETS_MINE = 'setIsLoadingAssetsMine',

  SET_PAGE = 'setPage',
  SET_PAGESIZE = 'setPageSize',
  SET_TOTAL = 'setTotal',
  SET_SEARCH = 'setSearch',
  SET_FILTER = 'setFilter',
  SET_AVAILABLEFIELDS = 'setAvailableFields',
  SET_SORTS = 'setSorts',

  SET_ASSET = 'setAsset',
  SET_ASSET_ERROR = 'setAssetError',
  SET_ASSET_ERROR_VALIDATION = 'setAssetErrorValidation',
  SET_IS_LOADING_ASSET = 'setIsLoadingAsset',
  SET_IS_ASSET_LOADED = 'setIsAssetLoaded',
  SET_IS_CREATING_ASSET = 'setIsCreatingAsset',
  SET_IS_ASSET_CREATED = 'setIsAssetCreated',
  SET_CREATED_ASSET_DATA = 'setCreatedAssetData',
  SET_IS_UPDATING_ASSET = 'setIsUpdatingAsset',
  SET_IS_ASSET_UPDATED = 'setIsAssetUpdated',
  SET_UPDATED_ASSET_DATA = 'setUpdatedAssetData',
  SET_IS_DELETING_ASSET = 'setIsDeletingAsset',

  SET_DELETED_ASSET_SUCCEEDED = 'setDeletedAssetSucceeded',
  SET_DELETED_ASSET_DATA = 'setDeletedAssetData',

  SET_ASSET_TO_PREVIEW_MOD = 'setAssetToPreviewMod',
  SET_ASSET_PREVIEW_OPEN_STATE = 'setAssetPreviewOpenState',




  SET_FIELD = 'setField',
  SET_FIELD_ERROR = 'setFieldError',
  SET_FIELD_ERROR_VALIDATION = 'setFieldErrorValidation',
  SET_IS_LOADING_FIELD = 'setIsLoadingField',
  SET_IS_DELETING_FIELD = 'setIsDeletingField',
  SET_DELETED_FIELD_SUCCEEDED = 'setDeletedFieldSucceeded',
  SET_DELETED_FIELD_DATA = 'setDeletedFieldData',





  SET_CATEGORIES = 'setCategories',
  SET_IS_LOADING_CATEGORIES = 'setIsLoadingCategories',
  SET_IS_CATEGORIES_LOADED = 'setIsCategoriesLoaded',
  SET_IS_SAVING_CATEGORIES = 'setIsSavingCategories',

  SET_IS_LOADING_CATEGORY = 'setIsLoadingCategory',
  SET_IS_CATEGORY_LOADED = 'setIsCategoryLoaded',
  SET_IS_SAVING_CATEGORY = 'setIsSavingCategory',

  SET_IS_CATEGORY_SAVED = 'setIsCategorySaved',
  SET_CATEGORY_SAVE_ERROR = 'setCategorySaveError',

  SET_IS_DELETING_CATEGORY = 'setIsDeletingCategory',

  SET_FIELDS = 'setFields',
  SET_IS_LOADING_FIELDS = 'setIsLoadingFields',
  SET_IS_FIELDS_LOADED = 'setIsFieldsLoaded',
  SET_FIELDS_PAGE = 'setFieldsPage',
  SET_FIELDS_PAGESIZE = 'setFieldsPageSize',
  SET_FIELDS_TOTAL = 'setFieldsTotal',

  SET_MANUFACTURERS = 'setManufacturers',
  SET_IS_SAVING_MANUFACTURERS = 'setIsSavingManufacturers',
  SET_IS_MANUFACTURERS_SAVED = 'setIsManufacturersSaved',
  SET_IS_LOADING_MANUFACTURERS = 'setIsLoadingManufacturers',
  SET_IS_MANUFACTURERS_LOADED = 'setIsManufacturersLoaded',

  SET_MANUFACTURER_MODELS = 'setManufacturerModels',
  SET_IS_SAVING_MANUFACTURER_MODELS = 'setIsSavingManufacturerModels',
  SET_IS_MANUFACTURER_MODELS_SAVED = 'setIsManufacturerModelsSaved',
  SET_IS_LOADING_MANUFACTURER_MODELS = 'setIsLoadingManufacturerModels',
  SET_IS_MANUFACTURER_MODELS_LOADED = 'setIsManufacturerModelsLoaded',

  SET_LIFE_CYCLE_STAGES = 'setLifeCycleStages',
  SET_IS_LOADING_LIFE_CYCLE_STAGES = 'setIsLoadingLifeCycleStages',
  SET_IS_LIFE_CYCLE_STAGES_LOADED = 'setIsLifeCycleStagesLoaded',

  SET_CHART_ASSET_CATEGORIES = 'setChartAssetCategories',
  SET_IS_LOADING_CHART_ASSET_CATEGORIES = 'setIsLoadingChartAssetCategories',
  SET_IS_CHART_ASSET_CATEGORIES_LOADED = 'setIsChartAssetCategoriesLoaded',

  SET_ASSET_IMPORT = "SetAssetImport",
  SET_ASSET_IMPORT_MAPPING = "SetAssetImportMapping",

  SET_ASSET_IMPORT_IS_IMPORTING = "SetAssetImportIsImporting",
  SET_ASSET_IMPORT_DONE = "SetAssetImportDone",
  SET_ASSET_IMPORT_RESULTS = "SetAssetImportResults",

  SET_ASSET_EXPORT_IS_GENERATING = "SetAssetExportIsGenerating",
  SET_ASSET_EXPORT_GENERATE_DONE = "SetAssetExportGenerateDone",
  SET_ASSET_EXPORT_GENERATE_RESULTS = "SetAssetExportGenerateResults",

  SET_ASSET_EXPORTS_IS_LOADING = "SetAssetExportsIsLoading",
  SET_ASSET_EXPORTS_LEADED = "SetAssetExportsLoaded",
  SET_ASSET_EXPORTS = "SetAssetExports"
}

// export enum AssetMutationTypes {
//   SET_ASSETS_CHARTS = 'setAssetsCharts',
//   SET_IS_LOADING_ASSETS_CHARTS = 'setIsLoadingAssetsCharts',

//   SET_ASSETS = 'setAssets',
//   SET_IS_LOADING_ASSETS = 'setIsLoadingAssets',
//   SET_DELETED_ASSETS_DATA = 'setDeletedAssetsData',
//   SET_IS_DELETING_ASSETS = 'setIsDeletingAssets',
//   SET_IS_UPDATING_ASSETS = 'setIsUpdatingAssets',
//   SET_UPDATED_ASSETS_DATA = 'setUpdatedAssetsData',

//   SET_ASSETS_MINE = 'setAssetsMine',
//   SET_IS_LOADING_ASSETS_MINE = 'setIsLoadingAssetsMine',

//   SET_PAGE = 'setPage',
//   SET_PAGESIZE = 'setPageSize',
//   SET_TOTAL = 'setTotal',
//   SET_SEARCH = 'setSearch',
//   SET_FILTER = 'setFilter',
//   SET_AVAILABLEFIELDS = 'setAvailableFields',
//   SET_SORTS = 'setSorts',

//   SET_ASSET = 'setAsset',
//   SET_IS_LOADING_ASSET = 'setIsLoadingAsset',
//   SET_IS_CREATING_ASSET = 'setIsCreatingAsset',
//   SET_CREATED_ASSET_DATA = 'setCreatedAssetData',
//   SET_IS_UPDATING_ASSET = 'setIsUpdatingAsset',
//   SET_UPDATED_ASSET_DATA = 'setUpdatedAssetData',
//   SET_IS_DELETING_ASSET = 'setIsDeletingAsset',
//   SET_DELETED_ASSET_DATA = 'setDeletedAssetData',

//   SET_ASSET_TO_PREVIEW_MOD = 'setAssetToPreviewMod',
//   SET_ASSET_PREVIEW_OPEN_STATE = 'setAssetPreviewOpenState',

//   SET_CATEGORIES = 'setCategories',
//   SET_IS_LOADING_CATEGORIES = 'setIsLoadingCategories',
//   SET_IS_CATEGORIES_LOADED = 'setIsCategoriesLoaded',
//   SET_IS_SAVING_CATEGORIES = 'setIsSavingCategories',

//   SET_MANUFACTURERS = 'setManufacturers',
//   SET_IS_LOADING_MANUFACTURERS = 'setIsLoadingManufacturers',
//   SET_IS_MANUFACTURERS_LOADED = 'setIsManufacturersLoaded',
// }