import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
// import { State } from './state';
// import { KnowledgeActionTypes, KnowledgeState as State } from "@/types/index";
import { KnowledgeActionTypes } from "./action-types";
import { KnowledgeState as State } from "./state";
// import axios, { AxiosRequestConfig } from 'axios';
import { HttpClient as http, AxiosError, AxiosResponse } from "@/util/ZebritAxios";
import { Config } from "../../../config";
// import ZebritAxios from '../../../util/ZebritAxios';
import {
  Knowledge,
  IGetKnowledge,
  FieldDef,
  SortDef,
  FilterDef,
  KnowledgeListFilters,
  KnowledgeListFields,
} from "@/types/index";
import { Mutations } from "./mutations";
import { KnowledgeMutationTypes } from "./mutation-types";
// import { getters as KnowledgeGetters } from './getters';
import { omit } from "lodash";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [KnowledgeActionTypes.GETKNOWLEDGES_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      page: number;
      page_size: number;
      category: string | null;
      search: string | null;
    }
  ): void;
  [KnowledgeActionTypes.GETSEARCHKNOWLEDGES_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      // category: string | null;
      search: string | null;
    }
  ): void;
  [KnowledgeActionTypes.GETKNOWLEDGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetKnowledge
  ): void;
  [KnowledgeActionTypes.GETKNOWLEDGE_BYID_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetKnowledge
  ): void;
  [KnowledgeActionTypes.SAVEKNOWLEDGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: Knowledge
  ): void;
  [KnowledgeActionTypes.DELETEKNOWLEDGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: String
  ): void;
  [KnowledgeActionTypes.SETKNOWLEDGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: Knowledge | null
  ): void;
  [KnowledgeActionTypes.GET_CATEGORIES_ACTION]({ commit }: AugmentedActionContext): void;
  [KnowledgeActionTypes.SET_PAGE](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [KnowledgeActionTypes.SET_PAGESIZE](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [KnowledgeActionTypes.SET_TOTAL](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [KnowledgeActionTypes.SET_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [KnowledgeActionTypes.SET_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ): void;
  [KnowledgeActionTypes.SET_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ): void;
  [KnowledgeActionTypes.SET_SORTFIELDS](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ): void;
  [KnowledgeActionTypes.SET_SEARCHKNOWLEDGES](
    { commit }: AugmentedActionContext,
    payload: Knowledge[]
  ): void;

  [KnowledgeActionTypes.SET_KNOWLEDGE_TO_PREVIEW_MOD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;

  [KnowledgeActionTypes.SET_KNOWLEDGE_PREVIEW_OPEN_STATE_ACTION](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [KnowledgeActionTypes.SET_KNOWLEDGE_CATEGORIES_OPEN_STATE_ACTION](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;
}

// const getters = mapGetters({
//   page: "asset/StatePage",
//   page_size: "asset/StatePageSize",
//   total: "asset/StateTotal",
//   search: "asset/StateSearch",
//   filter: "asset/StateFilter"
// });

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [KnowledgeActionTypes.GETKNOWLEDGES_ACTION]({
    commit,
    getters,
  }: AugmentedActionContext) // payload: {
  //   // page: number;
  //   // page_size: number,
  //   category: string | null;
  //   // search: string | null
  // }
  {
    commit(KnowledgeMutationTypes.SET_IS_LOADING_KNOWLEDGES, true);

    const page = getters.StatePage; // AssetGetters.StatePage() || 1;
    const page_size = getters.StatePageSize; // AssetGetters.StatePageSize || 10;
    const search = getters.StateSearch; // AssetGetters.StateSearch || 1;
    const filter = getters.StateFilter;
    const params: any = {
      $page: page, //payload && payload.page ? payload.page : 1,
      $limit: page_size, //payload && payload.page_size ? payload.page_size : 10,
      //$term: search, //payload && payload.search ? payload.search : null
    };

    if (search) {
      params.$term = search;
      const searchFields = KnowledgeListFields.filter((kf) => kf.searchable === true).map(
        (kf) => kf.id
      );
      params.$searchFields = searchFields;
    }

    KnowledgeListFilters.forEach((f) => {
      const _f = filter.find((__f: FilterDef) => __f.id == f.id);
      if (_f && _f.value != f.value) {
        params[_f.queryParam] = _f.value.id;
      }
    });

    await http
      .get(Config.api.knowledges.knowledges, params, {
        paramsSerializer: {
          indexes: null,
        },
      })
      .then((res: AxiosResponse) => {
        commit(KnowledgeMutationTypes.SET_KNOWLEDGES, res.data.docs);
        // commit(KnowledgeMutationTypes.SET_PAGE, res.data.pages.current);
        // commit(KnowledgeMutationTypes.SET_PAGESIZE, res.data.items.limit);
        // commit(KnowledgeMutationTypes.SET_TOTAL, res.data.items.total);

        commit(KnowledgeMutationTypes.SET_PAGE, res.data.pages.current);
        commit(KnowledgeMutationTypes.SET_PAGESIZE, res.data.items.limit);
        commit(KnowledgeMutationTypes.SET_TOTAL, res.data.items.total);
        // commit(AssetMutationTypes.SET_STARTINDEX, res.data.startIndex);

        commit(KnowledgeMutationTypes.SET_IS_LOADING_KNOWLEDGES, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(KnowledgeMutationTypes.SET_IS_LOADING_KNOWLEDGES, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [KnowledgeActionTypes.GETSEARCHKNOWLEDGES_ACTION]({
    commit,
    getters,
  }: AugmentedActionContext) // payload: {
  //   // category: string | null
  //   search: string | null;
  // }
  {
    commit(KnowledgeMutationTypes.SET_IS_LOADING_SEARCH_KNOWLEDGES, true);

    const page = getters.StatePage; // AssetGetters.StatePage() || 1;
    const page_size = getters.StatePageSize; // AssetGetters.StatePageSize || 10;
    const search = getters.StateSearch; // AssetGetters.StateSearch || 1;
    const params: any = {
      $term: search,
      $page: page,
      $limit: page_size,
    };

    await http
      .get(Config.api.knowledges.knowledges, params)
      .then((res: AxiosResponse) => {
        commit(KnowledgeMutationTypes.SET_SEARCHKNOWLEDGES, res.data.docs);
        // commit(AssetMutationTypes.SET_STARTINDEX, res.data.startIndex);

        commit(KnowledgeMutationTypes.SET_IS_LOADING_SEARCH_KNOWLEDGES, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(KnowledgeMutationTypes.SET_IS_LOADING_SEARCH_KNOWLEDGES, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [KnowledgeActionTypes.GETKNOWLEDGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetKnowledge
  ) {
    //commit(KnowledgeMutationTypes.SET_IS_LOADING_ASSET, true);
    commit(KnowledgeMutationTypes.SET_IS_LOADING_KNOWLEDGE, true);
    // console.log(Config);
    // console.log(Config.api);
    // console.log(Config.api.asset);
    // console.log(Config.api.asset.get);

    await http
      .get(Config.api.knowledge.get.replace("{slug}", payload.slug)) // `api/asset/${id}`)
      .then((res: AxiosResponse) => {
        commit(KnowledgeMutationTypes.SET_KNOWLEDGE, res.data);
        commit(KnowledgeMutationTypes.SET_IS_LOADING_KNOWLEDGE, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(KnowledgeMutationTypes.SET_IS_LOADING_KNOWLEDGE, false);
      });
  },
  async [KnowledgeActionTypes.GETKNOWLEDGE_BYID_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetKnowledge
  ) {
    //commit(KnowledgeMutationTypes.SET_IS_LOADING_ASSET, true);
    commit(KnowledgeMutationTypes.SET_IS_LOADING_KNOWLEDGE, true);
    // console.log(Config);
    // console.log(Config.api);
    // console.log(Config.api.asset);
    // console.log(Config.api.asset.get);

    await http
      .get(Config.api.knowledge.getByID.replace("{id}", payload.id.toString())) // `api/asset/${id}`)
      .then((res: AxiosResponse) => {
        commit(KnowledgeMutationTypes.SET_KNOWLEDGE, res.data);
        commit(KnowledgeMutationTypes.SET_IS_LOADING_KNOWLEDGE, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(KnowledgeMutationTypes.SET_IS_LOADING_KNOWLEDGE, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [KnowledgeActionTypes.SAVEKNOWLEDGE_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: Knowledge
  ) {
    const actionType = payload._id
      ? KnowledgeMutationTypes.SET_IS_CREATING_KNOWLEDGE
      : KnowledgeMutationTypes.SET_IS_UPDATING_KNOWLEDGE;
    commit(actionType, true);

    // await axios.put(`api/assets/${payload.id}`, payload)
    //   .then(res => {
    //     commit(AssetMutationTypes.SET_ASSET, res.data)
    //     commit(actionType, false);
    //     dispatch(AssetActionTypes.GETASSETS_ACTION);
    //   })
    //   .catch(err => {
    //     console.log('error', err);
    //     commit(actionType, false);
    //   })
    // const axiosConfig: AxiosRequestConfig = {
    //   method: payload.id ? "PUT" : "POST",
    //   url:
    //     Config.api.knowledges.knowledge + (payload.id ? `/${payload.id}` : ""),
    //   data: payload,
    // };
    // await http(axiosConfig)
    const isNew = payload._id === "" || payload._id === null || payload._id === undefined;
    await http
      .request(
        isNew ? "POST" : "PUT",
        Config.api.knowledges.knowledge + (!isNew ? `/${payload._id}` : ""),
        omit(payload, isNew ? ["categoryName", "_id"] : ["categoryName"])
      )
      .then((res: AxiosResponse) => {
        commit(KnowledgeMutationTypes.SET_KNOWLEDGE, res.data);
        commit(actionType, false);
        dispatch(KnowledgeActionTypes.GETKNOWLEDGES_ACTION);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(actionType, false);
      });
  },

  async [KnowledgeActionTypes.DELETEKNOWLEDGE_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: String
  ) {
    commit(KnowledgeMutationTypes.SET_IS_DELETING_KNOWLEDGE, true);
    commit(KnowledgeMutationTypes.SET_DELETED_KNOWLEDGE_SUCCEEDED, false);

    await http
      .request("DELETE", Config.api.knowledges.knowledge, [payload])
      .then((res: AxiosResponse) => {
        // commit(KnowledgeMutationTypes.SET_KNOWLEDGE, res.data);
        commit(KnowledgeMutationTypes.SET_DELETED_KNOWLEDGE_SUCCEEDED, true);
        commit(KnowledgeMutationTypes.SET_DELETED_KNOWLEDGE_DATA, res.data);
        commit(KnowledgeMutationTypes.SET_IS_DELETING_KNOWLEDGE, false);
        dispatch(KnowledgeActionTypes.GETKNOWLEDGES_ACTION);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(KnowledgeMutationTypes.SET_DELETED_KNOWLEDGE_SUCCEEDED, false);
        commit(KnowledgeMutationTypes.SET_DELETED_KNOWLEDGE_DATA, err.message);
        commit(KnowledgeMutationTypes.SET_IS_DELETING_KNOWLEDGE, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [KnowledgeActionTypes.SETKNOWLEDGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: Knowledge | null
  ) {
    // const actionType = (payload.asset.id) ?
    //   AssetMutationTypes.SET_IS_CREATING_ASSET
    //   :
    //   AssetMutationTypes.SET_IS_UPDATING_ASSET;
    // commit(actionType, true);

    commit(KnowledgeMutationTypes.SET_KNOWLEDGE, payload);
    // commit(actionType, false);
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [KnowledgeActionTypes.GET_CATEGORIES_ACTION]({ commit }: AugmentedActionContext) {
    commit(KnowledgeMutationTypes.SET_IS_LOADING_CATEGORIES, true);
    await http
      .get(Config.api.knowledges.knowledgesCategories)
      .then((res: AxiosResponse) => {
        commit(KnowledgeMutationTypes.SET_CATEGORIES, res.data.docs);
        commit(KnowledgeMutationTypes.SET_IS_LOADING_CATEGORIES, false);
        commit(KnowledgeMutationTypes.SET_IS_CATEGORIES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(KnowledgeMutationTypes.SET_CATEGORIES, []);
        commit(KnowledgeMutationTypes.SET_IS_LOADING_CATEGORIES, false);
        commit(KnowledgeMutationTypes.SET_IS_CATEGORIES_LOADED, false);
      });
  },

  [KnowledgeActionTypes.SET_PAGE]({ commit }: AugmentedActionContext, payload: number) {
    commit(KnowledgeMutationTypes.SET_PAGE, payload);
  },

  [KnowledgeActionTypes.SET_PAGESIZE](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(KnowledgeMutationTypes.SET_PAGESIZE, payload);
  },

  [KnowledgeActionTypes.SET_TOTAL]({ commit }: AugmentedActionContext, payload: number) {
    commit(KnowledgeMutationTypes.SET_TOTAL, payload);
  },

  [KnowledgeActionTypes.SET_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(KnowledgeMutationTypes.SET_SEARCH, payload);
  },

  [KnowledgeActionTypes.SET_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ) {
    commit(KnowledgeMutationTypes.SET_FILTER, payload);
  },

  [KnowledgeActionTypes.SET_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ) {
    commit(KnowledgeMutationTypes.SET_AVAILABLEFIELDS, payload);
  },

  [KnowledgeActionTypes.SET_SORTFIELDS](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ) {
    commit(KnowledgeMutationTypes.SET_SORTFIELDS, payload);
  },

  [KnowledgeActionTypes.SET_SEARCHKNOWLEDGES](
    { commit }: AugmentedActionContext,
    payload: Knowledge[]
  ) {
    commit(KnowledgeMutationTypes.SET_SEARCHKNOWLEDGES, payload);
  },

  [KnowledgeActionTypes.SET_KNOWLEDGE_TO_PREVIEW_MOD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(KnowledgeMutationTypes.SET_KNOWLEDGE_TO_PREVIEW_MOD, payload);
  },

  [KnowledgeActionTypes.SET_KNOWLEDGE_PREVIEW_OPEN_STATE_ACTION](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(KnowledgeMutationTypes.SET_KNOWLEDGE_PREVIEW_OPEN_STATE, payload);
  },

  [KnowledgeActionTypes.SET_KNOWLEDGE_CATEGORIES_OPEN_STATE_ACTION](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(KnowledgeMutationTypes.SET_KNOWLEDGE_CATEGORIES_OPEN_STATE, payload);
  },
};
