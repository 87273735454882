// import store from '../store'
import { store } from "@/store";
// import App from '../main';
// import { useRouter, useRoute } from 'vue-router'
import router from '@/router/index'

// import { RESPONSE_MESSAGES } from '../config'
import { Config } from "../config";

const RESPONSE_MESSAGES = Config.RESPONSE_MESSAGES;

const authInterceptorService = {

  responseError: function(error:any) {
    let response = error.response
  
    // var Notification = $injector.get('Notification');
  
    // If the access token was expired, allow the apiHelperService to try a refresh token
    if (
      response.status === 401 &&
      response.data.message === RESPONSE_MESSAGES.EXPIRED_ACCESS_TOKEN
    ) {
      console.debug('authInterceptor.service: 401: response:', response)
  
      response = RESPONSE_MESSAGES.EXPIRED_ACCESS_TOKEN
  
      // If the token was invalid or the Refresh Token was expired, force user to login
    } else if (response.status === 401) {
      console.debug('authInterceptor.service: 401: response:', response)
  
      store.dispatch('auth/clearAuth')
      // this.÷`÷$router
      
      // const router = useRouter()
    // const route = useRoute()
      router.push('/login')
    } else if (response.status === 403) {
      // The user is unauthorized
      console.debug('authInterceptor.service: 403: response:', response)
  
      // vm.$snotify.warning('Not authorized: ' + response.data.message, 'Warning')
      // App.notify({
      //   group: "notification",
      //   title: "Warning",
      //   text: "Not authorized:" + response.data.message 
      // }, 2000) // 2s

    }
    // If not a 401 or 403, do nothing with this error. This is necessary to make a `responseError`
    // interceptor a no-op. */
    return Promise.reject(response)
  }

}

// const internals = {}

export default authInterceptorService

// export default {
//   responseError: internals.responseError
// }
