import { Tag } from '@/types/index';

export type State = {
  tags: Tag[],
  isLoadingTags: boolean,
  isTagsLoaded: boolean,
};

export const state: State = {
  tags: [],
  isLoadingTags: false,
  isTagsLoaded: false
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): State => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};