export enum TicketActionTypes {
    GET_TICKETS_ACTION = "GetTickets",
    GET_MY_TICKETS_ACTION = "GetMyTickets",
    // GET_TICKETS_CHARTS_ACTION = "GetTicketsCharts",
    GET_TICKET_ACTION = "GetTicket",
    SET_TICKET_ACTION = "SetTicket",
    SET_TICKETS_ACTION = "SetTickets",
    SAVE_TICKET_ACTION = "SaveTicket",
    SAVE_MY_TICKET_ACTION = "SaveMyTicket",
    CHANGE_TICKET_STATUS_ORDER_ACTION = "ChangeTicketStatusOrder",
    GET_CATEGORIES_ACTION = "GetCategories",
    GET_MANUFACTURERS_ACTION = "GetManufacturers",
    DELETE_TICKET_ACTION = "DeleteTicket",
  
    SET_TICKET_TO_PREVIEW_MOD_ACTION = "SetTicketToPreviewModAction",
    SET_TICKET_PREVIEW_OPEN_STATE = "SetTicketPreviewOpenStateAction",
  
    GET_TYPES_ACTION = "GetTypes",
    GET_SEVERITIES_ACTION = "GetSeverities",
    GET_STATUSES_ACTION = "GetStatuses",
  
    GET_TICKET_ACTIVITY_TYPES_ACTION = "GetTicketActivityTypes",
    GET_TICKET_ACTIVITIES_ACTION = "GetTicketActivities",
    SAVE_TICKET_ACTIVITY = "SaveTicketActivity",
    ASSIGN_TICKET_ACTIVITY = "AssignTicketActivity",
  
    SET_PAGE = "SetPage",
    SET_PAGESIZE = "SetPageSize",
    SET_TOTAL = "SetTotal",
    SET_SEARCH = "SetSearch",
    SET_FILTER = "SetFilter",
    SET_AVAILABLEFIELDS = "SetAvailableFields",
    SET_SORTS = "SetSorts",
  
    SET_MY_PAGE = "SetMyPage",
    SET_MY_PAGESIZE = "SetMyPageSize",
    SET_MY_TOTAL = "SetMyTotal",
    SET_MY_SEARCH = "SetMySearch",
    SET_MY_FILTER = "SetMyFilter",
    SET_MY_AVAILABLEFIELDS = "SetMyAvailableFields",
    SET_MY_SORTS = "SetMySorts",
  



    GET_TICKETS_KANBAN_ACTION = "GetTicketsKanban",

    SET_PAGE_KANBAN = "SetPageKanban",
    SET_PAGESIZE_KANBAN = "SetPageSizeKanban",
    SET_TOTAL_KANBAN = "SetTotalKanban",
    SET_SEARCH_KANBAN = "SetSearchKanban",
    SET_FILTER_KANBAN = "SetFilterKanban",
    SET_AVAILABLEFIELDS_KANBAN = "SetAvailableFieldsKanban",
    SET_SORTS_KANBAN = "SetSortsKanban",


    
    GET_CHART_TYPES = "GetChartTypes",
    GET_CHART_SEVERITIES = "GetChartSeverities",
    GET_CHART_STATUSES = "GetChartStatuses",
    GET_CHART_MONTHLY = "GetChartMonthly"
  }