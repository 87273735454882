import {
  ActionContext,
  ActionTree
} from 'vuex';
import { RootState } from '@/store';
import { AppActionTypes } from './action-types';
import { AppState as State, ThemeModes } from './state';
import { Mutations } from './mutations';
import { AppMutationTypes } from './mutation-types';
// import { getters as AssetGetters } from './getters';


type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [AppActionTypes.GETMODE_ACTION](
    { commit }: AugmentedActionContext
  ): ThemeModes;

  [AppActionTypes.SETMODE_ACTION](
    { commit }: AugmentedActionContext,
    payload: ThemeModes
  ): void;

  [AppActionTypes.GET_API_VERSION_ACTION](
    { commit }: AugmentedActionContext
  ): string;

  [AppActionTypes.SET_API_VERSION_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;

}

export const actions: ActionTree<State, RootState> & Actions = {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [AppActionTypes.GETMODE_ACTION](
    { commit }: AugmentedActionContext
  ) {

    let mode;
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      mode = <ThemeModes>'dark'
    } else {
      mode = <ThemeModes>'light'
    }
    return mode;

  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [AppActionTypes.SETMODE_ACTION](
    { commit }: AugmentedActionContext,
    payload: ThemeModes
  ) {
    
    localStorage.theme = payload
    commit(AppMutationTypes.SET_THEME_MODE, payload);
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [AppActionTypes.GET_API_VERSION_ACTION](
    { commit }: AugmentedActionContext
  ) {

    let apiversion;
    if ( !('apiversion' in localStorage) ) {
      apiversion = <string>'obtaining'
    } else {
      apiversion = <string>localStorage.apiversion
    }
    return apiversion;

  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [AppActionTypes.SET_API_VERSION_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ) {
    
    localStorage.apiversion = payload
    commit(AppMutationTypes.SET_API_VERSION, payload);
  }

}
