<template>
    <a  href="#" @click="toggleThemeMod" class="block py-2 px-4 text-sm text-gray-700 dark:text-gray-300 flex">
      <SunIcon v-if="themeMod == 'dark'" class="mr-2 h-5 w-5" aria-hidden="true" />
      <MoonIcon v-if="themeMod == 'light'" class="mr-2 h-5 w-5" aria-hidden="true" />
      <span v-if="themeMod == 'dark'" >Light Theme</span>
      <span v-if="themeMod == 'light'" >Dark Theme</span>
    </a>
</template>
<script>
import { MoonIcon, SunIcon } from "@heroicons/vue/24/outline";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TopMenuThemeColorMenuItem",
  components: {
    MoonIcon,
    SunIcon
  },
  computed: {
    ...mapGetters({
      themeMod: "app/StateMode",
    })
  },
  methods: {
    ...mapActions(["app/GetMode", "app/SetMode"]),
    toggleThemeMod() {
      let currentTheme = this.themeMod;
      if (currentTheme == "dark") {
        this["app/SetMode"]("light");
      } else {
        this["app/SetMode"]("dark");
      }
      currentTheme = this.themeMod;
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.remove("light");
      document.documentElement.classList.add(currentTheme);
    },
  },
};
</script>
