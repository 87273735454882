// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum OrganisationMutationTypes {
  RESET = "reset",

  SET_ORGANIZATION = 'setOrganisation',
  SET_SAVING_ORGANIZATION = 'setSavingOrganisation',
  SET_SAVE_ORGANIZATION_ERROR = 'setSaveOrganisationError',
  SET_LOADING_ORGANIZATION = 'setLoadingOrganisation',

  SET_TESTING_ORGANISATION_SUPPORT_EMAIL = 'setTestingOrganisationSupportEmail',
  SET_TESTED_ORGANISATION_SUPPORT_EMAIL = 'setTestedOrganisationSupportEmail',
  SET_TEST_ORGANISATION_SUPPORT_EMAILERROR = 'setTestOrganisationSupportEmailError',
  SET_TEST_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR = 'setTestOrganisationSupportEmailValidationError',

  SET_SAVING_ORGANISATION_SUPPORT_EMAIL = 'setSavingOrganisationSupportEmail',
  SET_SAVED_ORGANISATION_SUPPORT_EMAIL = 'setSavedOrganisationSupportEmail',
  SET_SAVE_ORGANISATION_SUPPORT_EMAILERROR = 'setSaveOrganisationSupportEmailError',
  SET_SAVE_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR = 'setSaveOrganisationSupportEmailValidationError',

  SET_USERS = 'setUsers',
  SET_LOADING_USERS = 'setLoadingUsers',
  SET_USERS_LOADED = 'setUsersLoaded',

  SET_USERS_PAGE = 'setUsersPage',
  SET_USERS_PAGESIZE = 'setUsersPageSize',
  SET_USERS_TOTAL = 'setUsersTotal',
  SET_USERS_SEARCH = 'setUsersSearch',
  SET_USERS_FILTER = 'setUsersFilter',
  SET_USERS_AVAILABLEFILEDS = 'setUsersAvailableFileds',
  SET_USERS_SORTS = 'setUsersSorts',

  SET_GROUPS = 'setGroups',
  SET_LOADING_GROUPS = 'setLoadingGroups',

  SET_GROUPS_PAGE = 'setGroupsPage',
  SET_GROUPS_PAGESIZE = 'setGroupsPageSize',
  SET_GROUPS_TOTAL = 'setGroupsTotal',
  SET_GROUPS_SEARCH = 'setGroupsSearch',
  SET_GROUPS_FILTER = 'setGroupsFilter',
  SET_GROUPS_AVAILABLEFILEDS = 'setGroupsAvailableFileds',
  SET_GROUPS_SORTS = 'setGroupsSorts',

  SET_ROLES = 'setRoles',
  SET_LOADING_ROLES = 'setLoadingRoles',
  SET_ROLES_LOADED = 'setRolesLoaded',

  SET_PERMISSIONS = 'setPermissions',
  SET_LOADING_PERMISSIONS = 'setLoadingPermissions',

  SET_ROLES_PAGE = 'setRolesPage',
  SET_ROLES_PAGESIZE = 'setRolesPageSize',
  SET_ROLES_TOTAL = 'setRolesTotal',
  SET_ROLES_SEARCH = 'setRolesSearch',
  SET_ROLES_FILTER = 'setRolesFilter',
  SET_ROLES_AVAILABLEFILEDS = 'setRolesAvailableFileds',
  SET_ROLES_SORTS = 'setRolesSorts',

  SET_CRUD_FOCUSED_USER = 'setCrudFocusedUser',
  SET_LOADING_CRUD_FOCUSED_USER = 'setLoadingCrudFocusedUser',
  SET_SAVING_CRUD_FOCUSED_USER = 'setSavingCrudFocusedUser',

  SET_IS_CREATING_CRUD_FOCUSED_USER = 'setIsCreatingCrudFocusedUser',
  SET_CREATING_CRUD_FOCUSED_USER_SUCCEEDED = 'setCreatingCrudFocusedUserSucceeded',
  SET_CREATING_CRUD_FOCUSED_USER_DATA = 'setCreatingCrudFocusedUserData',

  SET_DELETED_CRUD_FOCUSED_USER_DATA = 'setDeletedCrudFocusedUserData',
  SET_DELETED_CRUD_FOCUSED_USER_SUCCEEDED = 'setDeletedCrudFocusedUserSucceeded',

  SET_CRUD_FOCUSED_GROUP = 'setCrudFocusedGroup',
  SET_LOADING_CRUD_FOCUSED_GROUP = 'setloadingCrudFocusedGroup',
  SET_SAVING_CRUD_FOCUSED_GROUP = 'setSavingCrudFocusedGroup',

  SET_CRUD_FOCUSED_ROLE = 'setCrudFocusedRole',
  SET_LOADING_CRUD_FOCUSED_ROLE = 'setloadingCrudFocusedRole',
  SET_SAVING_CRUD_FOCUSED_ROLE = 'setSavingCrudFocusedRole',

  SET_INVITE_ERROR = 'setInviteUserError',
  SET_INVITE_ERROR_MESSAGE = 'setInviteUserErrorMessage'
}