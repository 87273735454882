import { GetterTree } from 'vuex';
import { RootState } from '@/store';
// import { State } from './state';
import {
    Ticket,
    FilterDef,
    TicketChart,
    TicketCategory,
    // TicketGetters,
    // TicketManufacturer,
    FilterOptionDef,
    FieldDef,
    SortDef,
    // TicketState as State
} from '@/types/index';
import { TicketState as State } from './state';

export type TicketGetters = {
    StateTicketsCharts(state: State): TicketChart[];
    StateIsLoadingTicketsCharts(state: State): boolean;
  
    StateTickets(state: State): Ticket[];
    StateIsLoadingTickets(state: State): boolean;
    StateIsUpdatingTickets(state: State): boolean;
    StateUpdatedTicketsData(state: State): any;
    StateIsDeletingTickets(state: State): boolean;
    StateDeletedTicketsData(state: State): any;
  
    StatePage(state: State): number;
    StatePageSize(state: State): number;
    StateTotal(state: State): number;
    StateSearch(state: State): string | null;
    StateFilter(state: State): FilterDef[];
    StateAvailableFields(state: State): FieldDef[];
    StateSorts(state: State): SortDef[];


    StateMyTickets(state: State): Ticket[];
    StateMyIsLoadingTickets(state: State): boolean;
    StateMyIsUpdatingTickets(state: State): boolean;
    StateMyUpdatedTicketsData(state: State): any;
    StateMyIsDeletingTickets(state: State): boolean;
    StateMyDeletedTicketsData(state: State): any;

    StateMyPage(state: State): number;
    StateMyPageSize(state: State): number;
    StateMyTotal(state: State): number;
    StateMySearch(state: State): string | null;
    StateMyFilter(state: State): FilterDef[];
    StateMyAvailableFields(state: State): FieldDef[];
    StateMySorts(state: State): SortDef[];


    StateTicketsKanban(state: State): Map<string, Ticket[]>;
    StateIsLoadingTicketsKanban(state: State): Map<string, boolean>;
    StateIsUpdatingTicketsKanban(state: State): Map<string, boolean>;
    // StateUpdatedTicketsKanbanData(state: State): any;
    StateIsDeletingTicketsKanban(state: State): Map<string, boolean>;
    // StateDeletedTicketsKanbanData(state: State): any;
  
    StatePageKanban(state: State): Map<string, number>;
    StatePageSizeKanban(state: State): Map<string, number>;
    StateTotalKanban(state: State): Map<string, number>;
    StateSearchKanban(state: State): Map<string, string | null>;
    StateFilterKanban(state: State): Map<string, FilterDef[]>;
    StateAvailableFieldsKanban(state: State): Map<string, FieldDef[]>;
    StateSortsKanban(state: State): Map<string, SortDef[]>;

  
    StateTicket(state: State): Ticket | null;
    StateTicketError(state: State): String | null,
    StateTicketErrorValidation(state: State): any,
    StateIsLoadingTicket(state: State): boolean;
    StateIsTicketLoaded(state: State): boolean;
    StateIsCreatingTicket(state: State): boolean;
    StateIsUpdatingTicket(state: State): boolean;
    StateIsTicketUpdated(state: State): boolean;
    StateIsDeletingTicket(state: State): boolean;
  
    StateTicketToPreviewMod(state: State): string | null;
    StateTicketPreviewOpenState(state: State): boolean;
  
    StateCreatedData(state: State): any;
    StateUpdatedData(state: State): any;
    StateIsDeleting(state: State): boolean;
    StateDeletedTicketSucceeded(state: State): boolean;
    StateDeletedData(state: State): any;
  
    StateCategories: (state: State) => TicketCategory[];
    StateIsLoadingCategories: (state: State) => boolean;
    StateCategoriesLoaded: (state: State) => boolean;
  
    StateTypes: (state: State) => FilterOptionDef[];
    StateIsLoadingTypes: (state: State) => boolean;
    StateTypesLoaded: (state: State) => boolean;
  
    StateSeverities: (state: State) => FilterOptionDef[];
    StateIsLoadingSeverities: (state: State) => boolean;
    StateSeveritiesLoaded: (state: State) => boolean;
  
    StateStatuses: (state: State) => FilterOptionDef[];
    StateIsLoadingStatuses: (state: State) => boolean;
    StateStatusesLoaded: (state: State) => boolean;
  
    // StateManufacturers: (state: State) => TicketManufacturer[],
    //StateIsLoadingManufacturers: (state: State) => boolean,
    //StateIsManufacturersLoaded: (state: State) => boolean,
  };

export const getters: GetterTree<State, RootState> & TicketGetters = {
    StateTicketsCharts: (state: State) => state.ticketsCharts,
    StateIsLoadingTicketsCharts: (state: State) => state.isLoadingTicketsCharts,

    StateTickets: (state: State) => state.tickets,
    StateIsLoadingTickets: (state: State) => state.isLoadingTickets,
    StateIsUpdatingTickets: (state: State) => state.isUpdatingTickets,
    StateUpdatedTicketsData: (state: State) => state.updatedTicketsData,
    StateIsDeletingTickets: (state: State) => state.isDeletingTickets,
    StateDeletedTicketsData: (state: State) => state.deletedTicketsData,

    StatePage: (state: State) => state.page,
    StatePageSize: (state: State) => state.pageSize,
    StateTotal: (state: State) => state.total,
    StateSearch: (state: State) => state.search,
    StateFilter: (state: State) => state.filters,
    StateAvailableFields: (state: State) => state.availableFields,
    StateSorts: (state: State) => state.sorts,



    StateMyTickets: (state: State) => state.myTickets,
    StateMyIsLoadingTickets: (state: State) => state.myIsLoadingTickets,
    StateMyIsUpdatingTickets: (state: State) => state.myIsUpdatingTickets,
    StateMyUpdatedTicketsData: (state: State) => state.myUpdatedTicketsData,
    StateMyIsDeletingTickets: (state: State) => state.myIsDeletingTickets,
    StateMyDeletedTicketsData: (state: State) => state.myDeletedTicketsData,

    StateMyPage: (state: State) => state.myPage,
    StateMyPageSize: (state: State) => state.myPageSize,
    StateMyTotal: (state: State) => state.myTotal,
    StateMySearch: (state: State) => state.mySearch,
    StateMyFilter: (state: State) => state.myFilters,
    StateMyAvailableFields: (state: State) => state.myAvailableFields,
    StateMySorts: (state: State) => state.mySorts,


    StateTicketsKanban: (state: State) => state.ticketsKanban,
    StateIsLoadingTicketsKanban: (state: State) => state.isLoadingTicketsKanban,
    StateIsUpdatingTicketsKanban: (state: State) => state.isUpdatingTicketsKanban,
    // StateUpdatedTicketsKanbanData: (state: State) => state.updatedTicketsKanbanData,
    StateIsDeletingTicketsKanban: (state: State) => state.isDeletingTicketsKanban,
    // StateDeletedTicketsKanbanData: (state: State) => state.deletedTicketsKanbanData,

    StatePageKanban: (state: State) => state.pageKanban,
    StatePageSizeKanban: (state: State) => state.pageSizeKanban,
    StateTotalKanban: (state: State) => state.totalKanban,
    StateSearchKanban: (state: State) => state.searchKanban,
    StateFilterKanban: (state: State) => state.filtersKanban,
    StateAvailableFieldsKanban: (state: State) => state.availableFieldsKanban,
    StateSortsKanban: (state: State) => state.sortsKanban,


    StateTicket: (state: State) => state.ticket,
    StateTicketError: (state: State) => state.ticketError,
    StateTicketErrorValidation: (state: State) => state.ticketErrorValidation,
    StateIsLoadingTicket: (state: State) => state.isLoadingTicket,
    StateIsTicketLoaded: (state: State) => state.isTicketLoaded,
    StateIsCreatingTicket: (state: State) => state.isCreatingTicket,
    StateIsUpdatingTicket: (state: State) => state.isUpdatingTicket,
    StateIsTicketUpdated: (state: State) => state.isTicketUpdated,
    StateIsDeletingTicket: (state: State) => state.isDeletingTicket,

    StateTicketToPreviewMod: (state: State) => state.ticketToPreviewMod,
    StateTicketPreviewOpenState: (state: State) => state.ticketPreviewOpenState,

    StateTicketActivities: (state: State) => state.ticketActivities,
    StateIsLoadingTicketActivities: (state: State) => state.isLoadingTicketActivities,
    StateIsCreatingActivity: (state: State) => state.isCreatingTicketActivity,
    StateIsUpdatingActivity: (state: State) => state.isUpdatingTicketActivity,
    StateIsDeletingActivity: (state: State) => state.isDeletingTicketActivity,


    StateTicketActivityTypes: (state: State) => state.ticketActivityTypes,
    StateIsLoadingTicketActivityTypes: (state: State) => state.isLoadingTicketActivityTypes,
    StateIsTicketActivityTypesLoaded: (state: State) => state.isTicketActivityTypesLoaded,
    StateIsSavingTicketActivity: (state: State) => state.isSavingTicketActivity,
    StateIsAssigningTicketActivity: (state: State) => state.isAssigningTicketActivity,

    StateCreatedData: (state: State) => state.createdTicketData,
    StateUpdatedData: (state: State) => state.updatedTicketData,
    StateIsDeleting: (state: State) => state.isDeletingTicket,

    StateDeletedTicketSucceeded: (state: State) => state.deletedTicketSucceeded,
    StateDeletedData: (state: State) => state.deletedTicketData,

    StateCategories: (state: State) => state.categories,
    StateIsLoadingCategories: (state: State) => state.isLoadingCategories,
    StateCategoriesLoaded: (state: State) => state.isCategoriesLoaded,


    StateTypes: (state: State) => state.types,
    StateIsLoadingTypes: (state: State) => state.isLoadingTypes,
    StateTypesLoaded: (state: State) => state.isTypesLoaded,


    StateSeverities: (state: State) => state.severities,
    StateIsLoadingSeverities: (state: State) => state.isLoadingSeverities,
    StateSeveritiesLoaded: (state: State) => state.isSeveritiesLoaded,


    StateStatuses: (state: State) => state.statuses,
    StateIsLoadingStatuses: (state: State) => state.isLoadingStatuses,
    StateStatusesLoaded: (state: State) => state.isStatusesLoaded,


    StateChartTypes: (state: State) => state.chartTypes,
    StateIsLoadingChartTypes: (state: State) => state.isLoadingChartTypes,
    StateChartTypesLoaded: (state: State) => state.isChartTypesLoaded,

    StateChartSeverities: (state: State) => state.chartSeverities,
    StateIsLoadingChartSeverities: (state: State) => state.isLoadingChartSeverities,
    StateChartSeveritiesLoaded: (state: State) => state.isChartSeveritiesLoaded,

    StateChartStatuses: (state: State) => state.chartStatuses,
    StateIsLoadingChartStatuses: (state: State) => state.isLoadingChartStatuses,
    StateChartStatusesLoaded: (state: State) => state.isChartStatusesLoaded,

    StateChartMonthly: (state: State) => state.chartMonthly,
    StateIsLoadingChartMonthly: (state: State) => state.isLoadingChartMonthly,
    StateChartMonthlyLoaded: (state: State) => state.isChartMonthlyLoaded,
    // StateManufacturers: (state: State) => state.manufacturers,
    // StateIsLoadingManufacturers: (state: State) => state.isLoadingManufacturers,
    // StateIsManufacturersLoaded: (state: State) => state.isManufacturersLoaded,
};
