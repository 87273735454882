import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { TagActionTypes } from "./action-types";
// import axios, { AxiosResponse, AxiosError } from 'axios';
import { Config } from "../../../config";
import { Mutations } from "./mutations";
import { TagMutationTypes } from "./mutation-types";
import { HttpClient as http, AxiosError } from "@/util/ZebritAxios";
import { Tag } from "@/types/index";
// import { LoginBody, LoginResponse } from "@/types/LoginTypes";
// import { AuthActionTypes } from "@/store/modules/auth/action-types";
// import { AuthMutationTypes } from "@/store/modules/auth/mutation-types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [TagActionTypes.LOAD_TAGS_ACTION]({ commit }: AugmentedActionContext): void;

  [TagActionTypes.SAVE_TAG_ACTION](
    { commit }: AugmentedActionContext,
    payload: Tag
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [TagActionTypes.LOAD_TAGS_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.tag.tags)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(TagMutationTypes.SET_IS_LOADING_TAGS, false);
            if (response.data) {
              // commit(AuthMutationTypes.SET_LOGIN_ERROR, response.data.error);
              // commit(AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, response.data.message);
            } else if (response.isAxiosError) {
              // commit(AuthMutationTypes.SET_LOGIN_ERROR, 0);
              // commit(AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, response.message);
            }
            commit(TagMutationTypes.SET_TAGS, []);
            resolve(response);
          } else {
            commit(TagMutationTypes.SET_TAGS, response.data);
            resolve(response);
          }
        })
        .catch((err:AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async [TagActionTypes.SAVE_TAG_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: Tag
  ) {
    console.log("SAVE_TAG_ACTION");
    console.log(payload);
    console.log(payload._id);

    //await axios.put(Config.api.account.tag + `/${payload.id}`, payload)
    await http
      .put(Config.api.tag.tags, payload)
      // .then((response:AxiosResponse) => {
      //   // commit(AuthMutationTypes.SET_TAG, response.data);
      //   // dispatch(AuthActionTypes.LOADCURRENTTAG_ACTION);
      // })
      .catch((err:AxiosError) => {
        console.log("error", err);
      });
  },
};
