<template>
  <Menu as="div" class="flex-shrink-0 relative ml-5" v-if="user">
    <div>
      <MenuButton
        class="bg-white dark:bg-gray-900 rounded-full flex items-center text-sm ring-offset-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-themeprimary-500"
        data-testid="user-menu-btn"
      >
        <span class="sr-only">Open user menu</span>
        <!-- <TopMenuOrganisationLogo /> -->
        <TopMenuUserProfileAvatar />
        <span class="hidden text-gray-700 dark:text-gray-300 text-sm font-medium lg:block"
          ><span class="sr-only"
            >Open user menu for {{ user.firstName || user.email }}</span
          >
        </span>
        <!-- <ChevronDownIcon
          class="
            hidden
            flex-shrink-0
            ml-1
            h-5
            w-5
            text-gray-400
            dark:text-gray-600
            lg:block
          "
          aria-hidden="true"
        /> -->
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="origin-top-right absolute z-40 right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-900 ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-5 py-1 ring-offset-transparent focus:outline-none divide-y divide-gray-100 dark:divide-gray-800"
      >
        <!-- divide-y divide-gray-100
          dark:divide-gray-800 -->
        <div class="px-4 py-3 text-center relative">
          <!-- <p class="text-sm text-gray-400 dark:text-gray-600">Signed in as</p> -->
          <img
            class="h-24 w-24 rounded-full m-auto mb-2"
            :src="baseFileUrl + user.avatarUrl"
            :alt="user.email"
          />
          <p class="font-medium text-gray-700 dark:text-gray-300" data-testid="profilename">
            <template v-if="user.firstName && user.lastName">
              {{ user.firstName }} {{ user.lastName }}
            </template>
            <template v-else>
              {{ user.email }}
            </template>
          </p>
          <!--          <div-->
          <!--            class="-->
          <!--              h-10-->
          <!--              w-32-->
          <!--              m-auto-->
          <!--              rounded-m-->
          <!--              p-2-->
          <!--              border-->
          <!--              rounded-full-->
          <!--              border-gray-300-->
          <!--              dark:border-gray-700-->
          <!--              bg-white-->
          <!--            "-->
          <!--          >-->
          <!--            <img class="h-full w-full" src="@/assets/dextern-logo.png" alt="" />-->
          <!--          </div>-->
          <p v-if="organisation" class="text-xs text-gray-500 dark:text-gray-500">
            {{ organisation.name }}
          </p>
        </div>

        <div>
          <MenuItem>
            <TopMenuThemeColorMenuItem />
          </MenuItem>
          <MenuItem
            v-for="item in userSecondaryMenus"
            :key="item.path"
            v-slot="{ active }"
            :data-testid="'user-' + kebabCase(item.name) + '-btn'"
          >
            <router-link
              :to="item.path"
              :class="[
                active ? 'bg-gray-100 dark:bg-gray-800' : '',
                'block py-2 px-4 text-sm text-gray-700 dark:text-gray-300 flex',
              ]"
            >
              <component :is="item.meta.icon" class="mr-2 h-5 w-5" aria-hidden="true" />
              {{ item.name }}</router-link
            >
          </MenuItem>
        </div>
        <div>
          <MenuItem
            v-for="item in userMenus"
            :key="item.path"
            v-slot="{ active }"
            :data-testid="'user-' + kebabCase(item.name) + '-btn'"
          >
            <router-link
              :to="item.path"
              :class="[
                active ? 'bg-gray-100 dark:bg-gray-800' : '',
                'block py-2 px-4 text-sm text-gray-700 dark:text-gray-300 flex',
              ]"
            >
              <component :is="item.meta.icon" class="mr-2 h-5 w-5" aria-hidden="true" />
              {{ item.name }}</router-link
            >
          </MenuItem>

          <MenuItem v-slot="{ active }" :data-testid="'user-lgout-btn'">
            <a
              href="#"
              :class="[
                active ? 'bg-gray-100 dark:bg-gray-900' : '',
                'block py-2 px-4 text-sm text-gray-700 dark:text-gray-300 flex',
              ]"
              @click.prevent="logout"
            >
              <ArrowRightOnRectangleIcon class="mr-2 h-5 w-5" aria-hidden="true" />
              Sign out</a
            >
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script>
import { Config } from "@/config";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ArrowRightOnRectangleIcon } from "@heroicons/vue/24/outline";
// import { mapGetters, mapActions } from "vuex";
import TopMenuUserProfileAvatar from "./TopMenuUserProfileAvatar.vue";
// import TopMenuOrganisationLogo from "./TopMenuOrganisationLogo.vue";
import { userRoles } from "@/composables/UserRole";
import TopMenuThemeColorMenuItem from "@/components/TopMenuThemeColorMenuItem.vue";
import AuthService from "@/services/auth.service";

export default {
  name: "TopMenuUserProfile",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TopMenuUserProfileAvatar,
    // TopMenuOrganisationLogo,
    ArrowRightOnRectangleIcon,
    TopMenuThemeColorMenuItem
  },
  data(){
    return {
      baseFileUrl: Config.baseFileUrl,
    }
  },
  // created() {
  //   this["auth/LoadCurrentUser"]().then(() => {
  //     this["organisation/LoadOrganisation"](this.user.organisation)
  //   })
  // },
  computed: {
    user() {
      const { user } = userRoles();
      return user;
    },
    organisation() {
      const { organisation } = userRoles();
      return organisation;
    },
    userMenus() {
      return this.$router.options.routes.filter(
        (r) => r.meta?.type === "menu" && r.meta?.menu === "user"
      );
    },
    userSecondaryMenus() {
      return this.$router.options.routes.filter(
        (r) => r.meta?.type === "menu" && r.meta?.menu === "user-secondary"
      );
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    }
    // ...mapGetters({
    //   // Notifications: "notification/StateNotifications",
    //   user: "auth/StateUser",
    //   organisation: "organisation/StateOrganisation",
    //   // UserFirstName: "StateFirstName",
    //   // UserAvatar: "StateAvatar",
    //   // UserEmail: "StateEmail",
    // }),
  },
  methods: {
    // ...mapActions([
    //   "organisation/LoadOrganisation",
    //   "auth/LoadCurrentUser"
    // ]),
    async logout() {
      console.log("logout");
      // await this.$store.dispatch("auth/LogOut");

      // this.$router.push("/login");
      const that = this;
      AuthService.logout().then(() => {
            // this.$router.push('/')
            this.$router.push("/login");
          }).catch((error) => {
            console.log(error);
          that.showError = true;
        });
    },
    kebabCase(string) {
      return string
        .replace(/\d+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join("-");
    },
  },
};
</script>
