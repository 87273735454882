class StripeSubscription {
  id: string;
  object: string;
  application: string;
  application_fee_percent: string;
  automatic_tax: object;
  billing_cycle_anchor: number;
  billing_thresholds: string;
  cancel_at: string;
  cancel_at_period_end: boolean;
  canceled_at: string;
  cancellation_details: object;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  days_until_due: string;
  default_payment_method: string;
  default_source: string;
  default_tax_rates: object;
  description: string;
  discount: string;
  ended_at: string;
  items: object;
  latest_invoice: string;
  livemode: false;
  metadata: object;
  next_pending_invoice_item_invoice: string;
  on_behalf_of: string;
  pause_collection: string;
  payment_settings: object;
  pending_invoice_item_interval: string;
  pending_setup_intent: string;
  pending_update: string;
  plan: object;
  quantity: number;
  schedule: string;
  start_date: number;
  status: string;
  test_clock: string;
  transfer_data: string;
  trial_end: number;
  trial_settings: object;
  trial_start: number
  constructor(
    id: string,
    object: string,
    application: string,
    application_fee_percent: string,
    automatic_tax: object,
    billing_cycle_anchor: number,
    billing_thresholds: string,
    cancel_at: string,
    cancel_at_period_end: boolean,
    canceled_at: string,
    cancellation_details: object,
    collection_method: string,
    created: number,
    currency: string,
    current_period_end: number,
    current_period_start: number,
    customer: string,
    days_until_due: string,
    default_payment_method: string,
    default_source: string,
    default_tax_rates: object,
    description: string,
    discount: string,
    ended_at: string,
    items: object,
    latest_invoice: string,
    livemode: false,
    metadata: object,
    next_pending_invoice_item_invoice: string,
    on_behalf_of: string,
    pause_collection: string,
    payment_settings: object,
    pending_invoice_item_interval: string,
    pending_setup_intent: string,
    pending_update: string,
    plan: object,
    quantity: number,
    schedule: string,
    start_date: number,
    status: string,
    test_clock: string,
    transfer_data: string,
    trial_end: number,
    trial_settings: object,
    trial_start: number
  ) {
    this.id = id
    this.object = object
    this.application = application
    this.application_fee_percent = application_fee_percent
    this.automatic_tax = automatic_tax
    this.billing_cycle_anchor = billing_cycle_anchor
    this.billing_thresholds = billing_thresholds
    this.cancel_at = cancel_at
    this.cancel_at_period_end = cancel_at_period_end
    this.canceled_at = canceled_at
    this.cancellation_details = cancellation_details
    this.collection_method = collection_method
    this.created = created
    this.currency = currency
    this.current_period_end = current_period_end
    this.current_period_start = current_period_start
    this.customer = customer
    this.days_until_due = days_until_due
    this.default_payment_method = default_payment_method
    this.default_source = default_source
    this.default_tax_rates = default_tax_rates
    this.description = description
    this.discount = discount
    this.ended_at = ended_at
    this.items = items
    this.latest_invoice = latest_invoice
    this.livemode = livemode
    this.metadata = metadata
    this.next_pending_invoice_item_invoice = next_pending_invoice_item_invoice
    this.on_behalf_of = on_behalf_of
    this.pause_collection = pause_collection
    this.payment_settings = payment_settings
    this.pending_invoice_item_interval = pending_invoice_item_interval
    this.pending_setup_intent = pending_setup_intent
    this.pending_update = pending_update
    this.plan = plan
    this.quantity = quantity
    this.schedule = schedule
    this.start_date = start_date
    this.status = status
    this.test_clock = test_clock
    this.transfer_data = transfer_data
    this.trial_end = trial_end
    this.trial_settings = trial_settings
    this.trial_start = trial_start
  }

  static fromObject(obj: any): StripeSubscription {
    return new StripeSubscription(
      obj.id,
      obj.object,
      obj.application,
      obj.application_fee_percent,
      obj.automatic_tax,
      obj.billing_cycle_anchor,
      obj.billing_thresholds,
      obj.cancel_at,
      obj.cancel_at_period_end,
      obj.canceled_at,
      obj.cancellation_details,
      obj.collection_method,
      obj.created,
      obj.currency,
      obj.current_period_end,
      obj.current_period_start,
      obj.customer,
      obj.days_until_due,
      obj.default_payment_method,
      obj.default_source,
      obj.default_tax_rates,
      obj.description,
      obj.discount,
      obj.ended_at,
      obj.items,
      obj.latest_invoice,
      obj.livemode,
      obj.metadata,
      obj.next_pending_invoice_item_invoice,
      obj.on_behalf_of,
      obj.pause_collection,
      obj.payment_settings,
      obj.pending_invoice_item_interval,
      obj.pending_setup_intent,
      obj.pending_update,
      obj.plan,
      obj.quantity,
      obj.schedule,
      obj.start_date,
      obj.status,
      obj.test_clock,
      obj.transfer_data,
      obj.trial_end,
      obj.trial_settings,
      obj.trial_start
    )
  }
}

export {
  StripeSubscription
}
