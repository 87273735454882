import { FilterOptionDef, AssetModel } from '@/types/index';
import { MutationTree } from 'vuex';
// import { State } from './state';
// import { AssetMutationTypes as MutationTypes, AssetState as State, ChartsPieChart } from '@/types/index';
import { ChartsPieChart } from '@/types/index';
import { AssetMutationTypes as MutationTypes } from './mutation-types';
import { AssetState as State, defaultState } from './state';
import {
  Asset,
  AssetCategory,
  AssetChart,
  AssetManufacturer,
  FieldDef,
  FilterDef,
  SortDef,
  AssetField,
  AssetImport,
  AssetImportMapping,
  AssetExport
} from '@/types/index';

export type Mutations<S = State> = {

  [MutationTypes.RESET](state: S): void;

  [MutationTypes.SET_HAS_ASSETS_CHARTS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_LOADING_HAS_ASSETS_CHARTS](state: S, payload: boolean): void;

  [MutationTypes.SET_ASSETS_CHARTS](state: S, payload: AssetChart[]): void;
  [MutationTypes.SET_IS_LOADING_ASSETS_CHARTS](state: S, payload: boolean): void;

  [MutationTypes.SET_ASSETS](state: S, payload: Asset[]): void;
  [MutationTypes.SET_IS_LOADING_ASSETS](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_ASSETS_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_ASSETS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_UPDATING_ASSETS](state: S, payload: boolean): void;
  [MutationTypes.SET_UPDATED_ASSETS_DATA](state: S, payload: any): void;

  [MutationTypes.SET_ASSETS_MINE](state: S, payload: Asset[]): void;
  [MutationTypes.SET_IS_LOADING_ASSETS_MINE](state: S, payload: boolean): void;

  [MutationTypes.SET_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_PAGESIZE](state: S, payload: number): void;
  [MutationTypes.SET_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_SEARCH](state: S, payload: string | null): void;
  [MutationTypes.SET_FILTER](state: S, payload: FilterDef[]): void;
  [MutationTypes.SET_AVAILABLEFIELDS](state: S, payload: FieldDef[]): void;
  [MutationTypes.SET_SORTS](state: S, payload: SortDef[]): void;

  [MutationTypes.SET_ASSET](state: S, payload: Asset | null): void;
  [MutationTypes.SET_ASSET_ERROR](state: S, payload: String | null): void;
  [MutationTypes.SET_ASSET_ERROR_VALIDATION](state: S, payload: any | null): void;
  [MutationTypes.SET_IS_LOADING_ASSET](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_ASSET_LOADED](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CREATING_ASSET](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_ASSET_CREATED](state: S, payload: boolean): void;
  [MutationTypes.SET_CREATED_ASSET_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_UPDATING_ASSET](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_ASSET_UPDATED](state: S, payload: boolean): void;
  [MutationTypes.SET_UPDATED_ASSET_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_ASSET](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_ASSET_SUCCEEDED](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_ASSET_DATA](state: S, payload: any): void;

  [MutationTypes.SET_ASSET_TO_PREVIEW_MOD](state: S, payload: string | null): void;
  [MutationTypes.SET_ASSET_PREVIEW_OPEN_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_CATEGORIES](state: S, payload: AssetCategory[]): void;
  [MutationTypes.SET_IS_LOADING_CATEGORIES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CATEGORIES_LOADED](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_SAVING_CATEGORIES](state: S, payload: boolean): void;

  [MutationTypes.SET_IS_LOADING_CATEGORY](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CATEGORY_LOADED](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_SAVING_CATEGORY](state: S, payload: boolean): void;

  [MutationTypes.SET_IS_CATEGORY_SAVED](state: S, payload: boolean): void;
  [MutationTypes.SET_CATEGORY_SAVE_ERROR](state: S, payload: string | null): void;

  [MutationTypes.SET_IS_DELETING_CATEGORY](state: S, payload: boolean): void;

  [MutationTypes.SET_FIELDS](state: S, payload: AssetField[]): void;
  [MutationTypes.SET_IS_LOADING_FIELDS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_FIELDS_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_FIELDS_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_FIELDS_PAGESIZE](state: S, payload: number): void;
  [MutationTypes.SET_FIELDS_TOTAL](state: S, payload: number): void;
  // [MutationTypes.SET_FIELDS_SEARCH](state: S, payload: string | null): void;
  // [MutationTypes.SET_FIELDS_FILTER](state: S, payload: FilterDef[]): void;
  // [MutationTypes.SET_FIELDS_AVAILABLEFIELDS](state: S, payload: FieldDef[]): void;
  // [MutationTypes.SET_FIELDS_SORTS](state: S, payload: SortDef[]): void;



  [MutationTypes.SET_FIELD](state: S, payload: AssetField | null): void;
  [MutationTypes.SET_FIELD_ERROR](state: S, payload: String | null): void;
  [MutationTypes.SET_FIELD_ERROR_VALIDATION](state: S, payload: any | null): void;
  [MutationTypes.SET_IS_LOADING_FIELD](state: S, payload: boolean): void;
  // [MutationTypes.SET_IS_CREATING_FIELD](state: S, payload: boolean): void;
  // [MutationTypes.SET_CREATED_FIELD_DATA](state: S, payload: any): void;
  // [MutationTypes.SET_IS_UPDATING_FIELD](state: S, payload: boolean): void;
  // [MutationTypes.SET_UPDATED_FIELD_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_FIELD](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_FIELD_DATA](state: S, payload: any): void;




  [MutationTypes.SET_MANUFACTURERS](state: S, payload: AssetManufacturer[]): void;
  [MutationTypes.SET_IS_SAVING_MANUFACTURERS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_MANUFACTURERS_SAVED](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_LOADING_MANUFACTURERS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_MANUFACTURERS_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_MANUFACTURER_MODELS](state: S, payload: AssetModel[]): void;
  [MutationTypes.SET_IS_SAVING_MANUFACTURER_MODELS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_MANUFACTURER_MODELS_SAVED](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_LOADING_MANUFACTURER_MODELS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_MANUFACTURER_MODELS_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_LIFE_CYCLE_STAGES](state: S, payload: FilterOptionDef[]): void;
  [MutationTypes.SET_IS_LOADING_LIFE_CYCLE_STAGES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_LIFE_CYCLE_STAGES_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_CHART_ASSET_CATEGORIES](state: S, payload: ChartsPieChart | null): void;
  [MutationTypes.SET_IS_LOADING_CHART_ASSET_CATEGORIES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CHART_ASSET_CATEGORIES_LOADED](state: S, payload: boolean): void;


  [MutationTypes.SET_ASSET_IMPORT](state: S, payload: AssetImport | null): void;
  [MutationTypes.SET_ASSET_IMPORT_MAPPING](state: S, payload: AssetImportMapping): void;

  [MutationTypes.SET_ASSET_IMPORT_IS_IMPORTING](state: S, payload: Boolean): void;
  [MutationTypes.SET_ASSET_IMPORT_DONE](state: S, payload: Boolean): void;
  [MutationTypes.SET_ASSET_IMPORT_RESULTS](state: S, payload: Object | null): void;

  [MutationTypes.SET_ASSET_EXPORT_IS_GENERATING](state: S, payload: Boolean): void;
  [MutationTypes.SET_ASSET_EXPORT_GENERATE_DONE](state: S, payload: Boolean): void;
  [MutationTypes.SET_ASSET_EXPORT_GENERATE_RESULTS](state: S, payload: Object | null): void;

  [MutationTypes.SET_ASSET_EXPORTS_IS_LOADING](state: S, payload: Boolean): void;
  [MutationTypes.SET_ASSET_EXPORTS_LEADED](state: S, payload: Boolean): void;
  [MutationTypes.SET_ASSET_EXPORTS](state: S, payload: AssetExport[]): void;
}

export const mutations: MutationTree<State> & Mutations = {

  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [MutationTypes.SET_HAS_ASSETS_CHARTS](state: State, hasAssetsCharts: boolean) {
    state.hasAssetsCharts = hasAssetsCharts
  },
  [MutationTypes.SET_IS_LOADING_HAS_ASSETS_CHARTS](state: State, isLoadingHasAssetsCharts: boolean) {
    state.isLoadingHasAssetsCharts = isLoadingHasAssetsCharts
  },

  [MutationTypes.SET_ASSETS_CHARTS](state: State, assetsCharts: AssetChart[]) {
    state.assetsCharts = assetsCharts
  },
  [MutationTypes.SET_IS_LOADING_ASSETS_CHARTS](state: State, isLoadingAssetsCharts: boolean) {
    state.isLoadingAssetsCharts = isLoadingAssetsCharts
  },

  [MutationTypes.SET_ASSETS](state: State, assets: Asset[]) {
    state.assets = assets
  },
  [MutationTypes.SET_IS_LOADING_ASSETS](state: State, isLoadingAssets: boolean) {
    state.isLoadingAssets = isLoadingAssets;
  },
  [MutationTypes.SET_DELETED_ASSETS_DATA](state: State, deletedAssetsData: any) {
    state.deletedAssetsData = deletedAssetsData;
  },
  [MutationTypes.SET_IS_DELETING_ASSETS](state: State, isDeletingAssets: boolean) {
    state.isDeletingAssets = isDeletingAssets;
  },
  [MutationTypes.SET_IS_UPDATING_ASSETS](state: State, isUpdatingAssets: boolean) {
    // debugger; // eslint-disable-line
    state.isUpdatingAssets = isUpdatingAssets;
  },
  [MutationTypes.SET_UPDATED_ASSETS_DATA](state: State, updatedAssetData: any) {
    state.updatedAssetData = updatedAssetData;
  },



  [MutationTypes.SET_DELETED_ASSET_SUCCEEDED](state: State, deletedAssetSucceeded: boolean) {
    // debugger; // eslint-disable-line
    state.deletedAssetSucceeded = deletedAssetSucceeded;
  },


  [MutationTypes.SET_ASSETS_MINE](state: State, assetsMine: Asset[]) {
    state.assetsMine = assetsMine
  },
  [MutationTypes.SET_IS_LOADING_ASSETS_MINE](state: State, isLoadingAssetsMine: boolean) {
    state.isLoadingAssetsMine = isLoadingAssetsMine;
  },



  [MutationTypes.SET_PAGE](state: State, page: number) {
    state.page = page;
  },

  [MutationTypes.SET_PAGESIZE](state: State, pageSize: number) {
    state.pageSize = pageSize;
  },

  [MutationTypes.SET_TOTAL](state: State, total: number) {
    state.total = total;
  },

  [MutationTypes.SET_SEARCH](state: State, search: string | null) {
    state.search = search;
  },

  [MutationTypes.SET_FILTER](state: State, filters: FilterDef[]) {

    state.filters = filters;
  },

  [MutationTypes.SET_AVAILABLEFIELDS](state: State, availableFields: FieldDef[]) {
    state.availableFields = availableFields;
  },

  [MutationTypes.SET_SORTS](state: State, sorts: SortDef[]) {
    state.sorts = sorts;
  },





  [MutationTypes.SET_ASSET](state: State, asset: Asset | null) {
    state.asset = asset === null ? null : Asset.fromObject(asset);
  },
  [MutationTypes.SET_ASSET_ERROR](state: State, message: string | null) {
    state.assetError = message;
  },
  [MutationTypes.SET_ASSET_ERROR_VALIDATION](state: State, assetErrorValidation: String | null) {
    state.assetErrorValidation = assetErrorValidation;
  },
  [MutationTypes.SET_IS_LOADING_ASSET](state: State, isLoadingAsset: boolean) {
    state.isLoadingAsset = isLoadingAsset;
  },
  [MutationTypes.SET_IS_ASSET_LOADED](state: State, isAssetLoaded: boolean) {
    state.isAssetLoaded = isAssetLoaded;
  },
  [MutationTypes.SET_IS_CREATING_ASSET](state: State, isCreatingAsset: boolean) {
    state.isCreatingAsset = isCreatingAsset;
  },
  [MutationTypes.SET_IS_ASSET_CREATED](state: State, isAssetCreated: boolean) {
    state.isAssetCreated = isAssetCreated;
  },
  [MutationTypes.SET_CREATED_ASSET_DATA](state: State, createdAssetData: any) {
    state.createdAssetData = createdAssetData;
  },
  [MutationTypes.SET_IS_UPDATING_ASSET](state: State, isUpdatingAsset: boolean) {
    state.isUpdatingAsset = isUpdatingAsset;
  },
  [MutationTypes.SET_IS_ASSET_UPDATED](state: State, isAssetUpdated: boolean) {
    state.isAssetUpdated = isAssetUpdated;
  },
  [MutationTypes.SET_UPDATED_ASSET_DATA](state: State, updatedAssetData: any) {
    state.updatedAssetData = updatedAssetData;
  },
  [MutationTypes.SET_IS_DELETING_ASSET](state: State, isDeletingAsset: boolean) {
    state.isDeletingAsset = isDeletingAsset;
  },
  [MutationTypes.SET_DELETED_ASSET_DATA](state: State, deletedAssetData: any) {
    state.deletedAssetData = deletedAssetData;
  },



  [MutationTypes.SET_ASSET_TO_PREVIEW_MOD](state: State, assetToPreviewMod: string | null) {
    state.assetToPreviewMod = assetToPreviewMod;
  },
  [MutationTypes.SET_ASSET_PREVIEW_OPEN_STATE](state: State, assetPreviewOpenState: boolean) {
    state.assetPreviewOpenState = assetPreviewOpenState;
  },



  [MutationTypes.SET_CATEGORIES](state: State, categories: AssetCategory[]) {
    state.categories = categories; // categories.map(category => AssetCategory.fromObject(categories));
    const categoryFilter = state.filters.find(f => f.id === 'category');
    if (categoryFilter) {
      categoryFilter.options = state.categories;
    }
  },
  [MutationTypes.SET_IS_LOADING_CATEGORIES](state: State, isLoadingCategories: boolean) {
    state.isLoadingCategories = isLoadingCategories;
  },
  [MutationTypes.SET_IS_CATEGORIES_LOADED](state: State, isCategoriesLoaded: boolean) {
    state.isCategoriesLoaded = isCategoriesLoaded;
  },
  [MutationTypes.SET_IS_SAVING_CATEGORIES](state: State, isSavingCategories: boolean) {
    state.isSavingCategories = isSavingCategories;
  },


  [MutationTypes.SET_IS_LOADING_CATEGORY](state: State, isLoadingCategory: boolean) {
    state.isLoadingCategory = isLoadingCategory;
  },
  [MutationTypes.SET_IS_CATEGORY_LOADED](state: State, isCategoryLoaded: boolean) {
    state.isCategoryLoaded = isCategoryLoaded;
  },
  [MutationTypes.SET_IS_SAVING_CATEGORY](state: State, isSavingCategory: boolean) {
    state.isSavingCategory = isSavingCategory;
  },


  [MutationTypes.SET_IS_CATEGORY_SAVED](state: State, isCategorySaved: boolean) {
    state.isCategorySaved = isCategorySaved;
  },

  [MutationTypes.SET_CATEGORY_SAVE_ERROR](state: State, categorySaveError: string | null) {
    state.categorySaveError = categorySaveError;
  },


  [MutationTypes.SET_IS_DELETING_CATEGORY](state: State, isDeletingCategory: boolean) {
    state.isDeletingCategory = isDeletingCategory;
  },


  [MutationTypes.SET_FIELDS](state: State, fields: AssetField[]) {
    // debugger; // eslint-disable-line
    state.fields = fields; // categories.map(category => AssetCategory.fromObject(categories));
    // const categoryFilter = state.filters.find(f => f.id === 'category');
    // if (categoryFilter) {
    //   categoryFilter.options = state.categories;
    // }
  },
  [MutationTypes.SET_IS_LOADING_FIELDS](state: State, isLoadingFields: boolean) {
    state.isLoadingFields = isLoadingFields;
  },
  [MutationTypes.SET_IS_FIELDS_LOADED](state: State, isFieldsLoaded: boolean) {
    state.isFieldsLoaded = isFieldsLoaded;
  },

  [MutationTypes.SET_FIELDS_PAGE](state: State, fieldsPage: number) {
    state.fieldsPage = fieldsPage;
  },

  [MutationTypes.SET_FIELDS_PAGESIZE](state: State, fieldsPageSize: number) {
    state.fieldsPageSize = fieldsPageSize;
  },

  [MutationTypes.SET_FIELDS_TOTAL](state: State, fieldsTotal: number) {
    state.fieldsTotal = fieldsTotal;
  },










  [MutationTypes.SET_FIELD](state: State, field: AssetField | null) {
    state.field = field === null ? null : AssetField.fromObject(field);
  },
  [MutationTypes.SET_FIELD_ERROR](state: State, message: String | null) {
    state.fieldError = message;
  },
  [MutationTypes.SET_FIELD_ERROR_VALIDATION](state: State, fieldErrorValidation: String | null) {
    state.fieldErrorValidation = fieldErrorValidation;
  },
  [MutationTypes.SET_IS_LOADING_FIELD](state: State, isLoadingField: boolean) {
    state.isLoadingField = isLoadingField;
  },
  [MutationTypes.SET_IS_DELETING_FIELD](state: State, isDeletingField: boolean) {
    state.isDeletingField = isDeletingField;
  },
  [MutationTypes.SET_DELETED_FIELD_DATA](state: State, deletedFieldData: any) {
    state.deletedFieldData = deletedFieldData;
  },




  [MutationTypes.SET_MANUFACTURERS](state: State, manufacturers: AssetManufacturer[]) {
    state.manufacturers = manufacturers;
  },
  [MutationTypes.SET_IS_SAVING_MANUFACTURERS](state: State, isSavingManufacturers: boolean) {
    state.isSavingManufacturers = isSavingManufacturers;
  },
  [MutationTypes.SET_IS_MANUFACTURERS_SAVED](state: State, isManufacturersSaved: boolean) {
    state.isManufacturersSaved = isManufacturersSaved;
  },
  [MutationTypes.SET_IS_LOADING_MANUFACTURERS](state: State, isLoadingManufacturers: boolean) {
    state.isLoadingManufacturers = isLoadingManufacturers;
  },
  [MutationTypes.SET_IS_MANUFACTURERS_LOADED](state: State, isManufacturersLoaded: boolean) {
    state.isManufacturersLoaded = isManufacturersLoaded;
  },

  [MutationTypes.SET_MANUFACTURER_MODELS](state: State, models: AssetModel[]) {
    state.models = models;
  },
  [MutationTypes.SET_IS_SAVING_MANUFACTURER_MODELS](state: State, isSavingModels: boolean) {
    state.isSavingModels = isSavingModels;
  },
  [MutationTypes.SET_IS_MANUFACTURER_MODELS_SAVED](state: State, isModelsSaved: boolean) {
    state.isModelsSaved = isModelsSaved;
  },
  [MutationTypes.SET_IS_LOADING_MANUFACTURER_MODELS](state: State, isLoadingModels: boolean) {
    state.isLoadingModels = isLoadingModels;
  },
  [MutationTypes.SET_IS_MANUFACTURER_MODELS_LOADED](state: State, isModelsLoaded: boolean) {
    state.isModelsLoaded = isModelsLoaded;
  },

  [MutationTypes.SET_LIFE_CYCLE_STAGES](state: State, lifeCycleStages: FilterOptionDef[]) {
    state.lifeCycleStages = lifeCycleStages;
  },
  [MutationTypes.SET_IS_LOADING_LIFE_CYCLE_STAGES](state: State, isLoadingLifeCycleStages: boolean) {
    state.isLoadingLifeCycleStages = isLoadingLifeCycleStages;
  },
  [MutationTypes.SET_IS_LIFE_CYCLE_STAGES_LOADED](state: State, isLifeCycleStagesLoaded: boolean) {
    state.isLifeCycleStagesLoaded = isLifeCycleStagesLoaded;
  },

  [MutationTypes.SET_CHART_ASSET_CATEGORIES](state: State, chartAssetCategories: ChartsPieChart | null) {
    state.chartAssetCategories = chartAssetCategories;
  },
  [MutationTypes.SET_IS_LOADING_CHART_ASSET_CATEGORIES](state: State, isLoadingChartAssetCategories: boolean) {
    state.isLoadingChartAssetCategories = isLoadingChartAssetCategories;
  },
  [MutationTypes.SET_IS_CHART_ASSET_CATEGORIES_LOADED](state: State, isChartAssetCategoriesLoaded: boolean) {
    state.isChartAssetCategoriesLoaded = isChartAssetCategoriesLoaded;
  },

  [MutationTypes.SET_ASSET_IMPORT](state: State, assetImport: AssetImport | null) {
    state.assetImport = assetImport;
  },

  [MutationTypes.SET_ASSET_IMPORT_MAPPING](state: State, assetImportMapping: AssetImportMapping) {
    if (state.assetImport)
      state.assetImport.mapping = assetImportMapping;
  },

  [MutationTypes.SET_ASSET_IMPORT_IS_IMPORTING](state: State, assetImportIsImporting: Boolean) {
    state.assetImportIsImporting = assetImportIsImporting;
  },

  [MutationTypes.SET_ASSET_IMPORT_DONE](state: State, assetImportDone: Boolean) {
    state.assetImportDone = assetImportDone;
  },

  [MutationTypes.SET_ASSET_IMPORT_RESULTS](state: State, assetImportResults: Object | null) {
    state.assetImportResults = assetImportResults;
  },

  [MutationTypes.SET_ASSET_EXPORT_IS_GENERATING](state: State, assetExportGenerating: Boolean) {
    state.assetExportGenerating = assetExportGenerating;
  },

  [MutationTypes.SET_ASSET_EXPORT_GENERATE_DONE](state: State, assetExportGenerationDone: Boolean) {
    state.assetExportGenerationDone = assetExportGenerationDone;
  },

  [MutationTypes.SET_ASSET_EXPORT_GENERATE_RESULTS](state: State, assetExportResults: Object | null) {
    state.assetExportResults = assetExportResults;
  },

  [MutationTypes.SET_ASSET_EXPORTS_IS_LOADING](state: State, assetExportsLoading: Boolean) {
    state.assetExportsLoading = assetExportsLoading;
  },

  [MutationTypes.SET_ASSET_EXPORTS_LEADED](state: State, assetExportsLoaded: Boolean) {
    state.assetExportsLoaded = assetExportsLoaded;
  },

  [MutationTypes.SET_ASSET_EXPORTS](state: State, assetExports: AssetExport[]) {
    state.assetExports = assetExports;
  }
};
