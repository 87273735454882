<template>
  <div
    v-if="organisation"
  >
    <img class="h-full aspect-org-logo" :src="logo" alt="" />
    <!-- <img class="h-full w-full" src="@/assets/dextern-logo.png" alt="" /> -->
    <!--    <img class="h-full w-full" :src="'@/assets'+this.organisation.logo.url" alt="Organisation logo" />-->
  </div>
</template>
<script type="ts">
// import { Organisation } from "@/types/index";
import { userRoles } from "@/composables/UserRole";
import { Config } from "@/config";

// class="
//       h-8
//       w-20
//       rounded-m
//       p-0
//       border
//       rounded-full
//       mr-1
//       border-gray-200
//       dark:border-gray-800
//       bg-white
//     "
export default {
  name: "TopMenuOrganisationLogo",
  // props: {
  //   organisation: {
  //     type: [Organisation, Object],
  //     required: false,
  //   },
  // },
  components: {},
  computed: {
    organisation() {
      const { organisation } = userRoles();
      return organisation;
    },
    type() {
      return this.organisation &&
        this.organisation.hasOwnproperty("logo") &&
        this.organisation.logo
        ? "img"
        : "letters";
    },
    letters() {
      return this.organisation.firstName[0] + this.organisation.lastName[0];
    },
    logo() {
      return this.organisation.logo ? Config.file.organisationLogo + this.organisation.logoUrl : '';
      // return this.organisation && this.organisation.code
      //   ? "/img/org-logo/" + this.organisation.code + "-1-2.png"
      //   : "";
    },
  },
};
</script>
