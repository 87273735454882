<template>
  <img v-if="user" class="h-8 w-8 rounded-full" :src="baseFileUrl + user.avatarUrl" alt="" />
</template>
<script type="ts">
// import { User } from "@/types/index";
import { Config } from "@/config";
import { userRoles } from "@/composables/UserRole";

export default {
  name: "TopMenuUserProfileAvatar",
  // props: {
  //   user: {
  //     type: [User, Object],
  //     required: false
  //   },
  // },
  data() {
    return {
      baseFileUrl: Config.baseFileUrl
    }
  },
  components: {},
  computed: {
    user() {
      const { user } = userRoles();
      return user;
    },
    type() {
      return this.user && this.user.hasOwnproperty("avatar") && this.user.avatar
        ? "img"
        : "letters";
    },
    letters() {
      return this.user.firstName[0] + this.user.lastName[0];
    },
  },
};
</script>
