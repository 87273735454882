import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
// import ZebritAxios from "@/util/ZebritAxios";
// import { State } from './state';
// import { TicketActionTypes, TicketMutationTypes, TicketState as State } from '@/types/index';
import {
  NotificationMessage,
  // User,
} from "@/types/index";
import { NotificationState as State } from './state';
  import {   NotificationActionTypes } from './action-types';
    import { NotificationMutationTypes } from './mutation-types';
// import axios from "axios";
import { HttpClient as http, AxiosError, AxiosResponse } from "@/util/ZebritAxios";
import { Config } from "../../../config";
// import {
//   // Notification,
//   // FieldDef,
//   IGetTicket,
//   // SortDef,
//   // FilterDef,
//   // TicketListFilters,
//   // MyTicketListFilters
// } from "@/types/index";
import { Mutations } from "./mutations";
// import { TicketMutationTypes } from './mutation-types';
// import { getters as TicketGetters } from './getters';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [NotificationActionTypes.GET_NOTIFICATION_MESSAGES]({
    commit,
  }: AugmentedActionContext): void;
  [NotificationActionTypes.ADD_NOTIFICATION_MESSAGE](
    { commit }: AugmentedActionContext,
    payload: NotificationMessage
  ): void;
  [NotificationActionTypes.SET_NOTIFICATION_OPEN_STATE](
    { commit }: AugmentedActionContext,
    payload: {
      openState: boolean;
    }
  ): void;
  [NotificationActionTypes.SET_NOTIFICATION_SEEN_STATUS](
    { commit }: AugmentedActionContext,
    payload: {
      seen: string[];
    }
  ): void;
}

// const getters = mapGetters({
//   messages: "chatbot/StateNotificationMessages",
//   openState: "chatbot/StateNotificationOpenState",
//   isTyping: "chatbot/StateNotificationIsTyping",
//   user: "chatbot/StateNotificationUser",
// });

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [NotificationActionTypes.GET_NOTIFICATION_MESSAGES]({
    commit
  }: AugmentedActionContext) {


    // debugger; // eslint-disable-line
    await http.get(Config.api.notification.messages)
      .then((res:AxiosResponse) => {
        // console.log(res);
        // debugger; // eslint-disable-line
        
        if (res.status == 403) {
          console.log("found 403");
        } else {
          commit(NotificationMutationTypes.SET_NOTIFICATION_MESSAGES, {
            notificationsCount: res.data.total,
            notificationsUnreadCount: res.data.unread,
            notifications: res.data.notifications,
          });
        }
        //     return res;
      })
      .catch((err:AxiosError) => {
        console.log("error", err);
      });
  },

  // async [AuthActionTypes.GETNOTIFICATIONS_ACTION]({ commit }, payload) {
  //   axios.get("api/notifications", {
  //     validateStatus: function (status) {
  //       return status < 500; // Resolve only if the status code is less than 500
  //     },
  //   }).then((res) => {
  //     console.log(res);
  //     if (res.status == 403) {
  //       console.log("found 403");
  //     } else {
  //       commit(AuthMutationTypes.SET_NOTIFICATIONS, res.data);
  //     }
  //     return res;
  //   });
  // },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [NotificationActionTypes.ADD_NOTIFICATION_MESSAGE](
    { commit }: AugmentedActionContext,
    payload: NotificationMessage
  ) {
    
    commit(NotificationMutationTypes.ADD_NOTIFICATION_MESSAGE, payload);
           
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [NotificationActionTypes.SET_NOTIFICATION_OPEN_STATE](
    { commit }: AugmentedActionContext,
    payload: {
      openState: boolean;
    }
  ) {
    commit(
      NotificationMutationTypes.SET_NOTIFICATION_IS_OPEN,
      payload.openState
    );
  },

  async [NotificationActionTypes.SET_NOTIFICATION_SEEN_STATUS](
    { commit }: AugmentedActionContext,
    payload: {
      seen: string[];
    }
  ) {
    await http.put(Config.api.notification.seen, { seen: payload.seen })
      .then((res:AxiosResponse) => {
        // console.log(res);
        if (res.status == 403) {
          console.log("found 403");
        }
      })
      .catch((err:AxiosError) => {
        console.log("error", err);
      });
  },
};
