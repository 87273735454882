import AuthService from "@/services/auth.service";
import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import { store, key } from "./store";
import { AuthActionTypes } from "@/store/modules/auth/action-types";
import { HttpClient } from "./util/ZebritAxios";
import "./index.css";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import RestHapiRepository from "./plugins/repository-plugin";
import { resources } from "./config";
// import { userRoles } from '@/composables/UserRole';
// import { AuthService } from "";
// import Notifications from 'notiwind'
// Import global directives
import vPermission from "./directives/v-permission";
import { PermissionKeys } from "./types";

declare global {
  interface Window {
    serverReady: any;
  }
}

const app = createApp(App);
app.config.errorHandler = (err) => {
  console.log(err);
};

dayjs.extend(relativeTime)
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$hasAccess = (_permissions: PermissionKeys[]) => {
  const userScope = store.state.auth.scope;
  const permissions = _permissions as string[];
// debugger; // eslint-disable-line
  let forbidden = !!permissions.filter(
    (permission: string) => userScope.indexOf("-" + permission) > -1
  )[0];

  const requiredPermissions = permissions.filter(
    (permission: string) => permission[0] === "+"
  );

  let hasRequired = true;
  if (requiredPermissions[0]) {
    hasRequired = !!userScope.filter(
      (scope) => requiredPermissions.indexOf("+" + scope) > -1
    )[0];
  }

  let hasPermission = !!userScope.filter((scope) => permissions.indexOf(scope) > -1)[0];

  // Root users always have access
  if (userScope.indexOf("root") > -1) {
    hasPermission = true;
    forbidden = false;
    hasRequired = true;
  }


  return !(!hasPermission || !hasRequired || forbidden);
};

window.addEventListener("error", (event) => {
  console.log(event);
  //Sentry.captureException(event)
});
window.addEventListener("unhandledrejection", (event) => {
  console.log(event);
  //Sentry.captureException(event)
});

if (
  localStorage.theme === "dark" ||
  (!("theme" in localStorage) &&
    window.matchMedia("(prefers-color-scheme: dark)").matches)
) {
  document.documentElement.classList.add("dark");
} else {
  document.documentElement.classList.remove("dark");
}

window.afterServerReady?.forEach((element: Function) => {
  element();
});

const accessToken = localStorage.getItem("accessToken");
const refreshToken = localStorage.getItem("refreshToken");
// debugger; // eslint-disable-line
if (accessToken && refreshToken) {
  const tokens = {
    accessToken: accessToken,
    refreshToken: refreshToken,
  };
  store.dispatch("auth/" + AuthActionTypes.UPDATE_TOKENS, tokens);
}

// Register global directives
app.directive("permission", vPermission);

// let isLoggedIn = false;
// app
//   // .use(Notifications)
//   .use(store, key)
//   .use(router)
//   .use(RestHapiRepository, { HttpClient, resources, log: true })
//   .mount("#app");
// debugger; // eslint-disable-line
app
  // .use(Notifications)
  .use(store, key);
// debugger; // eslint-disable-line

if (accessToken != null || refreshToken != null) {
  AuthService.loadCurrentUser()

    .then(() => {
      // debugger; // eslint-disable-line
    })
    .catch(() => {
      // debugger; // eslint-disable-line
      store.dispatch("auth/" + AuthActionTypes.CLEAR_AUTH);
    })
    .finally(async () => {
      // debugger; // eslint-disable-line
      // debugger; // eslint-disable-line
      // if(isLoggedIn){
      //   const { usersLoadPromisse, rolesLoadPromisse, permissionsLoadPromisse } = userRoles();
      //   await Promise.all([usersLoadPromisse, rolesLoadPromisse, permissionsLoadPromisse]);
      // }
      app
        .use(router)
        .use(RestHapiRepository, { HttpClient, resources, log: true })
        .mount("#app");
    });
} else {
  app
    .use(router)
    .use(RestHapiRepository, { HttpClient, resources, log: true })
    .mount("#app");
}

export default App;
