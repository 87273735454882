import { MutationTree } from "vuex";
import { State, defaultState } from "./state";
import { AuthMutationTypes as MutationTypes } from "./mutation-types";
import { User } from "@/types/index";

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;

  [MutationTypes.SET_IS_AUTHENTICATED](state: S, payload: boolean): void;
  // [MutationTypes.SET_SETACCESSTOKEN](state: S, payload: string | null): void;
  // [MutationTypes.SET_SETREFRESHTOKEN](state: S, payload: string | null): void;
  [MutationTypes.SET_LOGIN_ERROR](state: S, payload: number | null): void;
  [MutationTypes.SET_LOGIN_ERROR_MESSAGE](
    state: S,
    payload: string | null
  ): void;
  [MutationTypes.SET_REGISTER_ERROR](state: S, payload: number | null): void;
  [MutationTypes.SET_REGISTER_ERROR_MESSAGE](
    state: S,
    payload: string | null
  ): void;
  [MutationTypes.SET_USER](state: S, payload: User | null): void;
  [MutationTypes.SET_USER_LOADED](state: S, payload: boolean): void;
  [MutationTypes.SET_LOADING_USER](state: S, payload: boolean): void;


  [MutationTypes.SET_SAVING_USER](state: S, payload: boolean): void;
  [MutationTypes.SET_USER_SAVED](state: S, payload: boolean): void;
  [MutationTypes.SET_USER_SAVE_ERROR](state: S, payload: number | null): void;
  [MutationTypes.SET_USER_SAVE_ERROR_MESSAGE](state: S, payload: string | null): void;


  // [MutationTypes.SET_NOTIFICATIONS](state: S, payload: string[]): void;
  [MutationTypes.SET_NOORGANISATION](state: S, payload: boolean): void;
  [MutationTypes.SET_HASSUBSCRIPTION](state: S, payload: boolean): void;
  [MutationTypes.LOGOUT](state: S, payload: null): void;


  [MutationTypes.SET_ACCESS_TOKEN](state: S, payload: string | null): void;
  [MutationTypes.SET_REFRESH_TOKEN](state: S, payload: string | null): void;
  [MutationTypes.SET_SCOPE](state: S, payload: Array<string>): void;
  [MutationTypes.CLEAR_ACCESS_TOKEN](state: S, payload: null): void;
  [MutationTypes.CLEAR_REFRESH_TOKEN](state: S, payload: null): void;
  [MutationTypes.CLEAR_SCOPE](state: S, payload: null): void;
  [MutationTypes.CLEAR_USER](state: S, payload: null): void;


  [MutationTypes.SET_FORGOT_PASS_SUCCESS](state: S, payload: boolean): void;
  [MutationTypes.SET_FORGOT_PASS_SENDING_REQUEST](state: S, payload: boolean): void;
  [MutationTypes.SET_FORGOT_PASS_ERROR_MESSAGE](state: S, payload: string | null): void;

  [MutationTypes.SET_RESET_PASS_SUCCESS](state: S, payload: boolean): void;
  [MutationTypes.SET_RESET_PASS_SENDING_REQUEST](state: S, payload: boolean): void;
  [MutationTypes.SET_RESET_PASS_ERROR_MESSAGE](state: S, payload: string | null): void;

};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [MutationTypes.SET_IS_AUTHENTICATED](state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },

  // [MutationTypes.SET_SETACCESSTOKEN](state, accessToken) {
  //   state.accessToken = accessToken;
  // },

  // [MutationTypes.SET_SETREFRESHTOKEN](state, refreshToken) {
  //   state.refreshToken = refreshToken;
  // },

  [MutationTypes.SET_LOGIN_ERROR](state, error) {
    state.loginError = error;
  },

  [MutationTypes.SET_LOGIN_ERROR_MESSAGE](state, msg) {
    state.loginErrorMessage = msg;
  },

  [MutationTypes.SET_REGISTER_ERROR](state, error) {
    state.registerError = error;
  },

  [MutationTypes.SET_REGISTER_ERROR_MESSAGE](state, msg) {
    state.registerErrorMessage = msg;
  },

  [MutationTypes.SET_USER](state, user) {
    state.user = user;
  },

  [MutationTypes.SET_USER_LOADED](state, userLoaded) {
    state.userLoaded = userLoaded;
  },

  [MutationTypes.SET_LOADING_USER](state, loadingUser) {
    state.loadingUser = loadingUser;
  },

  [MutationTypes.SET_SAVING_USER](state, savingUser) {
    state.savingUser = savingUser;
  },
  [MutationTypes.SET_USER_SAVED](state, userSaved) {
    state.userSaved = userSaved;
  },
  [MutationTypes.SET_USER_SAVE_ERROR](state, userSaveError) {
    state.userSaveError = userSaveError;
  },
  [MutationTypes.SET_USER_SAVE_ERROR_MESSAGE](state, userSaveErrorMessage) {
    state.userSaveErrorMessage = userSaveErrorMessage;
  },

  // [MutationTypes.SET_NOTIFICATIONS](state, notifications) {
  //   state.notifications = notifications;
  // },

  [MutationTypes.LOGOUT](state) {
    state.user = null;
    // state.notifications = [];
  },

  [MutationTypes.SET_VERIFIED](state, verified) {
    state.verified = verified;
  },

  [MutationTypes.SET_NOORGANISATION](state, noOrganisation) {
    state.noOrganisation = noOrganisation;
  },
  [MutationTypes.SET_HASSUBSCRIPTION](state, hasSubscription) {
    state.hasSubscription = hasSubscription;
  },



  [MutationTypes.SET_ACCESS_TOKEN](state, accessToken) {
    localStorage.setItem("accessToken", accessToken ? accessToken : "");
    state.accessToken = accessToken;
  },

  [MutationTypes.SET_REFRESH_TOKEN](state, refreshToken) {
    localStorage.setItem("refreshToken", refreshToken ? refreshToken : "");
    state.refreshToken = refreshToken;
  },

  [MutationTypes.SET_SCOPE](state, scope) {
    state.scope = scope;
  },

  [MutationTypes.CLEAR_ACCESS_TOKEN](state) {
    state.accessToken = null;
  },

  [MutationTypes.CLEAR_REFRESH_TOKEN](state) {
    state.refreshToken = null;
  },

  [MutationTypes.CLEAR_SCOPE](state) {
    state.scope = [];
  },

  [MutationTypes.CLEAR_USER](state) {
    state.user = null;
  },

  [MutationTypes.SET_FORGOT_PASS_SUCCESS](state, forgotPassSuccess) {
    state.forgotPassSuccess = forgotPassSuccess;
  },

  [MutationTypes.SET_FORGOT_PASS_SENDING_REQUEST](state, forgotPassSendingRequest) {
    state.forgotPassSendingRequest = forgotPassSendingRequest;
  },

  [MutationTypes.SET_FORGOT_PASS_ERROR_MESSAGE](state, forgotPassErrorMessage) {
    state.forgotPassErrorMessage = forgotPassErrorMessage;
  },

  [MutationTypes.SET_RESET_PASS_SUCCESS](state, resetPassSuccess) {
    state.resetPassSuccess = resetPassSuccess;
  },

  [MutationTypes.SET_RESET_PASS_SENDING_REQUEST](state, resetPassSendingRequest) {
    state.resetPassSendingRequest = resetPassSendingRequest;
  },

  [MutationTypes.SET_RESET_PASS_ERROR_MESSAGE](state, resetPassErrorMessage) {
    state.resetPassErrorMessage = resetPassErrorMessage;
  }

};
