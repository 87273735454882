const colors = require('tailwindcss/colors')
const defaultTheme = require('tailwindcss/defaultTheme')

/** @type {import("@types/tailwindcss/tailwind-config").TailwindConfig } */
module.exports = {
  content: [
    './public/**/*.html',
    './src/**/*.vue',
    // './node_modules/litepie-datepicker-tw3/**/*.js'
    "./node_modules/vue-tailwind-datepicker/**/*.js"
  ],
  safelist: [
    'grid-cols-1',
    'grid-cols-2',
    'grid-cols-3',
    'grid-cols-4',
    'grid-cols-5',
    'grid-cols-6',
    'grid-cols-7',
    'grid-cols-8',

    'bg-blue-100',
    'text-blue-700',
    'bg-blue-400',
    'bg-green-100',
    'text-green-700',
    'bg-green-400',

    'bg-blue-900',
    'text-blue-300',
    'bg-blue-600',
    'bg-green-900',
    'text-green-300',
    'bg-green-600',

    'bg-orange-100',
    'text-orange-700',
    'bg-orange-400',
    'bg-orange-900',
    'text-orange-300',
    'bg-orange-600',
  ],
  darkMode: 'class', // false or 'media' or 'class'
  theme: {
    fontFamily: {
      sans: ['Inter var experimental', ...defaultTheme.fontFamily.sans],
    },
    colors: {
      ...defaultTheme.colors,
      transparent: 'transparent',
      current: 'currentColor',
      black: colors.black,
      white: colors.white,
      // gray: colors.blueGray,
      green: colors.green,
      cyan: colors.cyan,
      // indigo: colors.indigo,
      red: colors.rose,
      orange: colors.orange,
      yellow: colors.amber,
      zebritprimary: {
        50: '#ECFFE3',
        100: '#D4FCC3',
        200: '#BDFF9F',
        300: '#89ED5A',
        400: '#71D63C',
        500: '#54C420',
        600: '#31A700',
        700: '#2C8C00',
        800: '#247200',
        900: '#1B5400',
      },
      //https://tailwindpalette.jurs.me/
      //https://meyerweb.com/eric/tools/color-blend/#262626:171717:1:hex
      //https://www.tailwindshades.com/#color=102.39520958083833%2C6%2C32.745098039215684&step-up=12&step-down=6&hue-shift=0&name=gray-asparagus&overrides=e30%3D
      themeprimary: {
        DEFAULT: '#31A700',
        '50': '#CFFFBB',
        '100': '#BAFF9D',
        '200': '#8EFF60',
        '300': '#63FF22',
        '400': '#43E400',
        '500': '#31A700',
        '600': '#288800',
        '700': '#1F6A00',
        '800': '#164B00',
        '850': '#123C00',
        '900': '#0D2D00'
        // 50: "#E6FFDB",
        // 100: "#D1FFBD",
        // 200: "#9FFF75",
        // 300: "#70FF33",
        // 400: "#46EB00",
        // 500: "#31A700",
        // 600: "#288500",
        // 700: "#1F6600",
        // 800: "#144200",
        // 850: "#144200",
        // 900: "#0B2400"
      },
      themesecondary: {
        DEFAULT: '#A84D7A',
        '50': '#D7A8BF',
        '100': '#D29DB7',
        '200': '#C988A8',
        '300': '#BF7399',
        '400': '#B55E89',
        '500': '#A84D7A',
        '600': '#893F63',
        '700': '#69304C',
        '800': '#4A2235',
        '850': '#3A1B2A',
        '900': '#2A131F'
        // DEFAULT: '#EE6C4D',
        // '50': '#F8C3B6',
        // '100': '#F7B9AA',
        // '200': '#F5A693',
        // '300': '#F2927C',
        // '400': '#F07F64',
        // '500': '#EE6C4D',
        // '600': '#E33D16',
        // '700': '#A62D10',
        // '800': '#6A1D0A',
        // '850': '#4C1507',
        // '900': '#2D0C04'
        // DEFAULT: '#5E4280',
        // '50': '#BBA7D2',
        // '100': '#B09ACB',
        // '200': '#9B7FBD',
        // '300': '#8664AF',
        // '400': '#72509B',
        // '500': '#5E4280',
        // '600': '#4F376C',
        // '700': '#402D58',
        // '800': '#322343',
        // '850': '#2B1E39',
        // '900': '#23182F'
        // 50: "#E6FFDB",
        // 100: "#D1FFBD",
        // 200: "#9FFF75",
        // 300: "#70FF33",
        // 400: "#46EB00",
        // 500: "#31A700",
        // 600: "#288500",
        // 700: "#1F6600",
        // 800: "#144200",
        // 850: "#144200",
        // 900: "#0B2400"
      },
      blue: colors.sky,
      // https://www.tailwindshades.com/#color=102.39520958083833%2C6%2C32.745098039215684&step-up=12&step-down=6&hue-shift=0&name=gray-asparagus&overrides=e30%3D
      gray: colors.neutral,
      "vtd-primary": {
        50: '#ECFFE3',
        100: '#D4FCC3',
        200: '#BDFF9F',
        300: '#89ED5A',
        400: '#71D63C',
        500: '#54C420',
        600: '#31A700',
        700: '#2C8C00',
        800: '#247200',
        900: '#1B5400',
      }, // Light mode Datepicker color
      "vtd-secondary": colors.neutral, // Dark mode Datepicker color
    },
    //fill: theme => theme('colors'),
    backgroundColor: (theme) => theme('colors'),
    borderColor: (theme) => ({
      ...theme('colors'),
      DEFAULT: theme('colors.gray.200', 'currentColor'),
    }),
    caretColor: (theme) => theme('colors'),
    gradientColorStops: (theme) => theme('colors'),
    placeholderColor: (theme) => theme('colors'),
    ringColor: (theme) => ({
      DEFAULT: theme('colors.themeprimary.500', '#3b82f6'),
      ...theme('colors'),
    }),
    ringOffsetColor: (theme) => theme('colors'),
    textColor: (theme) => theme('colors'),
    extend: {
      colors: {
        // "vtd-primary": colors.themeprimary, // Light mode Datepicker color
        // "vtd-secondary": colors.neutral, // Dark mode Datepicker color
        gray: {
          850: '#1F1F1F',
          950: '#101010',
        },
        // 'litepie-primary': colors.themeprimary, // '#FF0000', // colors.themeprimary, // color system for light mode
        // 'litepie-secondary': colors.gray // '#00FF00', //colors.gray, // color system for dark mode
      },
      aspectRatio: {
        'org-logo': '2 / 1',
        'chart': '4 / 3',
        'chart-vertical': '8 / 3',
        'chart-horizontal': '2 / 3',
        '4/3': '4 / 3',
      },
    }
  },
  variants: {
    extend: {
      cursor: ['disabled'],
      textOpacity: ['disabled'],
      textColor: ['disabled', 'autofill'],
      bgColor: ['autofill'],
      display: ['dark'],
      ringColor: ['dark', 'focus'],
      borderColor: ['dark', 'focus', 'autofill'],
      opacity: ['disabled'],
      shadowFill: ['autofill'],
      textFill: ['autofill'],
    },
    scrollbar: ['dark'],
    autofill: ['dark']
  },
  // corePlugins: {
  //   // aspectRatio: false,
  // },
  plugins: [
    require('tailwind-scrollbar'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography')
    // require('@tailwindcss/line-clamp'),
    // require('@tailwindcss/aspect-ratio')
    // require("tailwindcss-autofill"),
  ],
}