import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';
import {
  StripeCustomer,
  StripeInvoice,
  StripePaymentMethod,
  StripeSubscription,
  StripePlan,
  StripeProduct
} from '@/types/index';

export type Getters = {
  StateStripeCustomer(state: State): StripeCustomer[],
  StateStripeInvoice(state: State): StripeInvoice[],
  StateStripePaymentMethod(state: State): StripePaymentMethod[],
  StateStripeSubscription(state: State): StripeSubscription[],
  StateStripePlan(state: State): StripePlan | null,
  StateStripeProduct(state: State): StripeProduct | null
};

export const getters: GetterTree<State, RootState> & Getters = {
  StateStripeCustomer: state => state.stripeCustomer,
  StateStripeInvoice: state => state.stripeInvoice,
  StateStripePaymentMethod: state => state.stripePaymentMethod,
  StateStripeSubscription: state => state.stripeSubscription,
  StateStripePlan: state => state.stripePlan,
  StateStripeProduct: state => state.stripeProduct
};