import axios from 'axios'
import ResourceHelper from './restful-resource-utility'
// import HttpClient from '../services/http-client.service';
// import { HttpClient } from '@/util/ZebritAxios'

const RestHapiRepository = {
  // install(Vue:any, { httpClient, resources, log }) {
  install(app:any, options:any) {
    // { httpClient, resources, log } = options;
    const httpClient = options?.httpClient || axios;
    const resources = options?.options;
    const log = options?.log;

    const fakeLogger = {
      log: function() {},
      debug: function() {},
      error: function() {}
    }

    const logger = log ? console : fakeLogger

    const resourceHelper = ResourceHelper(httpClient, logger)
    for (const resourceName in resources) {
      const resource:any = resources[resourceName]
      const resourceRoute:any = resource.alias || resourceName
      let repoCalls = resourceHelper.generateCrudCallers(
        resourceRoute,
        resource.options
      )
      const associations = resource.associations

      for (const associationName in associations) {
        const association:any = associations[associationName]
        const associationRoute = association.alias || associationName
        repoCalls = Object.assign(
          {},
          repoCalls,
          resourceHelper.generateAssociationCallers(
            resourceRoute,
            associationName,
            associationRoute,
            association.options
          )
        )
      }

      const repoName:any = '$' + resourceName + 'Repository'
      app.prototype[repoName] = repoCalls
    }
  }
}

export default RestHapiRepository
