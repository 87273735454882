import {
  Organisation,
  User,
  Group,
  Role,
  Permission,
  PermissionKeys,
  FieldDef,
  SortDef,
  UserListFilters,
  GroupListFilters,
  RoleListFilters,
  ErrorValidation,
  FilterDef
} from "@/types/index";

const userFilters: FilterDef[] = UserListFilters.map((f: FilterDef) => f);
const groupFilters: FilterDef[] = GroupListFilters.map((f: FilterDef) => f);
const roleFilters: FilterDef[] = RoleListFilters.map((f: FilterDef) => f);

export type State = {
  organisation: Organisation | null;

  savingOrganisation: boolean;
  saveOrganisationError: string | null;
  loadingOrganisation: boolean;

  testingOrganisationSupportEmail: boolean;
  testedOrganisationSupportEmail: boolean;
  testOrganisationSupportEmailError: string | null;
  testOrganisationSupportEmailValidationError: ErrorValidation | null;

  savingOrganisationSupportEmail: boolean;
  savedOrganisationSupportEmail: boolean;
  saveOrganisationSupportEmailError: string | null;
  saveOrganisationSupportEmailValidationError: ErrorValidation | null;

  users: User[];
  loadingUsers: boolean;
  usersLoaded: boolean;

  usersPage: number;
  usersPageSize: number;
  usersTotal: number;
  usersSearch: string | null;
  usersFilter: FilterDef[];
  usersAvailableFileds: FieldDef[];
  usersSorts: SortDef[];

  groups: Group[];
  loadingGroups: boolean;
  groupsPage: number;
  groupsPageSize: number;
  groupsTotal: number;
  groupsSearch: string | null;
  groupsFilter: FilterDef[];
  groupsAvailableFileds: FieldDef[];
  groupsSorts: SortDef[];

  //roles: Record<RoleKeys, Role> | null;
  roles: Role[];
  loadingRoles: boolean;
  rolesLoaded: boolean;
  rolesPage: number;
  rolesPageSize: number;
  rolesTotal: number;
  rolesSearch: string | null;
  rolesFilter: FilterDef[];
  rolesAvailableFileds: FieldDef[];
  rolesSorts: SortDef[];

  permissions: Record<PermissionKeys, Permission> | null;
  loadingPermissions: boolean;

  crudFocusedUser: User | null;
  loadingCrudFocusedUser: boolean;
  savingCrudFocusedUser: boolean;
  creatingCrudFocusedUser: boolean;
  creatingCrudFocusedUserSucceeded: boolean;
  creatingCrudFocusedUserData: string | null;
  deletingCrudFocusedUser: boolean;
  deletedCrudFocusedUserSucceeded: boolean,
  deletedCrudFocusedUserData: string | null,

  crudFocusedGroup: Group | null;
  loadingCrudFocusedGroup: boolean;
  savingCrudFocusedGroup: boolean;

  crudFocusedRole: Role | null;
  loadingCrudFocusedRole: boolean;
  savingCrudFocusedRole: boolean;

  inviteUserError: string | null;
  inviteUserErrorMessage: string | null;
};

export const state: State = {
  organisation: null,

  savingOrganisation: false,
  saveOrganisationError: null,
  loadingOrganisation: false,

  testingOrganisationSupportEmail: false,
  testedOrganisationSupportEmail: false,
  testOrganisationSupportEmailError: null,
  testOrganisationSupportEmailValidationError: null,

  savingOrganisationSupportEmail: false,
  savedOrganisationSupportEmail: false,
  saveOrganisationSupportEmailError: null,
  saveOrganisationSupportEmailValidationError: null,

  users: [],
  loadingUsers: false,
  usersLoaded: false,

  usersPage: 1,
  usersPageSize: 10,
  usersTotal: 0,
  usersSearch: null,
  usersFilter: userFilters,
  usersAvailableFileds: [],
  usersSorts: [],

  groups: [],
  loadingGroups: false,
  groupsPage: 1,
  groupsPageSize: 10,
  groupsTotal: 0,
  groupsSearch: null,
  groupsFilter: groupFilters,
  groupsAvailableFileds: [],
  groupsSorts: [],

  roles: [],
  loadingRoles: false,
  rolesLoaded: false,
  rolesPage: 1,
  rolesPageSize: 10,
  rolesTotal: 0,
  rolesSearch: null,
  rolesFilter: roleFilters,
  rolesAvailableFileds: [],
  rolesSorts: [],

  permissions: {} as Record<PermissionKeys, Permission>,
  loadingPermissions: false,

  crudFocusedUser: null,
  loadingCrudFocusedUser: false,
  savingCrudFocusedUser: false,
  creatingCrudFocusedUser: false,
  creatingCrudFocusedUserSucceeded: false,
  creatingCrudFocusedUserData: null,
  deletingCrudFocusedUser: false,
  deletedCrudFocusedUserSucceeded: false,
  deletedCrudFocusedUserData: null,

  crudFocusedGroup: null,
  loadingCrudFocusedGroup: false,
  savingCrudFocusedGroup: false,

  crudFocusedRole: null,
  loadingCrudFocusedRole: false,
  savingCrudFocusedRole: false,

  inviteUserError: null,
  inviteUserErrorMessage: null

};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): State => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};