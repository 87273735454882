// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
// export enum KnowledgeMutationTypes {
//   SET_KNOWLEDGES = 'setKnowledges',
//   SET_IS_LOADING_KNOWLEDGES = 'setIsLoadingKnowledges',

//   SET_PAGE = 'setPage',
//   SET_PAGESIZE = 'setPageSize',
//   SET_TOTAL = 'setTotal',
//   SET_SEARCH = 'setSearch',
//   SET_FILTER = 'setFilter',
//   SET_AVAILABLEFIELDS = 'setAvailableFields',
//   SET_SORTFIELDS = 'setSortFields',

//   SET_SEARCHKNOWLEDGES = 'setSearchKnowledges',
//   SET_IS_LOADING_SEARCH_KNOWLEDGES = 'setIsLoadingSearchKnowledges',

//   SET_KNOWLEDGE = 'setKnowledge',
//   SET_IS_LOADING_KNOWLEDGE = 'setIsLoadingKnowledge',
//   SET_IS_CREATING_KNOWLEDGE = 'setIsCreatingKnowledge',
//   SET_CREATED_KNOWLEDGE_DATA = 'setCreatedKnowledgeData',
//   SET_IS_UPDATING_KNOWLEDGE = 'setIsUpdatingKnowledge',
//   SET_UPDATED_KNOWLEDGE_DATA = 'setUpdatedKnowledgeData',
//   SET_IS_DELETING_KNOWLEDGE = 'setIsDeletingKnowledge',
//   SET_DELETED_KNOWLEDGE_DATA = 'setDeletedKnowledgeData',

//   SET_CATEGORIES = 'setCategories',
//   SET_IS_LOADING_CATEGORIES = 'setIsLoadingCategories',
//   SET_IS_CATEGORIES_LOADED = 'setIsCategoriesLoaded',

//   SET_KNOWLEDGE_TO_PREVIEW_MOD = 'setKnowledgeToPreviewMod',
//   SET_KNOWLEDGE_PREVIEW_OPEN_STATE = 'setKnowledgePreviewOpenState',

//   SET_KNOWLEDGE_CATEGORIES_OPEN_STATE = 'setKnowledgeCategoriesOpenState',
// }


export enum KnowledgeMutationTypes {
  RESET = "reset",

  SET_KNOWLEDGES = "setKnowledges",
  SET_IS_LOADING_KNOWLEDGES = "setIsLoadingKnowledges",

  SET_PAGE = "setPage",
  SET_PAGESIZE = "setPageSize",
  SET_TOTAL = "setTotal",
  SET_SEARCH = "setSearch",
  SET_FILTER = "setFilter",
  SET_AVAILABLEFIELDS = "setAvailableFields",
  SET_SORTFIELDS = "setSortFields",

  SET_SEARCHKNOWLEDGES = "setSearchKnowledges",
  SET_IS_LOADING_SEARCH_KNOWLEDGES = "setIsLoadingSearchKnowledges",

  SET_KNOWLEDGE = "setKnowledge",
  SET_IS_LOADING_KNOWLEDGE = "setIsLoadingKnowledge",
  SET_IS_CREATING_KNOWLEDGE = "setIsCreatingKnowledge",
  SET_CREATED_KNOWLEDGE_DATA = "setCreatedKnowledgeData",
  SET_IS_UPDATING_KNOWLEDGE = "setIsUpdatingKnowledge",
  SET_UPDATED_KNOWLEDGE_DATA = "setUpdatedKnowledgeData",
  SET_IS_DELETING_KNOWLEDGE = "setIsDeletingKnowledge",

  SET_DELETED_KNOWLEDGE_SUCCEEDED = "setDeletedKnowledgeSucceeded",
  SET_DELETED_KNOWLEDGE_DATA = "setDeletedKnowledgeData",

  SET_CATEGORIES = "setCategories",
  SET_IS_LOADING_CATEGORIES = "setIsLoadingCategories",
  SET_IS_CATEGORIES_LOADED = "setIsCategoriesLoaded",

  SET_KNOWLEDGE_TO_PREVIEW_MOD = "setKnowledgeToPreviewMod",
  SET_KNOWLEDGE_PREVIEW_OPEN_STATE = "setKnowledgePreviewOpenState",

  SET_KNOWLEDGE_CATEGORIES_OPEN_STATE = "setKnowledgeCategoriesOpenState",
}