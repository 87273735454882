import { MutationTree } from "vuex";
import {
  NotificationMessage
  // User,
} from "@/types/index";
import { NotificationState as State, defaultState } from './state';
import { NotificationMutationTypes as MutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;
  [MutationTypes.SET_NOTIFICATION_IS_OPEN](state: S, payload: boolean): void;
  [MutationTypes.SET_NOTIFICATION_MESSAGES](
    state: S,
    payload: {
      notificationsCount: number;
      notificationsUnreadCount: number;
      notifications: NotificationMessage[];
    }
  ): void;
  [MutationTypes.ADD_NOTIFICATION_MESSAGE](
    state: S,
    payload: NotificationMessage
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },
  [MutationTypes.SET_NOTIFICATION_IS_OPEN](
    state: State,
    notificationOpenState: boolean
  ) {
    state.notificationOpenState = notificationOpenState;
  },

  [MutationTypes.SET_NOTIFICATION_MESSAGES](
    state: State,
    payload: {
      notificationsCount: number;
      notificationsUnreadCount: number;
      notifications: NotificationMessage[];
    }
  ) {

    state.notificationsCount = payload.notificationsCount;
    state.notificationsUnreadCount = payload.notificationsUnreadCount;
    state.notifications = payload.notifications;
  },

  [MutationTypes.ADD_NOTIFICATION_MESSAGE](
    state: State,
    notificationMessage: NotificationMessage
  ) {

    state.notificationsCount += 1;
    state.notificationsUnreadCount += 1;
    state.notifications.push(notificationMessage);
  },
};
