import { MutationTree } from 'vuex';
import { State, defaultState } from './state';
import { TagMutationTypes as MutationTypes } from './mutation-types';
import { Tag } from '@/types/index';

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;
  [MutationTypes.SET_TAGS](state: S, payload: Tag[]): void;
  [MutationTypes.SET_IS_LOADING_TAGS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_TAGS_LOADED](state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {

  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [MutationTypes.SET_TAGS](state, tags) {
    state.tags = tags
  },

  [MutationTypes.SET_IS_LOADING_TAGS](state, isLoadingTags) {
    state.isLoadingTags = isLoadingTags
  },

  [MutationTypes.SET_IS_TAGS_LOADED](state, isTagsLoaded) {
    state.isTagsLoaded = isTagsLoaded
  }

};
