// import { RequestMutationTypes } from "./../store/modules/request/---mutation-types";
import axios from "axios";
import { AxiosResponse, AxiosError } from "axios";
import { Config } from "@/config";
// import { useStore } from "@/store";
import { store } from "@/store";
import { AuthActionTypes } from "@/store/modules/auth/action-types";
import AuthInterceptorService from "@/services/auth-interceptor.service";
// import router from "../router";

const RESPONSE_MESSAGES = Config.RESPONSE_MESSAGES;

// axios.interceptors.request.use(function (config) {
//   const store = useStore();
//   const token = store.state.auth.accessToken;
//   debugger; // eslint-disable-line
//   const _token = token ? token : "";
//   if (config.headers && _token != "") config.headers.Authorization = _token;

//   return config;
// });

// axios.interceptors.response.use(
//   (response: AxiosResponse) => {
//     if (response.status == 401 || response.status == 400) {
//       // debugger; // eslint-disable-line
//       throw response;
//     } else if (response.status == 403) {
//       // localStorage.removeItem('is-authenticated');
//       throw response;
//     }
//     return response;
//   },
//   (error: AxiosError) => {
//     // if (error.response?.status == 401) {
//     //   //place your reentry code
//     //   // return error;
//     // } else if (error.response?.status == 403) {
//     //   // localStorage.removeItem('is-authenticated');
//     // }
//     //throw error;

//     // debugger; //eslint-disable-line
//     if (error) {
//       // const originalRequest = error.config;
//       if (error?.response?.status === 401 && window.location.pathname !== '/login') {
//         // originalRequest.data._retry = true;
//         store.dispatch("auth/LogOut");

//         return router.push("/login");
//       }
//     }


//     return error;
//   }
// );
// Add a response interceptor
axios.interceptors.response.use(function(response) {
  return Promise.resolve(response)
}, AuthInterceptorService.responseError)


axios.defaults.withCredentials = true;
axios.defaults.baseURL = Config.baseURL; // "/";


// axios.interceptors.response.use(undefined, function (error:AxiosError) {
//   debugger; //eslint-disable-line
//   if (error) {
//     const originalRequest = error.config;
//     if (error?.response?.status === 401 && !originalRequest.data._retry && window.location.pathname !== '/login') {
//       originalRequest.data._retry = true;
//       store.dispatch("auth/LogOut");

//       return router.push("/login");
//     }
//   }
// });




const HttpClient = {
  get: function (url: any, params: any = {}, options: any = {}): any {
    let config = {
      method: "GET",
      url: url,
      params: params,
    };
    // debugger; // eslint-disable-line
    config = Object.assign(config, options);
    return axios(config)
      .then(function (response) {
        if (response && response.headers && response.headers["x-access-token"]) {
          HttpClient.updateTokens(response.headers);
        }
        return response;
      })
      .catch(function (error) {
        if (error === RESPONSE_MESSAGES.EXPIRED_ACCESS_TOKEN) {
          store.dispatch("auth/" + AuthActionTypes.USE_REFRESH_TOKEN);
          return HttpClient.get(url, params, options);
        } else {
          throw error;
        }
      });
  },

  request: function (
    method: string,
    url: any,
    payload: any = {},
    options: any = {}
  ): any {
    switch (method.toUpperCase()) {
      case "PUT":
        return this.put(url, payload, options);
        break;
      case "POST":
        return this.post(url, payload, options);
        break;
      case "DELETE":
        return this.delete(url, payload, options);
        break;
      case "GET":
      default:
        return this.get(url, payload, options);
        break;
    }
  },

  put: function (url: any, payload: any = {}, options: any = {}): any {
    let config = {
      method: "PUT",
      url: url,
      data: payload,
    };
    config = Object.assign(config, options);
    return axios(config)
      .then(function (response) {
        if (response && response.headers && response.headers["x-access-token"]) {
          HttpClient.updateTokens(response.headers);
        }
        return response;
      })
      .catch(function (error) {
        if (error === RESPONSE_MESSAGES.EXPIRED_ACCESS_TOKEN) {
          store.dispatch("auth/" + AuthActionTypes.USE_REFRESH_TOKEN);
          return HttpClient.put(url, payload, options);
        } else {
          throw error;
        }
      });
  },

  post: function (url: any, payload: any = {}, options: any = {}): any {
    let config = {
      method: "POST",
      url: url,
      data: payload,
    };
    config = Object.assign(config, options);
    return axios(config)
      .then(function (response) {
        if (response && response.headers && response.headers["x-access-token"]) {
          HttpClient.updateTokens(response.headers);
        }
        return response;
      })
      .catch(function (error) {
        if (error === RESPONSE_MESSAGES.EXPIRED_ACCESS_TOKEN) {
          store.dispatch("auth/" + AuthActionTypes.USE_REFRESH_TOKEN);
          return HttpClient.post(url, payload, options);
        } else {
          throw error;
        }
      });
  },

  delete: function (url: any, payload: any = {}, options: any = {}): any {
    let config = {
      method: "DELETE",
      url: url,
      data: payload,
    };
    config = Object.assign(config, options);
    return axios(config)
      .then(function (response) {
        if (response && response.headers && response.headers["x-access-token"]) {
          HttpClient.updateTokens(response.headers);
        }
        return response;
      })
      .catch(function (error) {
        if (error === RESPONSE_MESSAGES.EXPIRED_ACCESS_TOKEN) {
          store.dispatch("auth/" + AuthActionTypes.USE_REFRESH_TOKEN);
          return HttpClient.delete(url, payload, options);
        } else {
          throw error;
        }
      });
  },

  updateTokens: function (headers: any) {
    const tokens = {
      accessToken: headers["x-access-token"],
      refreshToken: headers["x-refresh-token"],
    };

    // store.dispatch('auth/updateTokens', tokens)
    store.dispatch("auth/" + AuthActionTypes.UPDATE_TOKENS, tokens);
  },

  updateAccessToken: function (accessToken: string) {
    // debugger; // eslint-disable-line
    axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken
  },

  clearAuth: function () {
    // debugger; // eslint-disable-line
    axios.defaults.headers.common.Authorization = false
  },

  useRefreshToken: function (refreshToken: string | null) {
    // debugger; // eslint-disable-line
    axios.defaults.headers.common.Authorization = refreshToken ? ('Bearer ' + refreshToken) : false;
  }

};

// export default HttpClient
// export { HttpClient, axios, AxiosResponse, AxiosError, AxiosRequestConfig };
export { HttpClient, AxiosResponse, AxiosError };
