// import { ref } from "vue";
import { MutationTree } from "vuex";
// import { State } from './state';
import {
  // AssetCategory,
  // TicketMutationTypes as MutationTypes,
  // TicketSeverity,
  // TicketState as State,
  // TicketStatus,
  // TicketType,
  FilterOptionDef,
  TicketActivity,
  TicketActivityType,
  ChartsPieChart,
  ChartsStackedBarChart,
  ChartsBarChart
} from "@/types/index";
import { TicketMutationTypes as MutationTypes } from './mutation-types';
import { TicketState as State, defaultState } from './state';
import {
  Ticket,
  TicketChart,
  FieldDef,
  FilterDef,
  SortDef,
} from "@/types/index";

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;
  [MutationTypes.SET_TICKETS_CHARTS](state: S, payload: TicketChart[]): void;
  [MutationTypes.SET_IS_LOADING_TICKETS_CHARTS](
    state: S,
    payload: boolean
  ): void;

  [MutationTypes.SET_TICKETS](state: S, payload: Ticket[]): void;
  [MutationTypes.SET_IS_LOADING_TICKETS](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_TICKETS_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_TICKETS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_UPDATING_TICKETS](state: S, payload: boolean): void;
  [MutationTypes.SET_UPDATED_TICKETS_DATA](state: S, payload: any): void;

  [MutationTypes.SET_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_PAGESIZE](state: S, payload: number): void;
  [MutationTypes.SET_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_SEARCH](state: S, payload: string | null): void;
  [MutationTypes.SET_FILTER](state: S, payload: FilterDef[]): void;
  [MutationTypes.SET_AVAILABLEFIELDS](state: S, payload: FieldDef[]): void;
  [MutationTypes.SET_SORTS](state: S, payload: SortDef[]): void;

  [MutationTypes.SET_MY_TICKETS](state: S, payload: Ticket[]): void;
  [MutationTypes.SET_MY_IS_LOADING_TICKETS](state: S, payload: boolean): void;
  [MutationTypes.SET_MY_DELETED_TICKETS_DATA](state: S, payload: any): void;
  [MutationTypes.SET_MY_IS_DELETING_TICKETS](state: S, payload: boolean): void;
  [MutationTypes.SET_MY_IS_UPDATING_TICKETS](state: S, payload: boolean): void;
  [MutationTypes.SET_MY_UPDATED_TICKETS_DATA](state: S, payload: any): void;

  [MutationTypes.SET_MY_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_MY_PAGESIZE](state: S, payload: number): void;
  [MutationTypes.SET_MY_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_MY_SEARCH](state: S, payload: string | null): void;
  [MutationTypes.SET_MY_FILTER](state: S, payload: FilterDef[]): void;
  [MutationTypes.SET_MY_AVAILABLEFIELDS](state: S, payload: FieldDef[]): void;
  [MutationTypes.SET_MY_SORTS](state: S, payload: SortDef[]): void;

  [MutationTypes.SET_TICKETS_KANBAN](state: S, payload: Map<string, Ticket[]>): void;
  [MutationTypes.SET_IS_LOADING_TICKETS_KANBAN](state: S, payload: Map<string, boolean>): void;
  // [MutationTypes.SET_DELETED_TICKETS_KANBAN_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_TICKETS_KANBAN](state: S, payload: Map<string, boolean>): void;
  [MutationTypes.SET_IS_UPDATING_TICKETS_KANBAN](state: S, payload: Map<string, boolean>): void;
  // [MutationTypes.SET_UPDATED_TICKETS_KANBAN_DATA](state: S, payload: any): void;

  [MutationTypes.SET_PAGE_KANBAN](state: S, payload: Map<string, number>): void;
  [MutationTypes.SET_PAGESIZE_KANBAN](state: S, payload: Map<string, number>): void;
  [MutationTypes.SET_TOTAL_KANBAN](state: S, payload: Map<string, number>): void;
  [MutationTypes.SET_SEARCH_KANBAN](state: S, payload: Map<string, string | null>): void;
  [MutationTypes.SET_FILTER_KANBAN](state: S, payload: Map<string, FilterDef[]>): void;
  [MutationTypes.SET_AVAILABLEFIELDS_KANBAN](state: S, payload: Map<string, FieldDef[]>): void;
  [MutationTypes.SET_SORTS_KANBAN](state: S, payload: Map<string, SortDef[]>): void;

  [MutationTypes.SET_TICKET](state: S, payload: Ticket | null): void;
  [MutationTypes.SET_TICKET_ERROR](state: S, payload: String | null): void;
  [MutationTypes.SET_TICKET_ERROR_VALIDATION](state: S, payload: any | null): void;
  [MutationTypes.SET_IS_LOADING_TICKET](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_TICKET_LOADED](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CREATING_TICKET](state: S, payload: boolean): void;
  [MutationTypes.SET_CREATED_TICKET_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_UPDATING_TICKET](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_TICKET_UPDATED](state: S, payload: boolean): void;
  [MutationTypes.SET_UPDATED_TICKET_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_TICKET](state: S, payload: boolean): void;

  [MutationTypes.SET_DELETED_TICKET_SUCCEEDED](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_TICKET_DATA](state: S, payload: any): void;

  [MutationTypes.SET_TICKET_TO_PREVIEW_MOD](
    state: S,
    payload: string | null
  ): void;
  [MutationTypes.SET_TICKET_PREVIEW_OPEN_STATE](
    state: S,
    payload: boolean
  ): void;

  // [MutationTypes.SET_CATEGORIES](state: S, payload: AssetCategory[]): void;
  // [MutationTypes.SET_IS_LOADING_CATEGORIES](state: S, payload: boolean): void;
  // [MutationTypes.SET_IS_CATEGORIES_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_TYPES](state: S, payload: FilterOptionDef[]): void;
  [MutationTypes.SET_IS_LOADING_TYPES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_TYPES_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_SEVERITIES](state: S, payload: FilterOptionDef[]): void;
  [MutationTypes.SET_IS_LOADING_SEVERITIES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_SEVERITIES_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_STATUSES](state: S, payload: FilterOptionDef[]): void;
  [MutationTypes.SET_IS_LOADING_STATUSES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_STATUSES_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_TICKET_ACTIVITIES](
    state: S,
    payload: TicketActivity[]
  ): void;
  [MutationTypes.SET_IS_LOADING_TICKET_ACTIVITIES](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_TICKET_ACTIVITY_TYPES_ACTION](
    state: S,
    payload: TicketActivityType[]
  ): void;
  [MutationTypes.SET_IS_LOADING_TICKET_ACTIVITY_TYPES](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_IS_TICKET_ACTIVITY_TYPES_LOADED](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_IS_SAVING_TICKET_ACTIVITY](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_IS_ASSIGNING_TICKET_ACTIVITY](
    state: S,
    payload: boolean
  ): void;



  [MutationTypes.SET_CHARTTYPES](state: S, payload: ChartsPieChart | null): void;
  [MutationTypes.SET_IS_LOADING_CHARTTYPES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CHARTTYPES_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_CHARTSEVERITIES](state: S, payload: ChartsStackedBarChart | null): void;
  [MutationTypes.SET_IS_LOADING_CHARTSEVERITIES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CHARTSEVERITIES_LOADED](state: S, payload: boolean): void;


  [MutationTypes.SET_CHARTSTATUSES](state: S, payload: ChartsPieChart | null): void;
  [MutationTypes.SET_IS_LOADING_CHARTSTATUSES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CHARTSTATUSES_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_CHARTMONTHLY](state: S, payload: ChartsBarChart | null): void;
  [MutationTypes.SET_IS_LOADING_CHARTMONTHLY](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CHARTMONTHLY_LOADED](state: S, payload: boolean): void;

};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },
  [MutationTypes.SET_TICKETS_CHARTS](
    state: State,
    ticketsCharts: TicketChart[]
  ) {
    state.ticketsCharts = ticketsCharts;
  },
  [MutationTypes.SET_IS_LOADING_TICKETS_CHARTS](
    state: State,
    isLoadingTicketsCharts: boolean
  ) {
    state.isLoadingTicketsCharts = isLoadingTicketsCharts;
  },

  [MutationTypes.SET_TICKETS](state: State, tickets: Ticket[]) {
    state.tickets = tickets;
  },
  [MutationTypes.SET_IS_LOADING_TICKETS](
    state: State,
    isLoadingTickets: boolean
  ) {
    state.isLoadingTickets = isLoadingTickets;
  },
  [MutationTypes.SET_DELETED_TICKETS_DATA](
    state: State,
    deletedTicketsData: any
  ) {
    state.deletedTicketsData = deletedTicketsData;
  },
  [MutationTypes.SET_IS_DELETING_TICKETS](
    state: State,
    isDeletingTickets: boolean
  ) {
    state.isDeletingTickets = isDeletingTickets;
  },
  [MutationTypes.SET_IS_UPDATING_TICKETS](
    state: State,
    isUpdatingTickets: boolean
  ) {
    state.isUpdatingTickets = isUpdatingTickets;
  },
  [MutationTypes.SET_UPDATED_TICKETS_DATA](
    state: State,
    updatedTicketData: any
  ) {
    state.updatedTicketData = updatedTicketData;
  },

  [MutationTypes.SET_MY_TICKETS](state: State, myTickets: Ticket[]) {
    state.myTickets = myTickets;
  },
  [MutationTypes.SET_MY_IS_LOADING_TICKETS](
    state: State,
    myIsLoadingTickets: boolean
  ) {
    state.myIsLoadingTickets = myIsLoadingTickets;
  },
  [MutationTypes.SET_MY_DELETED_TICKETS_DATA](
    state: State,
    myDeletedTicketsData: any
  ) {
    state.myDeletedTicketsData = myDeletedTicketsData;
  },
  [MutationTypes.SET_MY_IS_DELETING_TICKETS](
    state: State,
    myIsDeletingTickets: boolean
  ) {
    state.myIsDeletingTickets = myIsDeletingTickets;
  },
  [MutationTypes.SET_MY_IS_UPDATING_TICKETS](
    state: State,
    myIsUpdatingTickets: boolean
  ) {
    state.myIsUpdatingTickets = myIsUpdatingTickets;
  },
  [MutationTypes.SET_MY_UPDATED_TICKETS_DATA](
    state: State,
    myUpdatedTicketsData: any
  ) {
    state.myUpdatedTicketsData = myUpdatedTicketsData;
  },

  // [MutationTypes.SET_CATEGORIES](state: State, categories: AssetCategory[]) {
  //     state.categories = categories; // categories.map(category => AssetCategory.fromObject(categories));
  // },
  // [MutationTypes.SET_IS_LOADING_CATEGORIES](state: State, isLoadingCategories: boolean) {
  //     state.isLoadingCategories = isLoadingCategories;
  // },
  // [MutationTypes.SET_IS_CATEGORIES_LOADED](state: State, isCategoriesLoaded: boolean) {
  //     state.isCategoriesLoaded = isCategoriesLoaded;
  // },

  // [MutationTypes.SET_TYPES](state: State, types: TicketType[]) {
  [MutationTypes.SET_TYPES](state: State, types: FilterOptionDef[]) {
    state.types = types;
    const typeFilter = state.filters.find((f) => f.id === "type");
    if (typeFilter) {
      typeFilter.options = state.types;
    }
  },
  [MutationTypes.SET_IS_LOADING_TYPES](state: State, isLoadingTypes: boolean) {
    state.isLoadingTypes = isLoadingTypes;
  },
  [MutationTypes.SET_IS_TYPES_LOADED](state: State, isTypesLoaded: boolean) {
    state.isTypesLoaded = isTypesLoaded;
  },

  // [MutationTypes.SET_SEVERITIES](state: State, severities: TicketSeverity[]) {
  [MutationTypes.SET_SEVERITIES](state: State, severities: FilterOptionDef[]) {
    state.severities = severities;
    const severityFilter = state.filters.find((f) => f.id === "severity");
    if (severityFilter) {
      severityFilter.options = state.severities;
    }
  },
  [MutationTypes.SET_IS_LOADING_SEVERITIES](
    state: State,
    isLoadingSeverities: boolean
  ) {
    state.isLoadingSeverities = isLoadingSeverities;
  },
  [MutationTypes.SET_IS_SEVERITIES_LOADED](
    state: State,
    isSeveritiesLoaded: boolean
  ) {
    state.isSeveritiesLoaded = isSeveritiesLoaded;
  },

  // [MutationTypes.SET_STATUSES](state: State, statuses: TicketStatus[]) {
  [MutationTypes.SET_STATUSES](state: State, statuses: FilterOptionDef[]) {
    state.statuses = statuses;
    const statusFilter = state.filters.find((f) => f.id === "status");
    if (statusFilter) {
      statusFilter.options = state.statuses;
    }
  },
  [MutationTypes.SET_IS_LOADING_STATUSES](
    state: State,
    isLoadingStatuses: boolean
  ) {
    state.isLoadingStatuses = isLoadingStatuses;
  },
  [MutationTypes.SET_IS_STATUSES_LOADED](
    state: State,
    isStatusesLoaded: boolean
  ) {
    state.isStatusesLoaded = isStatusesLoaded;
  },

  [MutationTypes.SET_PAGE](state: State, page: number) {
    state.page = page;
  },

  [MutationTypes.SET_PAGESIZE](state: State, pageSize: number) {
    state.pageSize = pageSize;
  },

  [MutationTypes.SET_TOTAL](state: State, total: number) {
    state.total = total;
  },

  [MutationTypes.SET_SEARCH](state: State, search: string | null) {
    state.search = search;
  },

  [MutationTypes.SET_FILTER](state: State, filters: FilterDef[]) {
    state.filters = filters;
  },
  [MutationTypes.SET_AVAILABLEFIELDS](
    state: State,
    availableFields: FieldDef[]
  ) {
    state.availableFields = availableFields;
  },

  [MutationTypes.SET_SORTS](state: State, sorts: SortDef[]) {
    state.sorts = sorts;
  },

  [MutationTypes.SET_MY_PAGE](state: State, myPage: number) {
    state.myPage = myPage;
  },

  [MutationTypes.SET_MY_PAGESIZE](state: State, myPageSize: number) {
    state.myPageSize = myPageSize;
  },

  [MutationTypes.SET_MY_TOTAL](state: State, myTotal: number) {
    state.myTotal = myTotal;
  },

  [MutationTypes.SET_MY_SEARCH](state: State, mySearch: string | null) {
    state.mySearch = mySearch;
  },

  [MutationTypes.SET_MY_FILTER](state: State, myFilters: FilterDef[]) {
    state.myFilters = myFilters;
  },
  [MutationTypes.SET_MY_AVAILABLEFIELDS](
    state: State,
    myAvailableFields: FieldDef[]
  ) {
    state.myAvailableFields = myAvailableFields;
  },

  [MutationTypes.SET_MY_SORTS](state: State, mySorts: SortDef[]) {
    state.mySorts = mySorts;
  },









  [MutationTypes.SET_TICKETS_KANBAN](state: State, ticketsKanban: Map<string, Ticket[]>) {
    state.ticketsKanban = ticketsKanban;
  },
  [MutationTypes.SET_IS_LOADING_TICKETS_KANBAN](
    state: State,
    isLoadingTicketsKanban: Map<string, boolean>
  ) {
    state.isLoadingTicketsKanban = isLoadingTicketsKanban;
  },
  // [MutationTypes.SET_DELETED_TICKETS_KANBAN_DATA](
  //   state: State,
  //   deletedTicketsData: any
  // ) {
  //   state.deletedTicketsData = deletedTicketsData;
  // },
  [MutationTypes.SET_IS_DELETING_TICKETS_KANBAN](
    state: State,
    isDeletingTicketsKanban: Map<string, boolean>
  ) {
    state.isDeletingTicketsKanban = isDeletingTicketsKanban;
  },
  [MutationTypes.SET_IS_UPDATING_TICKETS_KANBAN](
    state: State,
    isUpdatingTicketsKanban: Map<string, boolean>
  ) {
    state.isUpdatingTicketsKanban = isUpdatingTicketsKanban;
  },
  // [MutationTypes.SET_UPDATED_TICKETS_KANBAN_DATA](
  //   state: State,
  //   updatedTicketData: any
  // ) {
  //   state.updatedTicketData = updatedTicketData;
  // },

  [MutationTypes.SET_PAGE_KANBAN](state: State, pageKanban: Map<string, number>) {
    state.pageKanban = pageKanban;
  },

  [MutationTypes.SET_PAGESIZE_KANBAN](state: State, pageSizeKanban: Map<string, number>) {
    state.pageSizeKanban = pageSizeKanban;
  },

  [MutationTypes.SET_TOTAL_KANBAN](state: State, totalKanban: Map<string, number>) {
    state.totalKanban = totalKanban;
  },

  [MutationTypes.SET_SEARCH_KANBAN](state: State, searchKanban: Map<string, string | null>) {
    state.searchKanban = searchKanban;
  },

  [MutationTypes.SET_FILTER_KANBAN](state: State, filtersKanban: Map<string, FilterDef[]>) {
    state.filtersKanban = filtersKanban;
  },
  [MutationTypes.SET_AVAILABLEFIELDS_KANBAN](
    state: State,
    availableFieldsKanban: Map<string, FieldDef[]>
  ) {
    state.availableFieldsKanban = availableFieldsKanban;
  },

  [MutationTypes.SET_SORTS_KANBAN](state: State, sortsKanban: Map<string, SortDef[]>) {
    state.sortsKanban = sortsKanban;
  },









  [MutationTypes.SET_TICKET](state: State, ticket: Ticket | null) {
    state.ticket = ticket === null ? null : Ticket.fromObject(ticket);
  },

  [MutationTypes.SET_TICKET_ERROR](state: State, ticketError: String | null) {
    state.ticketError = ticketError;
  },

  [MutationTypes.SET_TICKET_ERROR_VALIDATION](state: State, ticketErrorValidation: any | null) {
    state.ticketErrorValidation = ticketErrorValidation;
  },

  [MutationTypes.SET_IS_LOADING_TICKET](
    state: State,
    isLoadingTicket: boolean
  ) {
    state.isLoadingTicket = isLoadingTicket;
  },
  [MutationTypes.SET_IS_TICKET_LOADED](
    state: State,
    isTicketLoaded: boolean
  ) {
    state.isTicketLoaded = isTicketLoaded;
  },
  [MutationTypes.SET_IS_CREATING_TICKET](
    state: State,
    isCreatingTicket: boolean
  ) {
    state.isCreatingTicket = isCreatingTicket;
  },
  [MutationTypes.SET_CREATED_TICKET_DATA](
    state: State,
    createdTicketData: any
  ) {
    state.createdTicketData = createdTicketData;
  },
  [MutationTypes.SET_IS_UPDATING_TICKET](
    state: State,
    isUpdatingTicket: boolean
  ) {
    state.isUpdatingTicket = isUpdatingTicket;
  },
  [MutationTypes.SET_IS_TICKET_UPDATED](
    state: State,
    isTicketUpdated: boolean
  ) {
    state.isTicketUpdated = isTicketUpdated;
  },
  [MutationTypes.SET_UPDATED_TICKET_DATA](
    state: State,
    updatedTicketData: any
  ) {
    state.updatedTicketData = updatedTicketData;
  },
  [MutationTypes.SET_IS_DELETING_TICKET](
    state: State,
    isDeletingTicket: boolean
  ) {
    state.isDeletingTicket = isDeletingTicket;
  },
  [MutationTypes.SET_DELETED_TICKET_SUCCEEDED](
    state: State,
    deletedTicketSucceeded: boolean
  ) {
    state.deletedTicketSucceeded = deletedTicketSucceeded;
  },
  [MutationTypes.SET_DELETED_TICKET_DATA](
    state: State,
    deletedTicketData: any
  ) {
    state.deletedTicketData = deletedTicketData;
  },
  [MutationTypes.SET_TICKET_TO_PREVIEW_MOD](
    state: State,
    ticketToPreviewMod: string | null
  ) {
    state.ticketToPreviewMod = ticketToPreviewMod;
  },
  [MutationTypes.SET_TICKET_PREVIEW_OPEN_STATE](
    state: State,
    ticketPreviewOpenState: boolean
  ) {
    state.ticketPreviewOpenState = ticketPreviewOpenState;
  },

  [MutationTypes.SET_TICKET_ACTIVITIES](
    state: State,
    ticketActivities: TicketActivity[]
  ) {
    state.ticketActivities = ticketActivities;
  },
  [MutationTypes.SET_IS_LOADING_TICKET_ACTIVITIES](
    state: State,
    isLoadingTicketActivities: boolean
  ) {
    state.isLoadingTicketActivities = isLoadingTicketActivities;
  },

  [MutationTypes.SET_TICKET_ACTIVITY_TYPES_ACTION](
    state: State,
    ticketActivityTypes: TicketActivityType[]
  ) {
    state.ticketActivityTypes = ticketActivityTypes;
  },
  [MutationTypes.SET_IS_LOADING_TICKET_ACTIVITY_TYPES](
    state: State,
    isLoadingTicketActivityTypes: boolean
  ) {
    state.isLoadingTicketActivityTypes = isLoadingTicketActivityTypes;
  },
  [MutationTypes.SET_IS_TICKET_ACTIVITY_TYPES_LOADED](
    state: State,
    isTicketActivityTypesLoaded: boolean
  ) {
    state.isTicketActivityTypesLoaded = isTicketActivityTypesLoaded;
  },
  [MutationTypes.SET_IS_SAVING_TICKET_ACTIVITY](
    state: State,
    isSavingTicketActivity: boolean
  ) {
    state.isSavingTicketActivity = isSavingTicketActivity;
  },
  [MutationTypes.SET_IS_ASSIGNING_TICKET_ACTIVITY](
    state: State,
    isAssigningTicketActivity: boolean
  ) {
    state.isAssigningTicketActivity = isAssigningTicketActivity;
  },


  [MutationTypes.SET_CHARTTYPES](
    state: State,
    chartTypes: ChartsPieChart
  ) {
    state.chartTypes = chartTypes;
  },
  [MutationTypes.SET_IS_LOADING_CHARTTYPES](
    state: State,
    isLoadingChartTypes: boolean
  ) {
    state.isLoadingChartTypes = isLoadingChartTypes;
  },
  [MutationTypes.SET_IS_CHARTTYPES_LOADED](
    state: State,
    isChartTypesLoaded: boolean
  ) {
    state.isChartTypesLoaded = isChartTypesLoaded;
  },

  [MutationTypes.SET_CHARTSEVERITIES](
    state: State,
    chartSeverities: ChartsStackedBarChart
  ) {
    state.chartSeverities = chartSeverities;
  },
  [MutationTypes.SET_IS_LOADING_CHARTSEVERITIES](
    state: State,
    isLoadingChartSeverities: boolean
  ) {
    state.isLoadingChartSeverities = isLoadingChartSeverities;
  },
  [MutationTypes.SET_IS_CHARTSEVERITIES_LOADED](
    state: State,
    isChartSeveritiesLoaded: boolean
  ) {
    state.isChartSeveritiesLoaded = isChartSeveritiesLoaded;
  },

  [MutationTypes.SET_CHARTSTATUSES](
    state: State,
    chartStatuses: ChartsPieChart
  ) {
    state.chartStatuses = chartStatuses;
  },
  [MutationTypes.SET_IS_LOADING_CHARTSTATUSES](
    state: State,
    isLoadingChartStatuses: boolean
  ) {
    state.isLoadingChartStatuses = isLoadingChartStatuses;
  },
  [MutationTypes.SET_IS_CHARTSTATUSES_LOADED](
    state: State,
    isChartStatusesLoaded: boolean
  ) {
    state.isChartStatusesLoaded = isChartStatusesLoaded;
  },

  [MutationTypes.SET_CHARTMONTHLY](
    state: State,
    chartMonthly: ChartsBarChart
  ) {
    state.chartMonthly = chartMonthly;
  },
  [MutationTypes.SET_IS_LOADING_CHARTMONTHLY](
    state: State,
    isLoadingChartMonthly: boolean
  ) {
    state.isLoadingChartMonthly = isLoadingChartMonthly;
  },
  [MutationTypes.SET_IS_CHARTMONTHLY_LOADED](
    state: State,
    isChartMonthlyLoaded: boolean
  ) {
    state.isChartMonthlyLoaded = isChartMonthlyLoaded;
  }



};
