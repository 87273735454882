export type State = {
  sidebarOpen: boolean
};

export const state: State = {
  sidebarOpen: false
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): State => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};