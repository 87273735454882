import { hasPermission } from "@/composables/UserRole";
import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { User } from "@/types/index";

export type Getters = {
  StateIsAuthenticated(state: State): boolean;
  StateScope(state: State): string[];
  StateAccessToken(state: State): string | null;
  StateRefreshToken(state: State): string | null;
  StateLoginError(state: State): number | null;
  StateLoginErrorMessage(state: State): string | null;
  StateRegisterError(state: State): number | null;
  StateRegisterErrorMessage(state: State): string | null;
  // StateNotifications(state: State): string[],
  StateUser(state: State): User | null;
  StateUserLoaded(state: State): boolean;
  StateLoadingUser(state: State): boolean;

  StateSavingUser(state: State): boolean;
  StateUserSaved(state: State): boolean;
  StateUserSaveError(state: State): number | null;
  StateUserSaveErrorMessage(state: State): string | null;

  StateVerificationCode(state: State): string | null;
  StateVerificationError(state: State): string | null;
  StateVerified(state: State): boolean;
  StateNoOrganisation(state: State): boolean;
  StateHasSubscriptiob(state: State): boolean;

  StateForgotPassSuccess(state: State): boolean;
  StateForgotPassSendingRequest(state: State): boolean;
  StateForgotPassErrorMessage(state: State): string | null;

  StateResetPassSuccess(state: State): boolean;
  StateResetPassSendingRequest(state: State): boolean;
  StateResetPassErrorMessage(state: State): string | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  StateIsAuthenticated: (state) => state.isAuthenticated, // localStorage.getItem('is-authenticated'), // !!state.userName && localStorage.getItem('is-authenticated'),
  StateScope: (state) => state.scope,
  StateAccessToken: (state) => state.accessToken,
  StateRefreshToken: (state) => state.refreshToken,
  StateLoginError: (state) => state.loginError,
  StateLoginErrorMessage: (state) => state.loginErrorMessage,
  StateRegisterError: (state) => state.registerError,
  StateRegisterErrorMessage: (state) => state.registerErrorMessage,
  // StateNotifications: state => state.notifications,
  StateUser: (state) => state.user,
  StateUserLoaded: (state) => state.userLoaded,
  StateLoadingUser: (state) => state.loadingUser,

  StateSavingUser: (state) => state.savingUser,
  StateUserSaved: (state) => state.userSaved,
  StateUserSaveError: (state) => state.userSaveError,
  StateUserSaveErrorMessage: (state) => state.userSaveErrorMessage,

  StateVerificationCode: (state) => state.verificationCode,
  StateVerificationError: (state) => state.verificationError,
  StateVerified: (state) => state.verified,

  StateNoOrganisation: (state) => state.noOrganisation,
  StateHasSubscriptiob: (state) => state.hasSubscription,

  StateForgotPassSuccess: (state) => state.forgotPassSuccess,
  StateForgotPassSendingRequest: (state) => state.forgotPassSendingRequest,
  StateForgotPassErrorMessage: (state) => state.forgotPassErrorMessage,

  StateResetPassSuccess: (state) => state.resetPassSuccess,
  StateResetPassSendingRequest: (state) => state.resetPassSendingRequest,
  StateResetPassErrorMessage: (state) => state.resetPassErrorMessage
};
