import {
  Asset,
  AssetCategory,
  AssetManufacturer,
  AssetModel,
  AssetField,
  AssetChart,
  FieldDef,
  SortDef,
  FilterOptionDef,
  AssetListFilters,
  FilterDef,
  AssetImport,
  AssetExport
  // AssetState
} from '@/types/index'
import { ChartsPieChart } from "@/types/ChartsPieChart";

const filters: FilterDef[] = AssetListFilters.map((f: FilterDef) => f);

export type AssetState = {

  hasAssetsCharts: boolean,
  isLoadingHasAssetsCharts: boolean,

  assetsCharts: AssetChart[],
  isLoadingAssetsCharts: boolean,

  assets: Asset[],
  isLoadingAssets: boolean,
  isDeletingAssets: boolean,
  deletedAssetsData: null,
  isUpdatingAssets: boolean,
  updatedAssetsData: null,

  page: number,
  pageSize: number,
  total: number,
  search: string | null,
  filters: FilterDef[],
  availableFields: FieldDef[],
  sorts: SortDef[],

  assetsMine: Asset[],
  isLoadingAssetsMine: boolean,

  asset: Asset | null,
  assetError: string | null,
  assetErrorValidation: any,
  isLoadingAsset: boolean,
  isAssetLoaded: boolean,
  isCreatingAsset: boolean,
  isAssetCreated: boolean,
  createdAssetData: null,
  isUpdatingAsset: boolean,
  isAssetUpdated: boolean,
  updatedAssetData: null,
  isDeletingAsset: boolean,
  deletedAssetSucceeded: boolean,
  deletedAssetData: null,

  assetToPreviewMod: string | null,
  assetPreviewOpenState: boolean,

  categories: AssetCategory[],
  isLoadingCategories: boolean,
  isCategoriesLoaded: boolean,
  isSavingCategories: boolean,

  // categoriesSaved: boolean,
  // categoriesSaveError: string | null,

  isLoadingCategory: boolean,
  isCategoryLoaded: boolean,
  isSavingCategory: boolean,

  isCategorySaved: boolean,
  categorySaveError: string | null,

  isDeletingCategory: boolean,

  // categoryDeleted: boolean,
  // categoryDeleteError: string | null,

  fields: AssetField[],
  isLoadingFields: boolean,
  isFieldsLoaded: boolean,
  // isSavingFields: boolean,
  // isLoadingField: boolean,
  // isFieldLoaded: boolean,
  // isSavingField: boolean,
  // isDeletingField: boolean,

  fieldsPage: number,
  fieldsPageSize: number,
  fieldsTotal: number,

  field: AssetField | null,
  fieldError: String | null,
  fieldErrorValidation: any,
  isLoadingField: boolean,
  isCreatingField: boolean,
  createdFieldData: null,
  isUpdatingField: boolean,
  updatedFieldData: null,
  isDeletingField: boolean,
  deletedFieldSucceeded: boolean,
  deletedFieldData: null,

  imports: AssetImport[],
  isLoadingImports: boolean,
  isImportsLoaded: boolean,

  import: AssetImport | null,
  isLoadingImport: boolean,
  isImportLoaded: boolean,

  manufacturers: AssetManufacturer[],
  isSavingManufacturers: boolean,
  isManufacturersSaved: boolean,
  isLoadingManufacturers: boolean,
  isManufacturersLoaded: boolean,

  models: AssetModel[],
  isSavingModels: boolean,
  isModelsSaved: boolean,
  isLoadingModels: boolean,
  isModelsLoaded: boolean,

  lifeCycleStages: FilterOptionDef[],
  isLoadingLifeCycleStages: boolean,
  isLifeCycleStagesLoaded: boolean,

  chartAssetCategories: ChartsPieChart | null,
  isLoadingChartAssetCategories: boolean,
  isChartAssetCategoriesLoaded: boolean,

  assetImport: AssetImport | null,
  assetImportIsImporting: Boolean,
  assetImportDone: Boolean,
  assetImportResults: Object | null,

  assetExportGenerating: Boolean,
  assetExportGenerationDone: Boolean,
  assetExportResults: Object | null,
  
  assetExportsLoading: Boolean,
  assetExportsLoaded: Boolean,
  assetExports: AssetExport[]
};

export const state: AssetState = {

  isLoadingHasAssetsCharts: false,
  hasAssetsCharts: false,

  assetsCharts: [],
  isLoadingAssetsCharts: false,

  assets: [],

  page: 1,
  pageSize: 10,
  total: 0,
  search: null,
  filters: filters,
  availableFields: [],
  sorts: [],

  isLoadingAssets: false,
  isDeletingAssets: false,
  deletedAssetsData: null,
  isUpdatingAssets: false,
  updatedAssetsData: null,

  assetsMine: [],
  isLoadingAssetsMine: false,

  asset: null,
  assetError: null,
  assetErrorValidation: null,
  isLoadingAsset: false,
  isAssetLoaded: false,
  isCreatingAsset: false,
  isAssetCreated: false,
  createdAssetData: null,
  isUpdatingAsset: false,
  isAssetUpdated: false,
  updatedAssetData: null,
  isDeletingAsset: false,

  deletedAssetSucceeded: false,
  deletedAssetData: null,

  assetToPreviewMod: null,
  assetPreviewOpenState: false,

  categories: [],
  isLoadingCategories: false,
  isCategoriesLoaded: false,
  isSavingCategories: false,

  isLoadingCategory: false,
  isCategoryLoaded: false,
  isSavingCategory: false,

  isCategorySaved: false,
  categorySaveError: null,

  isDeletingCategory: false,

  fields: [],
  isLoadingFields: false,
  isFieldsLoaded: false,

  fieldsPage: 1,
  fieldsPageSize: 10,
  fieldsTotal: 0,
  // fieldsSearch: null,
  // fieldsFilters: filters,
  // fieldsAvailableFields: [],
  // fieldsSorts: [],



  field: null,
  fieldError: null,
  fieldErrorValidation: null,
  isLoadingField: false,
  isCreatingField: false,
  createdFieldData: null,
  isUpdatingField: false,
  updatedFieldData: null,
  isDeletingField: false,
  deletedFieldSucceeded: false,
  deletedFieldData: null,



  imports: [],
  isLoadingImports: false,
  isImportsLoaded: false,

  import: null,
  isLoadingImport: false,
  isImportLoaded: false,

  manufacturers: [],
  isSavingManufacturers: false,
  isManufacturersSaved: false,
  isLoadingManufacturers: false,
  isManufacturersLoaded: false,

  models: [],
  isSavingModels: false,
  isModelsSaved: false,
  isLoadingModels: false,
  isModelsLoaded: false,

  lifeCycleStages: [],
  isLoadingLifeCycleStages: false,
  isLifeCycleStagesLoaded: false,

  chartAssetCategories: null,
  isLoadingChartAssetCategories: false,
  isChartAssetCategoriesLoaded: false,

  assetImport: null,
  assetImportIsImporting: false,
  assetImportDone: false,
  assetImportResults: null,

  assetExportGenerating: false,
  assetExportGenerationDone: false,
  assetExportResults: null,

  assetExportsLoading: false,
  assetExportsLoaded: false,
  assetExports: [],
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): AssetState => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};