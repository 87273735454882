import {
  ChatBotMessage,
  User,
  ChatBotSettings
} from "@/types/index";

export type ChatBotState = {
  chatBotMessages: ChatBotMessage[];
  chatBotOpenState: Boolean;
  chatBotIsTyping: Boolean;
  chatBotUser: User | null;

  chatBotSettings: ChatBotSettings;
  isLoadingChatBotSettings: boolean,
  isChatBotSettingsLoaded: boolean,
  isSavingChatBotSettings: boolean,
};

export const state: ChatBotState = {
  chatBotMessages: [],
  chatBotOpenState: false,
  chatBotIsTyping: false,
  chatBotUser: null,

  chatBotSettings: {
    enable: true,
  },
  isLoadingChatBotSettings: false,
  isChatBotSettingsLoaded: false,
  isSavingChatBotSettings: false,
};
