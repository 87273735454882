import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { AppState as State, ThemeModes } from './state';

export type AppGetters = {
  StateMode(state: State): ThemeModes,
  StateApiVersion(state: State): string,
}
export const getters: GetterTree<State, RootState> & AppGetters = {
  StateMode: (state: State) => state.mode,
  StateApiVersion: (state: State) => state.apiVersion
};
