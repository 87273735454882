// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum PaymentsMutationTypes {
  RESET = "reset",

  SET_STRIPECUSTOMER = 'setStripeCustomer',
  SET_STRIPEINVOICE = 'setStripeInvoice',
  // SET_STRIPEPAYMENTEVENT = 'setStripePaymentEvent',
  SET_STRIPEPAYMENTMETHOD = 'setStripePaymentMethod',
  SET_STRIPESUBSCRIPTION = 'setStripeSubscription',
  SET_STRIPEPLAN = 'setStripePlan',
  SET_STRIPEPRODUCT = 'setStripeProduct'
}
