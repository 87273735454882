import { User } from '@/types/index';

export type State = {
  users: User[],
  isLoadingUsers: boolean,
  isUsersLoaded: boolean,

  // isSavingUser: boolean,
  // userSaveError: string,

};

export const state: State = {
  users: [],
  isLoadingUsers: false,
  isUsersLoaded: false
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): State => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};