/* eslint-disable no-unused-vars */
import { Document } from "./base/Document";
import { reactive } from "vue";
import { FieldDef, OFieldType, OActionType, FieldAction } from "./FieldDef";
import { FilterDef, OFilterType, OOptionsType } from "./FilterDef";

const OAssetLifeCycleStage = {
  procurement: "Procurement",
  operation: "Pperation",
  maintenance: "Maintenance",
  disposal: "Disposal",
} as const;
type AssetLifeCycleStage = keyof typeof OAssetLifeCycleStage;

const OAssetImportStage = {
  upload: "upload",
  mapping: "mapping",
  preview: "preview",
  progress: "progress",
  finish: "finish",
} as const;
type AssetImportStage = keyof typeof OAssetImportStage;

const OAssetImportMapping = {
  name: {
    assetField: "name",
    csvField: "",
    mapped: false,
  },
  pictureUrl: {
    assetField: "pictureUrl",
    csvField: "",
    mapped: false,
  },
  description: {
    assetField: "description",
    csvField: "",
    mapped: false,
  },
  serial: {
    assetField: "serial",
    csvField: "",
    mapped: false,
  },
  partNumber: {
    assetField: "partNumber",
    csvField: "",
    mapped: false,
  },
  lifeCycleStage: {
    assetField: "lifeCycleStage",
    csvField: "",
    mapped: false,
  },
  lifeSpan: {
    assetField: "lifeSpan",
    csvField: "",
    mapped: false,
  },
  purchaseDate: {
    assetField: "purchaseDate",
    csvField: "",
    mapped: false,
  },
  purchaseValue: {
    assetField: "purchaseValue",
    csvField: "",
    mapped: false,
  },
  warrantyExpireDate: {
    assetField: "warrantyExpireDate",
    csvField: "",
    mapped: false,
  },
  owner: {
    assetField: "owner",
    csvField: "",
    mapped: false,
  },
  tag: {
    assetField: "tag",
    csvField: "",
    mapped: false,
  },
  category: {
    assetField: "category",
    csvField: "",
    mapped: false,
  },
  subCategory: {
    assetField: "subCategory",
    csvField: "",
    mapped: false,
  },
  manufacturer: {
    assetField: "manufacturer",
    csvField: "",
    mapped: false,
  },
  model: {
    assetField: "model",
    csvField: "",
    mapped: false,
  },
  field01: {
    assetField: "field01",
    csvField: "",
    mapped: false,
  },
  field02: {
    assetField: "field02",
    csvField: "",
    mapped: false,
  },
  field03: {
    assetField: "field03",
    csvField: "",
    mapped: false,
  },
  field04: {
    assetField: "field04",
    csvField: "",
    mapped: false,
  },
  field05: {
    assetField: "field05",
    csvField: "",
    mapped: false,
  },
  field06: {
    assetField: "field06",
    csvField: "",
    mapped: false,
  },
  field07: {
    assetField: "field07",
    csvField: "",
    mapped: false,
  },
  field08: {
    assetField: "field08",
    csvField: "",
    mapped: false,
  },
  field09: {
    assetField: "field09",
    csvField: "",
    mapped: false,
  },
  field10: {
    assetField: "field10",
    csvField: "",
    mapped: false,
  },
};
// type AssetImportMapping = keyof typeof OAssetImportMapping;
type AssetImportMapping = typeof OAssetImportMapping;

class Asset {
  _id: number | string | readonly string[];
  name: string;
  categoryName: string;
  categoryIcon: string | null;
  categoryIconUrl: string | null;
  category: number | string | readonly string[];
  subCategoryName: string;
  subCategoryIcon: string | null;
  subCategoryIconUrl: string | null;
  subCategory: number | string | readonly string[];
  // width: number | null;
  // widthUnit: string | null;
  // height: number | null;
  // heightUnit: string | null;
  // depth: number | null;
  // depthUnit: string | null;
  // weight: number | null;
  // weightUnit: string | null;
  documents: Document[];
  picture: string;
  pictureUrl: string;
  description: string;
  manufacturerName: string;
  manufacturerText: string;
  manufacturer: number | string | readonly string[] | null;
  modelText: string;
  model: number | string | readonly string[] | null;
  tag: string;
  // tagName: string;
  owner: number | string | readonly string[];
  ownerFirstName: string;
  ownerLastName: string;
  ownerAvatar: string;
  // location: string;
  // locationId: number | string | readonly string[];
  // application: string;
  serial: string;
  partNumber: string;
  lifeCycleStage: string;
  lifeSpan: number | null;
  purchaseDate: Date | null;
  purchaseValue: number;
  warrantyExpireDate: Date | null;

  field01: string | null;
  field02: string | null;
  field03: string | null;
  field04: string | null;
  field05: string | null;
  field06: string | null;
  field07: string | null;
  field08: string | null;
  field09: string | null;
  field10: string | null;

  constructor(
    _id: number | string | readonly string[],
    name: string,
    category: number | string | readonly string[],
    categoryName: string,
    categoryIcon: string | null,
    categoryIconUrl: string | null,
    subCategory: number | string | readonly string[],
    subCategoryName: string,
    subCategoryIcon: string | null,
    subCategoryIconUrl: string | null,
    // width: number | null,
    // widthUnit: string | null,
    // height: number | null,
    // heightUnit: string | null,
    // depth: number | null,
    // depthUnit: string | null,
    // weight: number | null,
    // weightUnit: string | null,
    documents: Document[],
    picture: string,
    pictureUrl: string,
    description: string,
    manufacturer: number | string | readonly string[],
    manufacturerName: string,
    manufacturerText: string,
    model: number | string | readonly string[],
    modelText: string,
    tag: string,
    // tagName: string,
    // owner: string,
    owner: number | string | readonly string[],
    ownerFirstName: string,
    ownerLastName: string,
    ownerAvatar: string,
    // location: string,
    // locationId: number | string | readonly string[],
    // application: string,
    serial: string,
    partNumber: string,
    lifeCycleStage: AssetLifeCycleStage,
    lifeSpan: number | null,
    purchaseDate: Date | null,
    purchaseValue: number,
    warrantyExpireDate: Date | null,

    field01: string | null,
    field02: string | null,
    field03: string | null,
    field04: string | null,
    field05: string | null,
    field06: string | null,
    field07: string | null,
    field08: string | null,
    field09: string | null,
    field10: string | null
  ) {
    this._id = _id;
    this.name = name;
    this.category = category;
    this.categoryName = categoryName;
    this.categoryIcon = categoryIcon;
    this.categoryIconUrl = categoryIconUrl;
    this.subCategory = subCategory;
    this.subCategoryName = subCategoryName;
    this.subCategoryIcon = subCategoryIcon;
    this.subCategoryIconUrl = subCategoryIconUrl;

    // this.width = width
    // this.widthUnit = widthUnit
    // this.height = height
    // this.heightUnit = heightUnit
    // this.depth = depth
    // this.depthUnit = depthUnit
    // this.weight = weight
    // this.weightUnit = weightUnit
    this.documents = documents
      ? documents.map((d) => new Document(d._id, d.url, d.contains, d.mimeType))
      : [];
    this.picture = picture;
    this.pictureUrl = pictureUrl;
    this.description = description;
    this.manufacturer = manufacturer;
    this.manufacturerName = manufacturerName;
    this.manufacturerText = manufacturerText;
    this.model = model;
    this.modelText = modelText;
    this.tag = tag;
    // this.tagName = tagName
    this.owner = owner;
    this.ownerFirstName = ownerFirstName;
    this.ownerLastName = ownerLastName;
    this.ownerAvatar = ownerAvatar;
    // this.ownerId = ownerId;
    // this.location = location
    // this.locationId = locationId
    // this.application = application
    this.serial = serial;
    this.partNumber = partNumber;
    this.lifeCycleStage = lifeCycleStage;
    this.lifeSpan = lifeSpan;
    this.purchaseDate =
      purchaseDate && typeof purchaseDate == "string"
        ? new Date(purchaseDate)
        : purchaseDate;
    this.purchaseValue = purchaseValue;
    this.warrantyExpireDate =
      warrantyExpireDate && typeof warrantyExpireDate == "string"
        ? new Date(warrantyExpireDate)
        : warrantyExpireDate;

    this.field01 = field01;
    this.field02 = field02;
    this.field03 = field03;
    this.field04 = field04;
    this.field05 = field05;
    this.field06 = field06;
    this.field07 = field07;
    this.field08 = field08;
    this.field09 = field09;
    this.field10 = field10;
  }

  static fromObject(obj: any): Asset {
    return new Asset(
      obj._id,
      obj.name,
      obj.category,
      obj.categoryName,
      obj.categoryIcon,
      obj.categoryIconUrl,
      obj.subCategory,
      obj.subCategoryName,
      obj.subCategoryIcon,
      obj.subCategoryIconUrl,
      // obj.width,
      // obj.widthUnit,
      // obj.height,
      // obj.heightUnit,
      // obj.depth,
      // obj.depthUnit,
      // obj.weight,
      // obj.weightUnit,
      obj.documents,
      obj.picture,
      obj.pictureUrl,
      obj.description,
      obj.manufacturer,
      obj.manufacturerName,
      obj.manufacturerText,
      obj.model,
      obj.modelText,
      obj.tag,
      // obj.tagName,
      obj.owner,
      obj.ownerFirstName,
      obj.ownerLastName,
      obj.ownerAvatar,
      // obj.location,
      // obj.locationId,
      // obj.application,
      obj.serial,
      obj.partNumber,
      obj.lifeCycleStage,
      obj.lifeSpan,
      obj.purchaseDate,
      obj.purchaseValue,
      obj.warrantyExpireDate,

      obj.field01,
      obj.field02,
      obj.field03,
      obj.field04,
      obj.field05,
      obj.field06,
      obj.field07,
      obj.field08,
      obj.field09,
      obj.field10
    );
  }

  static getDefaultObject(): Asset {
    return Asset.fromObject({
      name: "",
      category: "",
      categoryName: "",
      categoryIcon: null,
      categoryIconUrl: null,
      subCategory: "",
      subCategoryName: "",
      subCategoryIcon: null,
      subCategoryIconUrl: null,
      // width: null,
      // widthUnit: null,
      // height: null,
      //  heightUnit: null,
      //  depth: null,
      // depthUnit: null,
      // weight: null,
      // weightUnit: null,
      documents: [],
      picture: "",
      pictureUrl: "",
      description: "",
      id: "",
      manufacturer: "",
      manufacturerName: "",
      manufacturerText: "",
      model: "",
      modelText: "",
      tag: "",
      // tagName: '',
      owner: "",
      ownerFirstName: "",
      ownerLastName: "",
      ownerAvatar: "",
      // ownerId: "",
      // location: '',
      // locationId: '',
      // application: '',
      serial: "",
      partNumber: "",
      lifeCycleStage: "",
      lifeSpan: "",
      purchaseDate: "",
      purchaseValue: 0,
      warrantyExpireDate: "",

      field01: "",
      field02: "",
      field03: "",
      field04: "",
      field05: "",
      field06: "",
      field07: "",
      field08: "",
      field09: "",
      field10: "",
    });
  }
}

const AssetListFields = [
  FieldDef.fromObject({
    id: "pictureUrl",
    title: "Picture",
    available: true,
    show: true,
    type: OFieldType.AssetPicture,
    sortable: false,
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "_id",
    title: "ID",
    available: true,
    show: false,
    type: OFieldType.id,
    searchable: false,
    actions: [
      // FieldAction.fromObject({
      //   id: 'view',
      //   text: 'View',
      //   icon: null,
      //   type: OActionType.view,
      //   color: "themeprimary"
      // })
    ],
    // [OActionType.view],
  }),
  FieldDef.fromObject({
    id: "name",
    title: "Name",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "purchaseDate",
    title: "Purchase Date",
    available: true,
    show: true,
    type: OFieldType.Date,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "lifeCycleStage",
    title: "Life Cycle Stage",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "lifeSpan",
    title: "Life Span in Month",
    available: true,
    show: false,
    type: OFieldType.String,
    sortable: true,
    searchable: false,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "purchaseValue",
    title: "Purchase Value",
    available: true,
    show: true,
    type: OFieldType.Money,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "categoryName",
    title: "Category",
    available: true,
    show: false,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "subCategoryName",
    title: "subCategory",
    available: true,
    show: false,
    type: "string",
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "manufacturerText",
    title: "Manufacturer",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "modelText",
    title: "Model",
    available: true,
    show: false,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "owner",
    title: "Owner",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: false,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    //actions: [OActionType.view, OActionType.edit],
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
      // FieldAction.fromObject({
      //   id: 'edit',
      //   text: 'Edit',
      //   icon: null,
      //   type: OActionType.edit,
      //   color: "themeprimary"
      // }),
      FieldAction.fromObject({
        id: "delete",
        text: "Delete",
        icon: null,
        type: OActionType.delete,
        color: "red",
      }),
    ],
    //  [OActionType.edit, OActionType.delete],
  }),
];

const AssetFieldsListFields = [
  FieldDef.fromObject({
    id: "_id",
    title: "ID",
    available: true,
    show: false,
    type: OFieldType.id,
    searchable: false,
    actions: [
      // FieldAction.fromObject({
      //   id: 'view',
      //   text: 'View',
      //   icon: null,
      //   type: OActionType.view,
      //   color: "themeprimary"
      // })
    ],
    // [OActionType.view],
  }),
  FieldDef.fromObject({
    id: "name",
    title: "Name",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "assetFieldId",
    title: "Field Id",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "fieldType",
    title: "Field Type",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "description",
    title: "Description",
    available: true,
    show: false,
    type: OFieldType.String,
    sortable: false,
    searchable: false,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
      FieldAction.fromObject({
        id: "delete",
        text: "Delete",
        icon: null,
        type: OActionType.delete,
        color: "red",
      }),
    ],
  }),
];

class AssetCategory {
  _id: number | string | readonly string[];
  name: string;
  order: number;
  icon: string;
  iconUrl: string;
  key: string;
  parent: string[];
  subCategories?: AssetCategory[];
  expanded: boolean | undefined;

  constructor(
    _id: number | string | readonly string[],
    name: string,
    order: number,
    icon: string,
    iconUrl: string,
    key: string,
    parent: string[],
    subCategories?: AssetCategory[],
    expanded?: boolean
  ) {
    this._id = _id;
    this.name = name;
    this.order = order;
    this.icon = icon;
    this.iconUrl = iconUrl;
    this.key = key;
    this.parent = parent;
    this.subCategories = subCategories;
    this.expanded = expanded;
  }

  static fromObject(obj: any): AssetCategory {
    if (obj.subCategories) {
      return new AssetCategory(
        obj._id,
        obj.name,
        obj.order,
        obj.icon,
        obj.iconUrl,
        obj.key,
        obj.parent,
        obj.subCategories.map((subCategory: any) => AssetCategory.fromObject(subCategory))
      );
    }
    return new AssetCategory(
      obj._id,
      obj.name,
      obj.order,
      obj.icon,
      obj.iconUrl,
      obj.key,
      obj.parent,
      obj.subCategories
    );
  }
}

class AssetCategoryTree {
  _id: number | string | readonly string[];
  order: number;
  parent: string[];

  constructor(_id: number | string | readonly string[], order: number, parent: string[]) {
    this._id = _id;
    this.order = order;
    this.parent = parent;
  }

  static fromObject(obj: any): AssetCategoryTree {
    if (obj.subCategories) {
      return new AssetCategoryTree(obj._id, obj.order, obj.parent);
    }
    return new AssetCategoryTree(obj._id, obj.order, obj.parent);
  }
}

class AssetCategoryAssetFieldRelation {
  _id: number | string | readonly string[];
  assetField: number | string | readonly string[];
  assetCategory: AssetCategory;

  constructor(
    _id: number | string | readonly string[],
    assetField: number | string | readonly string[],
    assetCategory: AssetCategory
  ) {
    this._id = _id;
    this.assetField = assetField;
    this.assetCategory = assetCategory;
  }
}

class AssetField {
  _id: number | string | readonly string[];
  assetFieldId: string;
  name: string;
  fieldType: string;
  prefix: string;
  suffix: string;
  category: AssetCategoryAssetFieldRelation[]; //number | string | readonly string[];
  description: string;

  constructor(
    _id: number | string | readonly string[],
    assetFieldId: string,
    name: string,
    fieldType: string,
    prefix: string,
    suffix: string,
    category: AssetCategoryAssetFieldRelation[], //number | string | readonly string[],
    description: string
  ) {
    this._id = _id;
    this.assetFieldId = assetFieldId;
    this.name = name;
    this.fieldType = fieldType;
    this.prefix = prefix;
    this.suffix = suffix;
    this.category = category;
    this.description = description;
  }

  static fromObject(obj: any): AssetField {
    return new AssetField(
      obj._id,
      obj.assetFieldId,
      obj.name,
      obj.fieldType,
      obj.prefix,
      obj.suffix,
      obj.category,
      obj.description
    );
  }

  static getDefaultObject(): AssetField {
    return AssetField.fromObject({
      _id: "",
      assetFieldId: "",
      name: "",
      fieldType: null,
      prefix: null,
      suffix: null,
      category: [],
      description: "",
    });
  }
}

class AssetExport {
  name: string;
  time: number;
  size: number;

  constructor(name: string, time: number, size: number) {
    this.name = name;
    this.time = time;
    this.size = size;
  }

  static fromObject(obj: any): AssetExport {
    return new AssetExport(obj.name, obj.time, obj.size);
  }
}

class AssetImport {
  _id: number | string | readonly string[];
  fileName: string;
  path: string;
  stage: AssetImportStage | null;
  mapping: AssetImportMapping;
  owner: string;
  csvHeaders: string[];
  csvData: any;

  constructor(
    _id: number | string | readonly string[],
    fileName: string,
    path: string,
    stage: AssetImportStage | null,
    mapping: AssetImportMapping,
    owner: string,
    csvHeaders: string[],
    csvData: any
  ) {
    this._id = _id;
    this.fileName = fileName;
    this.path = path;
    this.stage = stage;
    this.mapping = mapping;
    this.owner = owner;
    this.csvHeaders = csvHeaders;
    this.csvData = csvData;
  }

  static fromObject(obj: any): AssetImport {
    return new AssetImport(
      obj._id,
      obj.fileName,
      obj.path,
      obj.stage,
      obj.mapping,
      obj.owner,
      obj.csvHeaders,
      obj.csvData
    );
  }
}

class AssetModel {
  _id: number | string | readonly string[];
  text: string;
  constructor(_id: number | string | readonly string[], text: string) {
    this._id = _id;
    this.text = text;
  }

  static fromObject(obj: any): AssetModel {
    return new AssetModel(obj._id, obj.text);
  }
}

class AssetManufacturer {
  _id: number | string | readonly string[];
  name: string;
  key: string;
  text: string;
  // models: AssetModel[];
  constructor(
    _id: number | string | readonly string[],
    name: string,
    key: string,
    text: string
    // models: AssetModel[]
  ) {
    this._id = _id;
    this.name = name;
    this.key = key;
    this.text = text;
    // this.models = models
  }

  static fromObject(obj: any): AssetManufacturer {
    return new AssetManufacturer(
      obj._id,
      obj.name,
      obj.key,
      obj.text
      // obj.models.map((model: any) => AssetModel.fromObject(model))
    );
  }
}

class AssetLocation {
  id: number | string | readonly string[];
  name: string;
  constructor(id: number | string | readonly string[], name: string) {
    this.id = id;
    this.name = name;
  }

  static fromObject(obj: any): AssetLocation {
    return new AssetLocation(obj.id, obj.name);
  }
}

type CategoryFactories = Array<() => Asset>;

// debugger; // eslint-disable-line no-debugger
const AssetListFilters = [
  FilterDef.fromObject({
    id: "name",
    queryParam: "name",
    title: "Name",
    type: OFilterType.StringSearch,
    // storeOptionsGetter: null,
    // storeOptionsLoadAction: null,
    optionsType: null,
    options: [],
    value: "",
  }),
  FilterDef.fromObject({
    id: "category",
    queryParam: "category",
    title: "Category",
    type: OFilterType.Enum,
    // storeOptionsGetter: AssetCategory[], // AssetGetters.StateCategories,
    // storeOptionsLoadAction: AssetActionTypes.GET_CATEGORIES_ACTION,
    optionsType: OOptionsType.AssetCategory,
    options: reactive([]),
    value: "",
  }),
  FilterDef.fromObject({
    id: "owner",
    queryParam: "owner",
    title: "Owner",
    type: OFilterType.User,
    // storeOptionsGetter: AssetCategory[], // AssetGetters.StateCategories,
    // storeOptionsLoadAction: AssetActionTypes.GET_CATEGORIES_ACTION,
    optionsType: OOptionsType.User,
    options: reactive([]),
    value: "",
  }),
  FilterDef.fromObject({
    id: "manufacturer",
    queryParam: "manufacturer",
    title: "Manufacturer",
    type: OFilterType.Enum,
    optionsType: OOptionsType.AssetManufacturer,
    options: reactive([]),
    value: "",
  }),
  // FilterDef.fromObject({
  //   id: 'subcategory',
  //   queryParam: 'subcategory',
  //   title: 'Sub Category',
  //   type: OFilterType.Enum,
  //   // storeOptionsGetter: AssetCategory[], // AssetGetters.StateCategories,
  //   // storeOptionsLoadAction: AssetActionTypes.GET_CATEGORIES_ACTION,
  //   optionsType: OOptionsType.AssetCategory,
  //   relateTo: 'category',
  //   options: [],
  //   value: ''
  // }),
  // FilterDef.fromObject({
  //   id: 'category',
  //   queryParam: 'category',
  //   title: 'Category',
  //   type: OFilterType.Enum,
  //   // storeOptionsGetter: AssetCategory[], // AssetGetters.StateCategories,
  //   // storeOptionsLoadAction: AssetActionTypes.GET_CATEGORIES_ACTION,
  //   optionsType: OOptionsType.AssetCategory,
  //   options: [],
  //   value: ''
  // }),
  // FilterDef.fromObject({
  //   id: 'category',
  //   queryParam: 'category',
  //   title: 'Category',
  //   type: OFilterType.Enum,
  //   // storeOptionsGetter: AssetCategory[], // AssetGetters.StateCategories,
  //   // storeOptionsLoadAction: AssetActionTypes.GET_CATEGORIES_ACTION,
  //   optionsType: OOptionsType.AssetCategory,
  //   options: [],
  //   value: ''
  // }),
];
// debugger; // eslint-disable-line no-debugger

export {
  Asset,
  AssetCategory,
  AssetCategoryTree,
  AssetCategoryAssetFieldRelation,
  AssetManufacturer,
  AssetModel,
  AssetField,
  OAssetLifeCycleStage,
  AssetLifeCycleStage,
  AssetLocation,
  CategoryFactories,
  AssetListFields,
  AssetListFilters,
  AssetFieldsListFields,
  AssetImport,
  AssetImportMapping,
  OAssetImportMapping,
  AssetExport,
  // AssetActionTypes
  // AssetMutationTypes
  // AssetState,
  // AssetGetters
};
