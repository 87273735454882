import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { omit } from "lodash";
// import { State } from './state';
// import {
//   TicketActionTypes,
//   TicketMutationTypes,
//   TicketState as State
// } from "@/types/index";
import { TicketActionTypes } from "./action-types";
import { TicketMutationTypes } from "./mutation-types";
import { TicketState as State } from "./state";
// import axios from "axios";
import { HttpClient as http, AxiosError, AxiosResponse } from "@/util/ZebritAxios";
import { Config } from "../../../config";
import {
  Ticket,
  FieldDef,
  IGetTicket,
  SortDef,
  FilterDef,
  TicketListFilters,
  MyTicketListFilters,
  TicketActivity,
  TicketListFields,
  MyTicketListFields,
} from "@/types/index";
import { Mutations } from "./mutations";
// import { TicketMutationTypes } from './mutation-types';
// import { getters as TicketGetters } from './getters';

type TicketActivityPayload = {
  ticketActivityId: string;
  ticketId: string;
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [TicketActionTypes.GET_TICKETS_ACTION]({
    commit,
  }: AugmentedActionContext): // payload: {
  //   // page: number;
  //   // page_size: number;
  //   category: string | null;
  //   // search: string | null;
  // }
  void;
  [TicketActionTypes.GET_TICKETS_KANBAN_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      statusMapKey: string;
    }
  ): void;
  [TicketActionTypes.GET_MY_TICKETS_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      page: number;
      page_size: number;
      category: string | null;
      search: string | null;
    }
  ): void;
  // [TicketActionTypes.GET_TICKETS_CHARTS_ACTION]({ commit }: AugmentedActionContext): void;
  [TicketActionTypes.GET_TICKET_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetTicket
  ): void;
  [TicketActionTypes.SAVE_TICKET_ACTION](
    { commit }: AugmentedActionContext,
    payload: Ticket
  ): void;
  [TicketActionTypes.CHANGE_TICKET_STATUS_ORDER_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      ticketId: string;
      status: string;
      statusOrder: number;
    }
  ): void;
  [TicketActionTypes.DELETE_TICKET_ACTION](
    { commit }: AugmentedActionContext,
    payload: Ticket
  ): void;
  [TicketActionTypes.SET_TICKET_ACTION](
    { commit }: AugmentedActionContext,
    payload: Ticket | null
  ): void;
  [TicketActionTypes.SET_TICKETS_ACTION](
    { commit }: AugmentedActionContext,
    payload: Ticket[]
  ): void;
  // [TicketActionTypes.GET_CATEGORIES_ACTION](
  //     { commit }: AugmentedActionContext
  // ): void;
  /*[TicketActionTypes.GET_MANUFACTURERS_ACTION](
      { commit }: AugmentedActionContext
    ): void; */
  [TicketActionTypes.GET_TYPES_ACTION]({ commit }: AugmentedActionContext): void;
  [TicketActionTypes.GET_SEVERITIES_ACTION]({ commit }: AugmentedActionContext): void;
  [TicketActionTypes.GET_STATUSES_ACTION]({ commit }: AugmentedActionContext): void;

  [TicketActionTypes.GET_TICKET_ACTIVITIES_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [TicketActionTypes.GET_TICKET_ACTIVITY_TYPES_ACTION]({
    commit,
  }: AugmentedActionContext): void;

  [TicketActionTypes.SAVE_TICKET_ACTIVITY](
    { commit }: AugmentedActionContext,
    payload: TicketActivity
    // ticketId: string
  ): void;
  [TicketActionTypes.ASSIGN_TICKET_ACTIVITY](
    { commit }: AugmentedActionContext,
    payload: TicketActivityPayload
    // ticketId: string
  ): void;

  [TicketActionTypes.SET_PAGE]({ commit }: AugmentedActionContext, payload: number): void;
  [TicketActionTypes.SET_PAGESIZE](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [TicketActionTypes.SET_TOTAL](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [TicketActionTypes.SET_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [TicketActionTypes.SET_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ): void;
  [TicketActionTypes.SET_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ): void;
  [TicketActionTypes.SET_SORTS](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ): void;

  [TicketActionTypes.SET_MY_PAGE](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [TicketActionTypes.SET_MY_PAGESIZE](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [TicketActionTypes.SET_MY_TOTAL](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [TicketActionTypes.SET_MY_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [TicketActionTypes.SET_MY_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ): void;
  [TicketActionTypes.SET_MY_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ): void;
  [TicketActionTypes.SET_MY_SORTS](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ): void;

  [TicketActionTypes.SET_TICKET_TO_PREVIEW_MOD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [TicketActionTypes.SET_TICKET_PREVIEW_OPEN_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [TicketActionTypes.GET_CHART_TYPES]({ commit }: AugmentedActionContext): void;
}

// const getters = mapGetters({
//     page: "ticket/StatePage",
//     page_size: "ticket/StatePageSize",
//     total: "ticket/StateTotal",
//     search: "ticket/StateSearch",
//     filter: "ticket/StateFilter"
// });

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [TicketActionTypes.GET_TICKETS_ACTION]({
    commit,
    getters,
  }: AugmentedActionContext) {
    commit(TicketMutationTypes.SET_IS_LOADING_TICKETS, true);
    const page = getters.StatePage; // TicketGetters.StatePage() || 1;
    const page_size = getters.StatePageSize; // TicketGetters.StatePageSize || 10;
    const search = getters.StateSearch; // TicketGetters.StateSearch || 1;
    const filter = getters.StateFilter;
    const sorts = getters.StateSorts;

    const params: any = {
      $page: page, //payload && payload.page ? payload.page : 1,
      $limit: page_size, //payload && payload.page_size ? payload.page_size : 10,
      //category: payload && payload.category ? payload.category : null,
      // $text: search ? search : null, //payload && payload.search ? payload.search : null
    };

    // if (search) params.$text = search;
    if (search) {
      params.$term = search;
      const searchFields = TicketListFields.filter((tf) => tf.searchable === true).map(
        (tf) => tf.id
      );
      params.$searchFields = searchFields;
    }

    // debugger; // eslint-disable-line
    TicketListFilters.forEach((f) => {
      const _f = filter.find((__f: FilterDef) => __f.id == f.id);

      if (_f && _f.value !== f.value) {
        if (typeof _f.value == "string") {
          // debugger; // eslint-disable-line
          params[_f.queryParam] = _f.value;
        } else if (["type", "severity", "status"].indexOf(f.id) > -1) {
          if (_f.value._id !== null) params[_f.queryParam] = _f.value.name;
        } else {
          params[_f.queryParam] = _f.value._id;
        }
      }
    });

    // debugger; // eslint-disable-line
    if (sorts && sorts.length > 0) {
      params["$sort"] = (sorts[0].direction == "desc" ? "-" : "") + sorts[0].field.id;
    }

    // &organisation=636bcea8258a4899e28e86b0
    await http
      .get(
        //Config.api.tickets.tickets + "?$embed=assignee&$embed=submittedBy&$embed=organisation",
        Config.api.tickets.tickets,
        params
      )
      .then((res: AxiosResponse) => {
        res.data.docs.forEach((doc: any) => {
          if (doc.assignee) {
            doc.assignee = `${doc.assigneeFirstName} ${doc.assigneeLastName}`;
          }
          if (doc.submittedBy) {
            doc.submittedBy = `${doc.submittedByFirstName} ${doc.submittedByLastName}`;
          }
        });

        commit(TicketMutationTypes.SET_TICKETS, res.data.docs);
        commit(TicketMutationTypes.SET_PAGE, res.data.pages.current);
        commit(TicketMutationTypes.SET_PAGESIZE, res.data.items.limit);
        commit(TicketMutationTypes.SET_TOTAL, res.data.items.total);
        commit(TicketMutationTypes.SET_IS_LOADING_TICKETS, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_IS_LOADING_TICKETS, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [TicketActionTypes.GET_TICKETS_KANBAN_ACTION](
    { commit, getters }: AugmentedActionContext,
    payload: {
      statusMapKey: string;
    }
  ) {
    const statusMapKey = payload.statusMapKey;

    // commit(TicketMutationTypes.SET_IS_LOADING_TICKETS_KANBAN, true);

    const isLoadingTicketsKanban = new Map<string, boolean>(
      JSON.parse(JSON.stringify(Array.from(getters.StateIsLoadingTicketsKanban)))
    );
    isLoadingTicketsKanban.set(statusMapKey, true);
    commit(TicketMutationTypes.SET_IS_LOADING_TICKETS_KANBAN, isLoadingTicketsKanban);

    const page = getters.StatePageKanban.get(statusMapKey); // TicketGetters.StatePage() || 1;
    const page_size = getters.StatePageSizeKanban.get(statusMapKey); // TicketGetters.StatePageSize || 10;
    const search = getters.StateSearchKanban.get(statusMapKey) || null; // TicketGetters.StateSearch || 1;
    const filter = getters.StateFilterKanban.get(statusMapKey) || [];
    // const sorts = getters.StateSortsKanban.get(statusMapKey) || [];

    const params: any = {
      $page: page, //payload && payload.page ? payload.page : 1,
      $limit: page_size, //payload && payload.page_size ? payload.page_size : 10,
      //category: payload && payload.category ? payload.category : null,
      // $text: search ? search : null, //payload && payload.search ? payload.search : null
    };

    // if (search) params.$text = search;
    if (search) {
      params.$term = search;
      const searchFields = TicketListFields.filter((tf) => tf.searchable === true).map(
        (tf) => tf.id
      );
      params.$searchFields = searchFields;
    }

    // debugger; // eslint-disable-line
    TicketListFilters.forEach((f) => {
      const _f = filter.find((__f: FilterDef) => __f.id == f.id);

      if (_f && _f.value !== f.value) {
        if (typeof _f.value == "string") {
          // debugger; // eslint-disable-line
          params[_f.queryParam] = _f.value;
        } else if (["type", "severity", "status"].indexOf(f.id) > -1) {
          if (_f.value._id !== null) params[_f.queryParam] = _f.value.name;
        } else {
          params[_f.queryParam] = _f.value._id;
        }
      }
    });

    // debugger; // eslint-disable-line
    // if (sorts && sorts.length > 0) {
    //   params["$sort"] = (sorts[0].direction == "desc" ? "-" : "") + sorts[0].field.id;
    // }
    params["$sort"] = "statusOrder";

    // &organisation=636bcea8258a4899e28e86b0
    await http
      .get(
        //Config.api.tickets.tickets + "?$embed=assignee&$embed=submittedBy&$embed=organisation",
        Config.api.tickets.tickets + "?status=" + statusMapKey,
        params
      )
      .then((res: AxiosResponse) => {
        res.data.docs.forEach((doc: any) => {
          if (doc.assignee) {
            doc.assignee = `${doc.assigneeFirstName} ${doc.assigneeLastName}`;
          }
          if (doc.submittedBy) {
            doc.submittedBy = `${doc.submittedByFirstName} ${doc.submittedByLastName}`;
          }
        });

        const ticketsKanban = new Map<string, Ticket[]>(
          JSON.parse(JSON.stringify(Array.from(getters.StateTicketsKanban)))
        );
        ticketsKanban.set(statusMapKey, res.data.docs);
        commit(TicketMutationTypes.SET_TICKETS_KANBAN, ticketsKanban);

        const pageKanban = new Map<string, number>(
          JSON.parse(JSON.stringify(Array.from(getters.StatePageKanban)))
        );
        pageKanban.set(statusMapKey, res.data.pages.current);
        commit(TicketMutationTypes.SET_PAGE_KANBAN, pageKanban);

        const pageSizeKanban = new Map<string, number>(
          JSON.parse(JSON.stringify(Array.from(getters.StatePageSizeKanban)))
        );
        pageSizeKanban.set(statusMapKey, res.data.items.limit);
        commit(TicketMutationTypes.SET_PAGESIZE_KANBAN, pageSizeKanban);

        const totalKanban = new Map<string, number>(
          JSON.parse(JSON.stringify(Array.from(getters.StateTotalKanban)))
        );
        totalKanban.set(statusMapKey, res.data.items.total);
        commit(TicketMutationTypes.SET_TOTAL_KANBAN, totalKanban);

        const isLoadingTicketsKanban = new Map<string, boolean>(
          JSON.parse(JSON.stringify(Array.from(getters.StateIsLoadingTicketsKanban)))
        );
        isLoadingTicketsKanban.set(statusMapKey, false);
        commit(TicketMutationTypes.SET_IS_LOADING_TICKETS_KANBAN, isLoadingTicketsKanban);

        // if(getters.StateTicketsKanban.has(statusMapKey)){
        //   const ticketsKanban = getters.StateTicketsKanban.get(statusMapKey);
        //   ticketsKanban.set('statusMapKey', res.data.docs);
        // } else {
        //   getters.StateTicketsKanban.set
        //   ticketsKanban.set('statusMapKey', res.data.docs);
        // }

        // commit(TicketMutationTypes.SET_TICKETS_KANBAN, res.data.docs);

        // commit(TicketMutationTypes.SET_TICKETS_KANBAN, res.data.docs);
        // commit(TicketMutationTypes.SET_PAGE, res.data.pages.current);
        // commit(TicketMutationTypes.SET_PAGESIZE, res.data.items.limit);
        // commit(TicketMutationTypes.SET_TOTAL, res.data.items.total);
        // commit(TicketMutationTypes.SET_IS_LOADING_TICKETS, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        // commit(TicketMutationTypes.SET_IS_LOADING_TICKETS, false);

        const isLoadingTicketsKanban = new Map<string, boolean>(
          JSON.parse(JSON.stringify(Array.from(getters.StateIsLoadingTicketsKanban)))
        );
        isLoadingTicketsKanban.set(statusMapKey, false);
        commit(TicketMutationTypes.SET_IS_LOADING_TICKETS_KANBAN, isLoadingTicketsKanban);
      });
  },

  async [TicketActionTypes.GET_MY_TICKETS_ACTION]({
    commit,
    getters,
  }: AugmentedActionContext) // payload: {
  //   category: string | null;
  // }
  {
    commit(TicketMutationTypes.SET_MY_IS_LOADING_TICKETS, true);

    const page = getters.StateMyPage; // TicketGetters.StatePage() || 1;
    const page_size = getters.StateMyPageSize; // TicketGetters.StatePageSize || 10;
    const search = getters.StateMySearch; // TicketGetters.StateSearch || 1;
    const filter = getters.StateMyFilter;
    const sorts = getters.StateMySorts;

    const params: any = {
      $page: page, //payload && payload.page ? payload.page : 1,
      $limit: page_size, //payload && payload.page_size ? payload.page_size : 10,
      //category: payload && payload.category ? payload.category : null,
      // $text: search ? search : null, //payload && payload.search ? payload.search : null
    };

    // if (search) params.$text = search;
    if (search) {
      params.$term = search;
      const searchFields = MyTicketListFields.filter(
        (mtf) => mtf.searchable === true
      ).map((mtf) => mtf.id);
      params.$searchFields = searchFields;
    }

    // debugger; // eslint-disable-line
    MyTicketListFilters.forEach((f) => {
      const _f = filter.find((__f: FilterDef) => __f.id == f.id);

      if (_f && _f.value !== f.value) {
        if (typeof _f.value == "string") {
          // debugger; // eslint-disable-line
          params[_f.queryParam] = _f.value;
        } else if (["type", "severity", "status"].indexOf(f.id) > -1) {
          if (_f.value._id !== null) params[_f.queryParam] = _f.value.name;
        } else {
          params[_f.queryParam] = _f.value._id;
        }
      }
    });

    // debugger; // eslint-disable-line
    if (sorts && sorts.length > 0) {
      params["$sort"] = (sorts[0].direction == "desc" ? "-" : "") + sorts[0].field.id;
    }

    // debugger; // eslint-disable-line
    await http
      .get(Config.api.tickets.mine, params, {
        paramsSerializer: {
          indexes: null,
        },
      })
      .then((res: AxiosResponse) => {
        // debugger; // eslint-disable-line
        commit(TicketMutationTypes.SET_MY_TICKETS, res.data.docs);
        commit(TicketMutationTypes.SET_MY_PAGE, +res.data.pages.current);
        commit(TicketMutationTypes.SET_MY_PAGESIZE, +res.data.items.limit);
        commit(TicketMutationTypes.SET_MY_TOTAL, +res.data.items.total);
        commit(TicketMutationTypes.SET_MY_IS_LOADING_TICKETS, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_MY_IS_LOADING_TICKETS, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // async [TicketActionTypes.GET_TICKETS_CHARTS_ACTION]({
  //   commit,
  // }: AugmentedActionContext) {
  //   commit(TicketMutationTypes.SET_IS_LOADING_TICKETS_CHARTS, true);
  //   await http
  //     .get(Config.api.tickets.ticketGraph)
  //     .then((res: AxiosResponse) => {
  //       commit(TicketMutationTypes.SET_TICKETS_CHARTS, res.data);
  //       commit(TicketMutationTypes.SET_IS_LOADING_TICKETS_CHARTS, false);
  //     })
  //     .catch((err: AxiosError) => {
  //       console.log("error", err);
  //       commit(TicketMutationTypes.SET_IS_LOADING_TICKETS_CHARTS, false);
  //     });
  // },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [TicketActionTypes.GET_TICKET_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetTicket
  ) {
    //commit(TicketMutationTypes.SET_IS_LOADING_TICKET, true);
    commit(TicketMutationTypes.SET_IS_LOADING_TICKET, true);
    // console.log(Config);
    // console.log(Config.api);
    // console.log(Config.api.ticket);
    // console.log(Config.api.ticket.get);

    await http
      .get(Config.api.ticket.get.replace("{id}", payload.id.toString())) // `api/ticket/${id}`)
      .then((res: AxiosResponse) => {
        commit(TicketMutationTypes.SET_TICKET, res.data);
        commit(TicketMutationTypes.SET_IS_LOADING_TICKET, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_IS_LOADING_TICKET, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [TicketActionTypes.SAVE_TICKET_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: Ticket
  ) {
    const actionType = payload._id
      ? TicketMutationTypes.SET_IS_UPDATING_TICKET
      : TicketMutationTypes.SET_IS_CREATING_TICKET;
    commit(actionType, true);
    const endpint_url =
      Config.api.tickets.ticket +
      (actionType === TicketMutationTypes.SET_IS_UPDATING_TICKET
        ? `/${payload._id}`
        : "");

    let _payload = JSON.parse(JSON.stringify(payload));
    _payload.assignee = _payload.assigneeId;
    _payload.submittedBy = _payload.submittedById;
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== null)
    );
    _payload = Object.fromEntries(Object.entries(_payload).filter(([id, v]) => v !== ""));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== undefined)
    );
    // debugger; // eslint-disable-line
    _payload = omit(_payload, [
      "assigneeId",
      "submittedById",
      "submittedByFirstName",
      "submittedByLastName",
      "submittedByAvatar",
      "organisationName",
      "organisationCode",
      "createdAt",
      "createdBy",
      "assigneeFirstName",
      "assigneeLastName",
      "assigneeAvatar",
      "updatedAt",
      "updatedBy",
    ]);

    await http
      .request(payload._id ? "PUT" : "POST", endpint_url, _payload)
      // .put(endpint_url, payload)
      .then((res: any) => {
        if (res.isAxiosError) {
          commit(TicketMutationTypes.SET_TICKET_ERROR, res.response.data.message);
          commit(
            TicketMutationTypes.SET_TICKET_ERROR_VALIDATION,
            res.response.data.validation
          );
          commit(actionType, false);
        } else {
          commit(TicketMutationTypes.SET_TICKET_ERROR, null);
          commit(TicketMutationTypes.SET_TICKET_ERROR_VALIDATION, null);
          commit(TicketMutationTypes.SET_TICKET, res.data);
          dispatch(TicketActionTypes.SET_TICKET_PREVIEW_OPEN_STATE, false);
          commit(actionType, false);
          // dispatch(TicketActionTypes.GET_TICKETS_ACTION);
        }
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(actionType, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [TicketActionTypes.CHANGE_TICKET_STATUS_ORDER_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: {
      ticketId: string;
      status: string;
      statusOrder: number;
    }
  ) {
    // const actionType = payload._id
    //   ? TicketMutationTypes.SET_IS_UPDATING_TICKET
    //   : TicketMutationTypes.SET_IS_CREATING_TICKET;
    // commit(actionType, true);
    const endpint_url = Config.api.tickets.ticketStatusOrder;
    // (actionType === TicketMutationTypes.SET_IS_UPDATING_TICKET
    //   ? `/${payload._id}`
    //   : "");

    const _payload = JSON.parse(JSON.stringify(payload));
    // _payload.assignee = _payload.assigneeId;
    // _payload.submittedBy = _payload.submittedById;
    // _payload = Object.fromEntries(
    //   Object.entries(_payload).filter(([_, v]) => v !== null)
    // );
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== ""));
    // _payload = Object.fromEntries(
    //   Object.entries(_payload).filter(([_, v]) => v !== undefined)
    // );

    // _payload = omit(_payload, [
    //   "assigneeId",
    //   "submittedById",
    //   "submittedByFirstName",
    //   "submittedByLastName",
    //   "submittedByAvatar",
    //   "organisationName",
    //   "organisationCode",
    //   "createdAt",
    //   "assigneeFirstName",
    //   "assigneeLastName",
    //   "assigneeAvatar",
    //   "updatedAt"
    // ]);

    await http
      .request("PUT", endpint_url, _payload)
      // .put(endpint_url, payload)
      .then((res: any) => {
        if (res.isAxiosError) {
          // dispatch(TicketActionTypes.GET_TICKETS_ACTION);
          // commit(TicketMutationTypes.SET_TICKET_ERROR, res.response.data.message);
          // commit(
          //   TicketMutationTypes.SET_TICKET_ERROR_VALIDATION,
          //   res.response.data.validation
          // );
          // commit(actionType, false);
        } else {
          // commit(TicketMutationTypes.SET_TICKET_ERROR, null);
          // commit(TicketMutationTypes.SET_TICKET_ERROR_VALIDATION, null);
          // commit(TicketMutationTypes.SET_TICKET, res.data);
          // dispatch(TicketActionTypes.SET_TICKET_PREVIEW_OPEN_STATE, false);
          // commit(actionType, false);
          // dispatch(TicketActionTypes.GET_TICKETS_KANBAN_ACTION);
        }
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        // commit(actionType, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [TicketActionTypes.DELETE_TICKET_ACTION](
    { commit }: AugmentedActionContext,
    payload: Ticket
  ) {
    commit(TicketMutationTypes.SET_IS_DELETING_TICKET, true);
    // const endpint_url = `${Config.api.tickets.ticket}/${payload._id}`;

    await http
      .request("DELETE", Config.api.tickets.ticket, [payload])
      // .put(endpint_url, payload)
      .then(() => {
        // commit(TicketMutationTypes.SET_TICKET, res.data);
        commit(TicketMutationTypes.SET_DELETED_TICKET_DATA, null);
        commit(TicketMutationTypes.SET_DELETED_TICKET_SUCCEEDED, true);
        commit(TicketMutationTypes.SET_IS_DELETING_TICKET, false);
        // dispatch(TicketActionTypes.GET_TICKETS_ACTION);
      })
      .catch((err: any) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_DELETED_TICKET_DATA, err.data.message);
        commit(TicketMutationTypes.SET_DELETED_TICKET_SUCCEEDED, false);
        commit(TicketMutationTypes.SET_IS_DELETING_TICKET, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [TicketActionTypes.SET_TICKET_ACTION](
    { commit }: AugmentedActionContext,
    payload: Ticket | null
  ) {
    // const actionType = (payload.ticket._id) ?
    //   TicketMutationTypes.SET_IS_CREATING_TICKET
    //   :
    //   TicketMutationTypes.SET_IS_UPDATING_TICKET;
    // commit(actionType, true);

    commit(TicketMutationTypes.SET_TICKET, payload);
    // commit(actionType, false);
  },
  async [TicketActionTypes.SET_TICKETS_ACTION](
    { commit }: AugmentedActionContext,
    payload: Ticket[]
  ) {
    // const actionType = (payload.ticket._id) ?
    //   TicketMutationTypes.SET_IS_CREATING_TICKET
    //   :
    //   TicketMutationTypes.SET_IS_UPDATING_TICKET;
    // commit(actionType, true);

    commit(TicketMutationTypes.SET_TICKETS, payload);
    // commit(actionType, false);
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // async [TicketActionTypes.GET_CATEGORIES_ACTION](
  //     { commit }: AugmentedActionContext
  // ) {
  //     commit(TicketMutationTypes.SET_IS_LOADING_CATEGORIES, true);
  //     await http.get('api/tickets/categories')
  //         .then(res => {
  //             commit(TicketMutationTypes.SET_CATEGORIES, res.data)
  //             commit(TicketMutationTypes.SET_IS_LOADING_CATEGORIES, false);
  //             commit(TicketMutationTypes.SET_IS_CATEGORIES_LOADED, true);
  //         })
  //         .catch(err => {
  //             console.log('error', err);
  //             commit(TicketMutationTypes.SET_CATEGORIES, [])
  //             commit(TicketMutationTypes.SET_IS_LOADING_CATEGORIES, false);
  //             commit(TicketMutationTypes.SET_IS_CATEGORIES_LOADED, false);
  //         })
  // },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  /* async [TicketActionTypes.GET_MANUFACTURERS_ACTION](
      { commit }: AugmentedActionContext
    ) {
      commit(TicketMutationTypes.SET_IS_LOADING_MANUFACTURERS, true);
      await http.get('api/tickets/manufacturers')
        .then(res => {
          commit(TicketMutationTypes.SET_MANUFACTURERS, res.data)
          commit(TicketMutationTypes.SET_IS_LOADING_MANUFACTURERS, false);
          commit(TicketMutationTypes.SET_IS_MANUFACTURERS_LOADED, true);
        })
        .catch(err => {
          console.log('error', err);
          commit(TicketMutationTypes.SET_MANUFACTURERS, []);
          commit(TicketMutationTypes.SET_IS_LOADING_MANUFACTURERS, false);
          commit(TicketMutationTypes.SET_IS_MANUFACTURERS_LOADED, false);
        })
    }, */

  async [TicketActionTypes.GET_TYPES_ACTION]({ commit }: AugmentedActionContext) {
    commit(TicketMutationTypes.SET_IS_LOADING_TYPES, true);
    await http
      .get(Config.api.tickets.ticketTypes)
      .then((res: AxiosResponse) => {
        commit(TicketMutationTypes.SET_TYPES, res.data);
        commit(TicketMutationTypes.SET_IS_LOADING_TYPES, false);
        commit(TicketMutationTypes.SET_IS_TYPES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_TYPES, []);
        commit(TicketMutationTypes.SET_IS_LOADING_TYPES, false);
        commit(TicketMutationTypes.SET_IS_TYPES_LOADED, false);
      });
  },

  async [TicketActionTypes.GET_SEVERITIES_ACTION]({ commit }: AugmentedActionContext) {
    commit(TicketMutationTypes.SET_IS_LOADING_SEVERITIES, true);
    await http
      .get(Config.api.tickets.ticketSeverities)
      .then((res: AxiosResponse) => {
        commit(TicketMutationTypes.SET_SEVERITIES, res.data);
        commit(TicketMutationTypes.SET_IS_LOADING_SEVERITIES, false);
        commit(TicketMutationTypes.SET_IS_SEVERITIES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_SEVERITIES, []);
        commit(TicketMutationTypes.SET_IS_LOADING_SEVERITIES, false);
        commit(TicketMutationTypes.SET_IS_SEVERITIES_LOADED, false);
      });
  },

  async [TicketActionTypes.GET_STATUSES_ACTION]({ commit }: AugmentedActionContext) {
    commit(TicketMutationTypes.SET_IS_LOADING_STATUSES, true);
    await http
      .get(Config.api.tickets.ticketStatuses)
      .then((res: AxiosResponse) => {
        commit(TicketMutationTypes.SET_STATUSES, res.data);
        commit(TicketMutationTypes.SET_IS_LOADING_STATUSES, false);

        commit(TicketMutationTypes.SET_IS_STATUSES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_STATUSES, []);
        commit(TicketMutationTypes.SET_IS_LOADING_STATUSES, false);
        commit(TicketMutationTypes.SET_IS_STATUSES_LOADED, false);
      });
  },

  async [TicketActionTypes.GET_TICKET_ACTIVITIES_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    Config.api.ticket.getActivities;
    // payload.id;
    commit(TicketMutationTypes.SET_IS_LOADING_TICKET_ACTIVITIES, true);

    await http
      .get(Config.api.ticket.getActivities.replace("{id}", payload.toString())) // `api/ticket/${id}`)
      .then((res: AxiosResponse) => {
        commit(TicketMutationTypes.SET_TICKET_ACTIVITIES, res.data.docs);
        commit(TicketMutationTypes.SET_IS_LOADING_TICKET_ACTIVITIES, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_IS_LOADING_TICKET_ACTIVITIES, false);
      });
  },

  async [TicketActionTypes.SAVE_TICKET_ACTIVITY](
    { commit, dispatch }: AugmentedActionContext,
    payload: TicketActivity
    // ticketId: string
  ) {
    // Config.api.ticket.saveActivity;
    // payload.id;

    let ticketActivity = JSON.parse(JSON.stringify(payload));
    // let ticketActivity = JSON.parse(JSON.stringify(payload));
    // _payload.owner = _payload.ownerId;
    ticketActivity = Object.fromEntries(
      Object.entries(ticketActivity).filter(([id, v]) => v !== null)
    );
    ticketActivity = Object.fromEntries(
      Object.entries(ticketActivity).filter(([id, v]) => v !== "")
    );
    ticketActivity = Object.fromEntries(
      Object.entries(ticketActivity).filter(([id, v]) => v !== undefined)
    );

    // ticketActivity = omit(ticketActivity, [
    //   "_id"
    // ]);
    // if(ticketActivity.assigned == null){
    //   ticketActivity = omit(ticketActivity, [
    //     "assigned"
    //   ]);
    // }

    ticketActivity.user = ticketActivity.user._id;
    commit(TicketMutationTypes.SET_IS_SAVING_TICKET_ACTIVITY, true);
    debugger; // eslint-disable-line
    await http
      .post(Config.api.ticket.saveActivity, [ticketActivity]) // `api/ticket/${id}`)
      .then((res: AxiosResponse) => {
        // commit(TicketMutationTypes.SET_TICKET_ACTIVITIES, res.data.docs);
        debugger; // eslint-disable-line

        commit(TicketMutationTypes.SET_IS_SAVING_TICKET_ACTIVITY, false);
        payload = res.data[0];

        dispatch(TicketActionTypes.ASSIGN_TICKET_ACTIVITY, {
          ticketId: res.data[0].ticket,
          ticketActivityId: res.data[0]._id,
        });

        // return res.data;
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_IS_SAVING_TICKET_ACTIVITY, false);
      });
  },

  async [TicketActionTypes.ASSIGN_TICKET_ACTIVITY](
    { commit, dispatch }: AugmentedActionContext,
    payload: TicketActivityPayload
    // ticketId: string
  ) {
    // Config.api.ticket.saveActivity;
    // payload.id;

    // let ticketActivity = JSON.parse(JSON.stringify(payload.ticketActivity));
    // ticketActivity = omit(ticketActivity, [
    //   "_id"
    // ]);
    // if(ticketActivity.assigned == null){
    //   ticketActivity = omit(ticketActivity, [
    //     "assigned"
    //   ]);
    // }

    // ticketActivity.user = ticketActivity.user._id;
    commit(TicketMutationTypes.SET_IS_ASSIGNING_TICKET_ACTIVITY, true);
    debugger; // eslint-disable-line
    await http
      .post(
        Config.api.ticket.assignActivity.replace("{id}", payload.ticketId.toString()),
        [payload.ticketActivityId.toString()]
      ) // `api/ticket/${id}`)
      .then((res: AxiosResponse) => {
        // commit(TicketMutationTypes.SET_TICKET_ACTIVITIES, res.data.docs);
        commit(TicketMutationTypes.SET_IS_ASSIGNING_TICKET_ACTIVITY, false);
        dispatch(TicketActionTypes.GET_TICKET_ACTIVITIES_ACTION, payload.ticketId);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_IS_ASSIGNING_TICKET_ACTIVITY, false);
      });
  },

  // async [TicketActionTypes.GET_TICKET_ACTIVITIES_ACTION](
  //     { commit }: AugmentedActionContext,
  //     payload: IGetTicket
  // ) {
  //     commit(TicketMutationTypes.SET_IS_LOADING_TICKET_ACTIVITIES, true);

  //     await http.get(Config.api.ticket.getActivities.replace('{id}', payload.id.toString()))// `api/ticket/${id}`)
  //         .then(res => {
  //             commit(TicketMutationTypes.SET_TICKET_ACTIVITIES, res.data)
  //             commit(TicketMutationTypes.SET_IS_LOADING_TICKET_ACTIVITIES, false);
  //         })
  //         .catch(err => {
  //             console.log('error', err);
  //             commit(TicketMutationTypes.SET_IS_LOADING_TICKET_ACTIVITIES, false);
  //         })
  // },

  async [TicketActionTypes.GET_TICKET_ACTIVITY_TYPES_ACTION]({
    commit,
  }: AugmentedActionContext) {
    commit(TicketMutationTypes.SET_IS_LOADING_TICKET_ACTIVITY_TYPES, true);
    await http
      .get(Config.api.tickets.ticketTypes)
      .then((res: AxiosResponse) => {
        commit(TicketMutationTypes.SET_TICKET_ACTIVITY_TYPES_ACTION, res.data);
        commit(TicketMutationTypes.SET_IS_LOADING_TICKET_ACTIVITY_TYPES, false);
        commit(TicketMutationTypes.SET_IS_TICKET_ACTIVITY_TYPES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_TICKET_ACTIVITY_TYPES_ACTION, []);
        commit(TicketMutationTypes.SET_IS_LOADING_TICKET_ACTIVITY_TYPES, false);
        commit(TicketMutationTypes.SET_IS_TICKET_ACTIVITY_TYPES_LOADED, false);
      });
  },

  [TicketActionTypes.SET_PAGE]({ commit }: AugmentedActionContext, payload: number) {
    commit(TicketMutationTypes.SET_PAGE, payload);
  },

  [TicketActionTypes.SET_PAGESIZE]({ commit }: AugmentedActionContext, payload: number) {
    commit(TicketMutationTypes.SET_PAGESIZE, payload);
  },

  [TicketActionTypes.SET_TOTAL]({ commit }: AugmentedActionContext, payload: number) {
    commit(TicketMutationTypes.SET_TOTAL, payload);
  },

  [TicketActionTypes.SET_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(TicketMutationTypes.SET_SEARCH, payload);
  },

  [TicketActionTypes.SET_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ) {
    commit(TicketMutationTypes.SET_FILTER, payload);
  },

  [TicketActionTypes.SET_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ) {
    commit(TicketMutationTypes.SET_AVAILABLEFIELDS, payload);
  },

  [TicketActionTypes.SET_SORTS]({ commit }: AugmentedActionContext, payload: SortDef[]) {
    commit(TicketMutationTypes.SET_SORTS, payload);
  },

  [TicketActionTypes.SET_MY_PAGE]({ commit }: AugmentedActionContext, payload: number) {
    commit(TicketMutationTypes.SET_MY_PAGE, payload);
  },

  [TicketActionTypes.SET_MY_PAGESIZE](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(TicketMutationTypes.SET_MY_PAGESIZE, payload);
  },

  [TicketActionTypes.SET_MY_TOTAL]({ commit }: AugmentedActionContext, payload: number) {
    commit(TicketMutationTypes.SET_MY_TOTAL, payload);
  },

  [TicketActionTypes.SET_MY_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(TicketMutationTypes.SET_MY_SEARCH, payload);
  },

  [TicketActionTypes.SET_MY_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ) {
    commit(TicketMutationTypes.SET_MY_FILTER, payload);
  },

  [TicketActionTypes.SET_MY_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ) {
    commit(TicketMutationTypes.SET_MY_AVAILABLEFIELDS, payload);
  },

  [TicketActionTypes.SET_MY_SORTS](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ) {
    commit(TicketMutationTypes.SET_MY_SORTS, payload);
  },

  [TicketActionTypes.SET_TICKET_TO_PREVIEW_MOD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(TicketMutationTypes.SET_TICKET_TO_PREVIEW_MOD, payload);
  },

  [TicketActionTypes.SET_TICKET_PREVIEW_OPEN_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(TicketMutationTypes.SET_TICKET_PREVIEW_OPEN_STATE, payload);
  },

  async [TicketActionTypes.GET_CHART_TYPES]({ commit }: AugmentedActionContext) {
    // commit(TicketMutationTypes.SET_CHARTTYPES, payload);

    commit(TicketMutationTypes.SET_IS_LOADING_CHARTTYPES, true);
    await http
      .get(Config.api.ticket.chart.types)
      .then((res: AxiosResponse) => {
        commit(TicketMutationTypes.SET_CHARTTYPES, res.data);
        commit(TicketMutationTypes.SET_IS_LOADING_CHARTTYPES, false);
        commit(TicketMutationTypes.SET_IS_CHARTTYPES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_CHARTTYPES, null);
        commit(TicketMutationTypes.SET_IS_LOADING_CHARTTYPES, false);
        commit(TicketMutationTypes.SET_IS_CHARTTYPES_LOADED, false);
      });
  },

  async [TicketActionTypes.GET_CHART_SEVERITIES]({ commit }: AugmentedActionContext) {
    // commit(TicketMutationTypes.SET_CHARTTYPES, payload);

    commit(TicketMutationTypes.SET_IS_LOADING_CHARTSEVERITIES, true);
    await http
      .get(Config.api.ticket.chart.severities)
      .then((res: AxiosResponse) => {
        commit(TicketMutationTypes.SET_CHARTSEVERITIES, res.data);
        commit(TicketMutationTypes.SET_IS_LOADING_CHARTSEVERITIES, false);
        commit(TicketMutationTypes.SET_IS_CHARTSEVERITIES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_CHARTSEVERITIES, null);
        commit(TicketMutationTypes.SET_IS_LOADING_CHARTSEVERITIES, false);
        commit(TicketMutationTypes.SET_IS_CHARTSEVERITIES_LOADED, false);
      });
  },

  async [TicketActionTypes.GET_CHART_STATUSES]({ commit }: AugmentedActionContext) {
    // commit(TicketMutationTypes.SET_CHARTTYPES, payload);

    commit(TicketMutationTypes.SET_IS_LOADING_CHARTSTATUSES, true);
    await http
      .get(Config.api.ticket.chart.statuses)
      .then((res: AxiosResponse) => {
        commit(TicketMutationTypes.SET_CHARTSTATUSES, res.data);
        commit(TicketMutationTypes.SET_IS_LOADING_CHARTSTATUSES, false);
        commit(TicketMutationTypes.SET_IS_CHARTSTATUSES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_CHARTSTATUSES, null);
        commit(TicketMutationTypes.SET_IS_LOADING_CHARTSTATUSES, false);
        commit(TicketMutationTypes.SET_IS_CHARTSTATUSES_LOADED, false);
      });
  },

  async [TicketActionTypes.GET_CHART_MONTHLY]({ commit }: AugmentedActionContext) {
    // commit(TicketMutationTypes.SET_CHARTTYPES, payload);

    commit(TicketMutationTypes.SET_IS_LOADING_CHARTMONTHLY, true);
    await http
      .get(Config.api.ticket.chart.bymonth)
      .then((res: AxiosResponse) => {
        commit(TicketMutationTypes.SET_CHARTMONTHLY, res.data);
        commit(TicketMutationTypes.SET_IS_LOADING_CHARTMONTHLY, false);
        commit(TicketMutationTypes.SET_IS_CHARTMONTHLY_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(TicketMutationTypes.SET_CHARTMONTHLY, null);
        commit(TicketMutationTypes.SET_IS_LOADING_CHARTMONTHLY, false);
        commit(TicketMutationTypes.SET_IS_CHARTMONTHLY_LOADED, false);
      });
  },
};
