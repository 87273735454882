import {
  StripePaymentMethod,
  StripeCustomer,
  StripeSubscription,
  StripeInvoice,
  StripePlan,
  StripeProduct
} from "@/types/index";
import { store } from "@/store";
import { PaymentActionTypes } from "@/store/modules/payment/action-types";
import { reactive, computed } from "vue";

interface IpaymentData {

  paymentMethodsCalled: Boolean,
  paymentMethodsLoaded: Boolean,
  subscriptionCalled: Boolean,
  subscriptionLoaded: Boolean,
  invoiceCalled: Boolean,
  invoiceLoaded: Boolean,
  customerCalled: Boolean,
  customerLoaded: Boolean,
  planAndProductCalled: boolean,
  planAndProductLoaded: boolean,

  StripePaymentMethod: StripePaymentMethod[];
  StripeCustomer: StripeCustomer[];
  StripeSubscription: StripeSubscription[];
  StripeInvoice: StripeInvoice[];
  StripePlan: StripePlan;
  StripeProduct: StripeProduct;

  StripePaymentMethodLoadPromisse: Promise<any> | null;
  StripeCustomerLoadPromisse: Promise<any> | null;
  StripeSubscriptionLoadPromisse: Promise<any> | null;
  StripeInvoiceLoadPromisse: Promise<any> | null;
  StripePlanAndProductLoadPromisse: Promise<any> | null;
}

const paymentData: IpaymentData = reactive({

  paymentMethodsCalled: false,
  paymentMethodsLoaded: false,

  subscriptionCalled: false,
  subscriptionLoaded: false,

  invoiceCalled: false,
  invoiceLoaded: false,

  customerCalled: false,
  customerLoaded: false,

  planAndProductCalled: false,
  planAndProductLoaded: false,

  StripePaymentMethod: computed(() => {
    return store.state.payment?.stripePaymentMethod as StripePaymentMethod[];
  }),

  StripeCustomer: computed(() => {
    return store.state.payment?.stripeCustomer as StripeCustomer[];
  }),

  StripeSubscription: computed(() => {
    return store.state.payment?.stripeSubscription as StripeSubscription[];
  }),

  StripeInvoice: computed(() => {
    return store.state.payment?.stripeInvoice as StripeInvoice[];
  }),

  StripePlan: computed(() => {
    return store.state.payment?.stripePlan as StripePlan;
  }),

  StripeProduct: computed(() => {
    return store.state.payment?.stripeProduct as StripeProduct;
  }),

  StripePaymentMethodLoadPromisse: null,
  StripeCustomerLoadPromisse: null,
  StripeSubscriptionLoadPromisse: null,
  StripeInvoiceLoadPromisse: null,
  StripePlanAndProductLoadPromisse: null

});

export function paymentComposable(force = false) {

  paymentData.StripeCustomerLoadPromisse = force ? null : paymentData.StripeCustomerLoadPromisse;
  paymentData.StripeCustomerLoadPromisse = paymentData.StripeCustomerLoadPromisse || store.dispatch("payment/" + PaymentActionTypes.LOAD_STRIPECUSTOMER_ACTION)
    .catch((error) => {
      console.warn(error);
    });

  paymentData.StripeInvoiceLoadPromisse = force ? null : paymentData.StripeInvoiceLoadPromisse;
  paymentData.StripeInvoiceLoadPromisse = paymentData.StripeInvoiceLoadPromisse || store.dispatch("payment/" + PaymentActionTypes.LOAD_STRIPEINVOICE_ACTION)
    .catch((error) => {
      console.warn(error);
    });

  paymentData.StripeSubscriptionLoadPromisse = force ? null : paymentData.StripeSubscriptionLoadPromisse;
  paymentData.StripeSubscriptionLoadPromisse = paymentData.StripeSubscriptionLoadPromisse || store.dispatch("payment/" + PaymentActionTypes.LOAD_STRIPESUBSCRIPTION_ACTION)
    .catch((error) => {
      console.warn(error);
    });

  paymentData.StripePaymentMethodLoadPromisse = force ? null : paymentData.StripePaymentMethodLoadPromisse;
  paymentData.StripePaymentMethodLoadPromisse = paymentData.StripePaymentMethodLoadPromisse || store.dispatch("payment/" + PaymentActionTypes.LOAD_STRIPEPAYMENTMETHOD_ACTION)
    .catch((error) => {
      console.warn(error);
    });

  paymentData.StripeSubscriptionLoadPromisse.then(() => {
    paymentData.StripePlanAndProductLoadPromisse = force ? null : paymentData.StripePlanAndProductLoadPromisse;
    paymentData.StripePlanAndProductLoadPromisse = paymentData.StripePlanAndProductLoadPromisse || (
      paymentData.StripeSubscription && paymentData.StripeSubscription.length > 0 ?
        store.dispatch("payment/" + PaymentActionTypes.LOAD_STRIPEPLANANDPRODUCT_ACTION, { subscriptionID: (paymentData.StripeSubscription[0].plan as StripePlan).id })
        :
        Promise.resolve(null)
      )
      .catch((error) => {
        console.warn(error);
      });
  });

  return paymentData;
}

Promise.all([
  paymentData.StripePaymentMethodLoadPromisse,
  paymentData.StripeSubscriptionLoadPromisse,
  paymentData.StripeInvoiceLoadPromisse,
  paymentData.StripeCustomerLoadPromisse,
  paymentData.StripePlanAndProductLoadPromisse
])
  .catch((error) => {
    console.warn(error);
  });
