export enum TicketMutationTypes {
  RESET = "reset",

  SET_TICKETS_CHARTS = "setTicketsCharts",
  SET_IS_LOADING_TICKETS_CHARTS = "setIsLoadingTicketsCharts",

  SET_TICKETS = "setTickets",
  SET_IS_LOADING_TICKETS = "setIsLoadingTickets",
  SET_DELETED_TICKETS_DATA = "setDeletedTicketsData",
  SET_IS_DELETING_TICKETS = "setIsDeletingTickets",
  SET_IS_UPDATING_TICKETS = "setIsUpdatingTickets",
  SET_UPDATED_TICKETS_DATA = "setUpdatedTicketsData",

  SET_PAGE = "setPage",
  SET_PAGESIZE = "setPageSize",
  SET_TOTAL = "setTotal",
  SET_SEARCH = "setSearch",
  SET_FILTER = "setFilter",
  SET_AVAILABLEFIELDS = "setAvailableFields",
  SET_SORTS = "setSorts",

  SET_MY_TICKETS = "setMyTickets",
  SET_MY_IS_LOADING_TICKETS = "setMyIsLoadingTickets",
  SET_MY_DELETED_TICKETS_DATA = "setMyDeletedTicketsData",
  SET_MY_IS_DELETING_TICKETS = "setMyIsDeletingTickets",
  SET_MY_IS_UPDATING_TICKETS = "setMyIsUpdatingTickets",
  SET_MY_UPDATED_TICKETS_DATA = "setMyUpdatedTicketsData",

  SET_MY_PAGE = "setMyPage",
  SET_MY_PAGESIZE = "setMyPageSize",
  SET_MY_TOTAL = "setMyTotal",
  SET_MY_SEARCH = "setMySearch",
  SET_MY_FILTER = "setMyFilter",
  SET_MY_AVAILABLEFIELDS = "setMyAvailableFields",
  SET_MY_SORTS = "setMySorts",

  SET_TICKETS_KANBAN = "setTicketsKanban",
  SET_IS_LOADING_TICKETS_KANBAN = "setIsLoadingTicketsKanban",
  // SET_DELETED_TICKETS_KANBAN_DATA = "setDeletedTicketsKanbanData",
  SET_IS_DELETING_TICKETS_KANBAN = "setIsDeletingTicketsKanban",
  SET_IS_UPDATING_TICKETS_KANBAN = "setIsUpdatingTicketsKanban",
  // SET_UPDATED_TICKETS_DATA_KANBAN = "setUpdatedTicketsKanbanData",

  SET_PAGE_KANBAN = "setPageKanban",
  SET_PAGESIZE_KANBAN = "setPageSizeKanban",
  SET_TOTAL_KANBAN = "setTotalKanban",
  SET_SEARCH_KANBAN = "setSearchKanban",
  SET_FILTER_KANBAN = "setFilterKanban",
  SET_AVAILABLEFIELDS_KANBAN = "setAvailableFieldsKanban",
  SET_SORTS_KANBAN = "setSortsKanban",

  SET_TICKET = "setTicket",
  SET_TICKET_ERROR = 'setTicketError',
  SET_TICKET_ERROR_VALIDATION = 'setTicketErrorValidation',
  SET_IS_LOADING_TICKET = "setIsLoadingTicket",
  SET_IS_TICKET_LOADED = "setIsTicketLoaded",
  SET_IS_CREATING_TICKET = "setIsCreatingTicket",
  SET_CREATED_TICKET_DATA = "setCreatedTicketData",
  SET_IS_UPDATING_TICKET = "setIsUpdatingTicket",
  SET_IS_TICKET_UPDATED = "setIsTicketUpdated",
  SET_UPDATED_TICKET_DATA = "setUpdatedTicketData",
  SET_IS_DELETING_TICKET = "setIsDeletingTicket",

  SET_DELETED_TICKET_SUCCEEDED = "setDeletedTicketSucceeded",
  SET_DELETED_TICKET_DATA = "setDeletedTicketData",

  // SET_IS_UPDATING_TICKET_STATUS_ORDER = ""

  SET_TICKET_TO_PREVIEW_MOD = "setTicketToPreviewMod",
  SET_TICKET_PREVIEW_OPEN_STATE = "setTicketPreviewOpenState",

  SET_TICKET_ACTIVITIES = "setTicketActivities",
  SET_IS_LOADING_TICKET_ACTIVITIES = "setIsLoadingTicketActivities",
  SET_TICKET_ACTIVITY_TYPES_ACTION = "setTicketActivityTypes",
  SET_IS_LOADING_TICKET_ACTIVITY_TYPES = "setIsLoadingTicketActivityTypes",
  SET_IS_TICKET_ACTIVITY_TYPES_LOADED = "setIsTicketActivityTypesLoaded",
  SET_IS_SAVING_TICKET_ACTIVITY = "setIsSavingTicketActivity",
  SET_IS_ASSIGNING_TICKET_ACTIVITY = "setIsAssigningTicketActivity",

  SET_CATEGORIES = "setCategories",
  SET_IS_LOADING_CATEGORIES = "setIsLoadingCategories",
  SET_IS_CATEGORIES_LOADED = "setIsCategoriesLoaded",

  SET_TYPES = "setTypes",
  SET_IS_LOADING_TYPES = "setIsLoadingTypes",
  SET_IS_TYPES_LOADED = "setIsTypesLoaded",

  SET_SEVERITIES = "setSeverities",
  SET_IS_LOADING_SEVERITIES = "setIsLoadingSeverities",
  SET_IS_SEVERITIES_LOADED = "setIsSeveritiesLoaded",

  SET_STATUSES = "setStatuses",
  SET_IS_LOADING_STATUSES = "setIsLoadingStatuses",
  SET_IS_STATUSES_LOADED = "setIsStatusesLoaded",

  SET_CHARTTYPES = "setChartTypes",
  SET_IS_LOADING_CHARTTYPES = "setIsLoadingChartTypes",
  SET_IS_CHARTTYPES_LOADED = "setIsChartTypesLoaded",

  SET_CHARTSEVERITIES = "setChartSeverities",
  SET_IS_LOADING_CHARTSEVERITIES = "setIsLoadingChartSeverities",
  SET_IS_CHARTSEVERITIES_LOADED = "setIsChartSeveritiesLoaded",

  SET_CHARTSTATUSES = "setChartStatuses",
  SET_IS_LOADING_CHARTSTATUSES = "setIsLoadingChartStatuses",
  SET_IS_CHARTSTATUSES_LOADED = "setIsChartStatusesLoaded",

  SET_CHARTMONTHLY = "setChartMonthly",
  SET_IS_LOADING_CHARTMONTHLY = "setIsLoadingChartMonthly",
  SET_IS_CHARTMONTHLY_LOADED = "setIsChartMonthlyLoaded",
  //SET_MANUFACTURERS = 'setManufacturers',
  //SET_IS_LOADING_MANUFACTURERS = 'setIsLoadingManufacturers',
  //SET_IS_MANUFACTURERS_LOADED = 'setiIsManufacturersLoaded',
}