export enum ChatBotMutationTypes {
    SET_CHATBOT_IS_OPEN = "setChatBotIsOpen",
    SET_CHATBOT_MESSAGES = "setChatBotMessages",
    ADD_CHATBOT_MESSAGE = "addChatBotMessage",
    SET_CHATBOT_BOT_IS_TYPING = "setChatBotBotIsTyping",
    SET_CHATBOT_USER = "setChatBotUser",
  
    SET_CHATBOT_SETTINGS = "setChatBotSettings",
    SET_IS_LOADING_CHATBOT_SETTINGS = "setIsLoadingChatBotSettings",
    SET_IS_CHATBOT_SETTINGS_LOADED = "setIsChatBotSettingsLoaded",
    SET_IS_SAVING_CHATBOT_SETTINGS = "setIsSavingChatBotSettings",
  }