import { MutationTree } from "vuex";
import { State, defaultState } from "./state";
import { OrganisationMutationTypes as MutationTypes } from "./mutation-types";
import {
  Organisation,
  User,
  Group,
  Role,
  FieldDef,
  SortDef,
  FilterDef,
  Permission,
  PermissionKeys,
  ErrorValidation
} from "@/types/index";

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;

  [MutationTypes.SET_ORGANIZATION](state: S, payload: Organisation | null): void;

  [MutationTypes.SET_SAVING_ORGANIZATION](state: S, payload: boolean): void;
  [MutationTypes.SET_SAVE_ORGANIZATION_ERROR](state: S, payload: string | null): void;
  [MutationTypes.SET_LOADING_ORGANIZATION](state: S, payload: boolean): void;

  [MutationTypes.SET_TESTING_ORGANISATION_SUPPORT_EMAIL](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_TESTED_ORGANISATION_SUPPORT_EMAIL](state: S, payload: boolean): void;
  [MutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAILERROR](
    state: S,
    payload: string | null
  ): void;
  [MutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR](
    state: S,
    payload: ErrorValidation | null
  ): void;


  [MutationTypes.SET_SAVING_ORGANISATION_SUPPORT_EMAIL](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_SAVED_ORGANISATION_SUPPORT_EMAIL](state: S, payload: boolean): void;
  [MutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAILERROR](
    state: S,
    payload: string | null
  ): void;
  [MutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR](
    state: S,
    payload: ErrorValidation | null
  ): void;




  [MutationTypes.SET_USERS](state: S, payload: User[]): void;
  [MutationTypes.SET_LOADING_USERS](state: S, payload: boolean): void;
  [MutationTypes.SET_USERS_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_USERS_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_USERS_PAGESIZE](state: S, payload: number): void;
  [MutationTypes.SET_USERS_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_USERS_SEARCH](state: S, payload: string | null): void;
  [MutationTypes.SET_USERS_FILTER](state: S, payload: FilterDef[]): void;
  [MutationTypes.SET_USERS_AVAILABLEFILEDS](state: S, payload: FieldDef[]): void;
  [MutationTypes.SET_USERS_SORTS](state: S, payload: SortDef[]): void;

  [MutationTypes.SET_GROUPS](state: S, payload: Group[]): void;
  [MutationTypes.SET_LOADING_GROUPS](state: S, payload: boolean): void;

  [MutationTypes.SET_GROUPS_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_GROUPS_PAGESIZE](state: S, payload: number): void;
  [MutationTypes.SET_GROUPS_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_GROUPS_SEARCH](state: S, payload: string | null): void;
  [MutationTypes.SET_GROUPS_FILTER](state: S, payload: FilterDef[]): void;
  [MutationTypes.SET_GROUPS_AVAILABLEFILEDS](state: S, payload: FieldDef[]): void;
  [MutationTypes.SET_GROUPS_SORTS](state: S, payload: SortDef[]): void;

  [MutationTypes.SET_ROLES](state: S, payload: Role[]): void;
  [MutationTypes.SET_LOADING_ROLES](state: S, payload: boolean): void;
  [MutationTypes.SET_ROLES_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_ROLES_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_ROLES_PAGESIZE](state: S, payload: number): void;
  [MutationTypes.SET_ROLES_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_ROLES_SEARCH](state: S, payload: string | null): void;
  [MutationTypes.SET_ROLES_FILTER](state: S, payload: FilterDef[]): void;
  [MutationTypes.SET_ROLES_AVAILABLEFILEDS](state: S, payload: FieldDef[]): void;
  [MutationTypes.SET_ROLES_SORTS](state: S, payload: SortDef[]): void;

  [MutationTypes.SET_PERMISSIONS](
    state: S,
    payload: Record<PermissionKeys, Permission> | null
  ): void;
  [MutationTypes.SET_LOADING_PERMISSIONS](state: S, payload: boolean): void;

  [MutationTypes.SET_CRUD_FOCUSED_USER](state: S, payload: User | null): void;
  [MutationTypes.SET_LOADING_CRUD_FOCUSED_USER](state: S, payload: boolean): void;
  [MutationTypes.SET_SAVING_CRUD_FOCUSED_USER](state: S, payload: boolean): void;

  [MutationTypes.SET_IS_CREATING_CRUD_FOCUSED_USER](state: S, payload: boolean): void;
  [MutationTypes.SET_CREATING_CRUD_FOCUSED_USER_SUCCEEDED](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_CREATING_CRUD_FOCUSED_USER_DATA](
    state: S,
    payload: string | null
  ): void;

  [MutationTypes.SET_DELETED_CRUD_FOCUSED_USER_DATA](
    state: S,
    payload: string | null
  ): void;
  [MutationTypes.SET_DELETED_CRUD_FOCUSED_USER_SUCCEEDED](
    state: S,
    payload: boolean
  ): void;

  [MutationTypes.SET_CRUD_FOCUSED_GROUP](state: S, payload: Group): void;
  [MutationTypes.SET_LOADING_CRUD_FOCUSED_GROUP](state: S, payload: boolean): void;
  [MutationTypes.SET_SAVING_CRUD_FOCUSED_GROUP](state: S, payload: boolean): void;

  [MutationTypes.SET_CRUD_FOCUSED_ROLE](state: S, payload: Role): void;
  [MutationTypes.SET_LOADING_CRUD_FOCUSED_ROLE](state: S, payload: boolean): void;
  [MutationTypes.SET_SAVING_CRUD_FOCUSED_ROLE](state: S, payload: boolean): void;

  [MutationTypes.SET_INVITE_ERROR](state: S, payload: string | null): void;
  [MutationTypes.SET_INVITE_ERROR_MESSAGE](state: S, payload: string | null): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [MutationTypes.SET_ORGANIZATION](state, Organisation) {
    state.organisation = Organisation;
  },

  [MutationTypes.SET_SAVING_ORGANIZATION](state, savingOrganisation) {
    state.savingOrganisation = savingOrganisation;
  },

  [MutationTypes.SET_SAVE_ORGANIZATION_ERROR](state, saveOrganisationError) {
    state.saveOrganisationError = saveOrganisationError;
  },

  [MutationTypes.SET_LOADING_ORGANIZATION](state, loadingOrganisation) {
    state.loadingOrganisation = loadingOrganisation;
  },




  [MutationTypes.SET_TESTING_ORGANISATION_SUPPORT_EMAIL](state, testingOrganisationSupportEmail) {
    state.testingOrganisationSupportEmail = testingOrganisationSupportEmail;
  },

  [MutationTypes.SET_TESTED_ORGANISATION_SUPPORT_EMAIL](state, testedOrganisationSupportEmail) {
    state.testedOrganisationSupportEmail = testedOrganisationSupportEmail;
  },

  [MutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAILERROR](state, testOrganisationSupportEmailError) {
    state.testOrganisationSupportEmailError = testOrganisationSupportEmailError;
  },

  [MutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR](state, testOrganisationSupportEmailValidationError) {
    state.testOrganisationSupportEmailValidationError = testOrganisationSupportEmailValidationError;
  },

  [MutationTypes.SET_SAVING_ORGANISATION_SUPPORT_EMAIL](state, savingOrganisationSupportEmail) {
    state.savingOrganisationSupportEmail = savingOrganisationSupportEmail;
  },

  [MutationTypes.SET_SAVED_ORGANISATION_SUPPORT_EMAIL](state, savedOrganisationSupportEmail) {
    state.savedOrganisationSupportEmail = savedOrganisationSupportEmail;
  },

  [MutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAILERROR](state, saveOrganisationSupportEmailError) {
    state.saveOrganisationSupportEmailError = saveOrganisationSupportEmailError;
  },

  [MutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR](state, saveOrganisationSupportEmailValidationError) {
    state.saveOrganisationSupportEmailValidationError = saveOrganisationSupportEmailValidationError;
  },

  [MutationTypes.SET_USERS](state, users) {
    state.users = users;
  },

  [MutationTypes.SET_LOADING_USERS](state, loadingUsers) {
    state.loadingUsers = loadingUsers;
  },

  [MutationTypes.SET_USERS_LOADED](state, usersLoaded) {
    state.usersLoaded = usersLoaded;
  },

  [MutationTypes.SET_USERS_PAGE](state, usersPage) {
    state.usersPage = usersPage;
  },

  [MutationTypes.SET_USERS_PAGESIZE](state, usersPageSize) {
    state.usersPageSize = usersPageSize;
  },

  [MutationTypes.SET_USERS_SEARCH](state, usersSearch) {
    state.usersSearch = usersSearch;
  },

  [MutationTypes.SET_USERS_FILTER](state, usersFilter) {
    state.usersFilter = usersFilter;
  },

  [MutationTypes.SET_USERS_AVAILABLEFILEDS](state, usersAvailableFileds) {
    state.usersAvailableFileds = usersAvailableFileds;
  },

  [MutationTypes.SET_USERS_TOTAL](state, usersTotal) {
    state.usersTotal = usersTotal;
  },

  [MutationTypes.SET_USERS_SORTS](state, usersSorts) {
    state.usersSorts = usersSorts;
  },

  [MutationTypes.SET_GROUPS](state, groups) {
    state.groups = groups;
  },

  [MutationTypes.SET_LOADING_GROUPS](state, loadingGroups) {
    state.loadingGroups = loadingGroups;
  },

  [MutationTypes.SET_GROUPS_PAGE](state, groupsPage) {
    state.groupsPage = groupsPage;
  },

  [MutationTypes.SET_GROUPS_PAGESIZE](state, groupsPageSize) {
    state.groupsPageSize = groupsPageSize;
  },

  [MutationTypes.SET_GROUPS_SEARCH](state, groupsSearch) {
    state.groupsSearch = groupsSearch;
  },

  [MutationTypes.SET_GROUPS_FILTER](state, groupsFilter) {
    state.groupsFilter = groupsFilter;
  },

  [MutationTypes.SET_GROUPS_AVAILABLEFILEDS](state, groupsAvailableFileds) {
    state.groupsAvailableFileds = groupsAvailableFileds;
  },

  [MutationTypes.SET_GROUPS_TOTAL](state, groupsTotal) {
    state.groupsTotal = groupsTotal;
  },

  [MutationTypes.SET_GROUPS_SORTS](state, groupsSorts) {
    state.groupsSorts = groupsSorts;
  },

  [MutationTypes.SET_ROLES](state, roles) {
    state.roles = roles;
  },

  [MutationTypes.SET_LOADING_ROLES](state, loadingRoles) {
    state.loadingRoles = loadingRoles;
  },

  [MutationTypes.SET_ROLES_LOADED](state, rolesLoaded) {
    state.rolesLoaded = rolesLoaded;
  },

  [MutationTypes.SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },

  [MutationTypes.SET_LOADING_PERMISSIONS](state, loadingPermissions) {
    state.loadingPermissions = loadingPermissions;
  },

  [MutationTypes.SET_ROLES_PAGE](state, rolesPage) {
    state.rolesPage = rolesPage;
  },

  [MutationTypes.SET_ROLES_PAGESIZE](state, rolesPageSize) {
    state.rolesPageSize = rolesPageSize;
  },

  [MutationTypes.SET_ROLES_SEARCH](state, rolesSearch) {
    state.rolesSearch = rolesSearch;
  },

  [MutationTypes.SET_ROLES_FILTER](state, rolesFilter) {
    state.rolesFilter = rolesFilter;
  },

  [MutationTypes.SET_ROLES_AVAILABLEFILEDS](state, rolesAvailableFileds) {
    state.rolesAvailableFileds = rolesAvailableFileds;
  },

  [MutationTypes.SET_ROLES_TOTAL](state, rolesTotal) {
    state.rolesTotal = rolesTotal;
  },

  [MutationTypes.SET_ROLES_SORTS](state, rolesSorts) {
    state.rolesSorts = rolesSorts;
  },

  [MutationTypes.SET_CRUD_FOCUSED_USER](state, crudFocusedUser) {
    state.crudFocusedUser = crudFocusedUser;
  },
  [MutationTypes.SET_LOADING_CRUD_FOCUSED_USER](state, loadingCrudFocusedUser) {
    state.loadingCrudFocusedUser = loadingCrudFocusedUser;
  },
  [MutationTypes.SET_SAVING_CRUD_FOCUSED_USER](state, savingCrudFocusedUser) {
    state.savingCrudFocusedUser = savingCrudFocusedUser;
  },

  [MutationTypes.SET_IS_CREATING_CRUD_FOCUSED_USER](state, creatingCrudFocusedUser) {
    state.creatingCrudFocusedUser = creatingCrudFocusedUser;
  },
  [MutationTypes.SET_CREATING_CRUD_FOCUSED_USER_SUCCEEDED](
    state,
    creatingCrudFocusedUserSucceeded
  ) {
    state.creatingCrudFocusedUserSucceeded = creatingCrudFocusedUserSucceeded;
  },
  [MutationTypes.SET_CREATING_CRUD_FOCUSED_USER_DATA](
    state,
    creatingCrudFocusedUserData
  ) {
    state.creatingCrudFocusedUserData = creatingCrudFocusedUserData;
  },

  [MutationTypes.SET_DELETED_CRUD_FOCUSED_USER_DATA](state, deletedCrudFocusedUserData) {
    state.deletedCrudFocusedUserData = deletedCrudFocusedUserData;
  },
  [MutationTypes.SET_DELETED_CRUD_FOCUSED_USER_SUCCEEDED](state, savingCrudFocusedUser) {
    state.deletedCrudFocusedUserSucceeded = savingCrudFocusedUser;
  },

  [MutationTypes.SET_CRUD_FOCUSED_GROUP](state, crudFocusedGroup) {
    state.crudFocusedGroup = crudFocusedGroup;
  },
  [MutationTypes.SET_LOADING_CRUD_FOCUSED_GROUP](state, loadingCrudFocusedGroup) {
    state.loadingCrudFocusedGroup = loadingCrudFocusedGroup;
  },
  [MutationTypes.SET_SAVING_CRUD_FOCUSED_GROUP](state, savingCrudFocusedGroup) {
    state.savingCrudFocusedGroup = savingCrudFocusedGroup;
  },

  [MutationTypes.SET_CRUD_FOCUSED_ROLE](state, crudFocusedRole) {
    state.crudFocusedRole = crudFocusedRole;
  },
  [MutationTypes.SET_LOADING_CRUD_FOCUSED_ROLE](state, loadingCrudFocusedRole) {
    state.loadingCrudFocusedRole = loadingCrudFocusedRole;
  },
  [MutationTypes.SET_SAVING_CRUD_FOCUSED_ROLE](state, savingCrudFocusedRole) {
    state.savingCrudFocusedRole = savingCrudFocusedRole;
  },

  [MutationTypes.SET_INVITE_ERROR](state, inviteUserError) {
    state.inviteUserError = inviteUserError;
  },
  [MutationTypes.SET_INVITE_ERROR_MESSAGE](state, inviteUserErrorMessage) {
    state.inviteUserErrorMessage = inviteUserErrorMessage;
  },
};
