import {
  TicketListFilters,
  MyTicketListFilters,
  FilterDef,
  // TicketState,
  TicketChart,
  Ticket,
  SortDef,
  FieldDef,
  TicketActivity,
  TicketActivityType,
  TicketCategory,
  OTicketStatus,
  FilterOptionDef,
  ChartsPieChart,
  ChartsStackedBarChart,
  ChartsBarChart
} from "@/types/index";

const filters: FilterDef[] = TicketListFilters.map((f: FilterDef) => f);

const myFilters: FilterDef[] = MyTicketListFilters.map((f: FilterDef) => f);


export type TicketState = {
  ticketsCharts: TicketChart[];
  isLoadingTicketsCharts: boolean;

  tickets: Ticket[];
  isLoadingTickets: boolean;
  isDeletingTickets: boolean;
  deletedTicketsData: null;
  isUpdatingTickets: boolean;
  updatedTicketsData: null;

  page: number;
  pageSize: number;
  total: number;
  search: string | null;
  filters: FilterDef[];
  availableFields: FieldDef[];
  sorts: SortDef[];

  myTickets: Ticket[];
  myIsLoadingTickets: boolean;
  myIsDeletingTickets: boolean;
  myDeletedTicketsData: null;
  myIsUpdatingTickets: boolean;
  myUpdatedTicketsData: null;

  myPage: number;
  myPageSize: number;
  myTotal: number;
  mySearch: string | null;
  myFilters: FilterDef[];
  myAvailableFields: FieldDef[];
  mySorts: SortDef[];

  ticketsKanban: Map<string, Ticket[]>;
  isLoadingTicketsKanban: Map<string, boolean>;
  isDeletingTicketsKanban: Map<string, boolean>;
  // deletedTicketsKanbanData: null;
  isUpdatingTicketsKanban: Map<string, boolean>;
  // updatedTicketsKanbanData: null;

  pageKanban: Map<string, number>;
  pageSizeKanban: Map<string, number>;
  totalKanban: Map<string, number>;
  searchKanban: Map<string, string | null>;
  filtersKanban: Map<string, FilterDef[]>;
  availableFieldsKanban: Map<string, FieldDef[]>;
  sortsKanban: Map<string, SortDef[]>;

  ticket: Ticket | null;
  ticketError: String | null,
  ticketErrorValidation: any,
  isLoadingTicket: boolean;
  isTicketLoaded: boolean;
  isCreatingTicket: boolean;
  createdTicketData: null;
  isUpdatingTicket: boolean;
  isTicketUpdated: boolean;
  updatedTicketData: null;
  isDeletingTicket: boolean;
  deletedTicketSucceeded: boolean;
  deletedTicketData: null;

  ticketActivities: TicketActivity[];
  isLoadingTicketActivities: boolean;
  isCreatingTicketActivity: boolean;
  isUpdatingTicketActivity: boolean;
  isDeletingTicketActivity: boolean;

  ticketActivityTypes: TicketActivityType[];
  isLoadingTicketActivityTypes: boolean;
  isTicketActivityTypesLoaded: boolean;
  isSavingTicketActivity: boolean;
  isAssigningTicketActivity: boolean;

  ticketToPreviewMod: string | null;
  ticketPreviewOpenState: boolean;

  categories: TicketCategory[];
  isLoadingCategories: boolean;
  isCategoriesLoaded: boolean;

  types: FilterOptionDef[]; //ITicketWebType[], //TicketType[],
  isLoadingTypes: boolean;
  isTypesLoaded: boolean;

  severities: FilterOptionDef[]; //TicketSeverity[],
  isLoadingSeverities: boolean;
  isSeveritiesLoaded: boolean;

  statuses: FilterOptionDef[]; //TicketStatus[],
  isLoadingStatuses: boolean;
  isStatusesLoaded: boolean;

  // isLoadingManufacturers: boolean,
  // isManufacturersLoaded: boolean,

  //isLoadingManufacturerModels: boolean,
  //isManufacturerModelsLoaded: boolean

  chartTypes: ChartsPieChart | null,
  isLoadingChartTypes: boolean,
  isChartTypesLoaded: boolean,

  chartSeverities: ChartsStackedBarChart | null,
  isLoadingChartSeverities: boolean,
  isChartSeveritiesLoaded: boolean,

  chartStatuses: ChartsPieChart | null,
  isLoadingChartStatuses: boolean,
  isChartStatusesLoaded: boolean,

  chartMonthly: ChartsBarChart | null,
  isLoadingChartMonthly: boolean,
  isChartMonthlyLoaded: boolean,
  
};

export const state: TicketState = {
  ticketsCharts: [],
  isLoadingTicketsCharts: false,

  tickets: [],

  page: 1,
  pageSize: 10,
  total: 0,
  search: null,
  filters: filters,
  availableFields: [],
  sorts: [],

  isLoadingTickets: false,
  isDeletingTickets: false,
  deletedTicketsData: null,
  isUpdatingTickets: false,
  updatedTicketsData: null,

  myTickets: [],

  myPage: 1,
  myPageSize: 10,
  myTotal: 0,
  mySearch: null,
  myFilters: myFilters,
  myAvailableFields: [],
  mySorts: [],

  myIsLoadingTickets: false,
  myIsDeletingTickets: false,
  myDeletedTicketsData: null,
  myIsUpdatingTickets: false,
  myUpdatedTicketsData: null,

  ticketsKanban: new Map<string, Ticket[]>([
      [OTicketStatus.backlog, []],
      [OTicketStatus.in_progress, []],
      [OTicketStatus.quality_control, []],
      [OTicketStatus.done, []]
  ]),
  isLoadingTicketsKanban: new Map<string, boolean>(),
  isDeletingTicketsKanban: new Map<string, boolean>(),
  // deletedTicketsKanbanData: null(),
  isUpdatingTicketsKanban: new Map<string, boolean>(),
  // updatedTicketsKanbanData: null(),

  pageKanban: new Map<string, number>(),
  pageSizeKanban: new Map<string, number>(),
  totalKanban: new Map<string, number>(),
  searchKanban: new Map<string, string | null>(),
  filtersKanban: new Map<string, FilterDef[]>(),
  availableFieldsKanban: new Map<string, FieldDef[]>(),
  sortsKanban: new Map<string, SortDef[]>(),

  ticket: null,
  ticketError: null,
  ticketErrorValidation: null,
  isLoadingTicket: false,
  isTicketLoaded: false,
  isCreatingTicket: false,
  createdTicketData: null,
  isUpdatingTicket: false,
  isTicketUpdated: false,
  updatedTicketData: null,
  isDeletingTicket: false,
  deletedTicketSucceeded: false,
  deletedTicketData: null,

  ticketActivities: [],
  isLoadingTicketActivities: false,
  isCreatingTicketActivity: false,
  isUpdatingTicketActivity: false,
  isDeletingTicketActivity: false,

  ticketActivityTypes: [],
  isLoadingTicketActivityTypes: false,
  isTicketActivityTypesLoaded: false,
  isSavingTicketActivity: false,
  isAssigningTicketActivity: false,

  ticketToPreviewMod: null,
  ticketPreviewOpenState: false,

  categories: [],
  isLoadingCategories: false,
  isCategoriesLoaded: false,

  types: [],
  isLoadingTypes: false,
  isTypesLoaded: false,

  severities: [],
  isLoadingSeverities: false,
  isSeveritiesLoaded: false,

  statuses: [],
  isLoadingStatuses: false,
  isStatusesLoaded: false,

  chartTypes: null,
  isLoadingChartTypes: false,
  isChartTypesLoaded: false,

  chartSeverities: null,
  isLoadingChartSeverities: false,
  isChartSeveritiesLoaded: false,

  chartStatuses: null,
  isLoadingChartStatuses: false,
  isChartStatusesLoaded: false,

  chartMonthly: null,
  isLoadingChartMonthly: false,
  isChartMonthlyLoaded: false,
  
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): TicketState => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};