import { MutationTree } from 'vuex';
import { State, defaultState } from './state';
import { PaymentsMutationTypes as MutationTypes } from './mutation-types';
import {
  StripeCustomer,
  StripeInvoice,
  // StripePaymentEvent,
  StripePaymentMethod,
  StripeSubscription,
  StripePlan,
  StripeProduct
} from '@/types/index';

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;
  [MutationTypes.SET_STRIPECUSTOMER](state: S, payload: StripeCustomer[]): void;
  [MutationTypes.SET_STRIPEINVOICE](state: S, payload: StripeInvoice[]): void;
  // [MutationTypes.SET_STRIPEPAYMENTEVENT](state: S, payload: StripePaymentEvent[]): void;
  [MutationTypes.SET_STRIPEPAYMENTMETHOD](state: S, payload: StripePaymentMethod[]): void;
  [MutationTypes.SET_STRIPESUBSCRIPTION](state: S, payload: StripeSubscription[]): void;
  [MutationTypes.SET_STRIPEPLAN](state: S, payload: StripePlan | null): void;
  [MutationTypes.SET_STRIPEPRODUCT](state: S, payload: StripeProduct | null): void;
}

export const mutations: MutationTree<State> & Mutations = {

  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [MutationTypes.SET_STRIPECUSTOMER](state: State, payload: StripeCustomer[]) {
    state.stripeCustomer = payload;
  },

  [MutationTypes.SET_STRIPEINVOICE](state: State, payload: StripeInvoice[]) {
    state.stripeInvoice = payload;
  },

  // [MutationTypes.SET_STRIPEPAYMENTEVENT](state: State, payload: StripePaymentEvent[]) {
  //   state.stripePaymentEvent = payload;
  // },

  [MutationTypes.SET_STRIPEPAYMENTMETHOD](state: State, payload: StripePaymentMethod[]) {
    state.stripePaymentMethod = payload;
  },

  [MutationTypes.SET_STRIPESUBSCRIPTION](state: State, payload: StripeSubscription[]) {
    state.stripeSubscription = payload;
  },

  [MutationTypes.SET_STRIPEPLAN](state: State, payload: StripePlan | null) {
    state.stripePlan = payload;
  },

  [MutationTypes.SET_STRIPEPRODUCT](state: State, payload: StripeProduct | null) {
    state.stripeProduct = payload;
  }

};
