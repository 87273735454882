import { FieldDef, OFieldType, OActionType, FieldAction } from "./FieldDef";
import {
  FilterDef,
  // FilterOptionDef,
  OFilterType,
  OOptionsTypeTicket,
} from "./FilterDef";
// import { TicketChart } from "./TicketChart";
// import { ChartsPieChart } from "./ChartsPieChart";
// import { ChartsStackedBarChart } from "./ChartsStackedBarChart";
// import { ChartsBarChart} from "./ChartsBarChart";
// import { SortDef } from "./SortDef";
import { reactive } from "vue";
import dayjs from "dayjs";
import { User } from "./User";
// import { Note } from "./Note";
/* eslint-disable no-unused-vars */

// type TicketType = 'ServiceTicket' | 'ChangeTicket' | 'Incident';
// type TicketSeverity = 'Major' | 'Minor' | 'Critical';
// type TicketStatus = 'Done' | 'Not Assigned' | 'In Progress';
const OTicketType = {
  service_request: "Service Ticket",
  //ChangeTicket: 'Change Ticket',
  incident: "Incident",
} as const;
//type TicketType = typeof OTicketType[keyof typeof OTicketType];
type TicketType = keyof typeof OTicketType;

const OTicketActivityType = {
  noaction: null,
  created: "Created",
  note: "Note",
  assignment: "Assignment",
  reject: "Reject",
  accept: "Accept",
  severityChange: "Severity Change",
  email: "Email",
  phoneCall: "Phone Call",
  meeting: "Meeting",
  payment: "Payment",
  external: "External"
} as const;
//type TicketType = typeof OTicketType[keyof typeof OTicketType];
type TicketActivityType = keyof typeof OTicketActivityType;

interface ITicketTypeTransport {
  id: Number | string | readonly string[];
  name: String;
  key: TicketType;
}
// interface ITicketWebType {
//   id: number,
//   name: string,
//   key: TicketType
// }

const OTicketSeverity = {
  major: "Major",
  minor: "Minor",
  critical: "Critical",
} as const;
// type TicketSeverity = typeof OTicketSeverity[keyof typeof OTicketSeverity];
type TicketSeverity = keyof typeof OTicketSeverity;

interface ITicketSeverityTransport {
  id: Number | string | readonly string[];
  name: String;
  key: TicketSeverity;
}

const OTicketStatus = {
  done: "Done",
  // not_assigned: "Not Assigned",
  quality_control: "Quality Control",
  in_progress: "In Progress",
  backlog: "Backlog"
} as const;
// type TicketStatus = typeof OTicketStatus[keyof typeof OTicketStatus];
type TicketStatus = keyof typeof OTicketStatus;

interface ITicketStatusTransport {
  id: Number | string | readonly string[];
  name: String;
  key: TicketStatus;
}

class Ticket {
  _id: number | string | readonly string[];
  subject: string;
  number: string;
  assignee: string;
  assigneeId: number | string | readonly string[];
  submittedBy: string;
  submittedById: number | string | readonly string[];
  accepted: boolean;
  acceptedAt: Date | null;
  rejected: boolean;
  rejectedAt: Date | null;
  rejectReason: string | null;
  type: string;// TicketType;
  // typeId: number;
  severity: string;// TicketSeverity;
  // severityId: number;
  createdAt: Date;
  status: string; //TicketStatus;
  statusOrder: number;
  // statusId: number;
  description: string;
  constructor(
    _id: number | string | readonly string[],
    subject: string,
    number: string,
    assignee: string,
    assigneeId: number | string | readonly string[],
    submittedBy: string,
    submittedById: number | string | readonly string[],
    accepted: boolean,
    acceptedAt: Date,
    rejected: boolean,
    rejectedAt: Date,
    rejectReason: string,
    type: TicketType,
    // typeId: number,
    severity: TicketSeverity,
    // severityId: number,
    createdAt: Date,
    status: TicketStatus,
    statusOrder: number,
    // statusId: number,
    description: string
  ) {
    this._id = _id;
    this.subject = subject;
    this.number = number;
    this.assignee = assignee;
    this.assigneeId = assigneeId;
    this.submittedBy = submittedBy;
    this.submittedById = submittedById;
    this.accepted = accepted;
    this.acceptedAt = acceptedAt ? new Date(acceptedAt) : null;
    this.rejected = rejected;
    this.rejectedAt = rejectedAt ? new Date(rejectedAt) : null;
    this.rejectReason = rejectReason;
    this.type = type;
    // this.typeId = typeId
    this.severity = severity;
    // this.severityId = severityId
    this.createdAt = new Date(createdAt);
    this.status = status;
    this.statusOrder = statusOrder;
    // this.statusId = statusId
    this.description = description;
  }

  static generateNumber(ticket: Ticket): string {
    return (
      //dayjs(request.createdAt).format("YYYY-MMDD") + "-" + (234654 + request.id)
      dayjs(ticket.createdAt).format("YYYY-MMDD") + "-" + ticket._id
    );
  }

  static fromObject(obj: any): Ticket {
    return new Ticket(
      obj._id,
      obj.subject,
      obj.number,
      obj.assignee,
      obj.assigneeId,
      obj.submittedBy,
      obj.submittedById,
      obj.accepted,
      obj.acceptedAt,
      obj.rejected,
      obj.rejectedAt,
      obj.rejectReason,
      obj.type,
      // obj.typeId,
      obj.severity,
      // obj.severityId,
      obj.createdAt,
      obj.status,
      obj.statusOrder,
      // obj.statusId,
      obj.description
    );
  }

  static getDefaultObject(): Ticket {
    return Ticket.fromObject({
      subject: "",
      number: "",
      assignee: "",
      assigneeId: null,
      submittedBy: "",
      submittedById: null,
      accepted: false,
      acceptedAt: null,
      rejected: false,
      rejectedAt: null,
      rejectReason: "",
      type: "",
      // typeId: '',
      severity: "",
      // severityId: '',
      createdAt: null,
      status: null,
      statusOrder: 0,
      // statusId: '',
      description: "",
    });
  }
}

class TicketActivity {
  _id: number | null | string | readonly string[];
  type: TicketActivityType;
  user: User | null;
  ticket: string | null;
  assigned: User | null;
  //note: Note | null;
  note: string | null;
  paymentAmount: string | null;
  phoneNumber: string | null;
  meetingURL: string | null;
  date: Date;

  constructor(
    _id: number | null | string | readonly string[],
    type: TicketActivityType,
    user: User | null,
    ticket: string | null,
    assigned: User | null,
    note: string | null,
    paymentAmount: string | null,
    phoneNumber: string | null,
    meetingURL: string | null,
    date: Date
  ) {
    this._id = _id;
    this.type = type;
    this.user = user;
    this.ticket = ticket;
    this.assigned = assigned;
    this.note = note;
    this.paymentAmount = paymentAmount;
    this.phoneNumber = phoneNumber;
    this.meetingURL = meetingURL;
    this.date = date;
  }

  static fromObject(obj: any): TicketActivity {
    return new TicketActivity(
      obj?._id ? (obj?._id as number) : null,
      obj.type as TicketActivityType,
      obj?.user ? User.fromObject(obj.user) : null,
      obj?.ticket ? obj?.ticket : null ,
      obj?.assigned ? User.fromObject(obj.assigned) : null,
      obj?.note, // ? Note.fromObject(obj.note) : null,
      obj?.paymentAmount,
      obj?.phoneNumber,
      obj?.meetingURL,
      //obj.note,
      obj?.date
    );
  }

  static getDefaultObject(): TicketActivity {
    return TicketActivity.fromObject({
      type: 'note',
      user: null,
      ticket: null,
      assigned: null,
      note: null, //Note.getDefaultObject(),
      paymentAmount: null,
      phoneNumber: null,
      meetingURL: null,
      date: null,
    });
  }
}

const TicketListFields = [
  FieldDef.fromObject({
    id: "_id",
    title: "ID",
    available: true,
    show: false,
    type: OFieldType._id,
    sortable: false,
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    // [OActionType.view],
  }),

  FieldDef.fromObject({
    id: "subject",
    title: "Subject",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "number",
    title: "Number",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "type",
    title: "Type",
    available: true,
    show: true,
    type: OFieldType.Type,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "severity",
    title: "Severity Level",
    available: true,
    show: true,
    type: OFieldType.Severity,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "status",
    title: "Status",
    available: true,
    show: true,
    type: OFieldType.Status,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "assignee",
    title: "Assigned To",
    available: true,
    show: true,
    type: OFieldType.User,
    sortable: true,
    searchable: false,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "submittedBy",
    title: "Submitted By",
    available: true,
    show: true,
    type: OFieldType.User,
    sortable: true,
    searchable: false,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    sortable: false,
    searchable: false,
    //actions: [OActionType.view, OActionType.edit],
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
      // FieldAction.fromObject({
      //   id: "edit",
      //   text: "Edit",
      //   icon: null,
      //   type: OActionType.edit,
      //   color: "themeprimary",
      // }),
      FieldAction.fromObject({
        id: "delete",
        text: "Delete",
        icon: null,
        type: OActionType.delete,
        color: "red",
      }),
    ],
    //  [OActionType.edit, OActionType.delete],
  }),
];

const MyTicketListFields = [
  FieldDef.fromObject({
    id: "_id",
    title: "ID",
    available: true,
    show: false,
    type: OFieldType.id,
    sortable: false,
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    // [OActionType.view],
  }),

  FieldDef.fromObject({
    id: "subject",
    title: "Subject",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "number",
    title: "Number",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "type",
    title: "Type",
    available: true,
    show: true,
    type: OFieldType.Type,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "severity",
    title: "Severity Level",
    available: true,
    show: true,
    type: OFieldType.Severity,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "status",
    title: "Status",
    available: true,
    show: true,
    type: OFieldType.Status,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    sortable: false,
    searchable: false,
    //actions: [OActionType.view, OActionType.edit],
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
      // FieldAction.fromObject({
      //   id: "edit",
      //   text: "Edit",
      //   icon: null,
      //   type: OActionType.edit,
      //   color: "themeprimary",
      // }),
      // FieldAction.fromObject({
      //   id: "delete",
      //   text: "Delete",
      //   icon: null,
      //   type: OActionType.delete,
      //   color: "red",
      // }),
    ],
    //  [OActionType.edit, OActionType.delete],
  }),
];

class TicketCategory {
  id: number | string | readonly string[];
  name: string;
  key: string;
  subCategories?: TicketCategory[];
  constructor(
    id: number | string | readonly string[],
    name: string,
    key: string,
    subCategories?: TicketCategory[]
  ) {
    this.id = id;
    this.name = name;
    this.key = key;
    this.subCategories = subCategories;
  }

  static fromObject(obj: any): TicketCategory {
    if (obj.subCategories) {
      return new TicketCategory(
        obj.id,
        obj.name,
        obj.key,
        obj.subCategories.map((subCategory: any) =>
        TicketCategory.fromObject(subCategory)
        )
      );
    }
    return new TicketCategory(obj.id, obj.name, obj.key);
  }
}

type TicketCategoryFactories = Array<() => Ticket>;

const TicketListFilters = [
  FilterDef.fromObject({
    id: "number",
    queryParam: "number",
    title: "Number",
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: "",
  }),
  FilterDef.fromObject({
    id: "subject",
    queryParam: "subject",
    title: "Subject",
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: "",
  }),
  FilterDef.fromObject({
    id: "type",
    queryParam: "type",
    title: "Type",
    type: OFilterType.Enum,
    optionsType: OOptionsTypeTicket.TicketType,
    options: reactive([]),
    value: "",
  }),
  FilterDef.fromObject({
    id: "severity",
    queryParam: "severity",
    title: "Severity",
    type: OFilterType.Enum,
    optionsType: OOptionsTypeTicket.TicketSeverity,
    options: reactive([]),
    value: "",
  }),
  FilterDef.fromObject({
    id: "status",
    queryParam: "status",
    title: "Status",
    type: OFilterType.Enum,
    optionsType: OOptionsTypeTicket.TicketStatus,
    options: reactive([]),
    value: "",
  }),
  FilterDef.fromObject({
    id: "assignee",
    queryParam: "assignee",
    title: "Assigned To",
    type: OFilterType.User,
    optionsType: OOptionsTypeTicket.User,
    options: reactive([]),
    value: "",
  }),
  FilterDef.fromObject({
    id: "submittedBy",
    queryParam: "submittedBy",
    title: "Submitted By",
    type: OFilterType.User,
    optionsType: OOptionsTypeTicket.User,
    options: reactive([]),
    value: "",
  }),
];

const MyTicketListFilters = [
  FilterDef.fromObject({
    id: "number",
    queryParam: "number",
    title: "Number",
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: "",
  }),
  FilterDef.fromObject({
    id: "subject",
    queryParam: "subject",
    title: "Subject",
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: "",
  }),
  FilterDef.fromObject({
    id: "severity",
    queryParam: "severity",
    title: "Severity",
    type: OFilterType.Enum,
    optionsType: OOptionsTypeTicket.TicketSeverity,
    options: reactive([]),
    value: "",
  }),
  FilterDef.fromObject({
    id: "status",
    queryParam: "status",
    title: "Status",
    type: OFilterType.Enum,
    optionsType: OOptionsTypeTicket.TicketStatus,
    options: reactive([]),
    value: "",
  }),
];

export {
  Ticket,
  OTicketType,
  OTicketSeverity,
  OTicketStatus,
  TicketType,
  TicketActivity,
  TicketActivityType,
  OTicketActivityType,
  // ITicketWebType,
  TicketSeverity,
  TicketCategoryFactories,
  TicketCategory,
  TicketStatus,
  // TicketMutationTypes,
  TicketListFields,
  TicketListFilters,
  MyTicketListFields,
  MyTicketListFilters,
  // TicketState,
  // TicketGetters,
  // TicketActionTypes,
  ITicketStatusTransport,
  ITicketSeverityTransport,
  ITicketTypeTransport,
  // TicketManufacturer
};
