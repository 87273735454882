import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { AssetState as State } from './state';
import {
  Asset,
  AssetField,
  FilterDef,
  AssetChart,
  AssetCategory,
  // AssetGetters,
  AssetManufacturer,
  AssetModel,
  FieldDef,
  SortDef,
  AssetImport,
  AssetExport
} from '@/types/index';

export type AssetGetters = {

  StateHasAassetsCharts(state: State): boolean,
  StateIsLoadingHasAassetsCharts(state: State): boolean,

  StateAssetsCharts(state: State): AssetChart[],
  StateIsLoadingAssetsCharts(state: State): boolean,

  StateAssets(state: State): Asset[],
  StateIsLoadingAssets(state: State): boolean,
  StateIsUpdatingAssets(state: State): boolean,
  StateUpdatedAssetsData(state: State): any,
  StateIsDeletingAssets(state: State): boolean,
  StateDeletedAssetsData(state: State): any,

  StateAssetsMine(state: State): Asset[],
  StateIsLoadingAssetsMine(state: State): boolean,

  StatePage(state: State): number,
  StatePageSize(state: State): number,
  StateTotal(state: State): number,
  StateSearch(state: State): string | null,
  StateFilter(state: State): FilterDef[],
  StateAvailableFields(state: State): FieldDef[],
  StateSorts(state: State): SortDef[],

  StateAsset(state: State): Asset | null,
  StateAssetError(state: State): string | null,
  StateAssetErrorValidation(state: State): any,
  StateIsLoadingAsset(state: State): boolean,
  StateIsAssetLoaded(state: State): boolean,
  StateIsCreatingAsset(state: State): boolean,
  StateIsAssetCreated(state: State): boolean,
  StateIsUpdatingAsset(state: State): boolean,
  StateIsAssetUpdated(state: State): boolean,

  StateAssetToPreviewMod(state: State): string | null,
  StateAssetPreviewOpenState(state: State): boolean,

  StateCreatedData(state: State): any,
  StateUpdatedData(state: State): any,
  StateIsDeletingAsset(state: State): boolean,
  StateDeletedData(state: State): any,

  StateCategories: (state: State) => AssetCategory[],
  StateIsLoadingCategories: (state: State) => boolean,
  StateCategoriesLoaded: (state: State) => boolean,
  StateIsSavingCategories: (state: State) => boolean,

  StateIsLoadingCategory: (state: State) => boolean,
  StateCategoryLoaded: (state: State) => boolean,
  StateIsSavingCategory: (state: State) => boolean,

  StateISCategorySaved: (state: State) => boolean,
  StateCategorySaveError: (state: State) => string | null,

  StateIsDeletingCategory: (state: State) => boolean,

  StateFields: (state: State) => AssetField[],
  StateIsLoadingFields: (state: State) => boolean,
  StateIsFieldsLoaded: (state: State) => boolean,
  // StateIsSavingFields: (state: State) => boolean,
  // StateIsLoadingField: (state: State) => boolean,
  StateIsFieldLoaded: (state: State) => boolean,
  // StateIsSavingField: (state: State) => boolean,
  // StateIsDeletingField: (state: State) => boolean,
  StateFieldsPage(state: State): number,
  StateFieldsPageSize(state: State): number,
  StateFieldsTotal(state: State): number,
  // StateFieldsSearch(state: State): string | null,
  // StateFieldsFilter(state: State): FilterDef[],
  // StateFieldsAvailableFields(state: State): FieldDef[],
  // StateFieldsSorts(state: State): SortDef[],

  StateManufacturers: (state: State) => AssetManufacturer[],
  StateIsLoadingManufacturers: (state: State) => boolean,
  StateIsManufacturersLoaded: (state: State) => boolean,

  StateModels: (state: State) => AssetModel[],
  StateIsLoadingModels: (state: State) => boolean,
  StateIsModelsLoaded: (state: State) => boolean,

  StateAssetImport: (state: State) => AssetImport | null,
  StateAssetImportIsImporting: (state: State) => Boolean,
  StateAssetImportDone: (state: State) => Boolean,
  StateAssetImportResults: (state: State) => Object | null,

  StateAssetExportGenerating: (state: State) => Boolean,
  StateAssetExportGenerationDone: (state: State) => Boolean,
  StateAssetExportResults: (state: State) => Object | null,

  StateAssetExportsLoading: (state: State) => Boolean,
  StateAssetExportsLoaded: (state: State) => Boolean,
  StateAssetExports: (state: State) => AssetExport[]
}

export const getters: GetterTree<State, RootState> & AssetGetters = {

  StateHasAassetsCharts: (state: State) => state.hasAssetsCharts,
  StateIsLoadingHasAassetsCharts: (state: State) => state.isLoadingHasAssetsCharts,

  StateAssetsCharts: (state: State) => state.assetsCharts,
  StateIsLoadingAssetsCharts: (state: State) => state.isLoadingAssetsCharts,

  StateAssets: (state: State) => state.assets,
  StateIsLoadingAssets: (state: State) => state.isLoadingAssets,
  StateIsUpdatingAssets: (state: State) => state.isUpdatingAssets,
  StateUpdatedAssetsData: (state: State) => state.updatedAssetsData,
  StateIsDeletingAssets: (state: State) => state.isDeletingAssets,
  StateDeletedAssetsData: (state: State) => state.deletedAssetsData,

  StateAssetsMine: (state: State) => state.assetsMine,
  StateIsLoadingAssetsMine: (state: State) => state.isLoadingAssetsMine,

  StatePage: (state: State) => state.page,
  StatePageSize: (state: State) => state.pageSize,
  StateTotal: (state: State) => state.total,
  StateSearch: (state: State) => state.search,
  StateFilter: (state: State) => state.filters,
  StateAvailableFields: (state: State) => state.availableFields,
  StateSorts: (state: State) => state.sorts,

  StateAsset: (state: State) => state.asset,
  StateAssetError: (state: State) => state.assetError,
  StateAssetErrorValidation: (state: State) => state.assetErrorValidation,
  StateIsLoadingAsset: (state: State) => state.isLoadingAsset,
  StateIsAssetLoaded: (state: State) => state.isAssetLoaded,
  StateIsCreatingAsset: (state: State) => state.isCreatingAsset,
  StateIsAssetCreated: (state: State) => state.isAssetCreated,
  StateIsUpdatingAsset: (state: State) => state.isUpdatingAsset,
  StateIsAssetUpdated: (state: State) => state.isAssetUpdated,
  StateCreatedData: (state: State) => state.createdAssetData,
  StateUpdatedData: (state: State) => state.updatedAssetData,
  StateIsDeletingAsset: (state: State) => state.isDeletingAsset,

  StateDeletedAssetSucceeded: (state: State) => state.deletedAssetSucceeded,
  StateDeletedData: (state: State) => state.deletedAssetData,

  StateAssetToPreviewMod: (state: State) => state.assetToPreviewMod,
  StateAssetPreviewOpenState: (state: State) => state.assetPreviewOpenState,

  StateCategories: (state: State) => state.categories,
  StateIsLoadingCategories: (state: State) => state.isLoadingCategories,
  StateCategoriesLoaded: (state: State) => state.isCategoriesLoaded,
  StateIsSavingCategories: (state: State) => state.isSavingCategories,

  StateIsLoadingCategory: (state: State) => state.isLoadingCategory,
  StateCategoryLoaded: (state: State) => state.isCategoryLoaded,
  StateIsSavingCategory: (state: State) => state.isSavingCategory,

  StateISCategorySaved: (state: State) => state.isCategorySaved,
  StateCategorySaveError: (state: State) => state.categorySaveError,

  StateIsDeletingCategory: (state: State) => state.isDeletingCategory,

  StateFields: (state: State) => state.fields,
  StateIsLoadingFields: (state: State) => state.isLoadingFields,
  StateIsFieldsLoaded: (state: State) => state.isFieldsLoaded,
  // StateIsSavingFields: (state: State) => state.isSavingFields,
  // StateIsLoadingField: (state: State) => state.isLoadingField,
  StateIsFieldLoaded: (state: State) => state.isFieldsLoaded,
  // StateIsSavingField: (state: State) => state.Is,
  // StateIsDeletingField: (state: State) => state.,
  StateFieldsPage: (state: State) => state.fieldsPage,
  StateFieldsPageSize: (state: State) => state.fieldsPageSize,
  StateFieldsTotal: (state: State) => state.fieldsTotal,
  // StateFieldsSearch: (state: State) => state.fieldsSearch,
  // StateFieldsFilter: (state: State) => state.fieldsFilters,
  // StateFieldsAvailableFields: (state: State) => state.fieldsAvailableFields,
  // StateFieldsSorts: (state: State) => state.fieldsSorts,




  StateField: (state: State) => state.field,
  StateFieldError: (state: State) => state.fieldError,
  StateFieldErrorValidation: (state: State) => state.fieldErrorValidation,
  StateIsLoadingField: (state: State) => state.isLoadingField,
  StateIsCreatingField: (state: State) => state.isCreatingField,
  StateIsUpdatingField: (state: State) => state.isUpdatingField,



  StateManufacturers: (state: State) => state.manufacturers,
  StateIsLoadingManufacturers: (state: State) => state.isLoadingManufacturers,
  StateIsManufacturersLoaded: (state: State) => state.isManufacturersLoaded,

  StateModels: (state: State) => state.models,
  StateIsLoadingModels: (state: State) => state.isLoadingModels,
  StateIsModelsLoaded: (state: State) => state.isModelsLoaded,

  StateLifeCycleStages: (state: State) => state.lifeCycleStages,
  StateIsLoadingAssetLifeCycleStages: (state: State) => state.isLoadingLifeCycleStages,
  StateIsAssetLifeCycleStagesLoaded: (state: State) => state.isLifeCycleStagesLoaded,

  StateChartAssetCategories: (state: State) => state.chartAssetCategories,
  StateIsLoadingChartAssetCategories: (state: State) => state.isLoadingChartAssetCategories,
  StateChartAssetCategoriesLoaded: (state: State) => state.isChartAssetCategoriesLoaded,


  StateAssetImport: (state: State) => state.assetImport,
  StateAssetImportIsImporting: (state: State) => state.assetImportIsImporting,
  StateAssetImportDone: (state: State) => state.assetImportIsImporting,
  StateAssetImportResults: (state: State) => state.assetImportResults,

  StateAssetExportGenerating: (state: State) => state.assetExportGenerating,
  StateAssetExportGenerationDone: (state: State) => state.assetExportGenerationDone,
  StateAssetExportResults: (state: State) => state.assetExportResults,

  StateAssetExportsLoading: (state: State) => state.assetExportsLoading,
  StateAssetExportsLoaded: (state: State) => state.assetExportsLoaded,
  StateAssetExports: (state: State) => state.assetExports
};
