export enum AssetActionTypes {
  
  GETASSETS_ACTION = 'GetAssets',
  GETHASASSETSCHARTS_ACTION = 'GetHasAssetsCharts',
  GETASSETSCHARTS_ACTION = 'GetAssetsCharts',
  GETASSET_ACTION = 'GetAsset',
  SETASSET_ACTION = 'SetAsset',
  SAVEASSET_ACTION = 'SaveAsset',
  GET_CATEGORIES_ACTION = "GetCategories",
  SAVE_CATEGORIES_ACTION = "SaveCategories",

  SAVE_CATEGORY_ACTION = "SaveCategory",
  DELETE_CATEGORY_ACTION = "DeleteCategory",

  SAVE_CATEGORY_TREE_ACTION = "SaveCategoryTree",

  GET_MANUFACTURERS_ACTION = "GetManufacturers",
  GET_MODELS_ACTION = "GetModels",
  GET_LIFE_CYCLE_STAGES_ACTION = "GetLifeCycleStages",

  SAVE_MODELS_ACTION = "SaveModels",
  SAVE_MANUFACTURERS_ACTION = "SaveManufacturers",

  DELETEASSET_ACTION = 'DeleteAsset',

  SET_ASSET_TO_PREVIEW_MOD_ACTION = 'SetAssetToPreviewModAction',
  SET_ASSET_PREVIEW_OPEN_STATE_ACTION = 'SetAssetPreviewOpenStateAction',

  GETASSETS_MINE_ACTION = 'GetAssetsMine',

  SET_PAGE = 'SetPage',
  SET_PAGESIZE = 'SetPageSize',
  SET_TOTAL = 'SetTotal',
  SET_SEARCH = 'SetSearch',
  SET_FILTER = 'SetFilter',
  SET_AVAILABLEFIELDS = 'SetAvailableFields',
  SET_SORTS = 'SetSorts',

  GET_CHART_ASSET_CATEGORIES = "GetChartAssetCategories",

  GET_FIELDS_ACTION = "GetFields",
  GET_FIELD_ACTION = "GetField",
  SAVE_FIELD_ACTION = "SaveField",

  ADD_FIELD_CATEGORY_ACTION = "AddFieldCategory",

  ASSET_IMPORT_FILEUPLOAD = "AssetImportFileUpload",
  ASSET_IMPORT_DO_IMPORT = "AssetImportDoImport",

  ASSET_EXPORT_GENERATE = "AssetGenerateExport",
  ASSET_LIST_EXPORTS = "AssetListExports",
  ASSET_DELETE_EXPORT = "AssetDeleteExport",
  ASSET_DOWNLOAD_EXPORT = "AssetDownloadExport",

  // SET_FIELDS_PAGE = 'SetFieldsPage',
  // SET_FIELDS_PAGESIZE = 'SetFieldsPageSize',
  // SET_FIELDS_TOTAL = 'SetFieldsTotal',
}
// export enum AssetActionTypes {
//   GETASSETS_ACTION = 'GetAssets',
//   GETASSETSCHARTS_ACTION = 'GetAssetsCharts',
//   GETASSET_ACTION = 'GetAsset',
//   SETASSET_ACTION = 'SetAsset',
//   SAVEASSET_ACTION = 'SaveAsset',
//   GET_CATEGORIES_ACTION = "GetCategories",
//   SAVE_CATEGORIES_ACTION = "SaveCategories",
//   GET_MANUFACTURERS_ACTION = "GetManufacturers",

//   GETASSETS_MINE_ACTION = 'GetAssetsMine',

//   SET_PAGE = 'SetPage',
//   SET_PAGESIZE = 'SetPageSize',
//   SET_TOTAL = 'SetTotal',
//   SET_SEARCH = 'SetSearch',
//   SET_FILTER = 'SetFilter',
//   SET_AVAILABLEFIELDS = 'SetAvailableFields',
//   SET_SORTS = 'SetSorts',

//   SET_ASSET_TO_PREVIEW_MOD_ACTION = 'SetAssetToPreviewModAction',
//   SET_ASSET_PREVIEW_OPEN_STATE_ACTION = 'SetAssetPreviewOpenStateAction'
// }
