import { MutationTree } from 'vuex';
import { State, defaultState } from './state';
import { NavMutationTypes as MutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;
  [MutationTypes.SET_TOGGLE_SIDEBAR](state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [MutationTypes.SET_TOGGLE_SIDEBAR](state, sidebarOpen) {
    state.sidebarOpen = sidebarOpen
  }

};
