import { MutationTree } from 'vuex';
import { AppMutationTypes } from './mutation-types';
import { AppState as State, ThemeModes, defaultState } from './state';

export type Mutations<S = State> = {
  [AppMutationTypes.RESET](state: S): void;

  [AppMutationTypes.SET_THEME_MODE](state: S, mode: ThemeModes): void;
  [AppMutationTypes.SET_API_VERSION](state: S, apiVersion: string): void;

}

export const mutations: MutationTree<State> & Mutations = {

  [AppMutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [AppMutationTypes.SET_THEME_MODE](state: State, mode: ThemeModes) {
    state.mode = mode
  },

  [AppMutationTypes.SET_API_VERSION](state: State, apiVersion: string) {
    state.apiVersion = apiVersion
  }

};
