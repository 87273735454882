import { User } from "@/types/index";

export type State = {
  isAuthenticated: boolean;
  // jsToken: string | null,
  scope: string[],
  accessToken: string | null;
  refreshToken: string | null;
  loginError: number | null;
  loginErrorMessage: string | null;

  user: User | null;
  loadingUser: boolean;
  userLoaded: boolean;

  savingUser: boolean;
  userSaved: boolean;
  userSaveError: number | null;
  userSaveErrorMessage: string | null;


  registerError: number | null;
  registerErrorMessage: string | null;
  verificationCode: string | null;
  verificationError: string | null;
  verified: boolean;
  noOrganisation: boolean;
  // notifications: string[]
  hasSubscription: boolean;

  forgotPassSuccess: boolean;
  forgotPassSendingRequest: boolean;
  forgotPassErrorMessage: string | null;

  resetPassSuccess: boolean;
  resetPassSendingRequest: boolean;
  resetPassErrorMessage: string | null;
};

export const state: State = {
  isAuthenticated: false,
  scope: [],
  accessToken: null,
  refreshToken: null,
  loginError: null,
  loginErrorMessage: null,
  user: null,
  loadingUser: false,
  userLoaded: false,

  savingUser: false,
  userSaved: false,
  userSaveError: null,
  userSaveErrorMessage: null,

  registerError: null,
  registerErrorMessage: null,
  verificationCode: null,
  verificationError: null,
  verified: false,
  noOrganisation: true,
  hasSubscription: false,
  // notifications: []

  forgotPassSuccess: false,
  forgotPassSendingRequest: false,
  forgotPassErrorMessage: null,

  resetPassSuccess: false,
  resetPassSendingRequest: false,
  resetPassErrorMessage: null
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): State => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};