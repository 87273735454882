import { FieldAction, FieldDef, OActionType, OFieldType } from "./FieldDef";
import { FilterDef, OFilterType, OOptionsType } from "./FilterDef";
// import { SortDef } from "./SortDef";

class Knowledge {
  _id: number | string | readonly string[];
  name: string;
  slug: string;
  category: string;
  categoryName: string;
  description: string;
  constructor(
    _id: number | string | readonly string[],
    name: string,
    slug: string,
    category: string,
    categoryName: string,
    description: string
  ) {
    this._id = _id;
    this.name = name;
    this.slug = slug;
    this.category = category;
    this.categoryName = categoryName;
    this.description = description;
  }

  static fromObject(obj: any): Knowledge {
    return new Knowledge(
      obj._id,
      obj.name,
      obj.slug,
      obj.category,
      obj.categoryName,
      obj.description
    );
  }

  static getDefaultObject(): Knowledge {
    return Knowledge.fromObject({
      name: "",
      slug: "",
      category: "",
      categoryName: "",
      description: "",
      _id: "",
    });
  }
}

const KnowledgeListFields = [
  FieldDef.fromObject({
    id: "_id",
    title: "ID",
    available: true,
    show: false,
    type: OFieldType.id,
    sortable: false,
    searchable: false,
    actions: [],
    // [OActionType.view],
  }),
  FieldDef.fromObject({
    id: "name",
    title: "Name",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "categoryName",
    title: "Category",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    //actions: [OActionType.view, OActionType.edit],
    sortable: false,
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
      // FieldAction.fromObject({
      //   id: "edit",
      //   text: "Edit",
      //   icon: null,
      //   type: OActionType.edit,
      //   color: "themeprimary",
      // }),
      FieldAction.fromObject({
        id: "delete",
        text: "Delete",
        icon: null,
        type: OActionType.delete,
        color: "red",
      }),
    ],
    //  [OActionType.edit, OActionType.delete],
  }),
];

class KnowledgeCategory {
  _id: number | string | readonly string[];
  name: string;
  order: number;
  key: string;
  constructor(
    _id: number | string | readonly string[],
    name: string,
    order: number,
    key: string
  ) {
    this._id = _id;
    this.name = name;
    this.order = order;
    this.key = key;
  }

  static fromObject(obj: any): KnowledgeCategory {
    return new KnowledgeCategory(obj._id, obj.name, 0, obj.key);
  }
}

type KnowledgeCategoryFactories = Array<() => Knowledge>;

const KnowledgeListFilters = [
  FilterDef.fromObject({
    id: "name",
    queryParam: "name",
    title: "Name",
    type: OFilterType.StringSearch,
    // storeOptionsGetter: null,
    // storeOptionsLoadAction: null,
    optionsType: null,
    options: [],
    value: "",
  }),
  FilterDef.fromObject({
    id: "category",
    queryParam: "category",
    title: "Category",
    type: OFilterType.Enum,
    // storeOptionsGetter: KnowledgeCategory[], // KnowledgeGetters.StateCategories,
    // storeOptionsLoadAction: KnowledgeActionTypes.GET_CATEGORIES_ACTION,
    optionsType: OOptionsType.KnowledgeCategory,
    options: [],
    value: "",
  }),
];

export {
  Knowledge,
  KnowledgeCategory,
  KnowledgeCategoryFactories,
  KnowledgeListFields,
  KnowledgeListFilters
  // KnowledgeActionTypes,
  // KnowledgeMutationTypes,
  // KnowledgeState,
  // KnowledgeGetters,
};
