<template>
<svg alt="zebrit" title="zebrit" id="zebritFooterLogo" data-name="zebritFooterLogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1321.45 467.28">
  <path class="fill-[#303030] dark:fill-[#FFFFFF]" d="M218.49,462.38H0l110.35-195H0v-66.55H218.49l-110.35,195h110.35v66.55Z"/>
  <path class="fill-[#303030] dark:fill-[#FFFFFF]" d="M355.75,398.28c2.61,.82,5.22,1.35,7.83,1.59,2.61,.25,5.22,.37,7.83,.37,6.52,0,12.8-.89,18.84-2.69,6.03-1.79,11.7-4.36,17-7.71,5.3-3.34,10.03-7.42,14.19-12.23,4.16-4.81,7.54-10.15,10.15-16.03l48.93,49.18c-6.2,8.81-13.33,16.72-21.41,23.73-8.07,7.02-16.8,12.97-26.18,17.86-9.38,4.89-19.25,8.61-29.6,11.13-10.36,2.53-21,3.79-31.93,3.79-18.43,0-35.76-3.43-51.99-10.28-16.23-6.85-30.42-16.39-42.57-28.63-12.15-12.23-21.74-26.79-28.75-43.67-7.02-16.88-10.52-35.44-10.52-55.66s3.51-39.64,10.52-56.76c7.01-17.13,16.6-31.72,28.75-43.8,12.15-12.07,26.34-21.45,42.57-28.14,16.23-6.69,33.56-10.03,51.99-10.03,10.93,0,21.61,1.31,32.05,3.91,10.44,2.61,20.35,6.36,29.73,11.25,9.38,4.89,18.14,10.89,26.3,17.98,8.15,7.09,15.33,15.05,21.53,23.85l-125.27,150.96Zm34.25-137.75c-3.1-1.14-6.16-1.87-9.17-2.2-3.02-.33-6.16-.49-9.42-.49-9.14,0-17.74,1.67-25.81,5.02-8.07,3.34-15.09,8.12-21.04,14.31-5.96,6.2-10.64,13.66-14.07,22.39-3.43,8.73-5.14,18.56-5.14,29.48,0,2.45,.12,5.22,.37,8.32,.25,3.1,.65,6.24,1.22,9.42,.57,3.18,1.26,6.24,2.08,9.17,.81,2.94,1.87,5.55,3.18,7.83l77.8-103.25Z"/>
  <path class="fill-[#303030] dark:fill-[#FFFFFF]" d="M776.82,329.04c0,20.55-3.51,39.31-10.52,56.27-7.02,16.97-16.56,31.52-28.62,43.67-12.07,12.15-26.22,21.57-42.45,28.26-16.23,6.69-33.64,10.03-52.24,10.03s-35.76-3.43-51.99-10.28c-16.23-6.85-30.42-16.39-42.57-28.63-12.15-12.23-21.74-26.79-28.75-43.67-7.02-16.88-10.52-35.44-10.52-55.66V96.11h67.04v122.33c3.59-4.56,7.95-8.64,13.09-12.23,5.14-3.59,10.68-6.52,16.64-8.81,5.95-2.28,12.11-4.04,18.47-5.26,6.36-1.22,12.56-1.83,18.59-1.83,18.59,0,36,3.47,52.24,10.4,16.23,6.93,30.38,16.6,42.45,28.99,12.07,12.4,21.61,27.04,28.62,43.92,7.01,16.88,10.52,35.36,10.52,55.42Zm-67.28,0c0-10.11-1.75-19.53-5.26-28.26-3.51-8.72-8.28-16.27-14.31-22.63-6.04-6.36-13.09-11.33-21.16-14.92-8.07-3.59-16.68-5.38-25.81-5.38s-17.74,2-25.81,5.99c-8.07,4-15.13,9.3-21.16,15.9-6.04,6.61-10.77,14.19-14.19,22.75s-5.14,17.41-5.14,26.55c0,10.12,1.71,19.49,5.14,28.14,3.43,8.65,8.15,16.15,14.19,22.51,6.03,6.36,13.09,11.38,21.16,15.05,8.07,3.67,16.68,5.5,25.81,5.5s17.74-1.83,25.81-5.5,15.13-8.69,21.16-15.05c6.03-6.36,10.8-13.86,14.31-22.51,3.51-8.64,5.26-18.02,5.26-28.14Z"/>
  <path class="fill-[#303030] dark:fill-[#FFFFFF]" d="M880.56,462.38h-66.79V200.34h16.15l22.02,31.07c10.77-9.79,23-17.33,36.7-22.63,13.7-5.3,27.89-7.95,42.57-7.95h58.96v66.55h-58.96c-7.01,0-13.62,1.31-19.82,3.91-6.2,2.61-11.58,6.2-16.15,10.77s-8.16,9.95-10.77,16.15c-2.61,6.2-3.91,12.81-3.91,19.82v144.35Z"/>
  <rect class="fill-[#303030] dark:fill-[#FFFFFF]" x="1021.24" y="200.34" width="67.28" height="262.04"/>
  <path class="fill-[#303030] dark:fill-[#FFFFFF]" d="M1270.8,462.38c-16.15,0-31.32-3.06-45.51-9.17-14.19-6.12-26.63-14.52-37.31-25.2-10.68-10.68-19.08-23.12-25.2-37.31-6.12-14.19-9.18-29.36-9.18-45.51v-77.8h-32.54v-66.55h32.54V96.11h66.55v104.72h101.29v66.55h-101.29v77.8c0,7.02,1.3,13.58,3.92,19.7,2.61,6.12,6.2,11.46,10.77,16.03,4.56,4.57,9.95,8.2,16.15,10.89,6.2,2.69,12.8,4.04,19.82,4.04h50.65v66.55h-50.65Z"/>
  <circle class="fill-[#31a700]" cx="1053.75" cy="87" r="87"/>
</svg>
</template>