import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
// import { State } from './state';
// import { TicketActionTypes, TicketMutationTypes, TicketState as State } from '@/types/index';
import {
  ChatBotMessage,
  User, ChatBotSettings,
} from "@/types/index";
import { ChatBotState as State } from './state';
import { ChatBotActionTypes } from './action-types';
import { ChatBotMutationTypes } from './mutation-types';
// import axios from "axios";
import { HttpClient as http, AxiosError, AxiosResponse } from "@/util/ZebritAxios";
import { Config } from "../../../config";
// import {
//   // ChatBot,
//   // FieldDef,
//   IGetTicket,
//   // SortDef,
//   // FilterDef,
//   // TicketListFilters,
//   // MyTicketListFilters
// } from "@/types/index";
import { Mutations } from "./mutations";
// import {AssetMutationTypes} from "@/store/modules/asset/mutation-types";
// import { TicketMutationTypes } from './mutation-types';
// import { getters as TicketGetters } from './getters';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ChatBotActionTypes.GET_CHATBOT_MESSAGES]({
    commit,
  }: AugmentedActionContext): void;
  [ChatBotActionTypes.SEND_CHATBOT_MESSAGE](
    { commit }: AugmentedActionContext,
    payload: {
      sender: User | null;
      isBot: boolean;
      message: ChatBotMessage;
    }
  ): void;
  [ChatBotActionTypes.ADD_CHATBOT_MESSAGE](
    { commit }: AugmentedActionContext,
    payload: ChatBotMessage
  ): void;
  [ChatBotActionTypes.SET_CHATBOT_OPEN_STATE](
    { commit }: AugmentedActionContext,
    payload: {
      openState: boolean;
    }
  ): void;

  [ChatBotActionTypes.GET_CHATBOT_SETTINGS](
      { commit }: AugmentedActionContext,
      payload: ChatBotSettings
  ): void;
  [ChatBotActionTypes.SAVE_CHATBOT_SETTINGS](
      { commit }: AugmentedActionContext,
      payload: ChatBotSettings
  ): void;
}

// const getters = mapGetters({
//   messages: "chatbot/StateChatBotMessages",
//   openState: "chatbot/StateChatBotOpenState",
//   isTyping: "chatbot/StateChatBotIsTyping",
//   user: "chatbot/StateChatBotUser",
// });

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ChatBotActionTypes.GET_CHATBOT_MESSAGES]({
    commit
  }: AugmentedActionContext) {
    await http
      .get(Config.api.chatbot.messages)
      .then((res:AxiosResponse) => {
        commit(ChatBotMutationTypes.SET_CHATBOT_MESSAGES, res.data.messages);
      })
      .catch((err:AxiosError) => {
        console.log("error", err);
      });
  },

  async [ChatBotActionTypes.SEND_CHATBOT_MESSAGE](
    { commit, getters }: AugmentedActionContext,
    payload: {
      sender: User | null;
      isBot: boolean;
      message: ChatBotMessage;
    }
  ) {
    const sender = payload.sender;
    const isBot = payload.isBot;
    const message = payload.message;

    //commit(TicketMutationTypes.SET_MY_IS_LOADING_TICKETS, true);
    // const page = getters.StateMyPage; // TicketGetters.StatePage() || 1;
    // const pageP_size = getters.StateMyPageSize; // TicketGetters.StatePageSize || 10;
    // const search = getters.StateMySearch; // TicketGetters.StateSearch || 1;
    // const filter = getters.StateMyFilter;
    const params: any = {
      sender: sender, //payload && payload.page ? payload.page : 1,
      isBot: isBot, //payload && payload.page_size ? payload.page_size : 10,
      //category: payload && payload.category ? payload.category : null,
      message: message, //payload && payload.search ? payload.search : null
    };

    await http
      .get(Config.api.chatbot.send, params)
      .then(() => {
        // commit(TicketMutationTypes.SET_MY_TICKETS, res.data.requests);
        // commit(TicketMutationTypes.SET_MY_PAGE, res.data.page);
        // commit(TicketMutationTypes.SET_MY_PAGESIZE, res.data.pageSize);
        // commit(TicketMutationTypes.SET_MY_TOTAL, res.data.total);
        // commit(TicketMutationTypes.SET_MY_IS_LOADING_TICKETS, false);
      })
      .catch((err:AxiosError) => {
        console.log("error", err);
        // commit(TicketMutationTypes.SET_MY_IS_LOADING_TICKETS, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ChatBotActionTypes.ADD_CHATBOT_MESSAGE](
    { commit }: AugmentedActionContext,
    payload: ChatBotMessage
  ) {
    
    commit(ChatBotMutationTypes.ADD_CHATBOT_MESSAGE, payload);
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ChatBotActionTypes.SET_CHATBOT_OPEN_STATE](
    { commit }: AugmentedActionContext,
    payload: {
      openState: boolean;
    }
  ) {
    commit(ChatBotMutationTypes.SET_CHATBOT_IS_OPEN, payload.openState);
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ChatBotActionTypes.GET_CHATBOT_SETTINGS](
      { commit }: AugmentedActionContext,
      payload: ChatBotSettings
  ) {
    
    commit(ChatBotMutationTypes.SET_CHATBOT_SETTINGS, payload);
  },

  async [ChatBotActionTypes.GET_CHATBOT_SETTINGS](
      { commit }: AugmentedActionContext
  ) {
    commit(ChatBotMutationTypes.SET_IS_LOADING_CHATBOT_SETTINGS, true);
    await http.get(Config.api.chatbot.settings)
        .then((res:AxiosResponse) => {
          commit(ChatBotMutationTypes.SET_CHATBOT_SETTINGS, res.data);
          commit(ChatBotMutationTypes.SET_IS_LOADING_CHATBOT_SETTINGS, false);
          commit(ChatBotMutationTypes.SET_IS_CHATBOT_SETTINGS_LOADED, true);
        })
        .catch((err:AxiosError) => {
          console.log("error", err);
          commit(ChatBotMutationTypes.SET_IS_LOADING_CHATBOT_SETTINGS, false);
          commit(ChatBotMutationTypes.SET_IS_CHATBOT_SETTINGS_LOADED, false);
        });
  },

  async [ChatBotActionTypes.SAVE_CHATBOT_SETTINGS](
      { commit, dispatch }: AugmentedActionContext,
      payload: ChatBotSettings,
  ) {
    commit(ChatBotMutationTypes.SET_IS_SAVING_CHATBOT_SETTINGS, true);
    await http.put(Config.api.chatbot.settings, payload)
        .then((response:AxiosResponse) => {
          commit(ChatBotMutationTypes.SET_CHATBOT_SETTINGS, response.data);
          commit(ChatBotMutationTypes.SET_IS_SAVING_CHATBOT_SETTINGS, false);
          dispatch(ChatBotActionTypes.GET_CHATBOT_SETTINGS);
        })
        .catch((err:AxiosError) => {
          commit(ChatBotMutationTypes.SET_IS_SAVING_CHATBOT_SETTINGS, false);
          console.log("error", err);
        });
  },

};
