import {
  Knowledge,
  KnowledgeCategory,
  FieldDef,
  SortDef,
  KnowledgeListFilters,
  FilterDef,
  // KnowledgeState
} from '@/types/index'

const filters: FilterDef[] = KnowledgeListFilters.map((f: FilterDef) => f);

export type KnowledgeState = {
  knowledges: Knowledge[];
  isLoadingKnowledges: boolean;

  page: number;
  pageSize: number;
  total: number;
  search: string | null;
  filters: FilterDef[];
  availableFields: FieldDef[];
  sortFields: SortDef[];

  searchKnowledges: Knowledge[];
  isLoadingSearchKnowledges: boolean;

  knowledge: Knowledge | null;
  isLoadingKnowledge: boolean;
  isCreatingKnowledge: boolean;
  createdKnowledgeData: string | null;
  isUpdatingKnowledge: boolean;
  updatedKnowledgeData: string | null;
  isDeletingKnowledge: boolean;

  deletedKnowledgeSucceeded: boolean;
  deletedKnowledgeData: string | null;

  categories: KnowledgeCategory[];
  isLoadingCategories: boolean;
  isCategoriesLoaded: boolean;

  knowledgeToPreviewMod: string | null;
  knowledgePreviewOpenState: boolean;

  knowledgeCategoriesOpenState: boolean;
};


export const state: KnowledgeState = {
  knowledges: [],
  isLoadingKnowledges: false,

  page: 1,
  pageSize: 10,
  total: 0,
  search: null,
  filters: filters,
  availableFields: [],
  sortFields: [],

  searchKnowledges: [],
  isLoadingSearchKnowledges: false,

  knowledge: null,
  isLoadingKnowledge: false,
  isCreatingKnowledge: false,
  createdKnowledgeData: null,
  isUpdatingKnowledge: false,
  updatedKnowledgeData: null,
  isDeletingKnowledge: false,
  
  deletedKnowledgeSucceeded:false,
  deletedKnowledgeData: null,

  knowledgeToPreviewMod: null,
  knowledgePreviewOpenState: false,

  knowledgeCategoriesOpenState: false,

  categories: [],
  isLoadingCategories: false,
  isCategoriesLoaded: false,
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): KnowledgeState => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};