
import { defineComponent } from "vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import TopMenuUserProfile from "@/components/TopMenuUserProfile.vue";
// import TopMenuNotifications from "@/components/TopMenuNotifications.vue";
import TopMenuOrganisationLogo from "@/components/TopMenuOrganisationLogo.vue";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { Bars3BottomLeftIcon, PlusSmallIcon } from "@heroicons/vue/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import { PermissionKeys } from "@/types/index";
import { RouteRecordRaw } from "vue-router";
import { userRoles, hasPermission } from "@/composables/UserRole";

export default defineComponent({
  name: "TopMenu",
  components: {
    TopMenuUserProfile,
    // TopMenuNotifications,
    Bars3Icon,
    XMarkIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,

    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    Bars3BottomLeftIcon,
    PlusSmallIcon,
    MagnifyingGlassIcon,
    TopMenuOrganisationLogo
  },
  computed: {
    topMenus(): RouteRecordRaw[] {
      const { user, roles } = userRoles();
      if (!user || !roles) return [];
      return this.$router.options.routes.filter((r: RouteRecordRaw) => {
        let _hasPermission = false; //undefined as RolePermission | undefined;
        if (r.meta?.permission)
          _hasPermission = hasPermission(r.meta?.permission as PermissionKeys[] | null);
          // hasPermission = userRole
          //   ? userRole?.permissions.find(
          //       (a: Permission) => a.type === r.meta?.permission
          //     )
          //   : undefined;
        return (
          _hasPermission && r.meta?.type === "menu" && r.meta?.menu === "main"
        );
      });
    },
    // topIconLinks(): RouteRecordRaw[] {
    //   
    //   const { user, roles, userRole } = userRoles();
    //   if (!user || !roles) return [];
    //   return this.$router.options.routes.filter((r: RouteRecordRaw) => {
    //     let hasPermission = undefined as Permission | undefined;
    //     if (r.meta?.permission)
    //       hasPermission = userRole
    //         ? userRole?.permissions.find(
    //             (a: Permission) => a.type === r.meta?.permission
    //           )
    //         : undefined;
    //     return (
    //       hasPermission && r.meta?.type === "menu" && r.meta?.menu === "main"
    //     );
    //   });
    // },
  },
});
