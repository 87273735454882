import { User } from './User';
// import { AssetCategory, AssetManufacturer, AssetManufacturerModel } from "./Asset";
const OFilterType = {
    StringSearch: "String",
    Enum: "Enum",
    DateTimeRange: "DateTime",
    DateRange: "Date",
    TimeRange: "Time",
    User: "User",
    Severity: "Severity",
    Status: "Status",
    LifeCycleStage: "LifeCycleStage"
} as const;
type FilterType = typeof OFilterType[keyof typeof OFilterType];

const OOptionsType = {
    None: null,
    AssetCategory: 'AssetCategory',
    AssetManufacturer: 'AssetManufacturer',
    AssetManufacturerModel: 'AssetManufacturerModel',
    KnowledgeCategory: 'KnowledgeCategory',
    Group: 'Group',
    Role: 'Role',
    User: 'User',

} as const;
type OptionsType = typeof OOptionsType[keyof typeof OOptionsType];

const OOptionsTypeTicket = {
    None: null,
    TicketType: 'TicketType',
    TicketSeverity: 'TicketSeverity',
    TicketStatus: 'TicketStatus',
    AssetLifeCycleStage: 'AssetLifeCycleStage',
    User: 'User'
    // TicketCategory: 'TicketCategory',
    // TicketManufacturer: 'TicketManufacturer',
    // TicketManufacturerModel: 'TicketManufacturerModel'
} as const;
type OptionsTypeTicket = typeof OOptionsTypeTicket[keyof typeof OOptionsTypeTicket];

class FilterOptionDef {
    _id: number | string | readonly string[];
    name: string;
    // key: OptionsType | OptionsTypeTicket;
    key: string;

    constructor(
        _id: number | string | readonly string[],
        name: string,
        key: string
    ) {
        this._id = _id;
        this.name = name;
        this.key = key
    }

    static fromObject(obj: any) {
        return new FilterOptionDef(
            obj._id,
            obj.name,
            obj.key
        );
    }
}

type FilterOptionDefType = typeof FilterOptionDef;

class FilterDef {
    readonly id: string;
    readonly queryParam: string;
    readonly title: string;
    readonly type: FilterType;
    // readonly storeOptionsGetter: AssetGetters | null;
    // readonly storeOptionsLoadAction: AssetActionTypes | null;
    optionsType: OptionsType | OptionsTypeTicket;
    options: FilterOptionDef[] | User[]; //FilterOptionDefType[]; //OptionsType[] | OptionsTypeTicket[];
    // optionsTypeTicket: OptionsTypeTicket;
    // optionsTicket: OptionsTypeTicket[];
    value: string | null;

    constructor(
        id: string,
        queryParam: string,
        title: string,
        type: FilterType,
        // storeOptionsGetter: AssetGetters | null,
        // storeOptionsLoadAction: AssetActionTypes | null,
        optionsType: OptionsType | OptionsTypeTicket,
        options: FilterOptionDef[] | User[], //OptionsType[] | OptionsTypeTicket[],
        // optionsTypeTicket: OptionsTypeTicket,
        // optionsTicket: OptionsTypeTicket[],
        value: string | null,
    ) {
        this.id = id
        this.queryParam = queryParam
        this.title = title
        this.type = type
        // this.storeOptionsGetter = storeOptionsGetter
        // this.storeOptionsLoadAction = storeOptionsLoadAction
        this.optionsType = optionsType
        this.options = options
        // this.optionsTypeTicket = optionsTypeTicket
        // this.optionsTicket = optionsTicket
        this.value = value
    }

    static fromObject(obj: any): FilterDef {
        return new FilterDef(
            obj.id,
            obj.queryParam,
            obj.title,
            <FilterType>obj.type,
            // obj.storeOptionsGetter,
            // obj.storeOptionsLoadAction,
            obj.optionsType,
            obj.options,
            // obj.optionsTypeTicket,
            // obj.optionsTicket,
            obj.value
        )
    }

    clone(): FilterDef {
        return new FilterDef(
            this.id,
            this.queryParam,
            this.title,
            this.type,
            // this.storeOptionsGetter,
            // this.storeOptionsLoadAction,
            this.optionsType,
            this.options,
            // this.optionsTypeTicket,
            // this.optionsTicket,
            this.value
        )
    }

}

export {
    FilterDef,
    FilterType,
    OFilterType,
    OptionsType,
    OOptionsType,
    OptionsTypeTicket,
    OOptionsTypeTicket,
    FilterOptionDef,
    FilterOptionDefType
}
