
import { defineComponent } from "vue";
import AppLogo from "@/layout/AppLogo.vue";
import AppLogoMonoColor from "@/layout/AppLogoMonoColor.vue";
import { Config } from "@/config";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "LatoutFooter",
  props: ["theme"],
  components: { AppLogo, AppLogoMonoColor },
  computed: {
    ...mapGetters({
      apiversion: "app/StateApiVersion",
    }),

    version(): string {
      return Config.version.Version;
    },
  },
});
