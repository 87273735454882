// import store from '../store'
// import _ from 'lodash'

// import vm from '../main'
// import { default as http } from './http-client.service'
import { HttpClient as http } from '@/util/ZebritAxios'
import { Config } from "../config";
import { store, resetStore } from "@/store";
import { AuthMutationTypes } from "@/store/modules/auth/mutation-types";
import { AuthActionTypes } from "@/store/modules/auth/action-types";
import { AppMutationTypes } from "@/store/modules/app/mutation-types";
// import { userRoles } from "@/composables/UserRole";

// const store = useStore();

const AuthService = {

login: (credentials:any) => {
  return http
    .post(Config.api.account.login, credentials)
    .then((response:any) => {
      if(response.isAxiosError){
        const message = response.response.data.message;
        const error = response.response.data.error;
        store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR, error);
        store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, message);
        throw message;
      } else {
        store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR, null);
        store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, null);
        store.dispatch('auth/' + AuthActionTypes.SET_AUTH, response.data)
      }
    })
    .catch((error:any) => {
      console.error('authService.login-error:\n', error)
      store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR, error.data.error);
      store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, error.data.message);
      throw error
    })
},


loadCurrentUser: () => {
  // const { userRoles } = userRoles();
  return http
    .get(Config.api.account.currentuser)
    .then((response:any) => {
      // debugger; // eslint-disable-line
      const buildVersion = response.headers['x-build-version'];
      if(buildVersion)
      store.commit('app/' + AppMutationTypes.SET_API_VERSION, buildVersion);

      if(response.isAxiosError){
        const message = response.response.data.message;
        const error = response.response.data.error;
        store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR, error);
        store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, message);
        throw message;
      } else {
        store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR, null);
        store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, null);
        store.dispatch('auth/' + AuthActionTypes.SET_AUTH, response.data)
      }
    })
    .catch((error:any) => {

      const buildVersion = error.headers['x-build-version'];
      if(buildVersion)
      store.commit('app/' + AppMutationTypes.SET_API_VERSION, buildVersion);

      console.error('authService.load-current-user-error:\n', error)
      store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR, error.data.error);
      store.commit('auth/' + AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, error.data.message);
      throw error
    })
},

loginSocial: (token:any) => {
  return http
    .post('login/social', { token })
    .then((response:any) => {
      store.dispatch('auth/setAuth', response.data)
    })
    .catch((error:any) => {
      console.error('authService.loginSocial-error:\n', error)
      throw error
    })
},

logout: () => {
  // store.dispatch('auth/useRefreshToken')
  store.dispatch("auth/" + AuthActionTypes.USE_REFRESH_TOKEN);
  return http
    // .delete('/logout')
    .delete(Config.api.account.logout)
    .then(() => {
      // store.dispatch('auth/setAuth', response.data)
      store.dispatch('auth/clearAuth');
      resetStore();
    })
    .catch((error:any) => {
      store.dispatch('auth/clearAuth');
      resetStore();
      console.error('authService.logout-error:\n', error)
      throw error
    })
},

registerUser: (user:any) => {
  return http
    .post('/register', { user, registerType: 'Register' })
    .catch((error:any) => {
      console.error('authService.registerUser-error:\n', error)
      throw error
    })
},

// AuthService.inviteUser = user => {
//   user = Object.assign({}, user)
//   user.role = user.role.name
//   delete user.isActive
//   delete user.isEnabled

//   const groups = (user.groups || []).concat([]).map(group => {
//     return group.group._id
//   })
//   const permissions = (user.permissions || []).concat([]).map(permission => {
//     return { childId: permission.permission._id, state: permission.state }
//   })

//   delete user.permissions
//   delete user.groups

//   const promises = []
//   return http
//     .post('/register', { user, registerType: 'Invite' })
//     .then(result => {
//       user = result.data
//       if (!_.isEmpty(groups)) {
//         promises.push(vm.$userRepository.addManyGroups(user._id, groups))
//       }
//       if (!_.isEmpty(permissions)) {
//         promises.push(
//           vm.$userRepository.addManyPermissions(user._id, permissions)
//         )
//       }

//       return Promise.all(promises)
//     })
//     .catch(error => {
//       console.error('authService.inviteUser-error:\n', error)
//       throw error
//     })
// }

sendActivationEmail: (email:any) => {
  return http
    .post('/register/send-activation-email', { email })
    .catch((error:any) => {
      console.error('authService.sendActivationEmail-error:\n', error)
      throw error
    })
},

sendResetLink: (email:any) => {
  return http.post('/login/forgot', { email }).catch((error:any) => {
    console.error('authService.sendResetLink-error:\n', error)
    throw error
  })
},

resetPassword: (token:any, password:any, pin:any) => {
  return http.post('/login/reset', { token, password, pin }).catch((error:any) => {
    console.error('authService.resetPassword-error:\n', error)
    throw error
  })
},

activateAccount: (token:any) => {
  debugger; // eslint-disable-line
  return http.post('/register/activate', { token }).catch((error:any) => {
    console.error('authService.activateAccount-error:\n', error)
    throw error
  })
}
}
export default AuthService 
