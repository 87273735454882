import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { AuthActionTypes } from "./action-types";
import { Config } from "../../../config";
import { Mutations } from "./mutations";
import { AuthMutationTypes } from "./mutation-types";
import { HttpClient as http, AxiosError, AxiosResponse } from "@/util/ZebritAxios";
import { User } from "@/types/index";
import { LoginBody, ForgotPassBody, ResetPassBody } from "@/types/LoginTypes";
import { omit } from "lodash";
// import {
//   RegisterStep1Body,
//   RegisterStep1Response,
//   RegisterStep2Body,
//   RegisterStep2Response,
//   RegisterStep3Body,
//   RegisterStep3Response,
// } from "@/types/RegisterTypes";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [AuthActionTypes.REGISTER_STEP1_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      firstname: any;
      lastname: any;
      password: any;
      // pin: any;
      email: string;
    }
  ): void;
  [AuthActionTypes.REGISTER_STEP2_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      verificationCode: string;
    }
  ): void;
  [AuthActionTypes.REGISTER_STEP3_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      name: string;
      phone: string;
      accountnumber: string;
      website: string;
      address: string;
      registrationNumber: string;
      vat: string;
      seed: boolean;
    }
  ): void;
  [AuthActionTypes.LOGIN_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: LoginBody
  ): void;
  [AuthActionTypes.LOADCURRENTUSER_ACTION](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
  [AuthActionTypes.LOGOUT_ACTION]({ commit }: AugmentedActionContext, payload: any): void;
  [AuthActionTypes.USE_REFRESH_TOKEN](
    { commit }: AugmentedActionContext,
    payload: any
  ): void;
  [AuthActionTypes.UPDATE_TOKENS]({ commit }: AugmentedActionContext, payload: any): void;
  [AuthActionTypes.SAVE_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: User
  ): void;
  [AuthActionTypes.SEND_FORGOT_PASSWORD_REQUEST](
    { commit, dispatch }: AugmentedActionContext,
    payload: ForgotPassBody
  ): void;
  [AuthActionTypes.SEND_RESET_PASSWORD_REQUEST](
    { commit, dispatch }: AugmentedActionContext,
    payload: ResetPassBody
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [AuthActionTypes.REGISTER_STEP1_ACTION]({ commit }, payload) {
    const registrationStep1Payload = {
      user: {
        firstName: payload.firstname,
        lastName: payload.lastname,
        email: payload.email,
        password: payload.password,
        // pin: payload.pin,
        role: "User",
      },
      registerType: "Register",
    };

    await http
      .post(Config.api.account.registrationStep1, registrationStep1Payload)
      .then((response: any) => {
        if (response && (response.isAxiosError || response.data.error)) {
          if (response.data) {
            commit(AuthMutationTypes.SET_REGISTER_ERROR, response.data.error);
            commit(AuthMutationTypes.SET_REGISTER_ERROR_MESSAGE, response.data.message);
          } else if (response.isAxiosError) {
            commit(AuthMutationTypes.SET_REGISTER_ERROR, response.response.status);
            commit(
              AuthMutationTypes.SET_REGISTER_ERROR_MESSAGE,
              response.response.data.message
            );
          }
        } else {
          commit(AuthMutationTypes.SET_REGISTER_ERROR, null);
          commit(AuthMutationTypes.SET_REGISTER_ERROR_MESSAGE, null);
        }
      })
      .catch((err: any) => {
        console.log(err);
        commit(AuthMutationTypes.SET_REGISTER_ERROR, err.data.error);
        commit(AuthMutationTypes.SET_REGISTER_ERROR_MESSAGE, err.data.message);
      });
  },

  async [AuthActionTypes.REGISTER_STEP2_ACTION]({ commit }, payload) {
    await http
      .post(Config.api.account.registrationStep2, payload)
      .then((response: any) => {
        if (response && (response.isAxiosError || response.data.error)) {
          if (response.data) {
            commit(AuthMutationTypes.SET_REGISTER_ERROR, response.data.error);
            commit(AuthMutationTypes.SET_REGISTER_ERROR_MESSAGE, response.data.message);
          } else if (response.isAxiosError) {
            commit(AuthMutationTypes.SET_REGISTER_ERROR, 0);
            commit(AuthMutationTypes.SET_REGISTER_ERROR_MESSAGE, response.message);
          }
        } else {
          commit(AuthMutationTypes.SET_REGISTER_ERROR, null);
          commit(AuthMutationTypes.SET_REGISTER_ERROR_MESSAGE, null);
        }
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  },

  async [AuthActionTypes.REGISTER_STEP3_ACTION]({ commit, state }, payload) {
    const user = state.user;
    const registrationStep3Payload = {
      organisation: {
        name: payload.name,
        phone: payload.phone,
        accountnumber: payload.accountnumber,
        website: payload.website,
        address: payload.address,
        registrationNumber: payload.registrationNumber,
        vat: payload.vat,
      },
      user: user
      // seed: payload.seed
    };

    await http
      .post(Config.api.account.registrationStep3, registrationStep3Payload)
      .then((response: any) => {
        if (response && (response.isAxiosError || response.data.error)) {
          if (response.data) {
            commit(AuthMutationTypes.SET_REGISTER_ERROR, response.data.error);
            commit(AuthMutationTypes.SET_REGISTER_ERROR_MESSAGE, response.data.message);
          } else if (response.isAxiosError) {
            commit(AuthMutationTypes.SET_REGISTER_ERROR, 0);
            commit(AuthMutationTypes.SET_REGISTER_ERROR_MESSAGE, response.message);
          }
        } else {
          commit(AuthMutationTypes.SET_REGISTER_ERROR, null);
          commit(AuthMutationTypes.SET_REGISTER_ERROR_MESSAGE, null);
        }
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  },

  async [AuthActionTypes.LOGIN_ACTION]({ commit, dispatch }, data) {
    debugger; // eslint-disable-line
    await http
      .post(Config.api.account.login, data)
      .then((response: any) => {
        debugger; // eslint-disable-line
        if (response && (response.isAxiosError || response.data.error)) {
          commit(AuthMutationTypes.SET_IS_AUTHENTICATED, false);
          if (response.data) {
            commit(AuthMutationTypes.SET_LOGIN_ERROR, response.data.error);
            commit(AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, response.data.message);
          } else if (response.isAxiosError) {
            commit(AuthMutationTypes.SET_LOGIN_ERROR, 0);
            commit(AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, response.message);
          }
          commit(AuthMutationTypes.SET_USER, null);
        } else {
          //
          commit(AuthMutationTypes.SET_IS_AUTHENTICATED, true);
          commit(AuthMutationTypes.SET_LOGIN_ERROR, null);
          commit(AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, null);
          commit(AuthMutationTypes.SET_USER, response.data.user);
          // commit(
          //   AuthMutationTypes.SET_SETACCESSTOKEN,
          //   response.data.accessToken
          // );
          // commit(
          //   AuthMutationTypes.SET_SETREFRESHTOKEN,
          //   response.data.refreshToken
          // );

          dispatch(AuthActionTypes.UPDATE_TOKENS, response.data);
          commit(AuthMutationTypes.SET_SCOPE, response.data.scope);
        }
      })
      .catch((err: AxiosError) => {
        debugger; // eslint-disable-line
        console.log(err);
      });
  },

  async [AuthActionTypes.LOADCURRENTUSER_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.account.currentuser)
        .then(async (response: any) => {
          commit(AuthMutationTypes.SET_LOADING_USER, true);
          commit(AuthMutationTypes.SET_USER_LOADED, false);

          if (response && (response.isAxiosError || response.data.error)) {
            commit(AuthMutationTypes.SET_IS_AUTHENTICATED, false);
            if (response.data) {
              commit(AuthMutationTypes.SET_LOGIN_ERROR, response.data.error);
              commit(AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, response.data.message);
            } else if (response.isAxiosError) {
              commit(AuthMutationTypes.SET_LOGIN_ERROR, 0);
              commit(AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, response.message);
            }
            commit(AuthMutationTypes.SET_USER, null);
            commit(AuthMutationTypes.SET_LOADING_USER, false);
            commit(AuthMutationTypes.SET_USER_LOADED, false);
            reject(response);
          } else {
            commit(AuthMutationTypes.SET_IS_AUTHENTICATED, true);
            commit(AuthMutationTypes.SET_LOGIN_ERROR, null);
            commit(AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, null);

            // await http
            //   .get(
            //     Config.api.account.userOrganisations.replace(
            //       "{ownerId}",
            //       response.data._id
            //     )
            //   )
            //   .then((orgresponse: any) => {
            //     if (
            //       orgresponse &&
            //       (orgresponse.isAxiosError || orgresponse.data.error)
            //     ) {
            //       commit(AuthMutationTypes.SET_IS_AUTHENTICATED, false);
            //       if (orgresponse.data) {
            //         commit(
            //           AuthMutationTypes.SET_LOGIN_ERROR,
            //           orgresponse.data.error
            //         );
            //         commit(
            //           AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE,
            //           orgresponse.data.message
            //         );
            //       } else if (orgresponse.isAxiosError) {
            //         commit(AuthMutationTypes.SET_LOGIN_ERROR, 0);
            //         commit(
            //           AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE,
            //           orgresponse.message
            //         );
            //       }
            //       commit(AuthMutationTypes.SET_USER, null);
            //       commit(AuthMutationTypes.SET_LOADING_USER, false);
            //       commit(AuthMutationTypes.SET_USER_LOADED, false);
            //       reject(orgresponse);
            //     } else {
            //       commit(AuthMutationTypes.SET_IS_AUTHENTICATED, true);
            //       commit(AuthMutationTypes.SET_LOGIN_ERROR, null);
            //       commit(AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, null);
            //       if (
            //         orgresponse &&
            //         orgresponse.data.docs &&
            //         orgresponse?.data?.docs?.length > 0
            //       ) {
            //         response.data.organisation = orgresponse.data.docs[0];
            //         commit(AuthMutationTypes.SET_NOORGANISATION, false);
            //       } else {
            //         commit(AuthMutationTypes.SET_NOORGANISATION, true);
            //       }
            //       commit(AuthMutationTypes.SET_USER, response.data);
            //       commit(AuthMutationTypes.SET_LOADING_USER, false);
            //       commit(AuthMutationTypes.SET_USER_LOADED, true);
            //       resolve(orgresponse);
            //     }
            //   })
            //   .catch((orgerr) => {
            //     console.log(orgerr);
            //     reject(orgerr);
            //   });

            commit(AuthMutationTypes.SET_IS_AUTHENTICATED, true);
            commit(AuthMutationTypes.SET_LOGIN_ERROR, null);
            commit(AuthMutationTypes.SET_LOGIN_ERROR_MESSAGE, null);
            commit(AuthMutationTypes.SET_USER, response.data);
            commit(AuthMutationTypes.SET_LOADING_USER, false);
            commit(AuthMutationTypes.SET_USER_LOADED, true);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async [AuthActionTypes.LOGOUT_ACTION]({ commit }) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    await http
      .post(Config.api.account.logout)
      .then(() => {
        commit(AuthMutationTypes.LOGOUT, null);
        window.location.href = "/login";
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  },

  async [AuthActionTypes.VERIFY_ACTION]({ commit }, payload) {
    await http
      .post(Config.api.account.verify, { code: payload })
      .then((response: AxiosResponse) => {
        if (response) commit(AuthMutationTypes.SET_VERIFIED, true);
        else commit(AuthMutationTypes.SET_VERIFIED, false);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  },

  // new actions from appy

  // [AuthActionTypes.UPDATE_TOKENS]({ commit }, { accessToken, refreshToken }) {
  // updateTokens({ commit }, { accessToken, refreshToken }) {
  [AuthActionTypes.UPDATE_TOKENS]({ commit }, { accessToken, refreshToken }) {
    // axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken

    http.updateAccessToken(accessToken);
    // wsClient.client.overrideReconnectionAuth({
    //   headers: { authorization: 'Bearer' + accessToken }
    // })

    commit(AuthMutationTypes.SET_ACCESS_TOKEN, accessToken);
    commit(AuthMutationTypes.SET_REFRESH_TOKEN, refreshToken);

    console.debug("Tokens updated");
  },

  [AuthActionTypes.USE_REFRESH_TOKEN]({ state }) {
    // [AuthActionTypes.USE_REFRESH_TOKEN]({ commit, dispatch }, { state }) {

    // useRefreshToken({ state }) {
    // axios.defaults.headers.common.Authorization = 'Bearer ' + state.refreshToken
    // debugger; // eslint-disable-line
    http.useRefreshToken(state.refreshToken);

    // wsClient.client.overrideReconnectionAuth({
    //   headers: { authorization: 'Bearer' + state.refreshToken }
    // })

    console.debug("Using refresh token");
  },

  [AuthActionTypes.SET_AUTH]({ commit, dispatch }, data) {
    // setAuth({ commit, dispatch }, data) {
    // dispatch('updateTokens', data)
    // debugger; // eslint-disable-line
    dispatch(AuthActionTypes.UPDATE_TOKENS, data);
    commit(AuthMutationTypes.SET_SCOPE, data.scope);
    commit(AuthMutationTypes.SET_USER, data.user);
    commit(AuthMutationTypes.SET_HASSUBSCRIPTION, data.hasSubscription);
    // commit(AuthMutationTypes.SET_SCOPE, data.scope)
    commit(AuthMutationTypes.SET_IS_AUTHENTICATED, true);

    // wsClient.connect()
  },

  [AuthActionTypes.CLEAR_AUTH]({ commit }) {
    // clearAuth({ commit, dispatch }) {
    // axios.defaults.headers.common.Authorization = false
    http.clearAuth();

    commit(AuthMutationTypes.CLEAR_ACCESS_TOKEN);
    commit(AuthMutationTypes.CLEAR_REFRESH_TOKEN);
    commit(AuthMutationTypes.CLEAR_SCOPE);
    commit(AuthMutationTypes.CLEAR_USER);
    commit(AuthMutationTypes.SET_IS_AUTHENTICATED, false);

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    console.debug("Clearing auth");
    commit(AuthMutationTypes.RESET);

    // dispatch('websocket/disconnect', null, { root: true })
    // wsClient.disconnect()
  },

  // async [UserActionTypes.SAVE_USER_ACTION](
  //   { commit, dispatch }: AugmentedActionContext,
  //   payload: User
  // ) {
  async [AuthActionTypes.SAVE_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: User
  ) {
    // commit;
    let _payload = JSON.parse(JSON.stringify(payload));
    _payload.organisation = _payload.organisation._id;
    _payload.role = _payload.role._id;
    _payload = omit(_payload, [
      "password",
      "pin",
      "isActive",
      "createdAt",
      "createdBy",
      "isEnabled",
      "passwordUpdateRequired",
      "pinUpdateRequired",
      "activateAccountHash",
      "avatarUrl",
      "updatedAt",
      "updatedBy",
      "roleName",
      "roleRank",
      "resetPassword",
      "role",
      "__v",
    ]);
    // debugger; // eslint-disable-line
    commit(AuthMutationTypes.SET_SAVING_USER, true);
    commit(AuthMutationTypes.SET_USER_SAVED, false);
    //await axios.put(Config.api.account.user + `/${payload.id}`, payload)
    await http
      .put(Config.api.user.user + "/" + _payload._id, _payload)
      .then((Response:AxiosResponse) => {
        commit(AuthMutationTypes.SET_SAVING_USER, false);
        commit(AuthMutationTypes.SET_USER_SAVED, true);
        // debugger; // eslint-disable-line
      })
      // .then((response:AxiosResponse) => {
      //   // commit(UserMutationTypes.SET_USER, response.data);
      //   // dispatch(AuthActionTypes.LOADCURRENTUSER_ACTION);
      // })
      .catch((err:any) => {
        // debugger; // eslint-disable-line

        commit(AuthMutationTypes.SET_SAVING_USER, false);
        commit(AuthMutationTypes.SET_USER_SAVED, false);
        commit(AuthMutationTypes.SET_USER_SAVE_ERROR, err.data.statusCode);
        commit(AuthMutationTypes.SET_USER_SAVE_ERROR_MESSAGE, err.data.message);
        console.log("error", err);
      });
  },



  async [AuthActionTypes.SEND_FORGOT_PASSWORD_REQUEST]({ commit }, data) {
    debugger; // eslint-disable-line
    // await http
    //   .post(Config.api.account.login, data)


  // async [AuthActionTypes.SEND_FORGOT_PASSWORD_REQUEST]({ commit }) {
    commit(AuthMutationTypes.SET_FORGOT_PASS_SENDING_REQUEST, true);
    commit(AuthMutationTypes.SET_FORGOT_PASS_SUCCESS, false);
    commit(AuthMutationTypes.SET_FORGOT_PASS_ERROR_MESSAGE, null);
    await http
      .post(Config.api.account.forgotPass, data)
      .then(() => {
        commit(AuthMutationTypes.SET_FORGOT_PASS_SENDING_REQUEST, false);
        commit(AuthMutationTypes.SET_FORGOT_PASS_SUCCESS, true);
        commit(AuthMutationTypes.SET_FORGOT_PASS_ERROR_MESSAGE, null);
        // window.location.href = "/login";
      })
      .catch((err: AxiosError) => {
        console.log(err);
        commit(AuthMutationTypes.SET_FORGOT_PASS_SENDING_REQUEST, false);
        commit(AuthMutationTypes.SET_FORGOT_PASS_SUCCESS, false);
        commit(AuthMutationTypes.SET_FORGOT_PASS_ERROR_MESSAGE, err.message);
      });
  },

  async [AuthActionTypes.SEND_RESET_PASSWORD_REQUEST]({ commit }, data) {
    debugger; // eslint-disable-line
    // await http
    //   .post(Config.api.account.login, data)


  // async [AuthActionTypes.SEND_RESET_PASSWORD_REQUEST]({ commit }) {
    commit(AuthMutationTypes.SET_RESET_PASS_SENDING_REQUEST, true);
    commit(AuthMutationTypes.SET_RESET_PASS_SUCCESS, false);
    commit(AuthMutationTypes.SET_RESET_PASS_ERROR_MESSAGE, null);
    await http
      .post(Config.api.account.resetPass, data)
      .then(() => {
        commit(AuthMutationTypes.SET_RESET_PASS_SENDING_REQUEST, false);
        commit(AuthMutationTypes.SET_RESET_PASS_SUCCESS, true);
        commit(AuthMutationTypes.SET_RESET_PASS_ERROR_MESSAGE, null);
        // window.location.href = "/login";
      })
      .catch((err: AxiosError) => {
        console.log(err);
        commit(AuthMutationTypes.SET_RESET_PASS_SENDING_REQUEST, false);
        commit(AuthMutationTypes.SET_RESET_PASS_SUCCESS, false);
        commit(AuthMutationTypes.SET_RESET_PASS_ERROR_MESSAGE, err.message);
      });
  },
};
