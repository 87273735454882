// import { getters } from './../auth/getters';
import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { NotificationMessage } from '@/types/index';
import { NotificationState as State } from "./state";

export type NotificationGetters = {
  StateNotificationIsOpen(state: State): Boolean;
  StateNotifications(state: State): NotificationMessage[];
};

export const getters: GetterTree<State, RootState> & NotificationGetters = {
  StateNotifications: (state: State) => state.notifications,
  StateNotificationIsOpen: (state: State) => state.notificationOpenState
};
