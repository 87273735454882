import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';
import { Tag } from '@/types/index';

export type Getters = {
  StateTags(state: State): Tag[],
  StateIsLoadingTags(state: State): boolean,
  StateIsTagsLoaded(state: State): boolean,
}

export const getters: GetterTree<State, RootState> & Getters = {
  StateTags: state => state.tags,
  StateIsLoadingTags: state => state.isLoadingTags,
  StateIsTagsLoaded: state => state.isTagsLoaded
};
