import { MutationTree } from 'vuex';
import { State, defaultState } from './state';
import { UserMutationTypes as MutationTypes } from './mutation-types';
import { User } from '@/types/index';

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;

  [MutationTypes.SET_USERS](state: S, payload: User[]): void;
  [MutationTypes.SET_IS_LOADING_USERS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_USERS_LOADED](state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {

  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },
  
  [MutationTypes.SET_USERS](state, users) {
    state.users = users
  },

  [MutationTypes.SET_IS_LOADING_USERS](state, isLoadingUsers) {
    state.isLoadingUsers = isLoadingUsers
  },

  [MutationTypes.SET_IS_USERS_LOADED](state, isUsersLoaded) {
    state.isUsersLoaded = isUsersLoaded
  }

};
